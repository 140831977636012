import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import moment from 'moment';

import {
  Avatar,
  Box,
  Card,
  Typography,
  makeStyles,
  Dialog,
  DialogContent,
  Button,
  Snackbar,
} from '@material-ui/core';
import getInitials from 'src/utils/getInitials';
import { consoleError, getImageUrl } from 'src/utils/helper';
import MaterialTable from 'material-table';
import { tableIcons } from 'src/components/common/TableIcons';
import { Edit as EditIcon, Trash2 as DeleteIcon } from 'react-feather';
import adminService from 'src/services/adminService';
import { useDispatch, useSelector } from 'react-redux';
import { DialogActions, DialogTitle } from 'src/components/CustomDialog';
import { Alert } from '@material-ui/lab';
import AddUser from './AddUser';
import { useNavigate } from 'react-router';
import { useParams } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {},
  avatar: {
    marginRight: theme.spacing(2),
  },
}));

const UserTable = ({ className, ...rest }) => {
  const navigate = useNavigate();
  const classes = useStyles();
  const dispatch = useDispatch();

  const users = useSelector((state) => state.admin.users);
  const userProfile = useSelector((state) => state.user.profile);
  const { slug } = useParams();

  const filterUser =
    users &&
    users.filter((data) => {
      return data.role === slug;
    });

  useEffect(() => {
    async function getUsers() {
      try {
        adminService.getUsers(dispatch);
      } catch (error) {
        console.error(error);
      }
    }
    getUsers();
  }, []);

  const [updateModal, setUpdateModal] = useState(false);

  const [updateUser, setUpdateUser] = useState(null);

  const openUpdateModal = () => {
    setUpdateModal(true);
  };
  const closeUpdateModal = () => {
    setUpdateModal(false);
  };
  const [deleteModal, setDeleteModal] = useState(false);

  const [deleteUser, setDeleteUser] = useState(null);

  const openDeleteModal = () => {
    setDeleteModal(true);
  };
  const closeDeleteModal = () => {
    setDeleteModal(false);
    setDeleteUser(null);
  };

  const handleDeleteUser = async () => {
    if (userProfile.role === 'admin') {
      try {
        const res = await adminService.deactivateUser(deleteUser._id, dispatch);
        if (res && res.ok) {
          closeDeleteModal();
          setDeleteSuccess(true);
          await adminService.getUsers(dispatch);
        }
      } catch (error) {
        consoleError(error);
        closeDeleteModal();
        setDeleteError(true);
      }
    } else {
      try {
        await adminService.deleteUser(deleteUser._id, dispatch);
        closeDeleteModal();
        setDeleteSuccess(true);
      } catch (error) {
        consoleError(error);
        closeDeleteModal();
        setDeleteError(true);
      }
    }
  };

  const [deleteSuccess, setDeleteSuccess] = useState(false);
  const [deleteError, setDeleteError] = useState(false);

  const rowClickHandler = (event, rowData) => {
    event.stopPropagation();
    console.log(rowData);
    navigate(`/users/${rowData._id}`);
  };

  const [userDeleteError, setUserDeleteError] = useState(false);

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <Box maxWidth={'95%'}>
        <MaterialTable
          icons={tableIcons}
          columns={[
            {
              title: 'Name',
              field: 'name',
              filtering: false,
              // eslint-disable-next-line react/display-name
              render: (rowData) => (
                <Box alignItems="center" display="flex">
                  <Avatar
                    className={classes.avatar}
                    variant="circular"
                    src={getImageUrl(rowData?.avatar)}
                  >
                    {getInitials(rowData?.name)}
                  </Avatar>
                  <Typography color="textPrimary" variant="body1">
                    {rowData?.name}
                  </Typography>
                </Box>
              ),
            },
            { title: 'Email', field: 'email', filtering: false },
            { title: 'Phone', field: 'phone', filtering: false },
            {
              title: 'Role',
              field: 'role',
            },
            {
              title: 'Registration date',
              field: 'createdAt',
              filtering: false,
              render: (rowData) =>
                moment(rowData.createdAt).format('DD/MM/YYYY'),
            },
          ]}
          data={filterUser}
          title="Users"
          actions={[
            {
              icon: EditIcon,
              tooltip: 'Edit User',
              onClick: (event, rowData) => {
                event.stopPropagation();
                setUpdateUser(rowData);
                openUpdateModal();
              },
            },
            {
              icon: DeleteIcon,
              tooltip: 'Delete User',
              onClick: (event, rowData) => {
                if (
                  userProfile.role === 'admin' &&
                  rowData.role === 'superadmin'
                ) {
                  setUserDeleteError(true);
                } else {
                  if (rowData._id === userProfile._id) {
                    setUserDeleteError(true);
                  } else {
                    event.stopPropagation();
                    setDeleteUser(rowData);
                    openDeleteModal();
                  }
                }
              },
            },
          ]}
          options={{
            rowStyle: {
              fontSize: '1rem',
              fontWeight: 'lighter',
              fontFamily: 'Roboto, Helvetica, Arial, sans-serifs',
            },
            actionsColumnIndex: -1,
            filtering: true,
          }}
          onRowClick={(event, rowData) => rowClickHandler(event, rowData)}
        />
      </Box>
      <Dialog open={updateModal} onClose={closeUpdateModal}>
        <DialogTitle onClose={closeUpdateModal}>Update User</DialogTitle>
        <DialogContent>
          <AddUser user={updateUser} />
        </DialogContent>
      </Dialog>
      <Dialog open={deleteModal} onClose={closeDeleteModal}>
        <DialogTitle onClose={closeDeleteModal}>Delete User</DialogTitle>
        <DialogContent>
          <Box
            height="50px"
            display="flex"
            alignItems="center"
            flexDirection="column"
          >
            <Typography variant="h6">
              Do you really want to{' '}
              {userProfile.role === 'admin'
                ? 'deactivate User?'
                : 'delete user?'}
            </Typography>
            <Typography variant="h6">
              &quot;
              <small>
                <i>{deleteUser?.name}</i>
              </small>
              &quot;
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDeleteModal} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDeleteUser} color="primary">
            Yes
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={deleteSuccess}
        autoHideDuration={3000}
        onClose={() => setDeleteSuccess(false)}
      >
        <Alert onClose={() => setDeleteSuccess(false)} severity="success">
          {userProfile.role === 'admin'
            ? 'User deactivated successfully.'
            : 'User deleted successfully.'}
        </Alert>
      </Snackbar>

      <Snackbar
        open={deleteError}
        autoHideDuration={3000}
        onClose={() => setDeleteError(false)}
      >
        <Alert onClose={() => setDeleteError(false)} severity="error">
          {userProfile.role === 'admin'
            ? 'Error deactivating user.'
            : 'Error deleting user.'}
        </Alert>
      </Snackbar>

      <Snackbar
        open={userDeleteError}
        autoHideDuration={3000}
        onClose={() => setUserDeleteError(false)}
      >
        <Alert onClose={() => setUserDeleteError(false)} severity="error">
          {userProfile.role === 'admin'
            ? 'You cannnot deactivating this user.'
            : 'You cannot deleting this user.'}
        </Alert>
      </Snackbar>
    </Card>
  );
};

UserTable.propTypes = {
  className: PropTypes.string,
};

export default UserTable;
