import React, { useEffect, useState } from 'react';
import {
  Box,
  Breadcrumbs,
  Container,
  makeStyles,
  Typography,
  Grid,
  Card,
  TextField,
  Button,
  Divider,
  Snackbar,
  Dialog,
  DialogContent,
  Avatar,
} from '@material-ui/core';
import Page from 'src/components/Page';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { Link, useParams } from 'react-router-dom';

import { useNavigate } from 'react-router';
import moment from 'moment';
import MaterialTable from 'material-table';
import { tableIcons } from 'src/components/common/TableIcons';
import TabPanel from 'src/components/TabPanel';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { Edit as EditIcon, Trash2 as DeleteIcon } from 'react-feather';
import { DialogActions, DialogTitle } from 'src/components/CustomDialog';
import { Alert } from '@material-ui/lab';
import AddData from './AddData';
import adminService from 'src/services/adminService';
import { useDispatch, useSelector } from 'react-redux';
import api from '../../../services/api';
import { consoleError, getImageUrl } from 'src/utils/helper';
import getInitials from 'src/utils/getInitials';

const useStyles = makeStyles((theme) => ({
  root: {
    // backgroundColor: theme.palette.background.paper,
    // height: 'auto',
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(1),
  },
  orderDetailsCard: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2),
    minHeight: '60vh',
  },
  totalText: {
    fontWeight: 'bold',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  tabPan: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  avatar: {
    height: 200,
    width: 200,
  },
  phoneInput: {
    width: '100% !important',
  },
  addNewBtn: {
    marginBottom: '15px',
  },
  imagePP: {
    marginRight: theme.spacing(2),
  },
}));

const UserDetails = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user_id } = useParams();

  // const stories = useSelector((state) => state.admin.stories);
  const [userDetails, setUserDetails] = useState();
  const [userStories, setUserStories] = useState();
  const [userFriends, setUserFriends] = useState();
  const [userFriendRequest, setUserFriendRequest] = useState();
  const [userFriendRequestSends, setUserFriendRequestSends] = useState();
  // useEffect(() => {
  //   async function getStories() {
  //     try {
  //       adminService.getStories(dispatch);
  //     } catch (error) {
  //       console.error(error);
  //     }
  //   }
  //   getStories();
  //   getUserDetails();
  // }, []);
  // console.log(stories);
  useEffect(() => {
    getUserDetails();
    getUserStories();
    getUserFriends();
    getUserFriendRequest();
    getUserFriendRequestSend();
  }, []);

  const getUserStories = async () => {
    try {
      const res = await api.get(`/api/v1/admin/users/${user_id}/stories`);
      if (res) {
        // console.log(res.data);
        setUserStories(res.data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getUserFriends = async () => {
    try {
      const res = await api.get(`/api/v1/admin/users/${user_id}/friends`);
      if (res) {
        // console.log(res.data);
        setUserFriends(res.data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getUserFriendRequest = async () => {
    try {
      const res = await api.get(
        `/api/v1/admin/users/${user_id}/friends/requests`,
      );
      if (res) {
        // console.log(res.data);
        setUserFriendRequest(res.data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getUserFriendRequestSend = async () => {
    try {
      const res = await api.get(
        `/api/v1/admin/users/${user_id}/friends/requests/sent`,
      );
      if (res) {
        // console.log(res.data);
        setUserFriendRequestSends(res.data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getUserDetails = async () => {
    try {
      const res = await api.get(`/api/v1/admin/users/${user_id}`);
      if (res) {
        // console.log(res.data);
        setUserDetails(res.data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const classes = useStyles();
  const [tabValue, setTabValue] = React.useState(0);
  const handleTabChange = (event, newTabValue) => {
    setTabValue(newTabValue);
  };

  const rowClickStoryDetailsHandler = (event, rowData) => {
    event.stopPropagation();
    console.log(rowData);
    navigate(`/users/story/${rowData._id}`);
  };

  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteData, setDeleteData] = useState({
    functionName: '',
    data: '',
  });

  const [deleteSuccess, setDeleteSuccess] = useState(false);
  const [deleteError, setDeleteError] = useState(false);

  const openDeleteModal = (deleteStory, rowData) => {
    setDeleteData({
      functionName: deleteStory,
      data: rowData,
    });
    setDeleteModal(true);
  };
  const closeDeleteModal = () => {
    setDeleteModal(false);
    setDeleteData({
      functionName: '',
      data: '',
    });
  };

  const handleDeleteData = () => {
    console.log(deleteData);
    closeDeleteModal();
    setDeleteSuccess(true);
    // setDeleteError(true);
  };

  // DELETE STORY
  const [deleteStoryModal, setDeleteStoryModal] = useState(false);

  const [deleteStory, setDeleteStory] = useState(null);

  const openDeleteStoryModal = () => {
    setDeleteStoryModal(true);
  };
  const closeDeleteStoryModal = () => {
    setDeleteStoryModal(false);
    setDeleteStory(null);
  };

  const handleDeleteStory = async () => {
    try {
      await adminService.deleteStory(deleteStory._id, dispatch);
      closeDeleteStoryModal();
      setDeleteSuccess(true);
    } catch (error) {
      consoleError(error);
      closeDeleteStoryModal();
      setDeleteError(true);
    }
  };
  // DELETE STORY ENDS

  // Update states and functions
  const [updateModal, setUpdateModal] = useState(false);
  const [updateData, setUpdateData] = useState({
    functionName: '',
    data: '',
  });
  const openUpdateUser = (funtionName, rowData) => {
    setUpdateData({
      functionName: funtionName,
      data: rowData,
    });
    setUpdateModal(true);
  };

  const closeUpdateModal = () => {
    setUpdateModal(false);
  };

  // Update State and functions ends
  const [addModal, setAddModal] = useState(false);
  const [action, setAction] = useState();
  const [field, setField] = useState();
  const openAddModal = (functionName, fields) => {
    setAction(functionName);
    setField(fields);
    setAddModal(true);
  };
  const closeAddModal = () => {
    setAddModal(false);
  };

  return (
    <>
      <Page className={classes.root} title="Order Details">
        <Box
          display="flex"
          flexDirection="column"
          height="100%"
          justifyContent="center"
        >
          <Container maxWidth="lg">
            <Box my={2}>
              <Typography variant="h2">User Details</Typography>
            </Box>
            <Box my={2}>
              <Breadcrumbs
                separator={<NavigateNextIcon fontSize="small" />}
                aria-label="breadcrumb"
              >
                <Link color="gray" to="/">
                  Dashboard
                </Link>
                <Link
                  color="grey"
                  to={`/users/role/${userDetails ? userDetails.role : ''}`}
                >
                  users
                </Link>
                <Typography color="textPrimary">User Details</Typography>
              </Breadcrumbs>
            </Box>
            <Card className={classes.orderDetailsCard}>
              <Grid item xs={12} style={{ marginBottom: '20px' }}>
                <Typography variant="h5">User Details</Typography>
              </Grid>
              <Grid
                container
                alignItems="flex-start"
                justify="center"
                spacing={2}
              >
                <Grid
                  item
                  lg={4}
                  md={4}
                  xs={12}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%',
                    marginBottom: '20px',
                  }}
                >
                  <Avatar className={classes.avatar} src={''} />
                </Grid>
                <Grid
                  item
                  lg={8}
                  md={8}
                  xs={12}
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginBottom: '20px',
                  }}
                >
                  <Grid
                    item
                    md={12}
                    xs={12}
                    style={{
                      margin: '10px',
                      display: 'flex',
                      // backgroundColor: 'red',
                    }}
                  >
                    <Grid item md={6} xs={6}>
                      <Typography
                        style={{
                          marginTop: 5,
                          marginBottom: 2,
                          color: 'grey',
                        }}
                        variant="h4"
                      >
                        Name
                      </Typography>
                      <Typography
                        style={{
                          marginTop: 5,
                          marginBottom: 2,
                          fontWeight: 'bold',
                        }}
                        variant="h4"
                      >
                        <p>
                          {userDetails && userDetails.name
                            ? userDetails.name
                            : ''}
                        </p>
                      </Typography>
                    </Grid>
                    <Grid item md={6} xs={6}>
                      <Typography
                        style={{
                          marginTop: 5,
                          marginBottom: 2,
                          color: 'grey',
                        }}
                        variant="h4"
                      >
                        Email
                      </Typography>
                      <Typography
                        style={{
                          marginTop: 5,
                          marginBottom: 2,
                          fontWeight: 'bold',
                        }}
                        variant="h4"
                      >
                        <p>
                          {userDetails && userDetails.email
                            ? userDetails.email
                            : ''}
                        </p>
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    md={12}
                    xs={12}
                    style={{
                      margin: '10px',
                      display: 'flex',
                    }}
                  >
                    <Grid item md={6} xs={6}>
                      <Typography
                        style={{
                          marginTop: 5,
                          marginBottom: 2,
                          color: 'grey',
                        }}
                        variant="h4"
                      >
                        Phone Number
                      </Typography>
                      <Typography
                        style={{
                          marginTop: 5,
                          marginBottom: 2,
                          fontWeight: 'bold',
                        }}
                        variant="h4"
                      >
                        <p>
                          {userDetails && userDetails.phone
                            ? userDetails.phone
                            : ''}
                        </p>
                      </Typography>
                    </Grid>
                    <Grid item md={6} xs={6}>
                      <Typography
                        style={{
                          marginTop: 5,
                          marginBottom: 2,
                          color: 'grey',
                        }}
                        variant="h4"
                      >
                        Status
                      </Typography>
                      <Typography
                        style={{
                          marginTop: 5,
                          marginBottom: 2,
                          fontWeight: 'bold',
                        }}
                        variant="h4"
                      >
                        <p>
                          {userDetails && userDetails.status
                            ? userDetails.status
                            : ''}
                        </p>
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <AppBar position="static">
                  <Tabs
                    value={tabValue}
                    onChange={handleTabChange}
                    aria-label="User Details View Tab"
                  >
                    <Tab label="Stories" />
                    <Tab label="Friends" />
                    <Tab label="Friend Request" />
                    <Tab label="Sends Request" />
                    <Tab label="Subscription" />
                    <Tab label="Invitation" />
                  </Tabs>
                </AppBar>
                <TabPanel
                  value={tabValue}
                  index={0}
                  style={{
                    flex: '1',
                  }}
                >
                  {/* <Button
                    className={classes.addNewBtn}
                    color="primary"
                    variant="contained"
                    onClick={() =>
                      openAddModal('adminAddStory', {
                        user: '',
                        privacy: '',
                        likes: '',
                        comments: '',
                      })
                    }
                  >
                    Add New Story
                  </Button> */}
                  <MaterialTable
                    icons={tableIcons}
                    columns={[
                      {
                        title: 'Name',
                        field: 'name',
                        filtering: false,
                        // eslint-disable-next-line react/display-name
                        render: (rowData) => (
                          <Box alignItems="center" display="flex">
                            <Avatar
                              className={classes.imagePP}
                              variant="circular"
                              src={getImageUrl(rowData?.imageUrl)}
                            >
                              {getInitials(rowData?.user)}
                            </Avatar>
                            <Typography color="textPrimary" variant="body1">
                              {rowData?.user}
                            </Typography>
                          </Box>
                        ),
                      },
                      {
                        title: 'Privacy',
                        field: 'privacy',
                      },
                      {
                        title: 'Created At',
                        field: 'createdAt',
                        render: (rowData) =>
                          moment(rowData.createdAt).format('DD/MM/YYYY h:mm A'),
                      },
                    ]}
                    data={userStories}
                    actions={[
                      {
                        icon: EditIcon,
                        tooltip: 'Edit',
                        onClick: (event, rowData) => {
                          event.stopPropagation();
                          navigate(`/story/${rowData._id}/edit`);
                        },
                      },
                      {
                        icon: DeleteIcon,
                        tooltip: 'Delete',
                        onClick: (event, rowData) => {
                          event.stopPropagation();
                          setDeleteStory(rowData);
                          openDeleteStoryModal();
                        },
                      },
                    ]}
                    title="Stories"
                    options={{
                      rowStyle: {
                        fontSize: '1rem',
                        fontWeight: 'lighter',
                        fontFamily: 'Roboto, Helvetica, Arial, sans-serifs',
                      },
                      actionsColumnIndex: -1,
                    }}
                    // onRowClick={(event, rowData) =>
                    //   rowClickStoryDetailsHandler(event, rowData)
                    // }
                    onRowClick={(event, rowData) =>
                      navigate(`/story/${rowData._id}`)
                    }
                  />
                </TabPanel>
                <TabPanel
                  value={tabValue}
                  index={1}
                  style={{
                    flex: '1',
                  }}
                >
                  {/* <Button
                    className={classes.addNewBtn}
                    color="primary"
                    variant="contained"
                    onClick={() =>
                      openAddModal('adminAddFriend', {
                        name: '',
                        phone: '',
                        address: '',
                        email: '',
                      })
                    }
                  >
                    Add New Friend
                  </Button> */}
                  <MaterialTable
                    icons={tableIcons}
                    columns={[
                      { title: 'Name', field: 'name' },
                      // { title: 'Phone', field: 'phone' },
                      // {
                      //   title: 'address',
                      //   field: 'address',
                      // },
                      // {
                      //   title: 'email',
                      //   field: 'email',
                      // },
                    ]}
                    data={userFriends}
                    actions={[
                      {
                        icon: EditIcon,
                        tooltip: 'Edit',
                        onClick: (event, rowData) => {
                          event.stopPropagation();
                          openUpdateUser('adminUpdateFriend', rowData);
                        },
                      },
                      {
                        icon: DeleteIcon,
                        tooltip: 'Delete',
                        onClick: (event, rowData) => {
                          event.stopPropagation();
                          openDeleteModal('adminDeleteFriend', rowData);
                        },
                      },
                    ]}
                    options={{
                      rowStyle: {
                        fontSize: '1rem',
                        fontWeight: 'lighter',
                        fontFamily: 'Roboto, Helvetica, Arial, sans-serifs',
                      },
                      actionsColumnIndex: -1,
                    }}
                    title="Friends"
                  />
                </TabPanel>
                <TabPanel
                  value={tabValue}
                  index={2}
                  style={{
                    flex: '1',
                  }}
                >
                  {/* <Button
                    className={classes.addNewBtn}
                    color="primary"
                    variant="contained"
                    onClick={() =>
                      openAddModal('adminAddFriendRequest', {
                        name: '',
                        phone: '',
                        address: '',
                        email: '',
                      })
                    }
                  >
                    Add New Friend Request
                  </Button> */}
                  <MaterialTable
                    icons={tableIcons}
                    columns={[
                      { title: 'Name', field: 'name' },
                      // { title: 'Phone', field: 'phone' },
                      // {
                      //   title: 'address',
                      //   field: 'address',
                      // },
                      // {
                      //   title: 'email',
                      //   field: 'email',
                      // },
                    ]}
                    data={userFriendRequest}
                    actions={[
                      {
                        icon: EditIcon,
                        tooltip: 'Edit',
                        onClick: (event, rowData) => {
                          event.stopPropagation();
                          openUpdateUser('adminUpdateFriendRequest', rowData);
                        },
                      },
                      {
                        icon: DeleteIcon,
                        tooltip: 'Delete',
                        onClick: (event, rowData) => {
                          event.stopPropagation();
                          openDeleteModal('adminDeleteFriendRequest', rowData);
                        },
                      },
                    ]}
                    options={{
                      rowStyle: {
                        fontSize: '1rem',
                        fontWeight: 'lighter',
                        fontFamily: 'Roboto, Helvetica, Arial, sans-serifs',
                      },
                      actionsColumnIndex: -1,
                    }}
                    title="Friends Request"
                  />
                </TabPanel>
                <TabPanel
                  value={tabValue}
                  index={3}
                  style={{
                    flex: '1',
                  }}
                >
                  {/* <Button
                    className={classes.addNewBtn}
                    color="primary"
                    variant="contained"
                    onClick={() =>
                      openAddModal('adminAddSendRequest', {
                        name: '',
                        phone: '',
                        address: '',
                        email: '',
                      })
                    }
                  >
                    Add New Send Request
                  </Button> */}
                  <MaterialTable
                    icons={tableIcons}
                    columns={[
                      { title: 'Name', field: 'name' },
                      // { title: 'Phone', field: 'phone' },
                      // {
                      //   title: 'address',
                      //   field: 'address',
                      // },
                      // {
                      //   title: 'email',
                      //   field: 'email',
                      // },
                    ]}
                    data={userFriendRequestSends}
                    actions={[
                      {
                        icon: EditIcon,
                        tooltip: 'Edit',
                        onClick: (event, rowData) => {
                          event.stopPropagation();
                          openUpdateUser('adminUpdateSendRequest', rowData);
                        },
                      },
                      {
                        icon: DeleteIcon,
                        tooltip: 'Delete',
                        onClick: (event, rowData) => {
                          event.stopPropagation();
                          openDeleteModal('adminDeleteSendRequest', rowData);
                        },
                      },
                    ]}
                    options={{
                      rowStyle: {
                        fontSize: '1rem',
                        fontWeight: 'lighter',
                        fontFamily: 'Roboto, Helvetica, Arial, sans-serifs',
                      },
                      actionsColumnIndex: -1,
                    }}
                    title="Send Request"
                  />
                </TabPanel>
                <TabPanel
                  value={tabValue}
                  index={4}
                  style={{
                    flex: '1',
                  }}
                >
                  <Button
                    className={classes.addNewBtn}
                    color="primary"
                    variant="contained"
                    onClick={() =>
                      openAddModal('adminAddSubscription', {
                        user: '',
                        package: '',
                        subscribedOn: '',
                      })
                    }
                  >
                    Add New Subscription
                  </Button>
                  <MaterialTable
                    icons={tableIcons}
                    columns={[
                      { title: 'User', field: 'user' },
                      { title: 'Package', field: 'package' },
                      {
                        title: 'Subscribed On',
                        field: 'subscribedOn',
                      },
                    ]}
                    data={[
                      {
                        user: 'User user',
                        package: 'USA',
                        subscribedOn: '2021/06/17',
                      },
                    ]}
                    actions={[
                      {
                        icon: EditIcon,
                        tooltip: 'Edit',
                        onClick: (event, rowData) => {
                          event.stopPropagation();
                          openUpdateUser('adminUpdateSubscription', rowData);
                        },
                      },
                      {
                        icon: DeleteIcon,
                        tooltip: 'Delete',
                        onClick: (event, rowData) => {
                          event.stopPropagation();
                          openDeleteModal('adminDeleteSubscription', rowData);
                        },
                      },
                    ]}
                    options={{
                      rowStyle: {
                        fontSize: '1rem',
                        fontWeight: 'lighter',
                        fontFamily: 'Roboto, Helvetica, Arial, sans-serifs',
                      },
                      actionsColumnIndex: -1,
                    }}
                    title="Subscription"
                  />
                </TabPanel>
                <TabPanel
                  value={tabValue}
                  index={5}
                  style={{
                    flex: '1',
                  }}
                >
                  {/* <Button
                    className={classes.addNewBtn}
                    color="primary"
                    variant="contained"
                    onClick={() =>
                      openAddModal('adminAddInvitation', {
                        user: '',
                        code: '',
                        invitedUser: '',
                        registeredUser: '',
                      })
                    }
                  >
                    Add New Invitation
                  </Button> */}
                  <MaterialTable
                    icons={tableIcons}
                    columns={[
                      { title: 'User', field: 'user' },
                      { title: 'Code', field: 'code' },
                      {
                        title: 'Invited User',
                        field: 'invitedUser',
                      },
                      {
                        title: 'Registered User',
                        field: 'registeredUser',
                      },
                    ]}
                    data={[
                      {
                        user: 'Test',
                        code: 'FTUN-1212',
                        invitedUser: 'User User',
                        registeredUser: '63',
                      },
                    ]}
                    actions={[
                      {
                        icon: EditIcon,
                        tooltip: 'Edit',
                        onClick: (event, rowData) => {
                          event.stopPropagation();
                          openUpdateUser('adminUpdateInvitation', rowData);
                        },
                      },
                      {
                        icon: DeleteIcon,
                        tooltip: 'Delete',
                        onClick: (event, rowData) => {
                          event.stopPropagation();
                          openDeleteModal('adminDeleteInvitation', rowData);
                        },
                      },
                    ]}
                    options={{
                      rowStyle: {
                        fontSize: '1rem',
                        fontWeight: 'lighter',
                        fontFamily: 'Roboto, Helvetica, Arial, sans-serifs',
                      },
                      actionsColumnIndex: -1,
                    }}
                    title="Invitation"
                  />
                </TabPanel>
              </Grid>
            </Card>
          </Container>
        </Box>

        <Dialog open={addModal} onClose={closeAddModal}>
          <DialogTitle onClose={closeAddModal}>Add Data</DialogTitle>
          <DialogContent>
            <AddData field={field} functionName={action} userId={user_id} />
          </DialogContent>
        </Dialog>

        <Dialog open={updateModal} onClose={closeUpdateModal}>
          <DialogTitle onClose={closeUpdateModal}>Update Data</DialogTitle>
          <DialogContent>
            <AddData data={updateData} userId={user_id} />
          </DialogContent>
        </Dialog>

        <Dialog open={deleteStoryModal} onClose={closeDeleteStoryModal}>
          <DialogTitle onClose={closeDeleteStoryModal}>
            Delete Story
          </DialogTitle>
          <DialogContent>
            <Box
              height="50px"
              display="flex"
              alignItems="center"
              flexDirection="column"
            >
              <Typography variant="h6">
                Do you really want to delete Story?
              </Typography>
              <Typography variant="h6">
                &quot;
                <small>
                  <i>{deleteStory?.name}</i>
                </small>
                &quot;
              </Typography>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={closeDeleteStoryModal} color="primary">
              Cancel
            </Button>
            <Button onClick={handleDeleteStory} color="primary">
              Yes
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog open={deleteModal} onClose={closeDeleteModal}>
          <DialogTitle onClose={closeDeleteModal}>Delete Data</DialogTitle>
          <DialogContent>
            <Box
              height="50px"
              display="flex"
              alignItems="center"
              flexDirection="column"
            >
              <Typography variant="h6">
                Do you really want to delete ?
              </Typography>
              {/* <Typography variant="h6">
                &quot;
                <small>
                  <i>{deleteData ? deleteData.data._id : ''}</i>
                </small>
                &quot;
              </Typography> */}
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={closeDeleteModal} color="primary">
              Cancel
            </Button>
            <Button onClick={handleDeleteData} color="primary">
              Yes
            </Button>
          </DialogActions>
        </Dialog>

        <Snackbar
          open={deleteSuccess}
          autoHideDuration={3000}
          onClose={() => setDeleteSuccess(false)}
        >
          <Alert onClose={() => setDeleteSuccess(false)} severity="success">
            Deleted successfully.
          </Alert>
        </Snackbar>

        <Snackbar
          open={deleteError}
          autoHideDuration={3000}
          onClose={() => setDeleteError(false)}
        >
          <Alert onClose={() => setDeleteError(false)} severity="error">
            Error deleting user.
          </Alert>
        </Snackbar>
      </Page>
    </>
  );
};
export default UserDetails;
