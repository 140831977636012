import api from './api';

import {
  adminGetUsers,
  adminAddUser,
  adminUpdateUser,
  adminDeleteUser,
  adminDeactivateUser,
  adminGetStories,
  adminUpdateStory,
  adminDeleteStory,
  adminGetSubscriptions,
  adminDeleteSubscription,
  adminAddPackage,
  adminGetPackages,
  adminUpdatePackage,
  adminDeletePackage,
  adminGetPoints,
  adminAddPoints,
  adminUpdatePoints,
  adminDeletePoints,
} from '../store/actions/adminActions';

const adminService = {
  // USER
  getUsers: async (dispatch) => {
    try {
      const res = await api.get('/api/v1/admin/users');
      dispatch(adminGetUsers(res.data));
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  addUser: async (data, dispatch) => {
    try {
      const res = await api.post('/api/v1/admin/users', data, {
        headers: {
          'content-type': 'multipart/form-data',
        },
      });
      dispatch(adminAddUser(res.data));
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  updateUser: async (_id, data, dispatch) => {
    try {
      const res = await api.patch(`/api/v1/admin/users/${_id}`, data, {
        headers: {
          'content-type': 'multipart/form-data',
        },
      });
      dispatch(adminUpdateUser(res.data));
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  deactivateUser: async (_id, dispatch) => {
    try {
      const res = await api.delete(`/api/v1/admin/users/${_id}/deactivate`);
      dispatch(adminDeactivateUser(_id));
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  deleteUser: async (_id, dispatch) => {
    try {
      const res = await api.delete(`/api/v1/admin/users/${_id}`);
      dispatch(adminDeleteUser(_id));
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  getTranslation: async (text) => {
    try {
      let res = await api.post('/api/v1/admin/translate/chinese', {
        text: text,
      });
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  // Story
  getStories: async (dispatch) => {
    try {
      const res = await api.get('/api/v1/admin/stories');
      if (res && res.ok) {
        dispatch(adminGetStories(res.data));
      }
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  getStory: async (id) => {
    try {
      const res = await api.get(`/api/v1/admin/stories/${id}`);
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  updateStory: async (_id, data, dispatch) => {
    try {
      const res = await api.patch(`/api/v1/admin/stories/${_id}`, data, {
        headers: {
          'content-type': 'multipart/form-data',
        },
      });
      dispatch(adminUpdateStory(res.data));
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  deleteStory: async (_id, dispatch) => {
    try {
      const res = await api.delete(`/api/v1/admin/stories/${_id}`);
      dispatch(adminDeleteStory(_id));
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  // Subscriptions
  getSubscriptions: async (dispatch) => {
    try {
      const res = await api.get('/api/v1/admin/subscriptions');
      dispatch(adminGetSubscriptions(res.data));
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  getSubscription: async (id) => {
    try {
      const res = await api.get(`/api/v1/admin/subscriptions/${id}`);
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  deleteSubscription: async (_id, dispatch) => {
    try {
      const res = await api.delete(`/api/v1/admin/subscriptions/${_id}`);
      dispatch(adminDeleteSubscription(_id));
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    }
  },

  // Packages
  addPackage: async (data, dispatch) => {
    try {
      const res = await api.post('/api/v1/admin/packages', data);
      dispatch(adminAddPackage(res.data));
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  getPackages: async (dispatch) => {
    try {
      const res = await api.get('/api/v1/admin/packages');
      dispatch(adminGetPackages(res.data));
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  getPackage: async (id) => {
    try {
      const res = await api.get(`/api/v1/admin/packages/${id}`);
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  updatePackage: async (_id, data, dispatch) => {
    try {
      const res = await api.patch(`/api/v1/admin/packages/${_id}`, data);
      dispatch(adminUpdatePackage(res.data));
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  deletePackage: async (_id, dispatch) => {
    try {
      const res = await api.delete(`/api/v1/admin/packages/${_id}`);
      dispatch(adminDeletePackage(_id));
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  checkUsername: async (username) => {
    try {
      const res = await api.post('/api/v1/usernames', {
        username,
      });
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  // Point System
  getPoints: async (dispatch) => {
    try {
      const res = await api.get('/api/v1/admin/points');
      if (res && res.ok) {
        dispatch(adminGetPoints(res.data));
      }
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  addPoints: async (data, dispatch) => {
    try {
      const res = await api.post('/api/v1/admin/points', data);
      dispatch(adminAddPoints(res.data));
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  updatePoints: async (_id, data, dispatch) => {
    try {
      const res = await api.patch(`/api/v1/admin/points/${_id}`, data);
      dispatch(adminUpdatePoints(res.data));
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  deletePoints: async (_id, dispatch) => {
    try {
      const res = await api.delete(`/api/v1/admin/points/${_id}`);
      dispatch(adminDeletePoints(_id));
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    }
  },
};

export default adminService;
