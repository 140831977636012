// USERS
export const ADMIN_GET_USERS = 'ADMIN_GET_USERS';
export const ADMIN_ADD_USER = 'ADMIN_ADD_USER';
export const ADMIN_UPDATE_USER = 'ADMIN_UPDATE_USER';
export const ADMIN_DELETE_USER = 'ADMIN_DELETE_USER';
export const ADMIN_DEACTIVATE_USER = 'ADMIN_DEACTIVATE_USER';

export function adminGetUsers(payload) {
  return { type: ADMIN_GET_USERS, payload };
}
export function adminAddUser(payload) {
  return { type: ADMIN_ADD_USER, payload };
}
export function adminUpdateUser(payload) {
  return { type: ADMIN_UPDATE_USER, payload };
}
export function adminDeleteUser(payload) {
  return { type: ADMIN_DELETE_USER, payload };
}
export function adminDeactivateUser(payload) {
  return { type: ADMIN_DEACTIVATE_USER, payload };
}

// Story
export const ADMIN_GET_STORIES = 'ADMIN_GET_STORIES';
export const ADMIN_UPDATE_STORY = 'ADMIN_UPDATE_STORY';
export const ADMIN_DELETE_STORY = 'ADMIN_DELETE_STORY';

export function adminGetStories(payload) {
  return { type: ADMIN_GET_STORIES, payload };
}
export function adminUpdateStory(payload) {
  return { type: ADMIN_UPDATE_STORY, payload };
}
export function adminDeleteStory(payload) {
  return { type: ADMIN_DELETE_STORY, payload };
}

// Subscription
export const ADMIN_GET_SUBSCRIPTIONS = 'ADMIN_GET_SUBSCRIPTIONS';
export const ADMIN_DELETE_SUBSCRIPTION = 'ADMIN_DELETE_SUBSCRIPTION';

export function adminGetSubscriptions(payload) {
  return { type: ADMIN_GET_SUBSCRIPTIONS, payload };
}
export function adminDeleteSubscription(payload) {
  return { type: ADMIN_DELETE_SUBSCRIPTION, payload };
}

// Packages
export const ADMIN_ADD_PACKAGE = 'ADMIN_ADD_PACKAGE';
export const ADMIN_GET_PACKAGES = 'ADMIN_GET_PACKAGES';
export const ADMIN_UPDATE_PACKAGE = 'ADMIN_UPDATE_PACKAGE';
export const ADMIN_DELETE_PACKAGE = 'ADMIN_DELETE_PACKAGE';

export function adminAddPackage(payload) {
  return { type: ADMIN_ADD_PACKAGE, payload };
}
export function adminGetPackages(payload) {
  return { type: ADMIN_GET_PACKAGES, payload };
}
export function adminUpdatePackage(payload) {
  return { type: ADMIN_UPDATE_PACKAGE, payload };
}
export function adminDeletePackage(payload) {
  return { type: ADMIN_DELETE_PACKAGE, payload };
}

// Points
export const ADMIN_ADD_POINT = 'ADMIN_ADD_POINT';
export const ADMIN_GET_POINTS = 'ADMIN_GET_POINTS';
export const ADMIN_UPDATE_POINT = 'ADMIN_UPDATE_POINT';
export const ADMIN_DELETE_POINT = 'ADMIN_DELETE_POINT';

export function adminAddPoints(payload) {
  return { type: ADMIN_ADD_POINT, payload };
}
export function adminGetPoints(payload) {
  return { type: ADMIN_GET_POINTS, payload };
}
export function adminUpdatePoints(payload) {
  return { type: ADMIN_UPDATE_POINT, payload };
}
export function adminDeletePoints(payload) {
  return { type: ADMIN_DELETE_POINT, payload };
}
