import React, { useState } from 'react';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import getInitials from 'src/utils/getInitials';
import { consoleError, getImageUrl } from 'src/utils/helper';
import TabPanel from 'src/components/TabPanel';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import MaterialTable from 'material-table';
import { tableIcons } from 'src/components/common/TableIcons';
import Pagination from '@material-ui/lab/Pagination';

const useStyles = makeStyles((theme) => ({
  mainDiv: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    maxWidth: '100%',
  },
  root: {
    // width: '100%',
    // maxWidth: '100%',
    flex: '1',
    backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: 'inline',
  },
  heading: {
    backgroundColor: '#3A7BD5 !important',
    color: 'white',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  headingText: {
    fontSize: '24px',
  },
  rankingOrderNo: {
    fontSize: '24px',
    fontWeight: 'bold',
    marginRight: '20px',
    color: '#1F0304 !important',
  },
  listItem: {
    cursor: 'pointer',
  },
  pagination: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '20px',
  },
}));

const ranking = [
  {
    id: '1',
    name: 'Caroline Herschel',
    details: 'Other some detail',
    totalPoints: '1234 Points',
  },
  {
    id: '2',
    name: 'Chien-Shiung Wu',
    details: 'Other some detail',
    totalPoints: '1101 Points',
  },
  {
    id: '3',
    name: 'Enrico Fermi',
    details: 'Other some detail',
    totalPoints: '891 Points',
  },
  {
    id: '4',
    name: 'Frieda Robscheit',
    details: 'Other some detail',
    totalPoints: '884 Points',
  },
  {
    id: '5',
    name: 'Geraldine Seydoux',
    details: 'Other some detail',
    totalPoints: '764 Points',
  },
  {
    id: '6',
    name: 'Caroline',
    details: 'Other some detail',
    totalPoints: '1234 Points',
  },
  {
    id: '7',
    name: 'Chien Wu',
    details: 'Other some detail',
    totalPoints: '1101 Points',
  },
  {
    id: '8',
    name: 'Seydoux Fermi',
    details: 'Other some detail',
    totalPoints: '891 Points',
  },
  {
    id: '9',
    name: 'Wu Robscheit',
    details: 'Other some detail',
    totalPoints: '884 Points',
  },
  {
    id: '10',
    name: 'Fermi Seydoux',
    details: 'Other some detail',
    totalPoints: '764 Points',
  },
];

export default function Ranking() {
  const classes = useStyles();

  //   FOR PAGINATION
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(5);
  // Get current posts
  const indexOfLastRanking = currentPage * postsPerPage;
  const indexOfFirstRanking = indexOfLastRanking - postsPerPage;
  const slicedRankings = ranking.slice(indexOfFirstRanking, indexOfLastRanking);

  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(ranking.length / postsPerPage); i++) {
    pageNumbers.push(i);
  }

  const handleChange = (event, value) => {
    setCurrentPage(value);
  };
  //   FOR PAGINATION ENDS

  const rowClickHandler = (data) => {
    console.log(data);
  };

  const [tabValue, setTabValue] = useState(0);
  const handleTabChange = (event, newTabValue) => {
    setTabValue(newTabValue);
  };

  return (
    <div className={classes.mainDiv}>
      <AppBar position="static">
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          aria-label="User Details View Tab"
        >
          <Tab label="Table View" />
          <Tab label="List View" />
        </Tabs>
      </AppBar>

      <TabPanel
        value={tabValue}
        index={0}
        style={{
          flex: '1',
        }}
      >
        <MaterialTable
          icons={tableIcons}
          columns={[
            {
              title: 'Name',
              field: 'name',
              filtering: false,
              // eslint-disable-next-line react/display-name
              render: (rowData) => (
                <Box alignItems="center" display="flex">
                  <Avatar
                    className={classes.avatar}
                    variant="circular"
                    src={getImageUrl(rowData?.avatar)}
                  >
                    {getInitials(rowData?.name)}
                  </Avatar>
                  <Typography
                    color="textPrimary"
                    variant="body1"
                    style={{
                      marginLeft: '20px',
                    }}
                  >
                    {rowData?.name}
                  </Typography>
                </Box>
              ),
            },
            { title: 'Details', field: 'details', filtering: false },
            { title: 'Points', field: 'totalPoints', filtering: false },
          ]}
          data={ranking}
          title="Rankings"
          options={{
            rowStyle: {
              fontSize: '1rem',
              fontWeight: 'lighter',
              fontFamily: 'Roboto, Helvetica, Arial, sans-serifs',
            },
          }}
        />
      </TabPanel>

      <TabPanel value={tabValue} index={1}>
        <List className={classes.root}>
          <ListItem alignItems="center" className={classes.heading}>
            <Typography className={classes.headingText}>Rankings</Typography>
          </ListItem>
          {slicedRankings.map((data, index) => (
            <>
              <ListItem
                alignItems="center"
                className={classes.listItem}
                onClick={() => rowClickHandler(data)}
                key={index}
              >
                <Typography className={classes.rankingOrderNo}>
                  {data.id}
                </Typography>
                <ListItemAvatar>
                  <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg">
                    {getInitials(data.name)}
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={data.name}
                  secondary={<React.Fragment>{data.details}</React.Fragment>}
                />
                <Typography>{data.totalPoints}</Typography>
              </ListItem>
              <Divider />
            </>
          ))}
        </List>
        <Pagination
          className={classes.pagination}
          count={pageNumbers.length}
          variant="outlined"
          color="primary"
          onChange={handleChange}
        />
      </TabPanel>
    </div>
  );
}
