import {
  Box,
  Button,
  Container,
  Grid,
  LinearProgress,
  makeStyles,
  InputLabel,
  Breadcrumbs,
  MenuItem,
  Typography,
  Avatar,
  Select,
  FormControl,
} from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import { Field, Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Page from 'src/components/Page';
import adminService from 'src/services/adminService';
import { consoleError, getImageUrl } from 'src/utils/helper';
import * as yup from 'yup';
import { TextField, Switch } from 'formik-material-ui';
import FormPreviewDev from 'src/components/common/FormPreviewDev';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { useMatch, useNavigate, Link } from 'react-router-dom';
import CustomFileUpload from 'src/components/CustomFileUpload';
import CustomFileUploadServer from 'src/components/CustomFileUploadServer';
import countiresList from '../../Countries';
import continentList from '../../Continent';
import worldList from '../../World';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    // height: 'auto',
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(1),
  },
  switchField: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  dropDown: {
    height: 400,
  },
  selectField: {
    width: '100% !important',
    // padding: '15px !important',
    marginTop: '10px !important',
    // border: '2px #5664d2 solid',
  },
}));
function AddPackage() {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [update, setUpdate] = useState(false);
  const [errMsg, setErrMsg] = useState('');
  const [id, setId] = useState('');
  const [successMsg, setSuccessMsg] = useState('');

  const match = useMatch('/package/:id/edit');
  useEffect(() => {
    if (match && match.params && match.params.id) {
      setId(match.params.id);
      setUpdate(true);
    }
  }, [match]);

  const [uploadingImage, setUploadingImage] = useState(false);
  const packages = useSelector((state) => state.admin.packages);
  const packageData = packages.find((Item) => Item._id.toString() === id);
  // console.log(packageData);

  useEffect(() => {
    async function getPackages() {
      try {
        adminService.getPackages(dispatch);
      } catch (error) {
        console.error(error);
      }
    }
    getPackages();
  }, []);

  const addPackageSchema = yup.object().shape({
    name: yup
      .string()
      .required('Name is required.')
      .min(2, 'Name has min 2 chars.')
      .max(100, 'Name has max 100 chars.'),
    type: yup.string().required('Type is required.'),
  });
  const updatePackageSchema = yup.object().shape({
    name: yup
      .string()
      .required('Name is required.')
      .min(2, 'Name has min 2 chars.')
      .max(30, 'Name has max 100 chars.'),
    type: yup.string().required('Type is required.'),
  });

  const [selectedType, setSelectedType] = useState();

  let remainingCountryList = countiresList.filter(
    (countries) =>
      !packages.some((packagesList) => countries.name === packagesList.name),
  );

  let remainingContinentList = continentList.filter(
    (continent) =>
      !packages.some((packagesList) => continent.name === packagesList.name),
  );

  let remainingWorldList = worldList.filter(
    (world) =>
      !packages.some((packagesList) => world.name === packagesList.name),
  );

  const [list, setList] = useState();
  useEffect(() => {
    if (selectedType === 'country') {
      setList(remainingCountryList);
    }
    if (selectedType === 'continent') {
      setList(remainingContinentList);
    }
    if (selectedType === 'world') {
      setList(remainingWorldList);
    }
  }, [selectedType]);

  useEffect(() => {
    if (packageData) {
      setSelectedType(packageData.type);
    }
  }, [id]);

  const [countryDetails, setCountryDetails] = useState();
  const getCountryDetailsFunction = (countryName) => {
    const data =
      countryName &&
      countiresList.filter((data) => {
        return data.name === countryName;
      });
    setCountryDetails(data);
  };

  const [continentDetails, setContinentDetails] = useState();
  const getContinentDetailsFunction = (continentName) => {
    const data =
      continentName &&
      continentList.filter((data) => {
        return data.name === continentName;
      });
    setContinentDetails(data);
  };

  return (
    <Page
      className={classes.root}
      title={update ? 'Update package' : 'Add package'}
    >
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="lg">
          <Box my={2} display="flex" alignItems="center">
            <Typography variant="h2">
              {update ? 'Update package' : 'Add package'}
            </Typography>
            <Box ml={2}>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => {
                  navigate('/package/add');
                }}
              >
                Add New Package
              </Button>
            </Box>
          </Box>
          <Box mt={2}>
            <Breadcrumbs
              separator={<NavigateNextIcon fontSize="small" />}
              aria-label="breadcrumb"
            >
              <Link color="gray" to="/">
                Dashboard
              </Link>
              <Link color="grey" to="/package">
                Package
              </Link>
              <Typography color="textPrimary">
                {update ? 'Update' : 'Add'}
              </Typography>
            </Breadcrumbs>
          </Box>
          <Formik
            enableReinitialize={true}
            initialValues={
              update
                ? {
                    ...packageData,
                    name: countryDetails
                      ? packageData.name === countryDetails[0].name
                        ? packageData.name
                        : countryDetails[0].name
                      : continentDetails
                      ? packageData.name === continentDetails[0].name
                        ? packageData.name
                        : continentDetails[0].name
                      : packageData.name,
                    short: countryDetails
                      ? packageData.short === countryDetails[0].iso3
                        ? packageData.short
                        : countryDetails[0].iso3
                      : continentDetails
                      ? packageData.short === continentDetails[0].iso3
                        ? packageData.short
                        : continentDetails[0].iso3
                      : packageData.short,
                    flag: countryDetails
                      ? packageData.flag === countryDetails[0].iso2
                        ? packageData.flag
                        : countryDetails[0].iso2
                      : continentDetails
                      ? packageData.flag === continentDetails[0].iso2
                        ? packageData.flag
                        : continentDetails[0].iso2
                      : packageData.flag,
                    type: selectedType,
                  }
                : {
                    name: countryDetails
                      ? countryDetails[0].name
                      : continentDetails
                      ? continentDetails[0].name
                      : '',
                    price: '',
                    // currency: countryDetails
                    //   ? countryDetails[0].currency_symbol
                    //   : '',
                    // priority: '',
                    short: countryDetails
                      ? countryDetails[0].iso3
                      : continentDetails
                      ? continentDetails[0].iso3
                      : '',
                    flag: countryDetails
                      ? countryDetails[0].iso2
                      : continentDetails
                      ? continentDetails[0].iso2
                      : '',
                    type: selectedType,
                  }
            }
            validationSchema={update ? updatePackageSchema : addPackageSchema}
            onSubmit={async (values, { resetForm }) => {
              setErrMsg('');
              setSuccessMsg('');
              const {
                _id,
                name,
                flag,
                currency,
                price,
                short,
                type,
                priority,
              } = values;
              const data = {
                name,
                flag,
                currency,
                price,
                short,
                type,
                priority,
              };
              if (update) {
                try {
                  let res = await adminService.updatePackage(
                    _id,
                    data,
                    dispatch,
                  );
                  if (res && res.ok) {
                    setSuccessMsg(
                      res.message || 'package updated successfully.',
                    );
                  }
                } catch (error) {
                  consoleError(error);
                  setErrMsg(
                    error.message || 'Error updating package. Please try again',
                  );
                }
              } else {
                try {
                  let res = await adminService.addPackage(data, dispatch);
                  if (res && res.ok) {
                    setSuccessMsg(res.message || 'package added successfully.');
                    resetForm({});
                  }
                  //
                } catch (error) {
                  consoleError(error);
                  setErrMsg(
                    error.message || 'Error adding package. Please try again.',
                  );
                }
              }
            }}
          >
            {({ isSubmitting, values, setFieldValue }) => (
              <Form>
                <Grid container alignItems="flex-start" spacing={2}>
                  <Grid item xs={12}>
                    <FormControl
                      variant="outlined"
                      // className={classes.formControl}
                      className={classes.selectField}
                    >
                      <InputLabel htmlFor="outlined-age-native-simple">
                        Type
                      </InputLabel>
                      <Select
                        native
                        value={values.type}
                        onChange={(e) => {
                          const { name, value } = e.target;
                          setFieldValue(name, value);
                          setSelectedType(value);
                        }}
                        inputProps={{
                          name: 'type',
                          id: 'filled-age-native-simple',
                        }}
                      >
                        <option aria-label="None" value="" />
                        <option value="world">World</option>
                        <option value="continent">Continent</option>
                        <option value="country">Country</option>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      component={TextField}
                      label={values.type ? values.type : 'Name'}
                      margin="normal"
                      size="medium"
                      name="name"
                      fullWidth
                      variant="outlined"
                      select={true}
                      onChange={(e) => {
                        const { name, value } = e.target;
                        setFieldValue(name, value);
                        if (selectedType === 'country') {
                          getCountryDetailsFunction(value);
                        }
                        if (selectedType === 'continent') {
                          getContinentDetailsFunction(value);
                        }
                      }}
                    >
                      {update ? (
                        <MenuItem name="name" value={values.name}>
                          {values.name}
                        </MenuItem>
                      ) : (
                        ''
                      )}
                      {list
                        ? list.map((data, index) => (
                            <MenuItem name="name" key={index} value={data.name}>
                              {data.name}
                            </MenuItem>
                          ))
                        : ''}
                    </Field>
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      component={TextField}
                      label="Price"
                      margin="normal"
                      size="medium"
                      name="price"
                      fullWidth
                      variant="outlined"
                    />
                  </Grid>
                </Grid>
                <Box mt={1} mb={1}>
                  {errMsg && (
                    <Alert severity="error" onClose={() => setErrMsg('')}>
                      <AlertTitle>Error</AlertTitle>
                      {errMsg}
                    </Alert>
                  )}
                  {successMsg && (
                    <Alert severity="success" onClose={() => setSuccessMsg('')}>
                      <AlertTitle>Success</AlertTitle>
                      {successMsg}
                    </Alert>
                  )}
                </Box>
                {isSubmitting && <LinearProgress />}
                <Box my={2}>
                  <Button
                    color="primary"
                    disabled={isSubmitting}
                    fullWidth
                    size="medium"
                    type="submit"
                    variant="contained"
                  >
                    {update ? 'Update Package' : 'Add Package'}
                  </Button>
                </Box>
                <FormPreviewDev data={values} />
              </Form>
            )}
          </Formik>
        </Container>
      </Box>
    </Page>
  );
}

export default AddPackage;
