import React from 'react';
import { Navigate } from 'react-router-dom';
import DashboardLayout from 'src/layouts/DashboardLayout';
import MainLayout from 'src/layouts/MainLayout';
import AccountView from 'src/views/account/AccountView';
import UserListView from 'src/views/user/UserListView';

import DashboardView from 'src/views/reports/DashboardView';
import LoginView from 'src/views/auth/LoginView';
import NotFoundView from 'src/views/errors/NotFoundView';

import SettingsView from 'src/views/settings/SettingsView';
import { isLoggedIn } from './utils/helper';

import Error500 from './views/errors/Error500';
import StoryListView from './views/stories';
import EditStory from './views/stories/EditStory';
import Story from './views/stories/Story';
import SubscriptionListView from './views/subscription';
import Subscription from './views/subscription/Subscription';

import PackageListView from './views/package';
import Package from './views/package/Package';
import AddPackage from './views/package/AddPackage';

import UserDetails from './views/user/UserListView/UserDetails';

import RankingView from './views/ranking';

import PointsListView from './views/points';
import PointSystem from './views/points/PointSystem';
import EditPointSystem from './views/points/EditPointSystem';

export const getRoutes = (pathname) => {
  const routes = [
    {
      path: '/',
      element: <MainLayout />,
      children: [
        {
          path: 'login',
          element: isLoggedIn() ? <Navigate to="/" /> : <LoginView />,
        },
        { path: '404', element: <NotFoundView /> },
        { path: '500', element: <Error500 /> },
        {
          path: '/',
          element: isLoggedIn() ? (
            <DashboardLayout />
          ) : (
            <Navigate to="/login" state={{ pathname }} />
          ),
          children: [
            { path: '/', element: <Navigate to="/dashboard" /> },
            { path: 'account', element: <AccountView /> },
            {
              path: 'users',
              children: [
                {
                  path: '/role/:slug',
                  element: <UserListView />,
                },
                {
                  path: '/:user_id',
                  element: <UserDetails />,
                },
              ],
            },
            {
              path: 'story',
              children: [
                {
                  path: '/',
                  element: <StoryListView />,
                },
                {
                  path: ':storyId',
                  element: <Story />,
                },
                {
                  path: '/:storyId/edit',
                  element: <EditStory />,
                },
              ],
            },
            {
              path: 'subscription',
              children: [
                {
                  path: '/',
                  element: <SubscriptionListView />,
                },
                {
                  path: ':subscriptionId',
                  element: <Subscription />,
                },
              ],
            },
            {
              path: 'package',
              children: [
                {
                  path: '/',
                  element: <PackageListView />,
                },
                {
                  path: ':packageId',
                  element: <Package />,
                },
                {
                  path: '/:packageId/edit',
                  element: <AddPackage key={pathname} />,
                },
                {
                  path: '/add',
                  element: <AddPackage key={pathname} />,
                },
              ],
            },
            {
              path: 'point-system',
              children: [
                {
                  path: '/',
                  element: <PointsListView />,
                },
                {
                  path: ':pointId',
                  element: <PointSystem />,
                },
                {
                  path: '/:pointId/edit',
                  element: <EditPointSystem />,
                },
                {
                  path: '/add',
                  element: <EditPointSystem />,
                },
              ],
            },
            { path: 'ranking', element: <RankingView /> },
            { path: 'dashboard', element: <DashboardView /> },
            { path: 'settings', element: <SettingsView /> },
            { path: '*', element: <NotFoundView /> },
          ],
        },
        { path: '*', element: <NotFoundView /> },
      ],
    },
    // {
    //   path: '/',
    //   element: isLoggedIn() ? <DashboardLayout /> : <Navigate to="/login" />,
    //   children: [
    //     { path: 'account', element: <AccountView /> },
    //     { path: 'customers', element: <UserListView /> },
    //     { path: 'dashboard', element: <DashboardView /> },
    //     { path: 'products', element: <ProductListView /> },
    //     { path: 'settings', element: <SettingsView /> },
    //     { path: '*', element: <Navigate to="/404" /> },
    //   ],
    // },
  ];
  return routes;
};

// export default routes;
