import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import {
  Avatar,
  Box,
  Card,
  Typography,
  makeStyles,
  Dialog,
  DialogContent,
  Button,
  Snackbar,
} from '@material-ui/core';
import getInitials from 'src/utils/getInitials';
import { consoleError, getImageUrl } from 'src/utils/helper';
import MaterialTable from 'material-table';
import { tableIcons } from 'src/components/common/TableIcons';
import { Edit as EditIcon, Trash2 as DeleteIcon } from 'react-feather';
import adminService from 'src/services/adminService';
import { useDispatch, useSelector } from 'react-redux';
import { DialogActions, DialogTitle } from 'src/components/CustomDialog';
import { Alert } from '@material-ui/lab';
import { useNavigate } from 'react-router';
const useStyles = makeStyles((theme) => ({
  root: {},
  avatar: {
    marginRight: theme.spacing(2),
  },
}));

const StoryTable = ({ className, ...rest }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const stories = useSelector((state) => state.admin.stories);
  useEffect(() => {
    async function getStories() {
      try {
        adminService.getStories(dispatch);
      } catch (error) {
        console.error(error);
      }
    }
    getStories();
  }, []);

  const [deleteModal, setDeleteModal] = useState(false);

  const [deleteStory, setDeleteStory] = useState(null);
  const [updateStory, setUpdateStory] = useState(null);

  const openDeleteModal = () => {
    setDeleteModal(true);
  };
  const closeDeleteModal = () => {
    setDeleteModal(false);
    setDeleteStory(null);
  };

  const handleDeleteStory = async () => {
    try {
      await adminService.deleteStory(deleteStory._id, dispatch);
      closeDeleteModal();
      setDeleteSuccess(true);
    } catch (error) {
      consoleError(error);
      closeDeleteModal();
      setDeleteError(true);
    }
  };

  const [deleteSuccess, setDeleteSuccess] = useState(false);
  const [deleteError, setDeleteError] = useState(false);

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <Box>
        <MaterialTable
          icons={tableIcons}
          columns={[
            {
              title: 'Name',
              field: 'name',
              filtering: false,
              // eslint-disable-next-line react/display-name
              render: (rowData) => (
                <Box alignItems="center" display="flex">
                  <Avatar
                    className={classes.avatar}
                    variant="circular"
                    src={getImageUrl(rowData?.user?.avatar)}
                  >
                    {getInitials(rowData?.user?.name)}
                  </Avatar>
                  <Typography color="textPrimary" variant="body1">
                    {rowData?.user?.name}
                  </Typography>
                </Box>
              ),
            },
            { title: 'Likes Count', field: 'likesCount', filtering: false },
            { title: 'Privacy', field: 'privacy', filtering: false },
            // { title: 'Location', field: 'location', filtering: false },
          ]}
          data={stories}
          onRowClick={(event, rowData) => navigate(`./${rowData._id}`)}
          title="Stories"
          actions={[
            {
              icon: EditIcon,
              tooltip: 'Edit Story',
              onClick: (event, rowData) => {
                event.stopPropagation();
                setUpdateStory(rowData);
                navigate(`/story/${rowData._id}/edit`);
              },
            },
            {
              icon: DeleteIcon,
              tooltip: 'Delete Story',
              onClick: (event, rowData) => {
                event.stopPropagation();
                setDeleteStory(rowData);
                openDeleteModal();
              },
            },
          ]}
          options={{
            rowStyle: {
              fontSize: '1rem',
              fontWeight: 'lighter',
              fontFamily: 'Roboto, Helvetica, Arial, sans-serifs',
            },
            actionsColumnIndex: -1,
            filtering: true,
          }}
        />
      </Box>
      <Dialog open={deleteModal} onClose={closeDeleteModal}>
        <DialogTitle onClose={closeDeleteModal}>Delete Story</DialogTitle>
        <DialogContent>
          <Box
            height="50px"
            display="flex"
            alignItems="center"
            flexDirection="column"
          >
            <Typography variant="h6">
              Do you really want to delete Story?
            </Typography>
            <Typography variant="h6">
              &quot;
              <small>
                <i>{deleteStory?.name}</i>
              </small>
              &quot;
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDeleteModal} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDeleteStory} color="primary">
            Yes
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={deleteSuccess}
        autoHideDuration={3000}
        onClose={() => setDeleteSuccess(false)}
      >
        <Alert onClose={() => setDeleteSuccess(false)} severity="success">
          Story deleted successfully.
        </Alert>
      </Snackbar>

      <Snackbar
        open={deleteError}
        autoHideDuration={3000}
        onClose={() => setDeleteError(false)}
      >
        <Alert onClose={() => setDeleteError(false)} severity="error">
          Error deleting Story.
        </Alert>
      </Snackbar>
    </Card>
  );
};

StoryTable.propTypes = {
  className: PropTypes.string,
};

export default StoryTable;
