import React, { useEffect } from 'react';
import Page from 'src/components/Page';
import { Link } from 'react-router-dom';
import {
  Box,
  Card,
  CardContent,
  //   CardHeader,
  Container,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  Breadcrumbs,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import adminService from 'src/services/adminService';
import { useParams } from 'react-router';
import { getImageUrl } from 'src/utils/helper';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  mb3: {
    marginBottom: '15px',
  },
  storyImage: {
    maxHeight: '400px',
  },
}));

function Subscription() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const subscriptions = useSelector((state) => state.admin.subscription);
  const { subscriptionId } = useParams();

  const subscription = subscriptions.find(
    (Item) => Item._id.toString() === subscriptionId,
  );
  useEffect(() => {
    async function getSubscriptions() {
      try {
        adminService.getSubscriptions(dispatch);
      } catch (error) {
        console.error(error);
      }
    }
    getSubscriptions();
  }, []);
  return (
    <Page className={classes.root} title="Subscription">
      <Container maxWidth={false}>
        <Box mt={3}>
          <Typography color="textPrimary" gutterBottom variant="h2">
            Subscription
          </Typography>
          <Box mt={2}>
            <Breadcrumbs
              separator={<NavigateNextIcon fontSize="small" />}
              aria-label="breadcrumb"
            >
              <Link color="gray" to="/">
                Dashboard
              </Link>
              <Link color="grey" to="/subscription">
                Subscriptions
              </Link>
              <Typography color="textPrimary">{subscription._id}</Typography>
            </Breadcrumbs>
          </Box>
          {Subscription ? (
            <Box>
              <Card>
                <Box display="flex" ml={2} mt={2} alignItems="center">
                  <Typography color="textPrimary" variant="h4">
                    Details{' '}
                  </Typography>
                </Box>
                <CardContent>
                  <Table>
                    <TableBody>
                      <TableRow>
                        <TableCell>
                          <Typography
                            color="textSecondary"
                            gutterBottom
                            variant="h6"
                          >
                            Id
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography
                            color="textPrimary"
                            gutterBottom
                            variant="h6"
                          >
                            {subscription._id}
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <Typography
                            color="textSecondary"
                            gutterBottom
                            variant="h6"
                          >
                            Package
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography
                            color="textPrimary"
                            gutterBottom
                            variant="h6"
                          >
                            {subscription.package}
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <Typography
                            color="textSecondary"
                            gutterBottom
                            variant="h6"
                          >
                            Currency
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography
                            color="textPrimary"
                            gutterBottom
                            variant="h6"
                          >
                            {subscription.currency}
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <Typography
                            color="textSecondary"
                            gutterBottom
                            variant="h6"
                          >
                            Price
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography
                            color="textPrimary"
                            gutterBottom
                            variant="h6"
                          >
                            {subscription.price}
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <Typography
                            color="textSecondary"
                            gutterBottom
                            variant="h6"
                          >
                            Subscribed On
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography
                            color="textPrimary"
                            gutterBottom
                            variant="h6"
                          >
                            {subscription.createdAt}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </CardContent>
              </Card>
            </Box>
          ) : (
            <Box mt={5} display="flex" justifyContent="center">
              <Typography
                align="center"
                color="textSecondary"
                gutterBottom
                variant="h3"
              >
                Subscription Not Available
              </Typography>
            </Box>
          )}
        </Box>
      </Container>
    </Page>
  );
}
export default Subscription;
