const worldList = [
  {
    id: 1,
    name: 'World',
    iso3: 'World',
    iso2: '',
  },
];

export default worldList;
