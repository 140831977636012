import {
  Box,
  Button,
  Container,
  Grid,
  LinearProgress,
  makeStyles,
  InputLabel,
  Breadcrumbs,
  MenuItem,
  Typography,
  Avatar,
} from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import { Field, Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Page from 'src/components/Page';
import adminService from 'src/services/adminService';
import { consoleError, getImageUrl } from 'src/utils/helper';
import * as yup from 'yup';
import { TextField, Switch } from 'formik-material-ui';
import FormPreviewDev from 'src/components/common/FormPreviewDev';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { useMatch, useNavigate, Link } from 'react-router-dom';
import CustomFileUpload from 'src/components/CustomFileUpload';
import CustomFileUploadServer from 'src/components/CustomFileUploadServer';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    // height: 'auto',
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(1),
  },
  switchField: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  dropDown: {
    height: 400,
  },
}));
function EditPointSystem() {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [update, setUpdate] = useState(false);
  const [errMsg, setErrMsg] = useState('');
  const [id, setId] = useState('');
  const [successMsg, setSuccessMsg] = useState('');
  const [uploadingImage, setUploadingImage] = useState(false);

  const match = useMatch('/point-system/:id/edit');
  useEffect(() => {
    if (match && match.params && match.params.id) {
      setId(match.params.id);
      setUpdate(true);
    }
  }, [match]);

  const points = useSelector((state) => state.admin.points);
  const pointData = points.find((Item) => Item._id.toString() === id);
  //   console.log(id);
  //   console.log(pointData);
  useEffect(() => {
    async function getPoints() {
      try {
        adminService.getPoints(dispatch);
      } catch (error) {
        console.error(error);
      }
    }
    getPoints();
  }, []);

  const addPointSchema = yup.object().shape({
    name: yup
      .string()
      .required('Name is required.')
      .min(2, 'Name has min 2 chars.')
      .max(100, 'Name has max 100 chars.'),
  });
  const updatePointSchema = yup.object().shape({
    name: yup
      .string()
      .required('Name is required.')
      .min(2, 'Name has min 2 chars.')
      .max(30, 'Name has max 100 chars.'),
  });
  return (
    <Page
      className={classes.root}
      title={update ? 'Update Point' : 'Add Point'}
    >
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="lg">
          <Box my={2} display="flex" alignItems="center">
            <Typography variant="h2">
              {update ? 'Update Point' : 'Add Point'}
            </Typography>
            <Box ml={2}>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => {
                  navigate('./add');
                }}
              >
                Add New Point
              </Button>
            </Box>
          </Box>
          <Box mt={2}>
            <Breadcrumbs
              separator={<NavigateNextIcon fontSize="small" />}
              aria-label="breadcrumb"
            >
              <Link color="gray" to="/">
                Dashboard
              </Link>
              <Link color="grey" to="/point-system">
                point
              </Link>
              <Typography color="textPrimary">
                {update ? 'Update' : 'Add'}
              </Typography>
            </Breadcrumbs>
          </Box>
          <Formik
            enableReinitialize={true}
            initialValues={
              update
                ? {
                    ...pointData,
                    // image: '',
                  }
                : {
                    name: '',
                    type: '',
                    point: '',
                  }
            }
            validationSchema={update ? updatePointSchema : addPointSchema}
            onSubmit={async (values, { resetForm }) => {
              setErrMsg('');
              setSuccessMsg('');
              const { _id, name, type, point } = values;
              const data = {
                name,
                type,
                point,
              };
              if (update) {
                try {
                  let res = await adminService.updatePoints(
                    _id,
                    data,
                    dispatch,
                  );
                  if (res && res.ok) {
                    setSuccessMsg(res.message || 'Point updated successfully.');
                  }
                } catch (error) {
                  consoleError(error);
                  setErrMsg(
                    error.message || 'Error updating Point. Please try again',
                  );
                }
              } else {
                try {
                  let res = await adminService.addPoints(data, dispatch);
                  if (res && res.ok) {
                    setSuccessMsg(res.message || 'Point added successfully.');
                    resetForm({});
                  }
                  //
                } catch (error) {
                  consoleError(error);
                  setErrMsg(
                    error.message || 'Error adding Point. Please try again.',
                  );
                }
              }
            }}
          >
            {({ isSubmitting, values, setFieldValue }) => (
              <Form>
                <Grid container alignItems="flex-start" spacing={2}>
                  <Grid item xs={12}>
                    <Field
                      component={TextField}
                      label="Name"
                      margin="normal"
                      size="medium"
                      name="name"
                      fullWidth
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    {/* <Field
                      component={TextField}
                      label="Type"
                      margin="normal"
                      size="medium"
                      name="type"
                      fullWidth
                      variant="outlined"
                    /> */}
                    <Field
                      component={TextField}
                      name="type"
                      type="text"
                      label="Type"
                      select={true}
                      onChange={(e) => {
                        const { name, value } = e.target;
                        setFieldValue(name, value);
                      }}
                      variant="outlined"
                      fullWidth
                    >
                      <MenuItem value="new_story">New Story</MenuItem>
                      <MenuItem value="referral">Referral</MenuItem>
                      <MenuItem value="purchase_country">
                        Purchase Country
                      </MenuItem>
                      <MenuItem value="purchase_continent">
                        Purchase Continent
                      </MenuItem>
                      <MenuItem value="purchase_world">Purchase World</MenuItem>
                    </Field>
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      component={TextField}
                      label="Points"
                      margin="normal"
                      size="medium"
                      name="point"
                      fullWidth
                      variant="outlined"
                    />
                  </Grid>
                </Grid>
                <Box mt={1} mb={1}>
                  {errMsg && (
                    <Alert severity="error" onClose={() => setErrMsg('')}>
                      <AlertTitle>Error</AlertTitle>
                      {errMsg}
                    </Alert>
                  )}
                  {successMsg && (
                    <Alert severity="success" onClose={() => setSuccessMsg('')}>
                      <AlertTitle>Success</AlertTitle>
                      {successMsg}
                    </Alert>
                  )}
                </Box>
                {(isSubmitting || uploadingImage) && <LinearProgress />}
                <Box my={2}>
                  <Button
                    color="primary"
                    disabled={isSubmitting || uploadingImage}
                    fullWidth
                    size="medium"
                    type="submit"
                    variant="contained"
                  >
                    {update ? 'Update Point' : 'Add Point'}
                  </Button>
                </Box>
                <FormPreviewDev data={values} />
              </Form>
            )}
          </Formik>
        </Container>
      </Box>
    </Page>
  );
}

export default EditPointSystem;
