import {
  Box,
  Button,
  Container,
  Grid,
  InputLabel,
  LinearProgress,
  makeStyles,
  Breadcrumbs,
  Typography,
  FormControl,
  MenuItem,
  Avatar,
} from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import { Field, Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { consoleError, getImageUrl } from 'src/utils/helper';
import Page from 'src/components/Page';
import adminService from 'src/services/adminService';
import * as yup from 'yup';
import { TextField } from 'formik-material-ui';
import FormPreviewDev from 'src/components/common/FormPreviewDev';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { useMatch, useNavigate, Link } from 'react-router-dom';
import CustomFileUpload from 'src/components/CustomFileUpload';
import Carousel from 'react-material-ui-carousel';
import AsyncImage from 'src/components/common/AsyncImage';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    // height: 'auto',
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(1),
  },
  switchField: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  storyImage: {
    height: '350px !important',
    width: '100% !important',
    objectFit: 'contain !important',
  },
}));

var ImagesList = [
  {
    imgUrl:
      'https://via.placeholder.com/728x250.png?text=image%201%20C/O%20https://placeholder.com/',
  },
  {
    imgUrl:
      'https://via.placeholder.com/728x250.png?text=image%202%20C/O%20https://placeholder.com/',
  },
];
function EditStory() {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [errMsg, setErrMsg] = useState('');
  const [id, setId] = useState('');
  const [successMsg, setSuccessMsg] = useState('');
  const [story, setStory] = useState({
    _id: '',
    user: '',
    imageUrl: '',
    privacy: '',
    likes: '',
    comments: '',
  });
  const match = useMatch('/story/:id/edit');
  useEffect(() => {
    if (match && match.params && match.params.id) {
      setId(match.params.id);
    }
  }, [match]);
  useEffect(() => {
    async function fetchStories() {
      try {
        if (id) {
          const res = await adminService.getStory(id);
          if (res && res.ok) {
            const storyData = res.data;
            if (storyData) {
              setStory(storyData);
            } else {
              navigate('/story');
            }
          }
        }
      } catch (error) {
        consoleError(error);
      }
    }
    fetchStories();
  }, [id, history]);
  console.log(story);

  const updateStorySchema = yup.object().shape({
    user: yup
      .string()
      .required('Name is required.')
      .min(2, 'Title has min 2 chars.')
      .max(30, 'Title has max 30 chars.'),
    imageUrl: yup
      .mixed()
      .test('fileType', 'Please select image (jpeg/png) file only.', (value) =>
        value ? SUPPORTED_FORMATS.includes(value ? value.type : null) : true,
      ),
    location: yup.string().required('Location is required.'),
    privacy: yup.string().required('privacy is required'),
    likes: yup.string(),
    comments: yup.string(),
  });
  const SUPPORTED_FORMATS = ['image/jpeg', 'image/png'];

  return (
    <Page className={classes.root} title="Update Story">
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="lg">
          <Box my={2} display="flex" alignItems="center">
            <Typography variant="h2">Update Story</Typography>
          </Box>
          <Box mt={2}>
            <Breadcrumbs
              separator={<NavigateNextIcon fontSize="small" />}
              aria-label="breadcrumb"
            >
              <Link color="gray" to="/">
                Dashboard
              </Link>
              <Link color="grey" to="/story">
                Stories
              </Link>
              <Typography color="textPrimary">Update</Typography>
            </Breadcrumbs>
          </Box>
          <Grid xs={12} style={{ maxHeight: '400px' }}>
            <Carousel>
              {/* {ImagesList.map((item, i) => (
                <Avatar
                  key={i}
                  className={classes.storyImage}
                  src={item.imgUrl}
                  variant="square"
                />
              ))} */}
              {Array.isArray(story.images) &&
                story.images
                  .filter((img) => img?.url)
                  .map((img) => (
                    <Box key={img._id}>
                      <AsyncImage
                        url={img.url}
                        className={classes.storyImage}
                      />
                    </Box>
                  ))}
            </Carousel>
          </Grid>
          <Formik
            enableReinitialize={true}
            initialValues={story}
            validationSchema={updateStorySchema}
            onSubmit={async (values) => {
              console.log('hello');
              setErrMsg('');
              setSuccessMsg('');
              const {
                _id,
                user,
                // imageUrl,
                privacy,
                // likes,
                // comments,
                // location,
              } = values;
              const data = {
                user,
                // imageUrl,
                privacy,
                // likes,
                // comments,
                // location,
              };
              try {
                let res = await adminService.updateStory(_id, data, dispatch);
                if (res && res.ok) {
                  setSuccessMsg(res.message || 'Story updated successfully.');
                }
              } catch (error) {
                consoleError(error);
                setErrMsg(
                  error.message || 'Error updating Story. Please try again',
                );
              }
            }}
          >
            {({ isSubmitting, values, setFieldValue }) => (
              <Form>
                <Grid container alignItems="flex-start" spacing={2}>
                  <Grid item xs={12}>
                    <Field
                      component={TextField}
                      label="User"
                      margin="normal"
                      size="medium"
                      name="user"
                      fullWidth
                      variant="outlined"
                      disabled={true}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      component={TextField}
                      label="Privacy"
                      margin="normal"
                      size="medium"
                      name="privacy"
                      fullWidth
                      variant="outlined"
                      select={true}
                      onChange={(e) => {
                        const { name, value } = e.target;
                        setFieldValue(name, value);
                      }}
                    >
                      <MenuItem value={'private'}>Private</MenuItem>
                      <MenuItem value={'friends'}>Friends</MenuItem>
                      <MenuItem value={'public'}>Public</MenuItem>
                    </Field>
                  </Grid>
                  {/* <Grid item xs={12}>
                    <Field
                      component={TextField}
                      label="Likes"
                      margin="normal"
                      size="medium"
                      name="likes"
                      fullWidth
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      component={TextField}
                      label="Comments"
                      margin="normal"
                      size="medium"
                      name="comments"
                      fullWidth
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      component={TextField}
                      label="Location"
                      margin="normal"
                      size="medium"
                      name="location"
                      fullWidth
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      component={CustomFileUpload}
                      name="imageUrl"
                      label="Image"
                    />
                    {values.imageUrl ? (
                      <Avatar
                        src={getImageUrl(values.imageUrl)}
                        variant="square"
                        className={classes.avatar}
                      />
                    ) : null}
                  </Grid> */}
                </Grid>
                <Box mt={1} mb={1}>
                  {errMsg && (
                    <Alert severity="error" onClose={() => setErrMsg('')}>
                      <AlertTitle>Error</AlertTitle>
                      {errMsg}
                    </Alert>
                  )}
                  {successMsg && (
                    <Alert severity="success" onClose={() => setSuccessMsg('')}>
                      <AlertTitle>Success</AlertTitle>
                      {successMsg}
                    </Alert>
                  )}
                </Box>
                {isSubmitting && <LinearProgress />}
                <Box my={2}>
                  <Button
                    color="primary"
                    disabled={isSubmitting}
                    fullWidth
                    size="medium"
                    type="submit"
                    variant="contained"
                  >
                    Update Story
                  </Button>
                </Box>
                <FormPreviewDev data={values} />
              </Form>
            )}
          </Formik>
        </Container>
      </Box>
    </Page>
  );
}

export default EditStory;
