import React, { useEffect } from 'react';
import Page from 'src/components/Page';
import { Link } from 'react-router-dom';
import {
  Box,
  Card,
  CardContent,
  //   CardHeader,
  Container,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  Breadcrumbs,
  Avatar,
  Grid,
  Slide,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import adminService from 'src/services/adminService';
import { useParams } from 'react-router';
import Carousel from 'react-material-ui-carousel';

import AsyncImage from 'src/components/common/AsyncImage';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  mb3: {
    marginBottom: '15px',
  },
  storyImage: {
    height: '350px !important',
    width: '100% !important',
    objectFit: 'contain !important',
  },
}));

var ImagesList = [
  {
    imgUrl:
      'https://via.placeholder.com/728x250.png?text=image%201%20C/O%20https://placeholder.com/',
  },
  {
    imgUrl:
      'https://via.placeholder.com/728x250.png?text=image%202%20C/O%20https://placeholder.com/',
  },
];

function Story() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const stories = useSelector((state) => state.admin.stories);
  const { storyId } = useParams();

  const story = stories.find((Item) => Item._id.toString() === storyId);
  console.log(story);
  useEffect(() => {
    async function getStories() {
      try {
        adminService.getStories(dispatch);
      } catch (error) {
        console.error(error);
      }
    }
    getStories();
  }, []);
  return (
    <Page className={classes.root} title="Ledger">
      <Container maxWidth={false}>
        <Box mt={3}>
          <Typography color="textPrimary" gutterBottom variant="h2">
            Story
          </Typography>
          <Box mt={2}>
            <Breadcrumbs
              separator={<NavigateNextIcon fontSize="small" />}
              aria-label="breadcrumb"
            >
              <Link color="gray" to="/">
                Dashboard
              </Link>
              <Link color="grey" to="/story">
                Story
              </Link>
              <Typography color="textPrimary">
                {story ? story._id : ''}
              </Typography>
            </Breadcrumbs>
          </Box>
          {story ? (
            <Box>
              <Card>
                <Box display="flex" ml={2} mt={2} alignItems="center">
                  <Typography color="textPrimary" variant="h4">
                    Story Details{' '}
                  </Typography>
                </Box>
                <CardContent>
                  <Grid xs={12} style={{ maxHeight: '400px' }}>
                    <Carousel>
                      {/* {ImagesList.map((item, i) => (
                        <Avatar
                          key={i}
                          className={classes.storyImage}
                          src={item.imgUrl}
                          variant="square"
                        />
                      ))} */}
                      {Array.isArray(story.images) &&
                        story.images
                          .filter((img) => img?.url)
                          .map((img) => (
                            <Box key={img._id}>
                              <AsyncImage
                                url={img.url}
                                className={classes.storyImage}
                              />
                            </Box>
                          ))}
                    </Carousel>
                  </Grid>
                  <Table>
                    <TableBody>
                      <TableRow>
                        <TableCell>
                          <Typography
                            color="textSecondary"
                            gutterBottom
                            variant="h6"
                          >
                            Id
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography
                            color="textPrimary"
                            gutterBottom
                            variant="h6"
                          >
                            {story._id}
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <Typography
                            color="textSecondary"
                            gutterBottom
                            variant="h6"
                          >
                            Privacy
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography
                            color="textPrimary"
                            gutterBottom
                            variant="h6"
                          >
                            {story.privacy}
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <Typography
                            color="textSecondary"
                            gutterBottom
                            variant="h6"
                          >
                            Descriptions
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography
                            color="textPrimary"
                            gutterBottom
                            variant="h6"
                          >
                            {
                              story.googleData.details.detailedDescription
                                .articleBody
                            }
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <Typography
                            color="textSecondary"
                            gutterBottom
                            variant="h6"
                          >
                            Likes
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography
                            color="textPrimary"
                            gutterBottom
                            variant="h6"
                          >
                            {story.likesCount}
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <Typography
                            color="textSecondary"
                            gutterBottom
                            variant="h6"
                          >
                            Comments
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography
                            color="textPrimary"
                            gutterBottom
                            variant="h6"
                          >
                            {story.commentsCount}
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <Typography
                            color="textSecondary"
                            gutterBottom
                            variant="h6"
                          >
                            Uploaded At
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography
                            color="textPrimary"
                            gutterBottom
                            variant="h6"
                          >
                            {story.createdAt}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </CardContent>
              </Card>
            </Box>
          ) : (
            <Box mt={5} display="flex" justifyContent="center">
              <Typography
                align="center"
                color="textSecondary"
                gutterBottom
                variant="h3"
              >
                Story Not Available
              </Typography>
            </Box>
          )}
        </Box>
      </Container>
    </Page>
  );
}
export default Story;
