import {
  ADMIN_ADD_USER,
  ADMIN_DELETE_USER,
  ADMIN_DEACTIVATE_USER,
  ADMIN_GET_USERS,
  ADMIN_UPDATE_USER,
  ADMIN_GET_STORIES,
  ADMIN_UPDATE_STORY,
  ADMIN_DELETE_STORY,
  ADMIN_GET_SUBSCRIPTIONS,
  ADMIN_DELETE_SUBSCRIPTION,
  ADMIN_ADD_PACKAGE,
  ADMIN_GET_PACKAGES,
  ADMIN_UPDATE_PACKAGE,
  ADMIN_DELETE_PACKAGE,
  ADMIN_ADD_POINT,
  ADMIN_GET_POINTS,
  ADMIN_UPDATE_POINT,
  ADMIN_DELETE_POINT,
} from '../actions/adminActions';

const initialState = {
  users: [],
  stories: [],
  subscriptions: [],
  packages: [],
  points: [],
};

export default function adminReducer(state = initialState, action) {
  switch (action.type) {
    // USERS
    case ADMIN_GET_USERS:
      return {
        ...state,
        users: [...action.payload],
      };
    case ADMIN_ADD_USER: {
      const users = [...state.users];
      users.push(action.payload);
      return {
        ...state,
        users: users,
      };
    }
    case ADMIN_UPDATE_USER: {
      const users = [...state.users];
      let index = users.findIndex((item) => item._id === action.payload._id);
      users[index] = action.payload;
      return {
        ...state,
        users: users,
      };
    }
    case ADMIN_DELETE_USER: {
      const users = [...state.users];
      let index = users.findIndex((item) => item._id === action.payload);
      users.splice(index, 1);
      return {
        ...state,
        users: users,
      };
    }
    case ADMIN_DEACTIVATE_USER: {
      const users = [...state.users];
      return {
        ...state,
        users: users,
      };
    }
    // Stories
    case ADMIN_GET_STORIES:
      return {
        ...state,
        stories: [...action.payload],
      };
    case ADMIN_UPDATE_STORY: {
      const stories = [...state.stories];
      let index = stories.findIndex((item) => item._id === action.payload._id);
      stories[index] = action.payload;
      return {
        ...state,
        stories: stories,
      };
    }
    case ADMIN_DELETE_STORY: {
      const stories = [...state.stories];
      let index = stories.findIndex((item) => item._id === action.payload);
      stories.splice(index, 1);
      return {
        ...state,
        stories: stories,
      };
    }
    // Subscriptions
    case ADMIN_GET_SUBSCRIPTIONS:
      return {
        ...state,
        subscriptions: [...action.payload],
      };
    case ADMIN_DELETE_SUBSCRIPTION: {
      const subscriptions = [...state.subscriptions];
      let index = subscriptions.findIndex(
        (item) => item._id === action.payload,
      );
      subscriptions.splice(index, 1);
      return {
        ...state,
        subscriptions: subscriptions,
      };
    }

    // Packages
    case ADMIN_ADD_PACKAGE: {
      const packages = [...state.packages];
      packages.push(action.payload);
      return {
        ...state,
        packages: packages,
      };
    }
    case ADMIN_GET_PACKAGES:
      return {
        ...state,
        packages: [...action.payload],
      };
    case ADMIN_UPDATE_PACKAGE: {
      const packages = [...state.packages];
      let index = packages.findIndex((item) => item._id === action.payload._id);
      packages[index] = action.payload;
      return {
        ...state,
        packages: packages,
      };
    }
    case ADMIN_DELETE_PACKAGE: {
      const packages = [...state.packages];
      let index = packages.findIndex((item) => item._id === action.payload);
      packages.splice(index, 1);
      return {
        ...state,
        packages: packages,
      };
    }

    // POINTS
    case ADMIN_GET_POINTS:
      return {
        ...state,
        points: [...action.payload],
      };
    case ADMIN_ADD_POINT: {
      const points = [...state.points];
      points.push(action.payload);
      return {
        ...state,
        points: points,
      };
    }
    case ADMIN_UPDATE_POINT: {
      const points = [...state.points];
      let index = points.findIndex((item) => item._id === action.payload._id);
      points[index] = action.payload;
      return {
        ...state,
        points: points,
      };
    }
    case ADMIN_DELETE_POINT: {
      const points = [...state.points];
      let index = points.findIndex((item) => item._id === action.payload);
      points.splice(index, 1);
      return {
        ...state,
        points: points,
      };
    }

    default:
      return state;
  }
}
