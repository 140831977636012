const countiresList = [
  {
    id: 1,
    name: 'Afghanistan',
    iso3: 'AFG',
    iso2: 'AF',
    phone_code: '93',
    capital: 'Kabul',
    currency: 'AFN',
    currency_symbol: '؋',
    tld: '.af',
    native: 'افغانستان',
    region: 'Asia',
    subregion: 'Southern Asia',
    timezones: [
      {
        zoneName: 'Asia/Kabul',
        gmtOffset: 16200,
        gmtOffsetName: 'UTC+04:30',
        abbreviation: 'AFT',
        tzName: 'Afghanistan Time',
      },
    ],
    translations: {
      br: 'Afeganistão',
      pt: 'Afeganistão',
      nl: 'Afghanistan',
      hr: 'Afganistan',
      fa: 'افغانستان',
      de: 'Afghanistan',
      es: 'Afganistán',
      fr: 'Afghanistan',
      ja: 'アフガニスタン',
      it: 'Afghanistan',
    },
    latitude: '33.00000000',
    longitude: '65.00000000',
    emoji: '🇦🇫',
    emojiU: 'U+1F1E6 U+1F1EB',
  },
  {
    id: 2,
    name: 'Aland Islands',
    iso3: 'ALA',
    iso2: 'AX',
    phone_code: '+358-18',
    capital: 'Mariehamn',
    currency: 'EUR',
    currency_symbol: '€',
    tld: '.ax',
    native: 'Åland',
    region: 'Europe',
    subregion: 'Northern Europe',
    timezones: [
      {
        zoneName: 'Europe/Mariehamn',
        gmtOffset: 7200,
        gmtOffsetName: 'UTC+02:00',
        abbreviation: 'EET',
        tzName: 'Eastern European Time',
      },
    ],
    translations: {
      br: 'Ilhas de Aland',
      pt: 'Ilhas de Aland',
      nl: 'Ålandeilanden',
      hr: 'Ålandski otoci',
      fa: 'جزایر الند',
      de: 'Åland',
      es: 'Alandia',
      fr: 'Åland',
      ja: 'オーランド諸島',
      it: 'Isole Aland',
    },
    latitude: '60.11666700',
    longitude: '19.90000000',
    emoji: '🇦🇽',
    emojiU: 'U+1F1E6 U+1F1FD',
  },
  {
    id: 3,
    name: 'Albania',
    iso3: 'ALB',
    iso2: 'AL',
    phone_code: '355',
    capital: 'Tirana',
    currency: 'ALL',
    currency_symbol: 'Lek',
    tld: '.al',
    native: 'Shqipëria',
    region: 'Europe',
    subregion: 'Southern Europe',
    timezones: [
      {
        zoneName: 'Europe/Tirane',
        gmtOffset: 3600,
        gmtOffsetName: 'UTC+01:00',
        abbreviation: 'CET',
        tzName: 'Central European Time',
      },
    ],
    translations: {
      br: 'Albânia',
      pt: 'Albânia',
      nl: 'Albanië',
      hr: 'Albanija',
      fa: 'آلبانی',
      de: 'Albanien',
      es: 'Albania',
      fr: 'Albanie',
      ja: 'アルバニア',
      it: 'Albania',
    },
    latitude: '41.00000000',
    longitude: '20.00000000',
    emoji: '🇦🇱',
    emojiU: 'U+1F1E6 U+1F1F1',
  },
  {
    id: 4,
    name: 'Algeria',
    iso3: 'DZA',
    iso2: 'DZ',
    phone_code: '213',
    capital: 'Algiers',
    currency: 'DZD',
    currency_symbol: 'دج',
    tld: '.dz',
    native: 'الجزائر',
    region: 'Africa',
    subregion: 'Northern Africa',
    timezones: [
      {
        zoneName: 'Africa/Algiers',
        gmtOffset: 3600,
        gmtOffsetName: 'UTC+01:00',
        abbreviation: 'CET',
        tzName: 'Central European Time',
      },
    ],
    translations: {
      br: 'Argélia',
      pt: 'Argélia',
      nl: 'Algerije',
      hr: 'Alžir',
      fa: 'الجزایر',
      de: 'Algerien',
      es: 'Argelia',
      fr: 'Algérie',
      ja: 'アルジェリア',
      it: 'Algeria',
    },
    latitude: '28.00000000',
    longitude: '3.00000000',
    emoji: '🇩🇿',
    emojiU: 'U+1F1E9 U+1F1FF',
  },
  {
    id: 5,
    name: 'American Samoa',
    iso3: 'ASM',
    iso2: 'AS',
    phone_code: '+1-684',
    capital: 'Pago Pago',
    currency: 'USD',
    currency_symbol: '$',
    tld: '.as',
    native: 'American Samoa',
    region: 'Oceania',
    subregion: 'Polynesia',
    timezones: [
      {
        zoneName: 'Pacific/Pago_Pago',
        gmtOffset: -39600,
        gmtOffsetName: 'UTC-11:00',
        abbreviation: 'SST',
        tzName: 'Samoa Standard Time',
      },
    ],
    translations: {
      br: 'Samoa Americana',
      pt: 'Samoa Americana',
      nl: 'Amerikaans Samoa',
      hr: 'Američka Samoa',
      fa: 'ساموآی آمریکا',
      de: 'Amerikanisch-Samoa',
      es: 'Samoa Americana',
      fr: 'Samoa américaines',
      ja: 'アメリカ領サモア',
      it: 'Samoa Americane',
    },
    latitude: '-14.33333333',
    longitude: '-170.00000000',
    emoji: '🇦🇸',
    emojiU: 'U+1F1E6 U+1F1F8',
  },
  {
    id: 6,
    name: 'Andorra',
    iso3: 'AND',
    iso2: 'AD',
    phone_code: '376',
    capital: 'Andorra la Vella',
    currency: 'EUR',
    currency_symbol: '€',
    tld: '.ad',
    native: 'Andorra',
    region: 'Europe',
    subregion: 'Southern Europe',
    timezones: [
      {
        zoneName: 'Europe/Andorra',
        gmtOffset: 3600,
        gmtOffsetName: 'UTC+01:00',
        abbreviation: 'CET',
        tzName: 'Central European Time',
      },
    ],
    translations: {
      br: 'Andorra',
      pt: 'Andorra',
      nl: 'Andorra',
      hr: 'Andora',
      fa: 'آندورا',
      de: 'Andorra',
      es: 'Andorra',
      fr: 'Andorre',
      ja: 'アンドラ',
      it: 'Andorra',
    },
    latitude: '42.50000000',
    longitude: '1.50000000',
    emoji: '🇦🇩',
    emojiU: 'U+1F1E6 U+1F1E9',
  },
  {
    id: 7,
    name: 'Angola',
    iso3: 'AGO',
    iso2: 'AO',
    phone_code: '244',
    capital: 'Luanda',
    currency: 'AOA',
    currency_symbol: 'Kz',
    tld: '.ao',
    native: 'Angola',
    region: 'Africa',
    subregion: 'Middle Africa',
    timezones: [
      {
        zoneName: 'Africa/Luanda',
        gmtOffset: 3600,
        gmtOffsetName: 'UTC+01:00',
        abbreviation: 'WAT',
        tzName: 'West Africa Time',
      },
    ],
    translations: {
      br: 'Angola',
      pt: 'Angola',
      nl: 'Angola',
      hr: 'Angola',
      fa: 'آنگولا',
      de: 'Angola',
      es: 'Angola',
      fr: 'Angola',
      ja: 'アンゴラ',
      it: 'Angola',
    },
    latitude: '-12.50000000',
    longitude: '18.50000000',
    emoji: '🇦🇴',
    emojiU: 'U+1F1E6 U+1F1F4',
  },
  {
    id: 8,
    name: 'Anguilla',
    iso3: 'AIA',
    iso2: 'AI',
    phone_code: '+1-264',
    capital: 'The Valley',
    currency: 'XCD',
    currency_symbol: '$',
    tld: '.ai',
    native: 'Anguilla',
    region: 'Americas',
    subregion: 'Caribbean',
    timezones: [
      {
        zoneName: 'America/Anguilla',
        gmtOffset: -14400,
        gmtOffsetName: 'UTC-04:00',
        abbreviation: 'AST',
        tzName: 'Atlantic Standard Time',
      },
    ],
    translations: {
      br: 'Anguila',
      pt: 'Anguila',
      nl: 'Anguilla',
      hr: 'Angvila',
      fa: 'آنگویلا',
      de: 'Anguilla',
      es: 'Anguilla',
      fr: 'Anguilla',
      ja: 'アンギラ',
      it: 'Anguilla',
    },
    latitude: '18.25000000',
    longitude: '-63.16666666',
    emoji: '🇦🇮',
    emojiU: 'U+1F1E6 U+1F1EE',
  },
  {
    id: 9,
    name: 'Antarctica',
    iso3: 'ATA',
    iso2: 'AQ',
    phone_code: '',
    capital: '',
    currency: '',
    currency_symbol: '$',
    tld: '.aq',
    native: 'Antarctica',
    region: 'Polar',
    subregion: '',
    timezones: [
      {
        zoneName: 'Antarctica/Casey',
        gmtOffset: 39600,
        gmtOffsetName: 'UTC+11:00',
        abbreviation: 'AWST',
        tzName: 'Australian Western Standard Time',
      },
      {
        zoneName: 'Antarctica/Davis',
        gmtOffset: 25200,
        gmtOffsetName: 'UTC+07:00',
        abbreviation: 'DAVT',
        tzName: 'Davis Time',
      },
      {
        zoneName: 'Antarctica/DumontDUrville',
        gmtOffset: 36000,
        gmtOffsetName: 'UTC+10:00',
        abbreviation: 'DDUT',
        tzName: "Dumont d'Urville Time",
      },
      {
        zoneName: 'Antarctica/Mawson',
        gmtOffset: 18000,
        gmtOffsetName: 'UTC+05:00',
        abbreviation: 'MAWT',
        tzName: 'Mawson Station Time',
      },
      {
        zoneName: 'Antarctica/McMurdo',
        gmtOffset: 46800,
        gmtOffsetName: 'UTC+13:00',
        abbreviation: 'NZDT',
        tzName: 'New Zealand Daylight Time',
      },
      {
        zoneName: 'Antarctica/Palmer',
        gmtOffset: -10800,
        gmtOffsetName: 'UTC-03:00',
        abbreviation: 'CLST',
        tzName: 'Chile Summer Time',
      },
      {
        zoneName: 'Antarctica/Rothera',
        gmtOffset: -10800,
        gmtOffsetName: 'UTC-03:00',
        abbreviation: 'ROTT',
        tzName: 'Rothera Research Station Time',
      },
      {
        zoneName: 'Antarctica/Syowa',
        gmtOffset: 10800,
        gmtOffsetName: 'UTC+03:00',
        abbreviation: 'SYOT',
        tzName: 'Showa Station Time',
      },
      {
        zoneName: 'Antarctica/Troll',
        gmtOffset: 0,
        gmtOffsetName: 'UTC±00',
        abbreviation: 'GMT',
        tzName: 'Greenwich Mean Time',
      },
      {
        zoneName: 'Antarctica/Vostok',
        gmtOffset: 21600,
        gmtOffsetName: 'UTC+06:00',
        abbreviation: 'VOST',
        tzName: 'Vostok Station Time',
      },
    ],
    translations: {
      br: 'Antártida',
      pt: 'Antárctida',
      nl: 'Antarctica',
      hr: 'Antarktika',
      fa: 'جنوبگان',
      de: 'Antarktika',
      es: 'Antártida',
      fr: 'Antarctique',
      ja: '南極大陸',
      it: 'Antartide',
    },
    latitude: '-74.65000000',
    longitude: '4.48000000',
    emoji: '🇦🇶',
    emojiU: 'U+1F1E6 U+1F1F6',
  },
  {
    id: 10,
    name: 'Antigua And Barbuda',
    iso3: 'ATG',
    iso2: 'AG',
    phone_code: '+1-268',
    capital: "St. John's",
    currency: 'XCD',
    currency_symbol: '$',
    tld: '.ag',
    native: 'Antigua and Barbuda',
    region: 'Americas',
    subregion: 'Caribbean',
    timezones: [
      {
        zoneName: 'America/Antigua',
        gmtOffset: -14400,
        gmtOffsetName: 'UTC-04:00',
        abbreviation: 'AST',
        tzName: 'Atlantic Standard Time',
      },
    ],
    translations: {
      br: 'Antígua e Barbuda',
      pt: 'Antígua e Barbuda',
      nl: 'Antigua en Barbuda',
      hr: 'Antigva i Barbuda',
      fa: 'آنتیگوا و باربودا',
      de: 'Antigua und Barbuda',
      es: 'Antigua y Barbuda',
      fr: 'Antigua-et-Barbuda',
      ja: 'アンティグア・バーブーダ',
      it: 'Antigua e Barbuda',
    },
    latitude: '17.05000000',
    longitude: '-61.80000000',
    emoji: '🇦🇬',
    emojiU: 'U+1F1E6 U+1F1EC',
  },
  {
    id: 11,
    name: 'Argentina',
    iso3: 'ARG',
    iso2: 'AR',
    phone_code: '54',
    capital: 'Buenos Aires',
    currency: 'ARS',
    currency_symbol: '$',
    tld: '.ar',
    native: 'Argentina',
    region: 'Americas',
    subregion: 'South America',
    timezones: [
      {
        zoneName: 'America/Argentina/Buenos_Aires',
        gmtOffset: -10800,
        gmtOffsetName: 'UTC-03:00',
        abbreviation: 'ART',
        tzName: 'Argentina Time',
      },
      {
        zoneName: 'America/Argentina/Catamarca',
        gmtOffset: -10800,
        gmtOffsetName: 'UTC-03:00',
        abbreviation: 'ART',
        tzName: 'Argentina Time',
      },
      {
        zoneName: 'America/Argentina/Cordoba',
        gmtOffset: -10800,
        gmtOffsetName: 'UTC-03:00',
        abbreviation: 'ART',
        tzName: 'Argentina Time',
      },
      {
        zoneName: 'America/Argentina/Jujuy',
        gmtOffset: -10800,
        gmtOffsetName: 'UTC-03:00',
        abbreviation: 'ART',
        tzName: 'Argentina Time',
      },
      {
        zoneName: 'America/Argentina/La_Rioja',
        gmtOffset: -10800,
        gmtOffsetName: 'UTC-03:00',
        abbreviation: 'ART',
        tzName: 'Argentina Time',
      },
      {
        zoneName: 'America/Argentina/Mendoza',
        gmtOffset: -10800,
        gmtOffsetName: 'UTC-03:00',
        abbreviation: 'ART',
        tzName: 'Argentina Time',
      },
      {
        zoneName: 'America/Argentina/Rio_Gallegos',
        gmtOffset: -10800,
        gmtOffsetName: 'UTC-03:00',
        abbreviation: 'ART',
        tzName: 'Argentina Time',
      },
      {
        zoneName: 'America/Argentina/Salta',
        gmtOffset: -10800,
        gmtOffsetName: 'UTC-03:00',
        abbreviation: 'ART',
        tzName: 'Argentina Time',
      },
      {
        zoneName: 'America/Argentina/San_Juan',
        gmtOffset: -10800,
        gmtOffsetName: 'UTC-03:00',
        abbreviation: 'ART',
        tzName: 'Argentina Time',
      },
      {
        zoneName: 'America/Argentina/San_Luis',
        gmtOffset: -10800,
        gmtOffsetName: 'UTC-03:00',
        abbreviation: 'ART',
        tzName: 'Argentina Time',
      },
      {
        zoneName: 'America/Argentina/Tucuman',
        gmtOffset: -10800,
        gmtOffsetName: 'UTC-03:00',
        abbreviation: 'ART',
        tzName: 'Argentina Time',
      },
      {
        zoneName: 'America/Argentina/Ushuaia',
        gmtOffset: -10800,
        gmtOffsetName: 'UTC-03:00',
        abbreviation: 'ART',
        tzName: 'Argentina Time',
      },
    ],
    translations: {
      br: 'Argentina',
      pt: 'Argentina',
      nl: 'Argentinië',
      hr: 'Argentina',
      fa: 'آرژانتین',
      de: 'Argentinien',
      es: 'Argentina',
      fr: 'Argentine',
      ja: 'アルゼンチン',
      it: 'Argentina',
    },
    latitude: '-34.00000000',
    longitude: '-64.00000000',
    emoji: '🇦🇷',
    emojiU: 'U+1F1E6 U+1F1F7',
  },
  {
    id: 12,
    name: 'Armenia',
    iso3: 'ARM',
    iso2: 'AM',
    phone_code: '374',
    capital: 'Yerevan',
    currency: 'AMD',
    currency_symbol: '֏',
    tld: '.am',
    native: 'Հայաստան',
    region: 'Asia',
    subregion: 'Western Asia',
    timezones: [
      {
        zoneName: 'Asia/Yerevan',
        gmtOffset: 14400,
        gmtOffsetName: 'UTC+04:00',
        abbreviation: 'AMT',
        tzName: 'Armenia Time',
      },
    ],
    translations: {
      br: 'Armênia',
      pt: 'Arménia',
      nl: 'Armenië',
      hr: 'Armenija',
      fa: 'ارمنستان',
      de: 'Armenien',
      es: 'Armenia',
      fr: 'Arménie',
      ja: 'アルメニア',
      it: 'Armenia',
    },
    latitude: '40.00000000',
    longitude: '45.00000000',
    emoji: '🇦🇲',
    emojiU: 'U+1F1E6 U+1F1F2',
  },
  {
    id: 13,
    name: 'Aruba',
    iso3: 'ABW',
    iso2: 'AW',
    phone_code: '297',
    capital: 'Oranjestad',
    currency: 'AWG',
    currency_symbol: 'ƒ',
    tld: '.aw',
    native: 'Aruba',
    region: 'Americas',
    subregion: 'Caribbean',
    timezones: [
      {
        zoneName: 'America/Aruba',
        gmtOffset: -14400,
        gmtOffsetName: 'UTC-04:00',
        abbreviation: 'AST',
        tzName: 'Atlantic Standard Time',
      },
    ],
    translations: {
      br: 'Aruba',
      pt: 'Aruba',
      nl: 'Aruba',
      hr: 'Aruba',
      fa: 'آروبا',
      de: 'Aruba',
      es: 'Aruba',
      fr: 'Aruba',
      ja: 'アルバ',
      it: 'Aruba',
    },
    latitude: '12.50000000',
    longitude: '-69.96666666',
    emoji: '🇦🇼',
    emojiU: 'U+1F1E6 U+1F1FC',
  },
  {
    id: 14,
    name: 'Australia',
    iso3: 'AUS',
    iso2: 'AU',
    phone_code: '61',
    capital: 'Canberra',
    currency: 'AUD',
    currency_symbol: '$',
    tld: '.au',
    native: 'Australia',
    region: 'Oceania',
    subregion: 'Australia and New Zealand',
    timezones: [
      {
        zoneName: 'Antarctica/Macquarie',
        gmtOffset: 39600,
        gmtOffsetName: 'UTC+11:00',
        abbreviation: 'MIST',
        tzName: 'Macquarie Island Station Time',
      },
      {
        zoneName: 'Australia/Adelaide',
        gmtOffset: 37800,
        gmtOffsetName: 'UTC+10:30',
        abbreviation: 'ACDT',
        tzName: 'Australian Central Daylight Saving Time',
      },
      {
        zoneName: 'Australia/Brisbane',
        gmtOffset: 36000,
        gmtOffsetName: 'UTC+10:00',
        abbreviation: 'AEST',
        tzName: 'Australian Eastern Standard Time',
      },
      {
        zoneName: 'Australia/Broken_Hill',
        gmtOffset: 37800,
        gmtOffsetName: 'UTC+10:30',
        abbreviation: 'ACDT',
        tzName: 'Australian Central Daylight Saving Time',
      },
      {
        zoneName: 'Australia/Currie',
        gmtOffset: 39600,
        gmtOffsetName: 'UTC+11:00',
        abbreviation: 'AEDT',
        tzName: 'Australian Eastern Daylight Saving Time',
      },
      {
        zoneName: 'Australia/Darwin',
        gmtOffset: 34200,
        gmtOffsetName: 'UTC+09:30',
        abbreviation: 'ACST',
        tzName: 'Australian Central Standard Time',
      },
      {
        zoneName: 'Australia/Eucla',
        gmtOffset: 31500,
        gmtOffsetName: 'UTC+08:45',
        abbreviation: 'ACWST',
        tzName: 'Australian Central Western Standard Time (Unofficial)',
      },
      {
        zoneName: 'Australia/Hobart',
        gmtOffset: 39600,
        gmtOffsetName: 'UTC+11:00',
        abbreviation: 'AEDT',
        tzName: 'Australian Eastern Daylight Saving Time',
      },
      {
        zoneName: 'Australia/Lindeman',
        gmtOffset: 36000,
        gmtOffsetName: 'UTC+10:00',
        abbreviation: 'AEST',
        tzName: 'Australian Eastern Standard Time',
      },
      {
        zoneName: 'Australia/Lord_Howe',
        gmtOffset: 39600,
        gmtOffsetName: 'UTC+11:00',
        abbreviation: 'LHST',
        tzName: 'Lord Howe Summer Time',
      },
      {
        zoneName: 'Australia/Melbourne',
        gmtOffset: 39600,
        gmtOffsetName: 'UTC+11:00',
        abbreviation: 'AEDT',
        tzName: 'Australian Eastern Daylight Saving Time',
      },
      {
        zoneName: 'Australia/Perth',
        gmtOffset: 28800,
        gmtOffsetName: 'UTC+08:00',
        abbreviation: 'AWST',
        tzName: 'Australian Western Standard Time',
      },
      {
        zoneName: 'Australia/Sydney',
        gmtOffset: 39600,
        gmtOffsetName: 'UTC+11:00',
        abbreviation: 'AEDT',
        tzName: 'Australian Eastern Daylight Saving Time',
      },
    ],
    translations: {
      br: 'Austrália',
      pt: 'Austrália',
      nl: 'Australië',
      hr: 'Australija',
      fa: 'استرالیا',
      de: 'Australien',
      es: 'Australia',
      fr: 'Australie',
      ja: 'オーストラリア',
      it: 'Australia',
    },
    latitude: '-27.00000000',
    longitude: '133.00000000',
    emoji: '🇦🇺',
    emojiU: 'U+1F1E6 U+1F1FA',
  },
  {
    id: 15,
    name: 'Austria',
    iso3: 'AUT',
    iso2: 'AT',
    phone_code: '43',
    capital: 'Vienna',
    currency: 'EUR',
    currency_symbol: '€',
    tld: '.at',
    native: 'Österreich',
    region: 'Europe',
    subregion: 'Western Europe',
    timezones: [
      {
        zoneName: 'Europe/Vienna',
        gmtOffset: 3600,
        gmtOffsetName: 'UTC+01:00',
        abbreviation: 'CET',
        tzName: 'Central European Time',
      },
    ],
    translations: {
      br: 'áustria',
      pt: 'áustria',
      nl: 'Oostenrijk',
      hr: 'Austrija',
      fa: 'اتریش',
      de: 'Österreich',
      es: 'Austria',
      fr: 'Autriche',
      ja: 'オーストリア',
      it: 'Austria',
    },
    latitude: '47.33333333',
    longitude: '13.33333333',
    emoji: '🇦🇹',
    emojiU: 'U+1F1E6 U+1F1F9',
  },
  {
    id: 16,
    name: 'Azerbaijan',
    iso3: 'AZE',
    iso2: 'AZ',
    phone_code: '994',
    capital: 'Baku',
    currency: 'AZN',
    currency_symbol: 'm',
    tld: '.az',
    native: 'Azərbaycan',
    region: 'Asia',
    subregion: 'Western Asia',
    timezones: [
      {
        zoneName: 'Asia/Baku',
        gmtOffset: 14400,
        gmtOffsetName: 'UTC+04:00',
        abbreviation: 'AZT',
        tzName: 'Azerbaijan Time',
      },
    ],
    translations: {
      br: 'Azerbaijão',
      pt: 'Azerbaijão',
      nl: 'Azerbeidzjan',
      hr: 'Azerbajdžan',
      fa: 'آذربایجان',
      de: 'Aserbaidschan',
      es: 'Azerbaiyán',
      fr: 'Azerbaïdjan',
      ja: 'アゼルバイジャン',
      it: 'Azerbaijan',
    },
    latitude: '40.50000000',
    longitude: '47.50000000',
    emoji: '🇦🇿',
    emojiU: 'U+1F1E6 U+1F1FF',
  },
  {
    id: 17,
    name: 'Bahamas The',
    iso3: 'BHS',
    iso2: 'BS',
    phone_code: '+1-242',
    capital: 'Nassau',
    currency: 'BSD',
    currency_symbol: 'B$',
    tld: '.bs',
    native: 'Bahamas',
    region: 'Americas',
    subregion: 'Caribbean',
    timezones: [
      {
        zoneName: 'America/Nassau',
        gmtOffset: -18000,
        gmtOffsetName: 'UTC-05:00',
        abbreviation: 'EST',
        tzName: 'Eastern Standard Time (North America)',
      },
    ],
    translations: {
      br: 'Bahamas',
      pt: 'Baamas',
      nl: 'Bahama’s',
      hr: 'Bahami',
      fa: 'باهاما',
      de: 'Bahamas',
      es: 'Bahamas',
      fr: 'Bahamas',
      ja: 'バハマ',
      it: 'Bahamas',
    },
    latitude: '24.25000000',
    longitude: '-76.00000000',
    emoji: '🇧🇸',
    emojiU: 'U+1F1E7 U+1F1F8',
  },
  {
    id: 18,
    name: 'Bahrain',
    iso3: 'BHR',
    iso2: 'BH',
    phone_code: '973',
    capital: 'Manama',
    currency: 'BHD',
    currency_symbol: '.د.ب',
    tld: '.bh',
    native: '‏البحرين',
    region: 'Asia',
    subregion: 'Western Asia',
    timezones: [
      {
        zoneName: 'Asia/Bahrain',
        gmtOffset: 10800,
        gmtOffsetName: 'UTC+03:00',
        abbreviation: 'AST',
        tzName: 'Arabia Standard Time',
      },
    ],
    translations: {
      br: 'Bahrein',
      pt: 'Barém',
      nl: 'Bahrein',
      hr: 'Bahrein',
      fa: 'بحرین',
      de: 'Bahrain',
      es: 'Bahrein',
      fr: 'Bahreïn',
      ja: 'バーレーン',
      it: 'Bahrein',
    },
    latitude: '26.00000000',
    longitude: '50.55000000',
    emoji: '🇧🇭',
    emojiU: 'U+1F1E7 U+1F1ED',
  },
  {
    id: 19,
    name: 'Bangladesh',
    iso3: 'BGD',
    iso2: 'BD',
    phone_code: '880',
    capital: 'Dhaka',
    currency: 'BDT',
    currency_symbol: '৳',
    tld: '.bd',
    native: 'Bangladesh',
    region: 'Asia',
    subregion: 'Southern Asia',
    timezones: [
      {
        zoneName: 'Asia/Dhaka',
        gmtOffset: 21600,
        gmtOffsetName: 'UTC+06:00',
        abbreviation: 'BDT',
        tzName: 'Bangladesh Standard Time',
      },
    ],
    translations: {
      br: 'Bangladesh',
      pt: 'Bangladeche',
      nl: 'Bangladesh',
      hr: 'Bangladeš',
      fa: 'بنگلادش',
      de: 'Bangladesch',
      es: 'Bangladesh',
      fr: 'Bangladesh',
      ja: 'バングラデシュ',
      it: 'Bangladesh',
    },
    latitude: '24.00000000',
    longitude: '90.00000000',
    emoji: '🇧🇩',
    emojiU: 'U+1F1E7 U+1F1E9',
  },
  {
    id: 20,
    name: 'Barbados',
    iso3: 'BRB',
    iso2: 'BB',
    phone_code: '+1-246',
    capital: 'Bridgetown',
    currency: 'BBD',
    currency_symbol: 'Bds$',
    tld: '.bb',
    native: 'Barbados',
    region: 'Americas',
    subregion: 'Caribbean',
    timezones: [
      {
        zoneName: 'America/Barbados',
        gmtOffset: -14400,
        gmtOffsetName: 'UTC-04:00',
        abbreviation: 'AST',
        tzName: 'Atlantic Standard Time',
      },
    ],
    translations: {
      br: 'Barbados',
      pt: 'Barbados',
      nl: 'Barbados',
      hr: 'Barbados',
      fa: 'باربادوس',
      de: 'Barbados',
      es: 'Barbados',
      fr: 'Barbade',
      ja: 'バルバドス',
      it: 'Barbados',
    },
    latitude: '13.16666666',
    longitude: '-59.53333333',
    emoji: '🇧🇧',
    emojiU: 'U+1F1E7 U+1F1E7',
  },
  {
    id: 21,
    name: 'Belarus',
    iso3: 'BLR',
    iso2: 'BY',
    phone_code: '375',
    capital: 'Minsk',
    currency: 'BYN',
    currency_symbol: 'Br',
    tld: '.by',
    native: 'Белару́сь',
    region: 'Europe',
    subregion: 'Eastern Europe',
    timezones: [
      {
        zoneName: 'Europe/Minsk',
        gmtOffset: 10800,
        gmtOffsetName: 'UTC+03:00',
        abbreviation: 'MSK',
        tzName: 'Moscow Time',
      },
    ],
    translations: {
      br: 'Bielorrússia',
      pt: 'Bielorrússia',
      nl: 'Wit-Rusland',
      hr: 'Bjelorusija',
      fa: 'بلاروس',
      de: 'Weißrussland',
      es: 'Bielorrusia',
      fr: 'Biélorussie',
      ja: 'ベラルーシ',
      it: 'Bielorussia',
    },
    latitude: '53.00000000',
    longitude: '28.00000000',
    emoji: '🇧🇾',
    emojiU: 'U+1F1E7 U+1F1FE',
  },
  {
    id: 22,
    name: 'Belgium',
    iso3: 'BEL',
    iso2: 'BE',
    phone_code: '32',
    capital: 'Brussels',
    currency: 'EUR',
    currency_symbol: '€',
    tld: '.be',
    native: 'België',
    region: 'Europe',
    subregion: 'Western Europe',
    timezones: [
      {
        zoneName: 'Europe/Brussels',
        gmtOffset: 3600,
        gmtOffsetName: 'UTC+01:00',
        abbreviation: 'CET',
        tzName: 'Central European Time',
      },
    ],
    translations: {
      br: 'Bélgica',
      pt: 'Bélgica',
      nl: 'België',
      hr: 'Belgija',
      fa: 'بلژیک',
      de: 'Belgien',
      es: 'Bélgica',
      fr: 'Belgique',
      ja: 'ベルギー',
      it: 'Belgio',
    },
    latitude: '50.83333333',
    longitude: '4.00000000',
    emoji: '🇧🇪',
    emojiU: 'U+1F1E7 U+1F1EA',
  },
  {
    id: 23,
    name: 'Belize',
    iso3: 'BLZ',
    iso2: 'BZ',
    phone_code: '501',
    capital: 'Belmopan',
    currency: 'BZD',
    currency_symbol: '$',
    tld: '.bz',
    native: 'Belize',
    region: 'Americas',
    subregion: 'Central America',
    timezones: [
      {
        zoneName: 'America/Belize',
        gmtOffset: -21600,
        gmtOffsetName: 'UTC-06:00',
        abbreviation: 'CST',
        tzName: 'Central Standard Time (North America)',
      },
    ],
    translations: {
      br: 'Belize',
      pt: 'Belize',
      nl: 'Belize',
      hr: 'Belize',
      fa: 'بلیز',
      de: 'Belize',
      es: 'Belice',
      fr: 'Belize',
      ja: 'ベリーズ',
      it: 'Belize',
    },
    latitude: '17.25000000',
    longitude: '-88.75000000',
    emoji: '🇧🇿',
    emojiU: 'U+1F1E7 U+1F1FF',
  },
  {
    id: 24,
    name: 'Benin',
    iso3: 'BEN',
    iso2: 'BJ',
    phone_code: '229',
    capital: 'Porto-Novo',
    currency: 'XOF',
    currency_symbol: 'CFA',
    tld: '.bj',
    native: 'Bénin',
    region: 'Africa',
    subregion: 'Western Africa',
    timezones: [
      {
        zoneName: 'Africa/Porto-Novo',
        gmtOffset: 3600,
        gmtOffsetName: 'UTC+01:00',
        abbreviation: 'WAT',
        tzName: 'West Africa Time',
      },
    ],
    translations: {
      br: 'Benin',
      pt: 'Benim',
      nl: 'Benin',
      hr: 'Benin',
      fa: 'بنین',
      de: 'Benin',
      es: 'Benín',
      fr: 'Bénin',
      ja: 'ベナン',
      it: 'Benin',
    },
    latitude: '9.50000000',
    longitude: '2.25000000',
    emoji: '🇧🇯',
    emojiU: 'U+1F1E7 U+1F1EF',
  },
  {
    id: 25,
    name: 'Bermuda',
    iso3: 'BMU',
    iso2: 'BM',
    phone_code: '+1-441',
    capital: 'Hamilton',
    currency: 'BMD',
    currency_symbol: '$',
    tld: '.bm',
    native: 'Bermuda',
    region: 'Americas',
    subregion: 'Northern America',
    timezones: [
      {
        zoneName: 'Atlantic/Bermuda',
        gmtOffset: -14400,
        gmtOffsetName: 'UTC-04:00',
        abbreviation: 'AST',
        tzName: 'Atlantic Standard Time',
      },
    ],
    translations: {
      br: 'Bermudas',
      pt: 'Bermudas',
      nl: 'Bermuda',
      hr: 'Bermudi',
      fa: 'برمودا',
      de: 'Bermuda',
      es: 'Bermudas',
      fr: 'Bermudes',
      ja: 'バミューダ',
      it: 'Bermuda',
    },
    latitude: '32.33333333',
    longitude: '-64.75000000',
    emoji: '🇧🇲',
    emojiU: 'U+1F1E7 U+1F1F2',
  },
  {
    id: 26,
    name: 'Bhutan',
    iso3: 'BTN',
    iso2: 'BT',
    phone_code: '975',
    capital: 'Thimphu',
    currency: 'BTN',
    currency_symbol: 'Nu.',
    tld: '.bt',
    native: 'ʼbrug-yul',
    region: 'Asia',
    subregion: 'Southern Asia',
    timezones: [
      {
        zoneName: 'Asia/Thimphu',
        gmtOffset: 21600,
        gmtOffsetName: 'UTC+06:00',
        abbreviation: 'BTT',
        tzName: 'Bhutan Time',
      },
    ],
    translations: {
      br: 'Butão',
      pt: 'Butão',
      nl: 'Bhutan',
      hr: 'Butan',
      fa: 'بوتان',
      de: 'Bhutan',
      es: 'Bután',
      fr: 'Bhoutan',
      ja: 'ブータン',
      it: 'Bhutan',
    },
    latitude: '27.50000000',
    longitude: '90.50000000',
    emoji: '🇧🇹',
    emojiU: 'U+1F1E7 U+1F1F9',
  },
  {
    id: 27,
    name: 'Bolivia',
    iso3: 'BOL',
    iso2: 'BO',
    phone_code: '591',
    capital: 'Sucre',
    currency: 'BOB',
    currency_symbol: 'Bs.',
    tld: '.bo',
    native: 'Bolivia',
    region: 'Americas',
    subregion: 'South America',
    timezones: [
      {
        zoneName: 'America/La_Paz',
        gmtOffset: -14400,
        gmtOffsetName: 'UTC-04:00',
        abbreviation: 'BOT',
        tzName: 'Bolivia Time',
      },
    ],
    translations: {
      br: 'Bolívia',
      pt: 'Bolívia',
      nl: 'Bolivia',
      hr: 'Bolivija',
      fa: 'بولیوی',
      de: 'Bolivien',
      es: 'Bolivia',
      fr: 'Bolivie',
      ja: 'ボリビア多民族国',
      it: 'Bolivia',
    },
    latitude: '-17.00000000',
    longitude: '-65.00000000',
    emoji: '🇧🇴',
    emojiU: 'U+1F1E7 U+1F1F4',
  },
  {
    id: 155,
    name: 'Bonaire, Sint Eustatius and Saba',
    iso3: 'BES',
    iso2: 'BQ',
    phone_code: '599',
    capital: 'Kralendijk',
    currency: 'USD',
    currency_symbol: '$',
    tld: '.an',
    native: 'Caribisch Nederland',
    region: 'Americas',
    subregion: 'Caribbean',
    timezones: [
      {
        zoneName: 'America/Anguilla',
        gmtOffset: -14400,
        gmtOffsetName: 'UTC-04:00',
        abbreviation: 'AST',
        tzName: 'Atlantic Standard Time',
      },
    ],
    translations: {
      br: 'Bonaire',
      pt: 'Bonaire',
      fa: 'بونیر',
      de: 'Bonaire, Sint Eustatius und Saba',
      fr: 'Bonaire, Saint-Eustache et Saba',
      it: 'Bonaire, Saint-Eustache e Saba',
    },
    latitude: '12.15000000',
    longitude: '-68.26666700',
    emoji: '🇧🇶',
    emojiU: 'U+1F1E7 U+1F1F6',
  },
  {
    id: 28,
    name: 'Bosnia and Herzegovina',
    iso3: 'BIH',
    iso2: 'BA',
    phone_code: '387',
    capital: 'Sarajevo',
    currency: 'BAM',
    currency_symbol: 'KM',
    tld: '.ba',
    native: 'Bosna i Hercegovina',
    region: 'Europe',
    subregion: 'Southern Europe',
    timezones: [
      {
        zoneName: 'Europe/Sarajevo',
        gmtOffset: 3600,
        gmtOffsetName: 'UTC+01:00',
        abbreviation: 'CET',
        tzName: 'Central European Time',
      },
    ],
    translations: {
      br: 'Bósnia e Herzegovina',
      pt: 'Bósnia e Herzegovina',
      nl: 'Bosnië en Herzegovina',
      hr: 'Bosna i Hercegovina',
      fa: 'بوسنی و هرزگوین',
      de: 'Bosnien und Herzegowina',
      es: 'Bosnia y Herzegovina',
      fr: 'Bosnie-Herzégovine',
      ja: 'ボスニア・ヘルツェゴビナ',
      it: 'Bosnia ed Erzegovina',
    },
    latitude: '44.00000000',
    longitude: '18.00000000',
    emoji: '🇧🇦',
    emojiU: 'U+1F1E7 U+1F1E6',
  },
  {
    id: 29,
    name: 'Botswana',
    iso3: 'BWA',
    iso2: 'BW',
    phone_code: '267',
    capital: 'Gaborone',
    currency: 'BWP',
    currency_symbol: 'P',
    tld: '.bw',
    native: 'Botswana',
    region: 'Africa',
    subregion: 'Southern Africa',
    timezones: [
      {
        zoneName: 'Africa/Gaborone',
        gmtOffset: 7200,
        gmtOffsetName: 'UTC+02:00',
        abbreviation: 'CAT',
        tzName: 'Central Africa Time',
      },
    ],
    translations: {
      br: 'Botsuana',
      pt: 'Botsuana',
      nl: 'Botswana',
      hr: 'Bocvana',
      fa: 'بوتسوانا',
      de: 'Botswana',
      es: 'Botswana',
      fr: 'Botswana',
      ja: 'ボツワナ',
      it: 'Botswana',
    },
    latitude: '-22.00000000',
    longitude: '24.00000000',
    emoji: '🇧🇼',
    emojiU: 'U+1F1E7 U+1F1FC',
  },
  {
    id: 30,
    name: 'Bouvet Island',
    iso3: 'BVT',
    iso2: 'BV',
    phone_code: '0055',
    capital: '',
    currency: 'NOK',
    currency_symbol: 'kr',
    tld: '.bv',
    native: 'Bouvetøya',
    region: '',
    subregion: '',
    timezones: [
      {
        zoneName: 'Europe/Oslo',
        gmtOffset: 3600,
        gmtOffsetName: 'UTC+01:00',
        abbreviation: 'CET',
        tzName: 'Central European Time',
      },
    ],
    translations: {
      br: 'Ilha Bouvet',
      pt: 'Ilha Bouvet',
      nl: 'Bouveteiland',
      hr: 'Otok Bouvet',
      fa: 'جزیره بووه',
      de: 'Bouvetinsel',
      es: 'Isla Bouvet',
      fr: 'Île Bouvet',
      ja: 'ブーベ島',
      it: 'Isola Bouvet',
    },
    latitude: '-54.43333333',
    longitude: '3.40000000',
    emoji: '🇧🇻',
    emojiU: 'U+1F1E7 U+1F1FB',
  },
  {
    id: 31,
    name: 'Brazil',
    iso3: 'BRA',
    iso2: 'BR',
    phone_code: '55',
    capital: 'Brasilia',
    currency: 'BRL',
    currency_symbol: 'R$',
    tld: '.br',
    native: 'Brasil',
    region: 'Americas',
    subregion: 'South America',
    timezones: [
      {
        zoneName: 'America/Araguaina',
        gmtOffset: -10800,
        gmtOffsetName: 'UTC-03:00',
        abbreviation: 'BRT',
        tzName: 'Brasília Time',
      },
      {
        zoneName: 'America/Bahia',
        gmtOffset: -10800,
        gmtOffsetName: 'UTC-03:00',
        abbreviation: 'BRT',
        tzName: 'Brasília Time',
      },
      {
        zoneName: 'America/Belem',
        gmtOffset: -10800,
        gmtOffsetName: 'UTC-03:00',
        abbreviation: 'BRT',
        tzName: 'Brasília Time',
      },
      {
        zoneName: 'America/Boa_Vista',
        gmtOffset: -14400,
        gmtOffsetName: 'UTC-04:00',
        abbreviation: 'AMT',
        tzName: 'Amazon Time (Brazil)[3',
      },
      {
        zoneName: 'America/Campo_Grande',
        gmtOffset: -14400,
        gmtOffsetName: 'UTC-04:00',
        abbreviation: 'AMT',
        tzName: 'Amazon Time (Brazil)[3',
      },
      {
        zoneName: 'America/Cuiaba',
        gmtOffset: -14400,
        gmtOffsetName: 'UTC-04:00',
        abbreviation: 'BRT',
        tzName: 'Brasilia Time',
      },
      {
        zoneName: 'America/Eirunepe',
        gmtOffset: -18000,
        gmtOffsetName: 'UTC-05:00',
        abbreviation: 'ACT',
        tzName: 'Acre Time',
      },
      {
        zoneName: 'America/Fortaleza',
        gmtOffset: -10800,
        gmtOffsetName: 'UTC-03:00',
        abbreviation: 'BRT',
        tzName: 'Brasília Time',
      },
      {
        zoneName: 'America/Maceio',
        gmtOffset: -10800,
        gmtOffsetName: 'UTC-03:00',
        abbreviation: 'BRT',
        tzName: 'Brasília Time',
      },
      {
        zoneName: 'America/Manaus',
        gmtOffset: -14400,
        gmtOffsetName: 'UTC-04:00',
        abbreviation: 'AMT',
        tzName: 'Amazon Time (Brazil)',
      },
      {
        zoneName: 'America/Noronha',
        gmtOffset: -7200,
        gmtOffsetName: 'UTC-02:00',
        abbreviation: 'FNT',
        tzName: 'Fernando de Noronha Time',
      },
      {
        zoneName: 'America/Porto_Velho',
        gmtOffset: -14400,
        gmtOffsetName: 'UTC-04:00',
        abbreviation: 'AMT',
        tzName: 'Amazon Time (Brazil)[3',
      },
      {
        zoneName: 'America/Recife',
        gmtOffset: -10800,
        gmtOffsetName: 'UTC-03:00',
        abbreviation: 'BRT',
        tzName: 'Brasília Time',
      },
      {
        zoneName: 'America/Rio_Branco',
        gmtOffset: -18000,
        gmtOffsetName: 'UTC-05:00',
        abbreviation: 'ACT',
        tzName: 'Acre Time',
      },
      {
        zoneName: 'America/Santarem',
        gmtOffset: -10800,
        gmtOffsetName: 'UTC-03:00',
        abbreviation: 'BRT',
        tzName: 'Brasília Time',
      },
      {
        zoneName: 'America/Sao_Paulo',
        gmtOffset: -10800,
        gmtOffsetName: 'UTC-03:00',
        abbreviation: 'BRT',
        tzName: 'Brasília Time',
      },
    ],
    translations: {
      br: 'Brasil',
      pt: 'Brasil',
      nl: 'Brazilië',
      hr: 'Brazil',
      fa: 'برزیل',
      de: 'Brasilien',
      es: 'Brasil',
      fr: 'Brésil',
      ja: 'ブラジル',
      it: 'Brasile',
    },
    latitude: '-10.00000000',
    longitude: '-55.00000000',
    emoji: '🇧🇷',
    emojiU: 'U+1F1E7 U+1F1F7',
  },
  {
    id: 32,
    name: 'British Indian Ocean Territory',
    iso3: 'IOT',
    iso2: 'IO',
    phone_code: '246',
    capital: 'Diego Garcia',
    currency: 'USD',
    currency_symbol: '$',
    tld: '.io',
    native: 'British Indian Ocean Territory',
    region: 'Africa',
    subregion: 'Eastern Africa',
    timezones: [
      {
        zoneName: 'Indian/Chagos',
        gmtOffset: 21600,
        gmtOffsetName: 'UTC+06:00',
        abbreviation: 'IOT',
        tzName: 'Indian Ocean Time',
      },
    ],
    translations: {
      br: 'Território Britânico do Oceano íÍdico',
      pt: 'Território Britânico do Oceano Índico',
      nl: 'Britse Gebieden in de Indische Oceaan',
      hr: 'Britanski Indijskooceanski teritorij',
      fa: 'قلمرو بریتانیا در اقیانوس هند',
      de: 'Britisches Territorium im Indischen Ozean',
      es: 'Territorio Británico del Océano Índico',
      fr: "Territoire britannique de l'océan Indien",
      ja: 'イギリス領インド洋地域',
      it: "Territorio britannico dell'oceano indiano",
    },
    latitude: '-6.00000000',
    longitude: '71.50000000',
    emoji: '🇮🇴',
    emojiU: 'U+1F1EE U+1F1F4',
  },
  {
    id: 33,
    name: 'Brunei',
    iso3: 'BRN',
    iso2: 'BN',
    phone_code: '673',
    capital: 'Bandar Seri Begawan',
    currency: 'BND',
    currency_symbol: 'B$',
    tld: '.bn',
    native: 'Negara Brunei Darussalam',
    region: 'Asia',
    subregion: 'South-Eastern Asia',
    timezones: [
      {
        zoneName: 'Asia/Brunei',
        gmtOffset: 28800,
        gmtOffsetName: 'UTC+08:00',
        abbreviation: 'BNT',
        tzName: 'Brunei Darussalam Time',
      },
    ],
    translations: {
      br: 'Brunei',
      pt: 'Brunei',
      nl: 'Brunei',
      hr: 'Brunej',
      fa: 'برونئی',
      de: 'Brunei',
      es: 'Brunei',
      fr: 'Brunei',
      ja: 'ブルネイ・ダルサラーム',
      it: 'Brunei',
    },
    latitude: '4.50000000',
    longitude: '114.66666666',
    emoji: '🇧🇳',
    emojiU: 'U+1F1E7 U+1F1F3',
  },
  {
    id: 34,
    name: 'Bulgaria',
    iso3: 'BGR',
    iso2: 'BG',
    phone_code: '359',
    capital: 'Sofia',
    currency: 'BGN',
    currency_symbol: 'Лв.',
    tld: '.bg',
    native: 'България',
    region: 'Europe',
    subregion: 'Eastern Europe',
    timezones: [
      {
        zoneName: 'Europe/Sofia',
        gmtOffset: 7200,
        gmtOffsetName: 'UTC+02:00',
        abbreviation: 'EET',
        tzName: 'Eastern European Time',
      },
    ],
    translations: {
      br: 'Bulgária',
      pt: 'Bulgária',
      nl: 'Bulgarije',
      hr: 'Bugarska',
      fa: 'بلغارستان',
      de: 'Bulgarien',
      es: 'Bulgaria',
      fr: 'Bulgarie',
      ja: 'ブルガリア',
      it: 'Bulgaria',
    },
    latitude: '43.00000000',
    longitude: '25.00000000',
    emoji: '🇧🇬',
    emojiU: 'U+1F1E7 U+1F1EC',
  },
  {
    id: 35,
    name: 'Burkina Faso',
    iso3: 'BFA',
    iso2: 'BF',
    phone_code: '226',
    capital: 'Ouagadougou',
    currency: 'XOF',
    currency_symbol: 'CFA',
    tld: '.bf',
    native: 'Burkina Faso',
    region: 'Africa',
    subregion: 'Western Africa',
    timezones: [
      {
        zoneName: 'Africa/Ouagadougou',
        gmtOffset: 0,
        gmtOffsetName: 'UTC±00',
        abbreviation: 'GMT',
        tzName: 'Greenwich Mean Time',
      },
    ],
    translations: {
      br: 'Burkina Faso',
      pt: 'Burquina Faso',
      nl: 'Burkina Faso',
      hr: 'Burkina Faso',
      fa: 'بورکینافاسو',
      de: 'Burkina Faso',
      es: 'Burkina Faso',
      fr: 'Burkina Faso',
      ja: 'ブルキナファソ',
      it: 'Burkina Faso',
    },
    latitude: '13.00000000',
    longitude: '-2.00000000',
    emoji: '🇧🇫',
    emojiU: 'U+1F1E7 U+1F1EB',
  },
  {
    id: 36,
    name: 'Burundi',
    iso3: 'BDI',
    iso2: 'BI',
    phone_code: '257',
    capital: 'Bujumbura',
    currency: 'BIF',
    currency_symbol: 'FBu',
    tld: '.bi',
    native: 'Burundi',
    region: 'Africa',
    subregion: 'Eastern Africa',
    timezones: [
      {
        zoneName: 'Africa/Bujumbura',
        gmtOffset: 7200,
        gmtOffsetName: 'UTC+02:00',
        abbreviation: 'CAT',
        tzName: 'Central Africa Time',
      },
    ],
    translations: {
      br: 'Burundi',
      pt: 'Burúndi',
      nl: 'Burundi',
      hr: 'Burundi',
      fa: 'بوروندی',
      de: 'Burundi',
      es: 'Burundi',
      fr: 'Burundi',
      ja: 'ブルンジ',
      it: 'Burundi',
    },
    latitude: '-3.50000000',
    longitude: '30.00000000',
    emoji: '🇧🇮',
    emojiU: 'U+1F1E7 U+1F1EE',
  },
  {
    id: 37,
    name: 'Cambodia',
    iso3: 'KHM',
    iso2: 'KH',
    phone_code: '855',
    capital: 'Phnom Penh',
    currency: 'KHR',
    currency_symbol: 'KHR',
    tld: '.kh',
    native: 'Kâmpŭchéa',
    region: 'Asia',
    subregion: 'South-Eastern Asia',
    timezones: [
      {
        zoneName: 'Asia/Phnom_Penh',
        gmtOffset: 25200,
        gmtOffsetName: 'UTC+07:00',
        abbreviation: 'ICT',
        tzName: 'Indochina Time',
      },
    ],
    translations: {
      br: 'Camboja',
      pt: 'Camboja',
      nl: 'Cambodja',
      hr: 'Kambodža',
      fa: 'کامبوج',
      de: 'Kambodscha',
      es: 'Camboya',
      fr: 'Cambodge',
      ja: 'カンボジア',
      it: 'Cambogia',
    },
    latitude: '13.00000000',
    longitude: '105.00000000',
    emoji: '🇰🇭',
    emojiU: 'U+1F1F0 U+1F1ED',
  },
  {
    id: 38,
    name: 'Cameroon',
    iso3: 'CMR',
    iso2: 'CM',
    phone_code: '237',
    capital: 'Yaounde',
    currency: 'XAF',
    currency_symbol: 'FCFA',
    tld: '.cm',
    native: 'Cameroon',
    region: 'Africa',
    subregion: 'Middle Africa',
    timezones: [
      {
        zoneName: 'Africa/Douala',
        gmtOffset: 3600,
        gmtOffsetName: 'UTC+01:00',
        abbreviation: 'WAT',
        tzName: 'West Africa Time',
      },
    ],
    translations: {
      br: 'Camarões',
      pt: 'Camarões',
      nl: 'Kameroen',
      hr: 'Kamerun',
      fa: 'کامرون',
      de: 'Kamerun',
      es: 'Camerún',
      fr: 'Cameroun',
      ja: 'カメルーン',
      it: 'Camerun',
    },
    latitude: '6.00000000',
    longitude: '12.00000000',
    emoji: '🇨🇲',
    emojiU: 'U+1F1E8 U+1F1F2',
  },
  {
    id: 39,
    name: 'Canada',
    iso3: 'CAN',
    iso2: 'CA',
    phone_code: '1',
    capital: 'Ottawa',
    currency: 'CAD',
    currency_symbol: '$',
    tld: '.ca',
    native: 'Canada',
    region: 'Americas',
    subregion: 'Northern America',
    timezones: [
      {
        zoneName: 'America/Atikokan',
        gmtOffset: -18000,
        gmtOffsetName: 'UTC-05:00',
        abbreviation: 'EST',
        tzName: 'Eastern Standard Time (North America)',
      },
      {
        zoneName: 'America/Blanc-Sablon',
        gmtOffset: -14400,
        gmtOffsetName: 'UTC-04:00',
        abbreviation: 'AST',
        tzName: 'Atlantic Standard Time',
      },
      {
        zoneName: 'America/Cambridge_Bay',
        gmtOffset: -25200,
        gmtOffsetName: 'UTC-07:00',
        abbreviation: 'MST',
        tzName: 'Mountain Standard Time (North America)',
      },
      {
        zoneName: 'America/Creston',
        gmtOffset: -25200,
        gmtOffsetName: 'UTC-07:00',
        abbreviation: 'MST',
        tzName: 'Mountain Standard Time (North America)',
      },
      {
        zoneName: 'America/Dawson',
        gmtOffset: -25200,
        gmtOffsetName: 'UTC-07:00',
        abbreviation: 'MST',
        tzName: 'Mountain Standard Time (North America)',
      },
      {
        zoneName: 'America/Dawson_Creek',
        gmtOffset: -25200,
        gmtOffsetName: 'UTC-07:00',
        abbreviation: 'MST',
        tzName: 'Mountain Standard Time (North America)',
      },
      {
        zoneName: 'America/Edmonton',
        gmtOffset: -25200,
        gmtOffsetName: 'UTC-07:00',
        abbreviation: 'MST',
        tzName: 'Mountain Standard Time (North America)',
      },
      {
        zoneName: 'America/Fort_Nelson',
        gmtOffset: -25200,
        gmtOffsetName: 'UTC-07:00',
        abbreviation: 'MST',
        tzName: 'Mountain Standard Time (North America)',
      },
      {
        zoneName: 'America/Glace_Bay',
        gmtOffset: -14400,
        gmtOffsetName: 'UTC-04:00',
        abbreviation: 'AST',
        tzName: 'Atlantic Standard Time',
      },
      {
        zoneName: 'America/Goose_Bay',
        gmtOffset: -14400,
        gmtOffsetName: 'UTC-04:00',
        abbreviation: 'AST',
        tzName: 'Atlantic Standard Time',
      },
      {
        zoneName: 'America/Halifax',
        gmtOffset: -14400,
        gmtOffsetName: 'UTC-04:00',
        abbreviation: 'AST',
        tzName: 'Atlantic Standard Time',
      },
      {
        zoneName: 'America/Inuvik',
        gmtOffset: -25200,
        gmtOffsetName: 'UTC-07:00',
        abbreviation: 'MST',
        tzName: 'Mountain Standard Time (North America',
      },
      {
        zoneName: 'America/Iqaluit',
        gmtOffset: -18000,
        gmtOffsetName: 'UTC-05:00',
        abbreviation: 'EST',
        tzName: 'Eastern Standard Time (North America',
      },
      {
        zoneName: 'America/Moncton',
        gmtOffset: -14400,
        gmtOffsetName: 'UTC-04:00',
        abbreviation: 'AST',
        tzName: 'Atlantic Standard Time',
      },
      {
        zoneName: 'America/Nipigon',
        gmtOffset: -18000,
        gmtOffsetName: 'UTC-05:00',
        abbreviation: 'EST',
        tzName: 'Eastern Standard Time (North America',
      },
      {
        zoneName: 'America/Pangnirtung',
        gmtOffset: -18000,
        gmtOffsetName: 'UTC-05:00',
        abbreviation: 'EST',
        tzName: 'Eastern Standard Time (North America',
      },
      {
        zoneName: 'America/Rainy_River',
        gmtOffset: -21600,
        gmtOffsetName: 'UTC-06:00',
        abbreviation: 'CST',
        tzName: 'Central Standard Time (North America',
      },
      {
        zoneName: 'America/Rankin_Inlet',
        gmtOffset: -21600,
        gmtOffsetName: 'UTC-06:00',
        abbreviation: 'CST',
        tzName: 'Central Standard Time (North America',
      },
      {
        zoneName: 'America/Regina',
        gmtOffset: -21600,
        gmtOffsetName: 'UTC-06:00',
        abbreviation: 'CST',
        tzName: 'Central Standard Time (North America',
      },
      {
        zoneName: 'America/Resolute',
        gmtOffset: -21600,
        gmtOffsetName: 'UTC-06:00',
        abbreviation: 'CST',
        tzName: 'Central Standard Time (North America',
      },
      {
        zoneName: 'America/St_Johns',
        gmtOffset: -12600,
        gmtOffsetName: 'UTC-03:30',
        abbreviation: 'NST',
        tzName: 'Newfoundland Standard Time',
      },
      {
        zoneName: 'America/Swift_Current',
        gmtOffset: -21600,
        gmtOffsetName: 'UTC-06:00',
        abbreviation: 'CST',
        tzName: 'Central Standard Time (North America',
      },
      {
        zoneName: 'America/Thunder_Bay',
        gmtOffset: -18000,
        gmtOffsetName: 'UTC-05:00',
        abbreviation: 'EST',
        tzName: 'Eastern Standard Time (North America',
      },
      {
        zoneName: 'America/Toronto',
        gmtOffset: -18000,
        gmtOffsetName: 'UTC-05:00',
        abbreviation: 'EST',
        tzName: 'Eastern Standard Time (North America',
      },
      {
        zoneName: 'America/Vancouver',
        gmtOffset: -28800,
        gmtOffsetName: 'UTC-08:00',
        abbreviation: 'PST',
        tzName: 'Pacific Standard Time (North America',
      },
      {
        zoneName: 'America/Whitehorse',
        gmtOffset: -25200,
        gmtOffsetName: 'UTC-07:00',
        abbreviation: 'MST',
        tzName: 'Mountain Standard Time (North America',
      },
      {
        zoneName: 'America/Winnipeg',
        gmtOffset: -21600,
        gmtOffsetName: 'UTC-06:00',
        abbreviation: 'CST',
        tzName: 'Central Standard Time (North America',
      },
      {
        zoneName: 'America/Yellowknife',
        gmtOffset: -25200,
        gmtOffsetName: 'UTC-07:00',
        abbreviation: 'MST',
        tzName: 'Mountain Standard Time (North America',
      },
    ],
    translations: {
      br: 'Canadá',
      pt: 'Canadá',
      nl: 'Canada',
      hr: 'Kanada',
      fa: 'کانادا',
      de: 'Kanada',
      es: 'Canadá',
      fr: 'Canada',
      ja: 'カナダ',
      it: 'Canada',
    },
    latitude: '60.00000000',
    longitude: '-95.00000000',
    emoji: '🇨🇦',
    emojiU: 'U+1F1E8 U+1F1E6',
  },
  {
    id: 40,
    name: 'Cape Verde',
    iso3: 'CPV',
    iso2: 'CV',
    phone_code: '238',
    capital: 'Praia',
    currency: 'CVE',
    currency_symbol: '$',
    tld: '.cv',
    native: 'Cabo Verde',
    region: 'Africa',
    subregion: 'Western Africa',
    timezones: [
      {
        zoneName: 'Atlantic/Cape_Verde',
        gmtOffset: -3600,
        gmtOffsetName: 'UTC-01:00',
        abbreviation: 'CVT',
        tzName: 'Cape Verde Time',
      },
    ],
    translations: {
      br: 'Cabo Verde',
      pt: 'Cabo Verde',
      nl: 'Kaapverdië',
      hr: 'Zelenortska Republika',
      fa: 'کیپ ورد',
      de: 'Kap Verde',
      es: 'Cabo Verde',
      fr: 'Cap Vert',
      ja: 'カーボベルデ',
      it: 'Capo Verde',
    },
    latitude: '16.00000000',
    longitude: '-24.00000000',
    emoji: '🇨🇻',
    emojiU: 'U+1F1E8 U+1F1FB',
  },
  {
    id: 41,
    name: 'Cayman Islands',
    iso3: 'CYM',
    iso2: 'KY',
    phone_code: '+1-345',
    capital: 'George Town',
    currency: 'KYD',
    currency_symbol: '$',
    tld: '.ky',
    native: 'Cayman Islands',
    region: 'Americas',
    subregion: 'Caribbean',
    timezones: [
      {
        zoneName: 'America/Cayman',
        gmtOffset: -18000,
        gmtOffsetName: 'UTC-05:00',
        abbreviation: 'EST',
        tzName: 'Eastern Standard Time (North America',
      },
    ],
    translations: {
      br: 'Ilhas Cayman',
      pt: 'Ilhas Caimão',
      nl: 'Caymaneilanden',
      hr: 'Kajmanski otoci',
      fa: 'جزایر کیمن',
      de: 'Kaimaninseln',
      es: 'Islas Caimán',
      fr: 'Îles Caïmans',
      ja: 'ケイマン諸島',
      it: 'Isole Cayman',
    },
    latitude: '19.50000000',
    longitude: '-80.50000000',
    emoji: '🇰🇾',
    emojiU: 'U+1F1F0 U+1F1FE',
  },
  {
    id: 42,
    name: 'Central African Republic',
    iso3: 'CAF',
    iso2: 'CF',
    phone_code: '236',
    capital: 'Bangui',
    currency: 'XAF',
    currency_symbol: 'FCFA',
    tld: '.cf',
    native: 'Ködörösêse tî Bêafrîka',
    region: 'Africa',
    subregion: 'Middle Africa',
    timezones: [
      {
        zoneName: 'Africa/Bangui',
        gmtOffset: 3600,
        gmtOffsetName: 'UTC+01:00',
        abbreviation: 'WAT',
        tzName: 'West Africa Time',
      },
    ],
    translations: {
      br: 'República Centro-Africana',
      pt: 'República Centro-Africana',
      nl: 'Centraal-Afrikaanse Republiek',
      hr: 'Srednjoafrička Republika',
      fa: 'جمهوری آفریقای مرکزی',
      de: 'Zentralafrikanische Republik',
      es: 'República Centroafricana',
      fr: 'République centrafricaine',
      ja: '中央アフリカ共和国',
      it: 'Repubblica Centrafricana',
    },
    latitude: '7.00000000',
    longitude: '21.00000000',
    emoji: '🇨🇫',
    emojiU: 'U+1F1E8 U+1F1EB',
  },
  {
    id: 43,
    name: 'Chad',
    iso3: 'TCD',
    iso2: 'TD',
    phone_code: '235',
    capital: "N'Djamena",
    currency: 'XAF',
    currency_symbol: 'FCFA',
    tld: '.td',
    native: 'Tchad',
    region: 'Africa',
    subregion: 'Middle Africa',
    timezones: [
      {
        zoneName: 'Africa/Ndjamena',
        gmtOffset: 3600,
        gmtOffsetName: 'UTC+01:00',
        abbreviation: 'WAT',
        tzName: 'West Africa Time',
      },
    ],
    translations: {
      br: 'Chade',
      pt: 'Chade',
      nl: 'Tsjaad',
      hr: 'Čad',
      fa: 'چاد',
      de: 'Tschad',
      es: 'Chad',
      fr: 'Tchad',
      ja: 'チャド',
      it: 'Ciad',
    },
    latitude: '15.00000000',
    longitude: '19.00000000',
    emoji: '🇹🇩',
    emojiU: 'U+1F1F9 U+1F1E9',
  },
  {
    id: 44,
    name: 'Chile',
    iso3: 'CHL',
    iso2: 'CL',
    phone_code: '56',
    capital: 'Santiago',
    currency: 'CLP',
    currency_symbol: '$',
    tld: '.cl',
    native: 'Chile',
    region: 'Americas',
    subregion: 'South America',
    timezones: [
      {
        zoneName: 'America/Punta_Arenas',
        gmtOffset: -10800,
        gmtOffsetName: 'UTC-03:00',
        abbreviation: 'CLST',
        tzName: 'Chile Summer Time',
      },
      {
        zoneName: 'America/Santiago',
        gmtOffset: -10800,
        gmtOffsetName: 'UTC-03:00',
        abbreviation: 'CLST',
        tzName: 'Chile Summer Time',
      },
      {
        zoneName: 'Pacific/Easter',
        gmtOffset: -18000,
        gmtOffsetName: 'UTC-05:00',
        abbreviation: 'EASST',
        tzName: 'Easter Island Summer Time',
      },
    ],
    translations: {
      br: 'Chile',
      pt: 'Chile',
      nl: 'Chili',
      hr: 'Čile',
      fa: 'شیلی',
      de: 'Chile',
      es: 'Chile',
      fr: 'Chili',
      ja: 'チリ',
      it: 'Cile',
    },
    latitude: '-30.00000000',
    longitude: '-71.00000000',
    emoji: '🇨🇱',
    emojiU: 'U+1F1E8 U+1F1F1',
  },
  {
    id: 45,
    name: 'China',
    iso3: 'CHN',
    iso2: 'CN',
    phone_code: '86',
    capital: 'Beijing',
    currency: 'CNY',
    currency_symbol: '¥',
    tld: '.cn',
    native: '中国',
    region: 'Asia',
    subregion: 'Eastern Asia',
    timezones: [
      {
        zoneName: 'Asia/Shanghai',
        gmtOffset: 28800,
        gmtOffsetName: 'UTC+08:00',
        abbreviation: 'CST',
        tzName: 'China Standard Time',
      },
      {
        zoneName: 'Asia/Urumqi',
        gmtOffset: 21600,
        gmtOffsetName: 'UTC+06:00',
        abbreviation: 'XJT',
        tzName: 'China Standard Time',
      },
    ],
    translations: {
      br: 'China',
      pt: 'China',
      nl: 'China',
      hr: 'Kina',
      fa: 'چین',
      de: 'China',
      es: 'China',
      fr: 'Chine',
      ja: '中国',
      it: 'Cina',
    },
    latitude: '35.00000000',
    longitude: '105.00000000',
    emoji: '🇨🇳',
    emojiU: 'U+1F1E8 U+1F1F3',
  },
  {
    id: 46,
    name: 'Christmas Island',
    iso3: 'CXR',
    iso2: 'CX',
    phone_code: '61',
    capital: 'Flying Fish Cove',
    currency: 'AUD',
    currency_symbol: '$',
    tld: '.cx',
    native: 'Christmas Island',
    region: 'Oceania',
    subregion: 'Australia and New Zealand',
    timezones: [
      {
        zoneName: 'Indian/Christmas',
        gmtOffset: 25200,
        gmtOffsetName: 'UTC+07:00',
        abbreviation: 'CXT',
        tzName: 'Christmas Island Time',
      },
    ],
    translations: {
      br: 'Ilha Christmas',
      pt: 'Ilha do Natal',
      nl: 'Christmaseiland',
      hr: 'Božićni otok',
      fa: 'جزیره کریسمس',
      de: 'Weihnachtsinsel',
      es: 'Isla de Navidad',
      fr: 'Île Christmas',
      ja: 'クリスマス島',
      it: 'Isola di Natale',
    },
    latitude: '-10.50000000',
    longitude: '105.66666666',
    emoji: '🇨🇽',
    emojiU: 'U+1F1E8 U+1F1FD',
  },
  {
    id: 47,
    name: 'Cocos (Keeling) Islands',
    iso3: 'CCK',
    iso2: 'CC',
    phone_code: '61',
    capital: 'West Island',
    currency: 'AUD',
    currency_symbol: '$',
    tld: '.cc',
    native: 'Cocos (Keeling) Islands',
    region: 'Oceania',
    subregion: 'Australia and New Zealand',
    timezones: [
      {
        zoneName: 'Indian/Cocos',
        gmtOffset: 23400,
        gmtOffsetName: 'UTC+06:30',
        abbreviation: 'CCT',
        tzName: 'Cocos Islands Time',
      },
    ],
    translations: {
      br: 'Ilhas Cocos',
      pt: 'Ilhas dos Cocos',
      nl: 'Cocoseilanden',
      hr: 'Kokosovi Otoci',
      fa: 'جزایر کوکوس',
      de: 'Kokosinseln',
      es: 'Islas Cocos o Islas Keeling',
      fr: 'Îles Cocos',
      ja: 'ココス（キーリング）諸島',
      it: 'Isole Cocos e Keeling',
    },
    latitude: '-12.50000000',
    longitude: '96.83333333',
    emoji: '🇨🇨',
    emojiU: 'U+1F1E8 U+1F1E8',
  },
  {
    id: 48,
    name: 'Colombia',
    iso3: 'COL',
    iso2: 'CO',
    phone_code: '57',
    capital: 'Bogota',
    currency: 'COP',
    currency_symbol: '$',
    tld: '.co',
    native: 'Colombia',
    region: 'Americas',
    subregion: 'South America',
    timezones: [
      {
        zoneName: 'America/Bogota',
        gmtOffset: -18000,
        gmtOffsetName: 'UTC-05:00',
        abbreviation: 'COT',
        tzName: 'Colombia Time',
      },
    ],
    translations: {
      br: 'Colômbia',
      pt: 'Colômbia',
      nl: 'Colombia',
      hr: 'Kolumbija',
      fa: 'کلمبیا',
      de: 'Kolumbien',
      es: 'Colombia',
      fr: 'Colombie',
      ja: 'コロンビア',
      it: 'Colombia',
    },
    latitude: '4.00000000',
    longitude: '-72.00000000',
    emoji: '🇨🇴',
    emojiU: 'U+1F1E8 U+1F1F4',
  },
  {
    id: 49,
    name: 'Comoros',
    iso3: 'COM',
    iso2: 'KM',
    phone_code: '269',
    capital: 'Moroni',
    currency: 'KMF',
    currency_symbol: 'CF',
    tld: '.km',
    native: 'Komori',
    region: 'Africa',
    subregion: 'Eastern Africa',
    timezones: [
      {
        zoneName: 'Indian/Comoro',
        gmtOffset: 10800,
        gmtOffsetName: 'UTC+03:00',
        abbreviation: 'EAT',
        tzName: 'East Africa Time',
      },
    ],
    translations: {
      br: 'Comores',
      pt: 'Comores',
      nl: 'Comoren',
      hr: 'Komori',
      fa: 'کومور',
      de: 'Union der Komoren',
      es: 'Comoras',
      fr: 'Comores',
      ja: 'コモロ',
      it: 'Comore',
    },
    latitude: '-12.16666666',
    longitude: '44.25000000',
    emoji: '🇰🇲',
    emojiU: 'U+1F1F0 U+1F1F2',
  },
  {
    id: 50,
    name: 'Congo',
    iso3: 'COG',
    iso2: 'CG',
    phone_code: '242',
    capital: 'Brazzaville',
    currency: 'XAF',
    currency_symbol: 'FC',
    tld: '.cg',
    native: 'République du Congo',
    region: 'Africa',
    subregion: 'Middle Africa',
    timezones: [
      {
        zoneName: 'Africa/Brazzaville',
        gmtOffset: 3600,
        gmtOffsetName: 'UTC+01:00',
        abbreviation: 'WAT',
        tzName: 'West Africa Time',
      },
    ],
    translations: {
      br: 'Congo',
      pt: 'Congo',
      nl: 'Congo [Republiek]',
      hr: 'Kongo',
      fa: 'کنگو',
      de: 'Kongo',
      es: 'Congo',
      fr: 'Congo',
      ja: 'コンゴ共和国',
      it: 'Congo',
    },
    latitude: '-1.00000000',
    longitude: '15.00000000',
    emoji: '🇨🇬',
    emojiU: 'U+1F1E8 U+1F1EC',
  },
  {
    id: 51,
    name: 'Congo The Democratic Republic Of The',
    iso3: 'COD',
    iso2: 'CD',
    phone_code: '243',
    capital: 'Kinshasa',
    currency: 'CDF',
    currency_symbol: 'FC',
    tld: '.cd',
    native: 'République démocratique du Congo',
    region: 'Africa',
    subregion: 'Middle Africa',
    timezones: [
      {
        zoneName: 'Africa/Kinshasa',
        gmtOffset: 3600,
        gmtOffsetName: 'UTC+01:00',
        abbreviation: 'WAT',
        tzName: 'West Africa Time',
      },
      {
        zoneName: 'Africa/Lubumbashi',
        gmtOffset: 7200,
        gmtOffsetName: 'UTC+02:00',
        abbreviation: 'CAT',
        tzName: 'Central Africa Time',
      },
    ],
    translations: {
      br: 'RD Congo',
      pt: 'RD Congo',
      nl: 'Congo [DRC]',
      hr: 'Kongo, Demokratska Republika',
      fa: 'جمهوری کنگو',
      de: 'Kongo (Dem. Rep.)',
      es: 'Congo (Rep. Dem.)',
      fr: 'Congo (Rép. dém.)',
      ja: 'コンゴ民主共和国',
      it: 'Congo (Rep. Dem.)',
    },
    latitude: '0.00000000',
    longitude: '25.00000000',
    emoji: '🇨🇩',
    emojiU: 'U+1F1E8 U+1F1E9',
  },
  {
    id: 52,
    name: 'Cook Islands',
    iso3: 'COK',
    iso2: 'CK',
    phone_code: '682',
    capital: 'Avarua',
    currency: 'NZD',
    currency_symbol: '$',
    tld: '.ck',
    native: 'Cook Islands',
    region: 'Oceania',
    subregion: 'Polynesia',
    timezones: [
      {
        zoneName: 'Pacific/Rarotonga',
        gmtOffset: -36000,
        gmtOffsetName: 'UTC-10:00',
        abbreviation: 'CKT',
        tzName: 'Cook Island Time',
      },
    ],
    translations: {
      br: 'Ilhas Cook',
      pt: 'Ilhas Cook',
      nl: 'Cookeilanden',
      hr: 'Cookovo Otočje',
      fa: 'جزایر کوک',
      de: 'Cookinseln',
      es: 'Islas Cook',
      fr: 'Îles Cook',
      ja: 'クック諸島',
      it: 'Isole Cook',
    },
    latitude: '-21.23333333',
    longitude: '-159.76666666',
    emoji: '🇨🇰',
    emojiU: 'U+1F1E8 U+1F1F0',
  },
  {
    id: 53,
    name: 'Costa Rica',
    iso3: 'CRI',
    iso2: 'CR',
    phone_code: '506',
    capital: 'San Jose',
    currency: 'CRC',
    currency_symbol: '₡',
    tld: '.cr',
    native: 'Costa Rica',
    region: 'Americas',
    subregion: 'Central America',
    timezones: [
      {
        zoneName: 'America/Costa_Rica',
        gmtOffset: -21600,
        gmtOffsetName: 'UTC-06:00',
        abbreviation: 'CST',
        tzName: 'Central Standard Time (North America',
      },
    ],
    translations: {
      br: 'Costa Rica',
      pt: 'Costa Rica',
      nl: 'Costa Rica',
      hr: 'Kostarika',
      fa: 'کاستاریکا',
      de: 'Costa Rica',
      es: 'Costa Rica',
      fr: 'Costa Rica',
      ja: 'コスタリカ',
      it: 'Costa Rica',
    },
    latitude: '10.00000000',
    longitude: '-84.00000000',
    emoji: '🇨🇷',
    emojiU: 'U+1F1E8 U+1F1F7',
  },
  {
    id: 54,
    name: "Cote D'Ivoire (Ivory Coast)",
    iso3: 'CIV',
    iso2: 'CI',
    phone_code: '225',
    capital: 'Yamoussoukro',
    currency: 'XOF',
    currency_symbol: 'CFA',
    tld: '.ci',
    native: null,
    region: 'Africa',
    subregion: 'Western Africa',
    timezones: [
      {
        zoneName: 'Africa/Abidjan',
        gmtOffset: 0,
        gmtOffsetName: 'UTC±00',
        abbreviation: 'GMT',
        tzName: 'Greenwich Mean Time',
      },
    ],
    translations: {
      br: 'Costa do Marfim',
      pt: 'Costa do Marfim',
      nl: 'Ivoorkust',
      hr: 'Obala Bjelokosti',
      fa: 'ساحل عاج',
      de: 'Elfenbeinküste',
      es: 'Costa de Marfil',
      fr: "Côte d'Ivoire",
      ja: 'コートジボワール',
      it: "Costa D'Avorio",
    },
    latitude: '8.00000000',
    longitude: '-5.00000000',
    emoji: '🇨🇮',
    emojiU: 'U+1F1E8 U+1F1EE',
  },
  {
    id: 55,
    name: 'Croatia (Hrvatska)',
    iso3: 'HRV',
    iso2: 'HR',
    phone_code: '385',
    capital: 'Zagreb',
    currency: 'HRK',
    currency_symbol: 'kn',
    tld: '.hr',
    native: 'Hrvatska',
    region: 'Europe',
    subregion: 'Southern Europe',
    timezones: [
      {
        zoneName: 'Europe/Zagreb',
        gmtOffset: 3600,
        gmtOffsetName: 'UTC+01:00',
        abbreviation: 'CET',
        tzName: 'Central European Time',
      },
    ],
    translations: {
      br: 'Croácia',
      pt: 'Croácia',
      nl: 'Kroatië',
      hr: 'Hrvatska',
      fa: 'کرواسی',
      de: 'Kroatien',
      es: 'Croacia',
      fr: 'Croatie',
      ja: 'クロアチア',
      it: 'Croazia',
    },
    latitude: '45.16666666',
    longitude: '15.50000000',
    emoji: '🇭🇷',
    emojiU: 'U+1F1ED U+1F1F7',
  },
  {
    id: 56,
    name: 'Cuba',
    iso3: 'CUB',
    iso2: 'CU',
    phone_code: '53',
    capital: 'Havana',
    currency: 'CUP',
    currency_symbol: '$',
    tld: '.cu',
    native: 'Cuba',
    region: 'Americas',
    subregion: 'Caribbean',
    timezones: [
      {
        zoneName: 'America/Havana',
        gmtOffset: -18000,
        gmtOffsetName: 'UTC-05:00',
        abbreviation: 'CST',
        tzName: 'Cuba Standard Time',
      },
    ],
    translations: {
      br: 'Cuba',
      pt: 'Cuba',
      nl: 'Cuba',
      hr: 'Kuba',
      fa: 'کوبا',
      de: 'Kuba',
      es: 'Cuba',
      fr: 'Cuba',
      ja: 'キューバ',
      it: 'Cuba',
    },
    latitude: '21.50000000',
    longitude: '-80.00000000',
    emoji: '🇨🇺',
    emojiU: 'U+1F1E8 U+1F1FA',
  },
  {
    id: 249,
    name: 'Curaçao',
    iso3: 'CUW',
    iso2: 'CW',
    phone_code: '599',
    capital: 'Willemstad',
    currency: 'ANG',
    currency_symbol: 'ƒ',
    tld: '.cw',
    native: 'Curaçao',
    region: 'Americas',
    subregion: 'Caribbean',
    timezones: [
      {
        zoneName: 'America/Curacao',
        gmtOffset: -14400,
        gmtOffsetName: 'UTC-04:00',
        abbreviation: 'AST',
        tzName: 'Atlantic Standard Time',
      },
    ],
    translations: {
      br: 'Curaçao',
      pt: 'Curaçao',
      nl: 'Curaçao',
      fa: 'کوراسائو',
      de: 'Curaçao',
      fr: 'Curaçao',
      it: 'Curaçao',
    },
    latitude: '12.11666700',
    longitude: '-68.93333300',
    emoji: '🇨🇼',
    emojiU: 'U+1F1E8 U+1F1FC',
  },
  {
    id: 57,
    name: 'Cyprus',
    iso3: 'CYP',
    iso2: 'CY',
    phone_code: '357',
    capital: 'Nicosia',
    currency: 'EUR',
    currency_symbol: '€',
    tld: '.cy',
    native: 'Κύπρος',
    region: 'Europe',
    subregion: 'Southern Europe',
    timezones: [
      {
        zoneName: 'Asia/Famagusta',
        gmtOffset: 7200,
        gmtOffsetName: 'UTC+02:00',
        abbreviation: 'EET',
        tzName: 'Eastern European Time',
      },
      {
        zoneName: 'Asia/Nicosia',
        gmtOffset: 7200,
        gmtOffsetName: 'UTC+02:00',
        abbreviation: 'EET',
        tzName: 'Eastern European Time',
      },
    ],
    translations: {
      br: 'Chipre',
      pt: 'Chipre',
      nl: 'Cyprus',
      hr: 'Cipar',
      fa: 'قبرس',
      de: 'Zypern',
      es: 'Chipre',
      fr: 'Chypre',
      ja: 'キプロス',
      it: 'Cipro',
    },
    latitude: '35.00000000',
    longitude: '33.00000000',
    emoji: '🇨🇾',
    emojiU: 'U+1F1E8 U+1F1FE',
  },
  {
    id: 58,
    name: 'Czech Republic',
    iso3: 'CZE',
    iso2: 'CZ',
    phone_code: '420',
    capital: 'Prague',
    currency: 'CZK',
    currency_symbol: 'Kč',
    tld: '.cz',
    native: 'Česká republika',
    region: 'Europe',
    subregion: 'Eastern Europe',
    timezones: [
      {
        zoneName: 'Europe/Prague',
        gmtOffset: 3600,
        gmtOffsetName: 'UTC+01:00',
        abbreviation: 'CET',
        tzName: 'Central European Time',
      },
    ],
    translations: {
      br: 'República Tcheca',
      pt: 'República Checa',
      nl: 'Tsjechië',
      hr: 'Češka',
      fa: 'جمهوری چک',
      de: 'Tschechische Republik',
      es: 'República Checa',
      fr: 'République tchèque',
      ja: 'チェコ',
      it: 'Repubblica Ceca',
    },
    latitude: '49.75000000',
    longitude: '15.50000000',
    emoji: '🇨🇿',
    emojiU: 'U+1F1E8 U+1F1FF',
  },
  {
    id: 59,
    name: 'Denmark',
    iso3: 'DNK',
    iso2: 'DK',
    phone_code: '45',
    capital: 'Copenhagen',
    currency: 'DKK',
    currency_symbol: 'Kr.',
    tld: '.dk',
    native: 'Danmark',
    region: 'Europe',
    subregion: 'Northern Europe',
    timezones: [
      {
        zoneName: 'Europe/Copenhagen',
        gmtOffset: 3600,
        gmtOffsetName: 'UTC+01:00',
        abbreviation: 'CET',
        tzName: 'Central European Time',
      },
    ],
    translations: {
      br: 'Dinamarca',
      pt: 'Dinamarca',
      nl: 'Denemarken',
      hr: 'Danska',
      fa: 'دانمارک',
      de: 'Dänemark',
      es: 'Dinamarca',
      fr: 'Danemark',
      ja: 'デンマーク',
      it: 'Danimarca',
    },
    latitude: '56.00000000',
    longitude: '10.00000000',
    emoji: '🇩🇰',
    emojiU: 'U+1F1E9 U+1F1F0',
  },
  {
    id: 60,
    name: 'Djibouti',
    iso3: 'DJI',
    iso2: 'DJ',
    phone_code: '253',
    capital: 'Djibouti',
    currency: 'DJF',
    currency_symbol: 'Fdj',
    tld: '.dj',
    native: 'Djibouti',
    region: 'Africa',
    subregion: 'Eastern Africa',
    timezones: [
      {
        zoneName: 'Africa/Djibouti',
        gmtOffset: 10800,
        gmtOffsetName: 'UTC+03:00',
        abbreviation: 'EAT',
        tzName: 'East Africa Time',
      },
    ],
    translations: {
      br: 'Djibuti',
      pt: 'Djibuti',
      nl: 'Djibouti',
      hr: 'Džibuti',
      fa: 'جیبوتی',
      de: 'Dschibuti',
      es: 'Yibuti',
      fr: 'Djibouti',
      ja: 'ジブチ',
      it: 'Gibuti',
    },
    latitude: '11.50000000',
    longitude: '43.00000000',
    emoji: '🇩🇯',
    emojiU: 'U+1F1E9 U+1F1EF',
  },
  {
    id: 61,
    name: 'Dominica',
    iso3: 'DMA',
    iso2: 'DM',
    phone_code: '+1-767',
    capital: 'Roseau',
    currency: 'XCD',
    currency_symbol: '$',
    tld: '.dm',
    native: 'Dominica',
    region: 'Americas',
    subregion: 'Caribbean',
    timezones: [
      {
        zoneName: 'America/Dominica',
        gmtOffset: -14400,
        gmtOffsetName: 'UTC-04:00',
        abbreviation: 'AST',
        tzName: 'Atlantic Standard Time',
      },
    ],
    translations: {
      br: 'Dominica',
      pt: 'Dominica',
      nl: 'Dominica',
      hr: 'Dominika',
      fa: 'دومینیکا',
      de: 'Dominica',
      es: 'Dominica',
      fr: 'Dominique',
      ja: 'ドミニカ国',
      it: 'Dominica',
    },
    latitude: '15.41666666',
    longitude: '-61.33333333',
    emoji: '🇩🇲',
    emojiU: 'U+1F1E9 U+1F1F2',
  },
  {
    id: 62,
    name: 'Dominican Republic',
    iso3: 'DOM',
    iso2: 'DO',
    phone_code: '+1-809 and 1-829',
    capital: 'Santo Domingo',
    currency: 'DOP',
    currency_symbol: '$',
    tld: '.do',
    native: 'República Dominicana',
    region: 'Americas',
    subregion: 'Caribbean',
    timezones: [
      {
        zoneName: 'America/Santo_Domingo',
        gmtOffset: -14400,
        gmtOffsetName: 'UTC-04:00',
        abbreviation: 'AST',
        tzName: 'Atlantic Standard Time',
      },
    ],
    translations: {
      br: 'República Dominicana',
      pt: 'República Dominicana',
      nl: 'Dominicaanse Republiek',
      hr: 'Dominikanska Republika',
      fa: 'جمهوری دومینیکن',
      de: 'Dominikanische Republik',
      es: 'República Dominicana',
      fr: 'République dominicaine',
      ja: 'ドミニカ共和国',
      it: 'Repubblica Dominicana',
    },
    latitude: '19.00000000',
    longitude: '-70.66666666',
    emoji: '🇩🇴',
    emojiU: 'U+1F1E9 U+1F1F4',
  },
  {
    id: 63,
    name: 'East Timor',
    iso3: 'TLS',
    iso2: 'TL',
    phone_code: '670',
    capital: 'Dili',
    currency: 'USD',
    currency_symbol: '$',
    tld: '.tl',
    native: 'Timor-Leste',
    region: 'Asia',
    subregion: 'South-Eastern Asia',
    timezones: [
      {
        zoneName: 'Asia/Dili',
        gmtOffset: 32400,
        gmtOffsetName: 'UTC+09:00',
        abbreviation: 'TLT',
        tzName: 'Timor Leste Time',
      },
    ],
    translations: {
      br: 'Timor Leste',
      pt: 'Timor Leste',
      nl: 'Oost-Timor',
      hr: 'Istočni Timor',
      fa: 'تیمور شرقی',
      de: 'Timor-Leste',
      es: 'Timor Oriental',
      fr: 'Timor oriental',
      ja: '東ティモール',
      it: 'Timor Est',
    },
    latitude: '-8.83333333',
    longitude: '125.91666666',
    emoji: '🇹🇱',
    emojiU: 'U+1F1F9 U+1F1F1',
  },
  {
    id: 64,
    name: 'Ecuador',
    iso3: 'ECU',
    iso2: 'EC',
    phone_code: '593',
    capital: 'Quito',
    currency: 'USD',
    currency_symbol: '$',
    tld: '.ec',
    native: 'Ecuador',
    region: 'Americas',
    subregion: 'South America',
    timezones: [
      {
        zoneName: 'America/Guayaquil',
        gmtOffset: -18000,
        gmtOffsetName: 'UTC-05:00',
        abbreviation: 'ECT',
        tzName: 'Ecuador Time',
      },
      {
        zoneName: 'Pacific/Galapagos',
        gmtOffset: -21600,
        gmtOffsetName: 'UTC-06:00',
        abbreviation: 'GALT',
        tzName: 'Galápagos Time',
      },
    ],
    translations: {
      br: 'Equador',
      pt: 'Equador',
      nl: 'Ecuador',
      hr: 'Ekvador',
      fa: 'اکوادور',
      de: 'Ecuador',
      es: 'Ecuador',
      fr: 'Équateur',
      ja: 'エクアドル',
      it: 'Ecuador',
    },
    latitude: '-2.00000000',
    longitude: '-77.50000000',
    emoji: '🇪🇨',
    emojiU: 'U+1F1EA U+1F1E8',
  },
  {
    id: 65,
    name: 'Egypt',
    iso3: 'EGY',
    iso2: 'EG',
    phone_code: '20',
    capital: 'Cairo',
    currency: 'EGP',
    currency_symbol: 'ج.م',
    tld: '.eg',
    native: 'مصر‎',
    region: 'Africa',
    subregion: 'Northern Africa',
    timezones: [
      {
        zoneName: 'Africa/Cairo',
        gmtOffset: 7200,
        gmtOffsetName: 'UTC+02:00',
        abbreviation: 'EET',
        tzName: 'Eastern European Time',
      },
    ],
    translations: {
      br: 'Egito',
      pt: 'Egipto',
      nl: 'Egypte',
      hr: 'Egipat',
      fa: 'مصر',
      de: 'Ägypten',
      es: 'Egipto',
      fr: 'Égypte',
      ja: 'エジプト',
      it: 'Egitto',
    },
    latitude: '27.00000000',
    longitude: '30.00000000',
    emoji: '🇪🇬',
    emojiU: 'U+1F1EA U+1F1EC',
  },
  {
    id: 66,
    name: 'El Salvador',
    iso3: 'SLV',
    iso2: 'SV',
    phone_code: '503',
    capital: 'San Salvador',
    currency: 'USD',
    currency_symbol: '$',
    tld: '.sv',
    native: 'El Salvador',
    region: 'Americas',
    subregion: 'Central America',
    timezones: [
      {
        zoneName: 'America/El_Salvador',
        gmtOffset: -21600,
        gmtOffsetName: 'UTC-06:00',
        abbreviation: 'CST',
        tzName: 'Central Standard Time (North America',
      },
    ],
    translations: {
      br: 'El Salvador',
      pt: 'El Salvador',
      nl: 'El Salvador',
      hr: 'Salvador',
      fa: 'السالوادور',
      de: 'El Salvador',
      es: 'El Salvador',
      fr: 'Salvador',
      ja: 'エルサルバドル',
      it: 'El Salvador',
    },
    latitude: '13.83333333',
    longitude: '-88.91666666',
    emoji: '🇸🇻',
    emojiU: 'U+1F1F8 U+1F1FB',
  },
  {
    id: 67,
    name: 'Equatorial Guinea',
    iso3: 'GNQ',
    iso2: 'GQ',
    phone_code: '240',
    capital: 'Malabo',
    currency: 'XAF',
    currency_symbol: 'FCFA',
    tld: '.gq',
    native: 'Guinea Ecuatorial',
    region: 'Africa',
    subregion: 'Middle Africa',
    timezones: [
      {
        zoneName: 'Africa/Malabo',
        gmtOffset: 3600,
        gmtOffsetName: 'UTC+01:00',
        abbreviation: 'WAT',
        tzName: 'West Africa Time',
      },
    ],
    translations: {
      br: 'Guiné Equatorial',
      pt: 'Guiné Equatorial',
      nl: 'Equatoriaal-Guinea',
      hr: 'Ekvatorijalna Gvineja',
      fa: 'گینه استوایی',
      de: 'Äquatorial-Guinea',
      es: 'Guinea Ecuatorial',
      fr: 'Guinée-Équatoriale',
      ja: '赤道ギニア',
      it: 'Guinea Equatoriale',
    },
    latitude: '2.00000000',
    longitude: '10.00000000',
    emoji: '🇬🇶',
    emojiU: 'U+1F1EC U+1F1F6',
  },
  {
    id: 68,
    name: 'Eritrea',
    iso3: 'ERI',
    iso2: 'ER',
    phone_code: '291',
    capital: 'Asmara',
    currency: 'ERN',
    currency_symbol: 'Nfk',
    tld: '.er',
    native: 'ኤርትራ',
    region: 'Africa',
    subregion: 'Eastern Africa',
    timezones: [
      {
        zoneName: 'Africa/Asmara',
        gmtOffset: 10800,
        gmtOffsetName: 'UTC+03:00',
        abbreviation: 'EAT',
        tzName: 'East Africa Time',
      },
    ],
    translations: {
      br: 'Eritreia',
      pt: 'Eritreia',
      nl: 'Eritrea',
      hr: 'Eritreja',
      fa: 'اریتره',
      de: 'Eritrea',
      es: 'Eritrea',
      fr: 'Érythrée',
      ja: 'エリトリア',
      it: 'Eritrea',
    },
    latitude: '15.00000000',
    longitude: '39.00000000',
    emoji: '🇪🇷',
    emojiU: 'U+1F1EA U+1F1F7',
  },
  {
    id: 69,
    name: 'Estonia',
    iso3: 'EST',
    iso2: 'EE',
    phone_code: '372',
    capital: 'Tallinn',
    currency: 'EUR',
    currency_symbol: '€',
    tld: '.ee',
    native: 'Eesti',
    region: 'Europe',
    subregion: 'Northern Europe',
    timezones: [
      {
        zoneName: 'Europe/Tallinn',
        gmtOffset: 7200,
        gmtOffsetName: 'UTC+02:00',
        abbreviation: 'EET',
        tzName: 'Eastern European Time',
      },
    ],
    translations: {
      br: 'Estônia',
      pt: 'Estónia',
      nl: 'Estland',
      hr: 'Estonija',
      fa: 'استونی',
      de: 'Estland',
      es: 'Estonia',
      fr: 'Estonie',
      ja: 'エストニア',
      it: 'Estonia',
    },
    latitude: '59.00000000',
    longitude: '26.00000000',
    emoji: '🇪🇪',
    emojiU: 'U+1F1EA U+1F1EA',
  },
  {
    id: 70,
    name: 'Ethiopia',
    iso3: 'ETH',
    iso2: 'ET',
    phone_code: '251',
    capital: 'Addis Ababa',
    currency: 'ETB',
    currency_symbol: 'Nkf',
    tld: '.et',
    native: 'ኢትዮጵያ',
    region: 'Africa',
    subregion: 'Eastern Africa',
    timezones: [
      {
        zoneName: 'Africa/Addis_Ababa',
        gmtOffset: 10800,
        gmtOffsetName: 'UTC+03:00',
        abbreviation: 'EAT',
        tzName: 'East Africa Time',
      },
    ],
    translations: {
      br: 'Etiópia',
      pt: 'Etiópia',
      nl: 'Ethiopië',
      hr: 'Etiopija',
      fa: 'اتیوپی',
      de: 'Äthiopien',
      es: 'Etiopía',
      fr: 'Éthiopie',
      ja: 'エチオピア',
      it: 'Etiopia',
    },
    latitude: '8.00000000',
    longitude: '38.00000000',
    emoji: '🇪🇹',
    emojiU: 'U+1F1EA U+1F1F9',
  },
  {
    id: 71,
    name: 'Falkland Islands',
    iso3: 'FLK',
    iso2: 'FK',
    phone_code: '500',
    capital: 'Stanley',
    currency: 'FKP',
    currency_symbol: '£',
    tld: '.fk',
    native: 'Falkland Islands',
    region: 'Americas',
    subregion: 'South America',
    timezones: [
      {
        zoneName: 'Atlantic/Stanley',
        gmtOffset: -10800,
        gmtOffsetName: 'UTC-03:00',
        abbreviation: 'FKST',
        tzName: 'Falkland Islands Summer Time',
      },
    ],
    translations: {
      br: 'Ilhas Malvinas',
      pt: 'Ilhas Falkland',
      nl: 'Falklandeilanden [Islas Malvinas]',
      hr: 'Falklandski Otoci',
      fa: 'جزایر فالکلند',
      de: 'Falklandinseln',
      es: 'Islas Malvinas',
      fr: 'Îles Malouines',
      ja: 'フォークランド（マルビナス）諸島',
      it: 'Isole Falkland o Isole Malvine',
    },
    latitude: '-51.75000000',
    longitude: '-59.00000000',
    emoji: '🇫🇰',
    emojiU: 'U+1F1EB U+1F1F0',
  },
  {
    id: 72,
    name: 'Faroe Islands',
    iso3: 'FRO',
    iso2: 'FO',
    phone_code: '298',
    capital: 'Torshavn',
    currency: 'DKK',
    currency_symbol: 'Kr.',
    tld: '.fo',
    native: 'Føroyar',
    region: 'Europe',
    subregion: 'Northern Europe',
    timezones: [
      {
        zoneName: 'Atlantic/Faroe',
        gmtOffset: 0,
        gmtOffsetName: 'UTC±00',
        abbreviation: 'WET',
        tzName: 'Western European Time',
      },
    ],
    translations: {
      br: 'Ilhas Faroé',
      pt: 'Ilhas Faroé',
      nl: 'Faeröer',
      hr: 'Farski Otoci',
      fa: 'جزایر فارو',
      de: 'Färöer-Inseln',
      es: 'Islas Faroe',
      fr: 'Îles Féroé',
      ja: 'フェロー諸島',
      it: 'Isole Far Oer',
    },
    latitude: '62.00000000',
    longitude: '-7.00000000',
    emoji: '🇫🇴',
    emojiU: 'U+1F1EB U+1F1F4',
  },
  {
    id: 73,
    name: 'Fiji Islands',
    iso3: 'FJI',
    iso2: 'FJ',
    phone_code: '679',
    capital: 'Suva',
    currency: 'FJD',
    currency_symbol: 'FJ$',
    tld: '.fj',
    native: 'Fiji',
    region: 'Oceania',
    subregion: 'Melanesia',
    timezones: [
      {
        zoneName: 'Pacific/Fiji',
        gmtOffset: 43200,
        gmtOffsetName: 'UTC+12:00',
        abbreviation: 'FJT',
        tzName: 'Fiji Time',
      },
    ],
    translations: {
      br: 'Fiji',
      pt: 'Fiji',
      nl: 'Fiji',
      hr: 'Fiđi',
      fa: 'فیجی',
      de: 'Fidschi',
      es: 'Fiyi',
      fr: 'Fidji',
      ja: 'フィジー',
      it: 'Figi',
    },
    latitude: '-18.00000000',
    longitude: '175.00000000',
    emoji: '🇫🇯',
    emojiU: 'U+1F1EB U+1F1EF',
  },
  {
    id: 74,
    name: 'Finland',
    iso3: 'FIN',
    iso2: 'FI',
    phone_code: '358',
    capital: 'Helsinki',
    currency: 'EUR',
    currency_symbol: '€',
    tld: '.fi',
    native: 'Suomi',
    region: 'Europe',
    subregion: 'Northern Europe',
    timezones: [
      {
        zoneName: 'Europe/Helsinki',
        gmtOffset: 7200,
        gmtOffsetName: 'UTC+02:00',
        abbreviation: 'EET',
        tzName: 'Eastern European Time',
      },
    ],
    translations: {
      br: 'Finlândia',
      pt: 'Finlândia',
      nl: 'Finland',
      hr: 'Finska',
      fa: 'فنلاند',
      de: 'Finnland',
      es: 'Finlandia',
      fr: 'Finlande',
      ja: 'フィンランド',
      it: 'Finlandia',
    },
    latitude: '64.00000000',
    longitude: '26.00000000',
    emoji: '🇫🇮',
    emojiU: 'U+1F1EB U+1F1EE',
  },
  {
    id: 75,
    name: 'France',
    iso3: 'FRA',
    iso2: 'FR',
    phone_code: '33',
    capital: 'Paris',
    currency: 'EUR',
    currency_symbol: '€',
    tld: '.fr',
    native: 'France',
    region: 'Europe',
    subregion: 'Western Europe',
    timezones: [
      {
        zoneName: 'Europe/Paris',
        gmtOffset: 3600,
        gmtOffsetName: 'UTC+01:00',
        abbreviation: 'CET',
        tzName: 'Central European Time',
      },
    ],
    translations: {
      br: 'França',
      pt: 'França',
      nl: 'Frankrijk',
      hr: 'Francuska',
      fa: 'فرانسه',
      de: 'Frankreich',
      es: 'Francia',
      fr: 'France',
      ja: 'フランス',
      it: 'Francia',
    },
    latitude: '46.00000000',
    longitude: '2.00000000',
    emoji: '🇫🇷',
    emojiU: 'U+1F1EB U+1F1F7',
  },
  {
    id: 76,
    name: 'French Guiana',
    iso3: 'GUF',
    iso2: 'GF',
    phone_code: '594',
    capital: 'Cayenne',
    currency: 'EUR',
    currency_symbol: '€',
    tld: '.gf',
    native: 'Guyane française',
    region: 'Americas',
    subregion: 'South America',
    timezones: [
      {
        zoneName: 'America/Cayenne',
        gmtOffset: -10800,
        gmtOffsetName: 'UTC-03:00',
        abbreviation: 'GFT',
        tzName: 'French Guiana Time',
      },
    ],
    translations: {
      br: 'Guiana Francesa',
      pt: 'Guiana Francesa',
      nl: 'Frans-Guyana',
      hr: 'Francuska Gvajana',
      fa: 'گویان فرانسه',
      de: 'Französisch Guyana',
      es: 'Guayana Francesa',
      fr: 'Guayane',
      ja: 'フランス領ギアナ',
      it: 'Guyana francese',
    },
    latitude: '4.00000000',
    longitude: '-53.00000000',
    emoji: '🇬🇫',
    emojiU: 'U+1F1EC U+1F1EB',
  },
  {
    id: 77,
    name: 'French Polynesia',
    iso3: 'PYF',
    iso2: 'PF',
    phone_code: '689',
    capital: 'Papeete',
    currency: 'XPF',
    currency_symbol: '₣',
    tld: '.pf',
    native: 'Polynésie française',
    region: 'Oceania',
    subregion: 'Polynesia',
    timezones: [
      {
        zoneName: 'Pacific/Gambier',
        gmtOffset: -32400,
        gmtOffsetName: 'UTC-09:00',
        abbreviation: 'GAMT',
        tzName: 'Gambier Islands Time',
      },
      {
        zoneName: 'Pacific/Marquesas',
        gmtOffset: -34200,
        gmtOffsetName: 'UTC-09:30',
        abbreviation: 'MART',
        tzName: 'Marquesas Islands Time',
      },
      {
        zoneName: 'Pacific/Tahiti',
        gmtOffset: -36000,
        gmtOffsetName: 'UTC-10:00',
        abbreviation: 'TAHT',
        tzName: 'Tahiti Time',
      },
    ],
    translations: {
      br: 'Polinésia Francesa',
      pt: 'Polinésia Francesa',
      nl: 'Frans-Polynesië',
      hr: 'Francuska Polinezija',
      fa: 'پلی‌نزی فرانسه',
      de: 'Französisch-Polynesien',
      es: 'Polinesia Francesa',
      fr: 'Polynésie française',
      ja: 'フランス領ポリネシア',
      it: 'Polinesia Francese',
    },
    latitude: '-15.00000000',
    longitude: '-140.00000000',
    emoji: '🇵🇫',
    emojiU: 'U+1F1F5 U+1F1EB',
  },
  {
    id: 78,
    name: 'French Southern Territories',
    iso3: 'ATF',
    iso2: 'TF',
    phone_code: '',
    capital: 'Port-aux-Francais',
    currency: 'EUR',
    currency_symbol: '€',
    tld: '.tf',
    native: 'Territoire des Terres australes et antarctiques fr',
    region: 'Africa',
    subregion: 'Southern Africa',
    timezones: [
      {
        zoneName: 'Indian/Kerguelen',
        gmtOffset: 18000,
        gmtOffsetName: 'UTC+05:00',
        abbreviation: 'TFT',
        tzName: 'French Southern and Antarctic Time',
      },
    ],
    translations: {
      br: 'Terras Austrais e Antárticas Francesas',
      pt: 'Terras Austrais e Antárticas Francesas',
      nl: 'Franse Gebieden in de zuidelijke Indische Oceaan',
      hr: 'Francuski južni i antarktički teritoriji',
      fa: 'سرزمین‌های جنوبی و جنوبگانی فرانسه',
      de: 'Französische Süd- und Antarktisgebiete',
      es: 'Tierras Australes y Antárticas Francesas',
      fr: 'Terres australes et antarctiques françaises',
      ja: 'フランス領南方・南極地域',
      it: 'Territori Francesi del Sud',
    },
    latitude: '-49.25000000',
    longitude: '69.16700000',
    emoji: '🇹🇫',
    emojiU: 'U+1F1F9 U+1F1EB',
  },
  {
    id: 79,
    name: 'Gabon',
    iso3: 'GAB',
    iso2: 'GA',
    phone_code: '241',
    capital: 'Libreville',
    currency: 'XAF',
    currency_symbol: 'FCFA',
    tld: '.ga',
    native: 'Gabon',
    region: 'Africa',
    subregion: 'Middle Africa',
    timezones: [
      {
        zoneName: 'Africa/Libreville',
        gmtOffset: 3600,
        gmtOffsetName: 'UTC+01:00',
        abbreviation: 'WAT',
        tzName: 'West Africa Time',
      },
    ],
    translations: {
      br: 'Gabão',
      pt: 'Gabão',
      nl: 'Gabon',
      hr: 'Gabon',
      fa: 'گابن',
      de: 'Gabun',
      es: 'Gabón',
      fr: 'Gabon',
      ja: 'ガボン',
      it: 'Gabon',
    },
    latitude: '-1.00000000',
    longitude: '11.75000000',
    emoji: '🇬🇦',
    emojiU: 'U+1F1EC U+1F1E6',
  },
  {
    id: 80,
    name: 'Gambia The',
    iso3: 'GMB',
    iso2: 'GM',
    phone_code: '220',
    capital: 'Banjul',
    currency: 'GMD',
    currency_symbol: 'D',
    tld: '.gm',
    native: 'Gambia',
    region: 'Africa',
    subregion: 'Western Africa',
    timezones: [
      {
        zoneName: 'Africa/Banjul',
        gmtOffset: 0,
        gmtOffsetName: 'UTC±00',
        abbreviation: 'GMT',
        tzName: 'Greenwich Mean Time',
      },
    ],
    translations: {
      br: 'Gâmbia',
      pt: 'Gâmbia',
      nl: 'Gambia',
      hr: 'Gambija',
      fa: 'گامبیا',
      de: 'Gambia',
      es: 'Gambia',
      fr: 'Gambie',
      ja: 'ガンビア',
      it: 'Gambia',
    },
    latitude: '13.46666666',
    longitude: '-16.56666666',
    emoji: '🇬🇲',
    emojiU: 'U+1F1EC U+1F1F2',
  },
  {
    id: 81,
    name: 'Georgia',
    iso3: 'GEO',
    iso2: 'GE',
    phone_code: '995',
    capital: 'Tbilisi',
    currency: 'GEL',
    currency_symbol: 'ლ',
    tld: '.ge',
    native: 'საქართველო',
    region: 'Asia',
    subregion: 'Western Asia',
    timezones: [
      {
        zoneName: 'Asia/Tbilisi',
        gmtOffset: 14400,
        gmtOffsetName: 'UTC+04:00',
        abbreviation: 'GET',
        tzName: 'Georgia Standard Time',
      },
    ],
    translations: {
      br: 'Geórgia',
      pt: 'Geórgia',
      nl: 'Georgië',
      hr: 'Gruzija',
      fa: 'گرجستان',
      de: 'Georgien',
      es: 'Georgia',
      fr: 'Géorgie',
      ja: 'グルジア',
      it: 'Georgia',
    },
    latitude: '42.00000000',
    longitude: '43.50000000',
    emoji: '🇬🇪',
    emojiU: 'U+1F1EC U+1F1EA',
  },
  {
    id: 82,
    name: 'Germany',
    iso3: 'DEU',
    iso2: 'DE',
    phone_code: '49',
    capital: 'Berlin',
    currency: 'EUR',
    currency_symbol: '€',
    tld: '.de',
    native: 'Deutschland',
    region: 'Europe',
    subregion: 'Western Europe',
    timezones: [
      {
        zoneName: 'Europe/Berlin',
        gmtOffset: 3600,
        gmtOffsetName: 'UTC+01:00',
        abbreviation: 'CET',
        tzName: 'Central European Time',
      },
      {
        zoneName: 'Europe/Busingen',
        gmtOffset: 3600,
        gmtOffsetName: 'UTC+01:00',
        abbreviation: 'CET',
        tzName: 'Central European Time',
      },
    ],
    translations: {
      br: 'Alemanha',
      pt: 'Alemanha',
      nl: 'Duitsland',
      hr: 'Njemačka',
      fa: 'آلمان',
      de: 'Deutschland',
      es: 'Alemania',
      fr: 'Allemagne',
      ja: 'ドイツ',
      it: 'Germania',
    },
    latitude: '51.00000000',
    longitude: '9.00000000',
    emoji: '🇩🇪',
    emojiU: 'U+1F1E9 U+1F1EA',
  },
  {
    id: 83,
    name: 'Ghana',
    iso3: 'GHA',
    iso2: 'GH',
    phone_code: '233',
    capital: 'Accra',
    currency: 'GHS',
    currency_symbol: 'GH₵',
    tld: '.gh',
    native: 'Ghana',
    region: 'Africa',
    subregion: 'Western Africa',
    timezones: [
      {
        zoneName: 'Africa/Accra',
        gmtOffset: 0,
        gmtOffsetName: 'UTC±00',
        abbreviation: 'GMT',
        tzName: 'Greenwich Mean Time',
      },
    ],
    translations: {
      br: 'Gana',
      pt: 'Gana',
      nl: 'Ghana',
      hr: 'Gana',
      fa: 'غنا',
      de: 'Ghana',
      es: 'Ghana',
      fr: 'Ghana',
      ja: 'ガーナ',
      it: 'Ghana',
    },
    latitude: '8.00000000',
    longitude: '-2.00000000',
    emoji: '🇬🇭',
    emojiU: 'U+1F1EC U+1F1ED',
  },
  {
    id: 84,
    name: 'Gibraltar',
    iso3: 'GIB',
    iso2: 'GI',
    phone_code: '350',
    capital: 'Gibraltar',
    currency: 'GIP',
    currency_symbol: '£',
    tld: '.gi',
    native: 'Gibraltar',
    region: 'Europe',
    subregion: 'Southern Europe',
    timezones: [
      {
        zoneName: 'Europe/Gibraltar',
        gmtOffset: 3600,
        gmtOffsetName: 'UTC+01:00',
        abbreviation: 'CET',
        tzName: 'Central European Time',
      },
    ],
    translations: {
      br: 'Gibraltar',
      pt: 'Gibraltar',
      nl: 'Gibraltar',
      hr: 'Gibraltar',
      fa: 'جبل‌طارق',
      de: 'Gibraltar',
      es: 'Gibraltar',
      fr: 'Gibraltar',
      ja: 'ジブラルタル',
      it: 'Gibilterra',
    },
    latitude: '36.13333333',
    longitude: '-5.35000000',
    emoji: '🇬🇮',
    emojiU: 'U+1F1EC U+1F1EE',
  },
  {
    id: 85,
    name: 'Greece',
    iso3: 'GRC',
    iso2: 'GR',
    phone_code: '30',
    capital: 'Athens',
    currency: 'EUR',
    currency_symbol: '€',
    tld: '.gr',
    native: 'Ελλάδα',
    region: 'Europe',
    subregion: 'Southern Europe',
    timezones: [
      {
        zoneName: 'Europe/Athens',
        gmtOffset: 7200,
        gmtOffsetName: 'UTC+02:00',
        abbreviation: 'EET',
        tzName: 'Eastern European Time',
      },
    ],
    translations: {
      br: 'Grécia',
      pt: 'Grécia',
      nl: 'Griekenland',
      hr: 'Grčka',
      fa: 'یونان',
      de: 'Griechenland',
      es: 'Grecia',
      fr: 'Grèce',
      ja: 'ギリシャ',
      it: 'Grecia',
    },
    latitude: '39.00000000',
    longitude: '22.00000000',
    emoji: '🇬🇷',
    emojiU: 'U+1F1EC U+1F1F7',
  },
  {
    id: 86,
    name: 'Greenland',
    iso3: 'GRL',
    iso2: 'GL',
    phone_code: '299',
    capital: 'Nuuk',
    currency: 'DKK',
    currency_symbol: 'Kr.',
    tld: '.gl',
    native: 'Kalaallit Nunaat',
    region: 'Americas',
    subregion: 'Northern America',
    timezones: [
      {
        zoneName: 'America/Danmarkshavn',
        gmtOffset: 0,
        gmtOffsetName: 'UTC±00',
        abbreviation: 'GMT',
        tzName: 'Greenwich Mean Time',
      },
      {
        zoneName: 'America/Nuuk',
        gmtOffset: -10800,
        gmtOffsetName: 'UTC-03:00',
        abbreviation: 'WGT',
        tzName: 'West Greenland Time',
      },
      {
        zoneName: 'America/Scoresbysund',
        gmtOffset: -3600,
        gmtOffsetName: 'UTC-01:00',
        abbreviation: 'EGT',
        tzName: 'Eastern Greenland Time',
      },
      {
        zoneName: 'America/Thule',
        gmtOffset: -14400,
        gmtOffsetName: 'UTC-04:00',
        abbreviation: 'AST',
        tzName: 'Atlantic Standard Time',
      },
    ],
    translations: {
      br: 'Groelândia',
      pt: 'Gronelândia',
      nl: 'Groenland',
      hr: 'Grenland',
      fa: 'گرینلند',
      de: 'Grönland',
      es: 'Groenlandia',
      fr: 'Groenland',
      ja: 'グリーンランド',
      it: 'Groenlandia',
    },
    latitude: '72.00000000',
    longitude: '-40.00000000',
    emoji: '🇬🇱',
    emojiU: 'U+1F1EC U+1F1F1',
  },
  {
    id: 87,
    name: 'Grenada',
    iso3: 'GRD',
    iso2: 'GD',
    phone_code: '+1-473',
    capital: "St. George's",
    currency: 'XCD',
    currency_symbol: '$',
    tld: '.gd',
    native: 'Grenada',
    region: 'Americas',
    subregion: 'Caribbean',
    timezones: [
      {
        zoneName: 'America/Grenada',
        gmtOffset: -14400,
        gmtOffsetName: 'UTC-04:00',
        abbreviation: 'AST',
        tzName: 'Atlantic Standard Time',
      },
    ],
    translations: {
      br: 'Granada',
      pt: 'Granada',
      nl: 'Grenada',
      hr: 'Grenada',
      fa: 'گرنادا',
      de: 'Grenada',
      es: 'Grenada',
      fr: 'Grenade',
      ja: 'グレナダ',
      it: 'Grenada',
    },
    latitude: '12.11666666',
    longitude: '-61.66666666',
    emoji: '🇬🇩',
    emojiU: 'U+1F1EC U+1F1E9',
  },
  {
    id: 88,
    name: 'Guadeloupe',
    iso3: 'GLP',
    iso2: 'GP',
    phone_code: '590',
    capital: 'Basse-Terre',
    currency: 'EUR',
    currency_symbol: '€',
    tld: '.gp',
    native: 'Guadeloupe',
    region: 'Americas',
    subregion: 'Caribbean',
    timezones: [
      {
        zoneName: 'America/Guadeloupe',
        gmtOffset: -14400,
        gmtOffsetName: 'UTC-04:00',
        abbreviation: 'AST',
        tzName: 'Atlantic Standard Time',
      },
    ],
    translations: {
      br: 'Guadalupe',
      pt: 'Guadalupe',
      nl: 'Guadeloupe',
      hr: 'Gvadalupa',
      fa: 'جزیره گوادلوپ',
      de: 'Guadeloupe',
      es: 'Guadalupe',
      fr: 'Guadeloupe',
      ja: 'グアドループ',
      it: 'Guadeloupa',
    },
    latitude: '16.25000000',
    longitude: '-61.58333300',
    emoji: '🇬🇵',
    emojiU: 'U+1F1EC U+1F1F5',
  },
  {
    id: 89,
    name: 'Guam',
    iso3: 'GUM',
    iso2: 'GU',
    phone_code: '+1-671',
    capital: 'Hagatna',
    currency: 'USD',
    currency_symbol: '$',
    tld: '.gu',
    native: 'Guam',
    region: 'Oceania',
    subregion: 'Micronesia',
    timezones: [
      {
        zoneName: 'Pacific/Guam',
        gmtOffset: 36000,
        gmtOffsetName: 'UTC+10:00',
        abbreviation: 'CHST',
        tzName: 'Chamorro Standard Time',
      },
    ],
    translations: {
      br: 'Guam',
      pt: 'Guame',
      nl: 'Guam',
      hr: 'Guam',
      fa: 'گوام',
      de: 'Guam',
      es: 'Guam',
      fr: 'Guam',
      ja: 'グアム',
      it: 'Guam',
    },
    latitude: '13.46666666',
    longitude: '144.78333333',
    emoji: '🇬🇺',
    emojiU: 'U+1F1EC U+1F1FA',
  },
  {
    id: 90,
    name: 'Guatemala',
    iso3: 'GTM',
    iso2: 'GT',
    phone_code: '502',
    capital: 'Guatemala City',
    currency: 'GTQ',
    currency_symbol: 'Q',
    tld: '.gt',
    native: 'Guatemala',
    region: 'Americas',
    subregion: 'Central America',
    timezones: [
      {
        zoneName: 'America/Guatemala',
        gmtOffset: -21600,
        gmtOffsetName: 'UTC-06:00',
        abbreviation: 'CST',
        tzName: 'Central Standard Time (North America',
      },
    ],
    translations: {
      br: 'Guatemala',
      pt: 'Guatemala',
      nl: 'Guatemala',
      hr: 'Gvatemala',
      fa: 'گواتمالا',
      de: 'Guatemala',
      es: 'Guatemala',
      fr: 'Guatemala',
      ja: 'グアテマラ',
      it: 'Guatemala',
    },
    latitude: '15.50000000',
    longitude: '-90.25000000',
    emoji: '🇬🇹',
    emojiU: 'U+1F1EC U+1F1F9',
  },
  {
    id: 91,
    name: 'Guernsey and Alderney',
    iso3: 'GGY',
    iso2: 'GG',
    phone_code: '+44-1481',
    capital: 'St Peter Port',
    currency: 'GBP',
    currency_symbol: '£',
    tld: '.gg',
    native: 'Guernsey',
    region: 'Europe',
    subregion: 'Northern Europe',
    timezones: [
      {
        zoneName: 'Europe/Guernsey',
        gmtOffset: 0,
        gmtOffsetName: 'UTC±00',
        abbreviation: 'GMT',
        tzName: 'Greenwich Mean Time',
      },
    ],
    translations: {
      br: 'Guernsey',
      pt: 'Guernsey',
      nl: 'Guernsey',
      hr: 'Guernsey',
      fa: 'گرنزی',
      de: 'Guernsey',
      es: 'Guernsey',
      fr: 'Guernesey',
      ja: 'ガーンジー',
      it: 'Guernsey',
    },
    latitude: '49.46666666',
    longitude: '-2.58333333',
    emoji: '🇬🇬',
    emojiU: 'U+1F1EC U+1F1EC',
  },
  {
    id: 92,
    name: 'Guinea',
    iso3: 'GIN',
    iso2: 'GN',
    phone_code: '224',
    capital: 'Conakry',
    currency: 'GNF',
    currency_symbol: 'FG',
    tld: '.gn',
    native: 'Guinée',
    region: 'Africa',
    subregion: 'Western Africa',
    timezones: [
      {
        zoneName: 'Africa/Conakry',
        gmtOffset: 0,
        gmtOffsetName: 'UTC±00',
        abbreviation: 'GMT',
        tzName: 'Greenwich Mean Time',
      },
    ],
    translations: {
      br: 'Guiné',
      pt: 'Guiné',
      nl: 'Guinee',
      hr: 'Gvineja',
      fa: 'گینه',
      de: 'Guinea',
      es: 'Guinea',
      fr: 'Guinée',
      ja: 'ギニア',
      it: 'Guinea',
    },
    latitude: '11.00000000',
    longitude: '-10.00000000',
    emoji: '🇬🇳',
    emojiU: 'U+1F1EC U+1F1F3',
  },
  {
    id: 93,
    name: 'Guinea-Bissau',
    iso3: 'GNB',
    iso2: 'GW',
    phone_code: '245',
    capital: 'Bissau',
    currency: 'XOF',
    currency_symbol: 'CFA',
    tld: '.gw',
    native: 'Guiné-Bissau',
    region: 'Africa',
    subregion: 'Western Africa',
    timezones: [
      {
        zoneName: 'Africa/Bissau',
        gmtOffset: 0,
        gmtOffsetName: 'UTC±00',
        abbreviation: 'GMT',
        tzName: 'Greenwich Mean Time',
      },
    ],
    translations: {
      br: 'Guiné-Bissau',
      pt: 'Guiné-Bissau',
      nl: 'Guinee-Bissau',
      hr: 'Gvineja Bisau',
      fa: 'گینه بیسائو',
      de: 'Guinea-Bissau',
      es: 'Guinea-Bisáu',
      fr: 'Guinée-Bissau',
      ja: 'ギニアビサウ',
      it: 'Guinea-Bissau',
    },
    latitude: '12.00000000',
    longitude: '-15.00000000',
    emoji: '🇬🇼',
    emojiU: 'U+1F1EC U+1F1FC',
  },
  {
    id: 94,
    name: 'Guyana',
    iso3: 'GUY',
    iso2: 'GY',
    phone_code: '592',
    capital: 'Georgetown',
    currency: 'GYD',
    currency_symbol: '$',
    tld: '.gy',
    native: 'Guyana',
    region: 'Americas',
    subregion: 'South America',
    timezones: [
      {
        zoneName: 'America/Guyana',
        gmtOffset: -14400,
        gmtOffsetName: 'UTC-04:00',
        abbreviation: 'GYT',
        tzName: 'Guyana Time',
      },
    ],
    translations: {
      br: 'Guiana',
      pt: 'Guiana',
      nl: 'Guyana',
      hr: 'Gvajana',
      fa: 'گویان',
      de: 'Guyana',
      es: 'Guyana',
      fr: 'Guyane',
      ja: 'ガイアナ',
      it: 'Guyana',
    },
    latitude: '5.00000000',
    longitude: '-59.00000000',
    emoji: '🇬🇾',
    emojiU: 'U+1F1EC U+1F1FE',
  },
  {
    id: 95,
    name: 'Haiti',
    iso3: 'HTI',
    iso2: 'HT',
    phone_code: '509',
    capital: 'Port-au-Prince',
    currency: 'HTG',
    currency_symbol: 'G',
    tld: '.ht',
    native: 'Haïti',
    region: 'Americas',
    subregion: 'Caribbean',
    timezones: [
      {
        zoneName: 'America/Port-au-Prince',
        gmtOffset: -18000,
        gmtOffsetName: 'UTC-05:00',
        abbreviation: 'EST',
        tzName: 'Eastern Standard Time (North America',
      },
    ],
    translations: {
      br: 'Haiti',
      pt: 'Haiti',
      nl: 'Haïti',
      hr: 'Haiti',
      fa: 'هائیتی',
      de: 'Haiti',
      es: 'Haiti',
      fr: 'Haïti',
      ja: 'ハイチ',
      it: 'Haiti',
    },
    latitude: '19.00000000',
    longitude: '-72.41666666',
    emoji: '🇭🇹',
    emojiU: 'U+1F1ED U+1F1F9',
  },
  {
    id: 96,
    name: 'Heard Island and McDonald Islands',
    iso3: 'HMD',
    iso2: 'HM',
    phone_code: '',
    capital: '',
    currency: 'AUD',
    currency_symbol: '$',
    tld: '.hm',
    native: 'Heard Island and McDonald Islands',
    region: '',
    subregion: '',
    timezones: [
      {
        zoneName: 'Indian/Kerguelen',
        gmtOffset: 18000,
        gmtOffsetName: 'UTC+05:00',
        abbreviation: 'TFT',
        tzName: 'French Southern and Antarctic Time',
      },
    ],
    translations: {
      br: 'Ilha Heard e Ilhas McDonald',
      pt: 'Ilha Heard e Ilhas McDonald',
      nl: 'Heard- en McDonaldeilanden',
      hr: 'Otok Heard i otočje McDonald',
      fa: 'جزیره هرد و جزایر مک‌دونالد',
      de: 'Heard und die McDonaldinseln',
      es: 'Islas Heard y McDonald',
      fr: 'Îles Heard-et-MacDonald',
      ja: 'ハード島とマクドナルド諸島',
      it: 'Isole Heard e McDonald',
    },
    latitude: '-53.10000000',
    longitude: '72.51666666',
    emoji: '🇭🇲',
    emojiU: 'U+1F1ED U+1F1F2',
  },
  {
    id: 97,
    name: 'Honduras',
    iso3: 'HND',
    iso2: 'HN',
    phone_code: '504',
    capital: 'Tegucigalpa',
    currency: 'HNL',
    currency_symbol: 'L',
    tld: '.hn',
    native: 'Honduras',
    region: 'Americas',
    subregion: 'Central America',
    timezones: [
      {
        zoneName: 'America/Tegucigalpa',
        gmtOffset: -21600,
        gmtOffsetName: 'UTC-06:00',
        abbreviation: 'CST',
        tzName: 'Central Standard Time (North America',
      },
    ],
    translations: {
      br: 'Honduras',
      pt: 'Honduras',
      nl: 'Honduras',
      hr: 'Honduras',
      fa: 'هندوراس',
      de: 'Honduras',
      es: 'Honduras',
      fr: 'Honduras',
      ja: 'ホンジュラス',
      it: 'Honduras',
    },
    latitude: '15.00000000',
    longitude: '-86.50000000',
    emoji: '🇭🇳',
    emojiU: 'U+1F1ED U+1F1F3',
  },
  {
    id: 98,
    name: 'Hong Kong S.A.R.',
    iso3: 'HKG',
    iso2: 'HK',
    phone_code: '852',
    capital: 'Hong Kong',
    currency: 'HKD',
    currency_symbol: '$',
    tld: '.hk',
    native: '香港',
    region: 'Asia',
    subregion: 'Eastern Asia',
    timezones: [
      {
        zoneName: 'Asia/Hong_Kong',
        gmtOffset: 28800,
        gmtOffsetName: 'UTC+08:00',
        abbreviation: 'HKT',
        tzName: 'Hong Kong Time',
      },
    ],
    translations: {
      br: 'Hong Kong',
      pt: 'Hong Kong',
      nl: 'Hongkong',
      hr: 'Hong Kong',
      fa: 'هنگ‌کنگ',
      de: 'Hong Kong',
      es: 'Hong Kong',
      fr: 'Hong Kong',
      ja: '香港',
      it: 'Hong Kong',
    },
    latitude: '22.25000000',
    longitude: '114.16666666',
    emoji: '🇭🇰',
    emojiU: 'U+1F1ED U+1F1F0',
  },
  {
    id: 99,
    name: 'Hungary',
    iso3: 'HUN',
    iso2: 'HU',
    phone_code: '36',
    capital: 'Budapest',
    currency: 'HUF',
    currency_symbol: 'Ft',
    tld: '.hu',
    native: 'Magyarország',
    region: 'Europe',
    subregion: 'Eastern Europe',
    timezones: [
      {
        zoneName: 'Europe/Budapest',
        gmtOffset: 3600,
        gmtOffsetName: 'UTC+01:00',
        abbreviation: 'CET',
        tzName: 'Central European Time',
      },
    ],
    translations: {
      br: 'Hungria',
      pt: 'Hungria',
      nl: 'Hongarije',
      hr: 'Mađarska',
      fa: 'مجارستان',
      de: 'Ungarn',
      es: 'Hungría',
      fr: 'Hongrie',
      ja: 'ハンガリー',
      it: 'Ungheria',
    },
    latitude: '47.00000000',
    longitude: '20.00000000',
    emoji: '🇭🇺',
    emojiU: 'U+1F1ED U+1F1FA',
  },
  {
    id: 100,
    name: 'Iceland',
    iso3: 'ISL',
    iso2: 'IS',
    phone_code: '354',
    capital: 'Reykjavik',
    currency: 'ISK',
    currency_symbol: 'kr',
    tld: '.is',
    native: 'Ísland',
    region: 'Europe',
    subregion: 'Northern Europe',
    timezones: [
      {
        zoneName: 'Atlantic/Reykjavik',
        gmtOffset: 0,
        gmtOffsetName: 'UTC±00',
        abbreviation: 'GMT',
        tzName: 'Greenwich Mean Time',
      },
    ],
    translations: {
      br: 'Islândia',
      pt: 'Islândia',
      nl: 'IJsland',
      hr: 'Island',
      fa: 'ایسلند',
      de: 'Island',
      es: 'Islandia',
      fr: 'Islande',
      ja: 'アイスランド',
      it: 'Islanda',
    },
    latitude: '65.00000000',
    longitude: '-18.00000000',
    emoji: '🇮🇸',
    emojiU: 'U+1F1EE U+1F1F8',
  },
  {
    id: 101,
    name: 'India',
    iso3: 'IND',
    iso2: 'IN',
    phone_code: '91',
    capital: 'New Delhi',
    currency: 'INR',
    currency_symbol: '₹',
    tld: '.in',
    native: 'भारत',
    region: 'Asia',
    subregion: 'Southern Asia',
    timezones: [
      {
        zoneName: 'Asia/Kolkata',
        gmtOffset: 19800,
        gmtOffsetName: 'UTC+05:30',
        abbreviation: 'IST',
        tzName: 'Indian Standard Time',
      },
    ],
    translations: {
      br: 'Índia',
      pt: 'Índia',
      nl: 'India',
      hr: 'Indija',
      fa: 'هند',
      de: 'Indien',
      es: 'India',
      fr: 'Inde',
      ja: 'インド',
      it: 'India',
    },
    latitude: '20.00000000',
    longitude: '77.00000000',
    emoji: '🇮🇳',
    emojiU: 'U+1F1EE U+1F1F3',
  },
  {
    id: 102,
    name: 'Indonesia',
    iso3: 'IDN',
    iso2: 'ID',
    phone_code: '62',
    capital: 'Jakarta',
    currency: 'IDR',
    currency_symbol: 'Rp',
    tld: '.id',
    native: 'Indonesia',
    region: 'Asia',
    subregion: 'South-Eastern Asia',
    timezones: [
      {
        zoneName: 'Asia/Jakarta',
        gmtOffset: 25200,
        gmtOffsetName: 'UTC+07:00',
        abbreviation: 'WIB',
        tzName: 'Western Indonesian Time',
      },
      {
        zoneName: 'Asia/Jayapura',
        gmtOffset: 32400,
        gmtOffsetName: 'UTC+09:00',
        abbreviation: 'WIT',
        tzName: 'Eastern Indonesian Time',
      },
      {
        zoneName: 'Asia/Makassar',
        gmtOffset: 28800,
        gmtOffsetName: 'UTC+08:00',
        abbreviation: 'WITA',
        tzName: 'Central Indonesia Time',
      },
      {
        zoneName: 'Asia/Pontianak',
        gmtOffset: 25200,
        gmtOffsetName: 'UTC+07:00',
        abbreviation: 'WIB',
        tzName: 'Western Indonesian Time',
      },
    ],
    translations: {
      br: 'Indonésia',
      pt: 'Indonésia',
      nl: 'Indonesië',
      hr: 'Indonezija',
      fa: 'اندونزی',
      de: 'Indonesien',
      es: 'Indonesia',
      fr: 'Indonésie',
      ja: 'インドネシア',
      it: 'Indonesia',
    },
    latitude: '-5.00000000',
    longitude: '120.00000000',
    emoji: '🇮🇩',
    emojiU: 'U+1F1EE U+1F1E9',
  },
  {
    id: 103,
    name: 'Iran',
    iso3: 'IRN',
    iso2: 'IR',
    phone_code: '98',
    capital: 'Tehran',
    currency: 'IRR',
    currency_symbol: '﷼',
    tld: '.ir',
    native: 'ایران',
    region: 'Asia',
    subregion: 'Southern Asia',
    timezones: [
      {
        zoneName: 'Asia/Tehran',
        gmtOffset: 12600,
        gmtOffsetName: 'UTC+03:30',
        abbreviation: 'IRDT',
        tzName: 'Iran Daylight Time',
      },
    ],
    translations: {
      br: 'Irã',
      pt: 'Irão',
      nl: 'Iran',
      hr: 'Iran',
      fa: 'ایران',
      de: 'Iran',
      es: 'Iran',
      fr: 'Iran',
      ja: 'イラン・イスラム共和国',
    },
    latitude: '32.00000000',
    longitude: '53.00000000',
    emoji: '🇮🇷',
    emojiU: 'U+1F1EE U+1F1F7',
  },
  {
    id: 104,
    name: 'Iraq',
    iso3: 'IRQ',
    iso2: 'IQ',
    phone_code: '964',
    capital: 'Baghdad',
    currency: 'IQD',
    currency_symbol: 'د.ع',
    tld: '.iq',
    native: 'العراق',
    region: 'Asia',
    subregion: 'Western Asia',
    timezones: [
      {
        zoneName: 'Asia/Baghdad',
        gmtOffset: 10800,
        gmtOffsetName: 'UTC+03:00',
        abbreviation: 'AST',
        tzName: 'Arabia Standard Time',
      },
    ],
    translations: {
      br: 'Iraque',
      pt: 'Iraque',
      nl: 'Irak',
      hr: 'Irak',
      fa: 'عراق',
      de: 'Irak',
      es: 'Irak',
      fr: 'Irak',
      ja: 'イラク',
      it: 'Iraq',
    },
    latitude: '33.00000000',
    longitude: '44.00000000',
    emoji: '🇮🇶',
    emojiU: 'U+1F1EE U+1F1F6',
  },
  {
    id: 105,
    name: 'Ireland',
    iso3: 'IRL',
    iso2: 'IE',
    phone_code: '353',
    capital: 'Dublin',
    currency: 'EUR',
    currency_symbol: '€',
    tld: '.ie',
    native: 'Éire',
    region: 'Europe',
    subregion: 'Northern Europe',
    timezones: [
      {
        zoneName: 'Europe/Dublin',
        gmtOffset: 0,
        gmtOffsetName: 'UTC±00',
        abbreviation: 'GMT',
        tzName: 'Greenwich Mean Time',
      },
    ],
    translations: {
      br: 'Irlanda',
      pt: 'Irlanda',
      nl: 'Ierland',
      hr: 'Irska',
      fa: 'ایرلند',
      de: 'Irland',
      es: 'Irlanda',
      fr: 'Irlande',
      ja: 'アイルランド',
      it: 'Irlanda',
    },
    latitude: '53.00000000',
    longitude: '-8.00000000',
    emoji: '🇮🇪',
    emojiU: 'U+1F1EE U+1F1EA',
  },
  {
    id: 106,
    name: 'Israel',
    iso3: 'ISR',
    iso2: 'IL',
    phone_code: '972',
    capital: 'Jerusalem',
    currency: 'ILS',
    currency_symbol: '₪',
    tld: '.il',
    native: 'יִשְׂרָאֵל',
    region: 'Asia',
    subregion: 'Western Asia',
    timezones: [
      {
        zoneName: 'Asia/Jerusalem',
        gmtOffset: 7200,
        gmtOffsetName: 'UTC+02:00',
        abbreviation: 'IST',
        tzName: 'Israel Standard Time',
      },
    ],
    translations: {
      br: 'Israel',
      pt: 'Israel',
      nl: 'Israël',
      hr: 'Izrael',
      fa: 'اسرائیل',
      de: 'Israel',
      es: 'Israel',
      fr: 'Israël',
      ja: 'イスラエル',
      it: 'Israele',
    },
    latitude: '31.50000000',
    longitude: '34.75000000',
    emoji: '🇮🇱',
    emojiU: 'U+1F1EE U+1F1F1',
  },
  {
    id: 107,
    name: 'Italy',
    iso3: 'ITA',
    iso2: 'IT',
    phone_code: '39',
    capital: 'Rome',
    currency: 'EUR',
    currency_symbol: '€',
    tld: '.it',
    native: 'Italia',
    region: 'Europe',
    subregion: 'Southern Europe',
    timezones: [
      {
        zoneName: 'Europe/Rome',
        gmtOffset: 3600,
        gmtOffsetName: 'UTC+01:00',
        abbreviation: 'CET',
        tzName: 'Central European Time',
      },
    ],
    translations: {
      br: 'Itália',
      pt: 'Itália',
      nl: 'Italië',
      hr: 'Italija',
      fa: 'ایتالیا',
      de: 'Italien',
      es: 'Italia',
      fr: 'Italie',
      ja: 'イタリア',
      it: 'Italia',
    },
    latitude: '42.83333333',
    longitude: '12.83333333',
    emoji: '🇮🇹',
    emojiU: 'U+1F1EE U+1F1F9',
  },
  {
    id: 108,
    name: 'Jamaica',
    iso3: 'JAM',
    iso2: 'JM',
    phone_code: '+1-876',
    capital: 'Kingston',
    currency: 'JMD',
    currency_symbol: 'J$',
    tld: '.jm',
    native: 'Jamaica',
    region: 'Americas',
    subregion: 'Caribbean',
    timezones: [
      {
        zoneName: 'America/Jamaica',
        gmtOffset: -18000,
        gmtOffsetName: 'UTC-05:00',
        abbreviation: 'EST',
        tzName: 'Eastern Standard Time (North America',
      },
    ],
    translations: {
      br: 'Jamaica',
      pt: 'Jamaica',
      nl: 'Jamaica',
      hr: 'Jamajka',
      fa: 'جامائیکا',
      de: 'Jamaika',
      es: 'Jamaica',
      fr: 'Jamaïque',
      ja: 'ジャマイカ',
      it: 'Giamaica',
    },
    latitude: '18.25000000',
    longitude: '-77.50000000',
    emoji: '🇯🇲',
    emojiU: 'U+1F1EF U+1F1F2',
  },
  {
    id: 109,
    name: 'Japan',
    iso3: 'JPN',
    iso2: 'JP',
    phone_code: '81',
    capital: 'Tokyo',
    currency: 'JPY',
    currency_symbol: '¥',
    tld: '.jp',
    native: '日本',
    region: 'Asia',
    subregion: 'Eastern Asia',
    timezones: [
      {
        zoneName: 'Asia/Tokyo',
        gmtOffset: 32400,
        gmtOffsetName: 'UTC+09:00',
        abbreviation: 'JST',
        tzName: 'Japan Standard Time',
      },
    ],
    translations: {
      br: 'Japão',
      pt: 'Japão',
      nl: 'Japan',
      hr: 'Japan',
      fa: 'ژاپن',
      de: 'Japan',
      es: 'Japón',
      fr: 'Japon',
      ja: '日本',
      it: 'Giappone',
    },
    latitude: '36.00000000',
    longitude: '138.00000000',
    emoji: '🇯🇵',
    emojiU: 'U+1F1EF U+1F1F5',
  },
  {
    id: 110,
    name: 'Jersey',
    iso3: 'JEY',
    iso2: 'JE',
    phone_code: '+44-1534',
    capital: 'Saint Helier',
    currency: 'GBP',
    currency_symbol: '£',
    tld: '.je',
    native: 'Jersey',
    region: 'Europe',
    subregion: 'Northern Europe',
    timezones: [
      {
        zoneName: 'Europe/Jersey',
        gmtOffset: 0,
        gmtOffsetName: 'UTC±00',
        abbreviation: 'GMT',
        tzName: 'Greenwich Mean Time',
      },
    ],
    translations: {
      br: 'Jersey',
      pt: 'Jersey',
      nl: 'Jersey',
      hr: 'Jersey',
      fa: 'جرزی',
      de: 'Jersey',
      es: 'Jersey',
      fr: 'Jersey',
      ja: 'ジャージー',
      it: 'Isola di Jersey',
    },
    latitude: '49.25000000',
    longitude: '-2.16666666',
    emoji: '🇯🇪',
    emojiU: 'U+1F1EF U+1F1EA',
  },
  {
    id: 111,
    name: 'Jordan',
    iso3: 'JOR',
    iso2: 'JO',
    phone_code: '962',
    capital: 'Amman',
    currency: 'JOD',
    currency_symbol: 'ا.د',
    tld: '.jo',
    native: 'الأردن',
    region: 'Asia',
    subregion: 'Western Asia',
    timezones: [
      {
        zoneName: 'Asia/Amman',
        gmtOffset: 7200,
        gmtOffsetName: 'UTC+02:00',
        abbreviation: 'EET',
        tzName: 'Eastern European Time',
      },
    ],
    translations: {
      br: 'Jordânia',
      pt: 'Jordânia',
      nl: 'Jordanië',
      hr: 'Jordan',
      fa: 'اردن',
      de: 'Jordanien',
      es: 'Jordania',
      fr: 'Jordanie',
      ja: 'ヨルダン',
      it: 'Giordania',
    },
    latitude: '31.00000000',
    longitude: '36.00000000',
    emoji: '🇯🇴',
    emojiU: 'U+1F1EF U+1F1F4',
  },
  {
    id: 112,
    name: 'Kazakhstan',
    iso3: 'KAZ',
    iso2: 'KZ',
    phone_code: '7',
    capital: 'Astana',
    currency: 'KZT',
    currency_symbol: 'лв',
    tld: '.kz',
    native: 'Қазақстан',
    region: 'Asia',
    subregion: 'Central Asia',
    timezones: [
      {
        zoneName: 'Asia/Almaty',
        gmtOffset: 21600,
        gmtOffsetName: 'UTC+06:00',
        abbreviation: 'ALMT',
        tzName: 'Alma-Ata Time[1',
      },
      {
        zoneName: 'Asia/Aqtau',
        gmtOffset: 18000,
        gmtOffsetName: 'UTC+05:00',
        abbreviation: 'AQTT',
        tzName: 'Aqtobe Time',
      },
      {
        zoneName: 'Asia/Aqtobe',
        gmtOffset: 18000,
        gmtOffsetName: 'UTC+05:00',
        abbreviation: 'AQTT',
        tzName: 'Aqtobe Time',
      },
      {
        zoneName: 'Asia/Atyrau',
        gmtOffset: 18000,
        gmtOffsetName: 'UTC+05:00',
        abbreviation: 'MSD+1',
        tzName: 'Moscow Daylight Time+1',
      },
      {
        zoneName: 'Asia/Oral',
        gmtOffset: 18000,
        gmtOffsetName: 'UTC+05:00',
        abbreviation: 'ORAT',
        tzName: 'Oral Time',
      },
      {
        zoneName: 'Asia/Qostanay',
        gmtOffset: 21600,
        gmtOffsetName: 'UTC+06:00',
        abbreviation: 'QYZST',
        tzName: 'Qyzylorda Summer Time',
      },
      {
        zoneName: 'Asia/Qyzylorda',
        gmtOffset: 18000,
        gmtOffsetName: 'UTC+05:00',
        abbreviation: 'QYZT',
        tzName: 'Qyzylorda Summer Time',
      },
    ],
    translations: {
      br: 'Cazaquistão',
      pt: 'Cazaquistão',
      nl: 'Kazachstan',
      hr: 'Kazahstan',
      fa: 'قزاقستان',
      de: 'Kasachstan',
      es: 'Kazajistán',
      fr: 'Kazakhstan',
      ja: 'カザフスタン',
      it: 'Kazakistan',
    },
    latitude: '48.00000000',
    longitude: '68.00000000',
    emoji: '🇰🇿',
    emojiU: 'U+1F1F0 U+1F1FF',
  },
  {
    id: 113,
    name: 'Kenya',
    iso3: 'KEN',
    iso2: 'KE',
    phone_code: '254',
    capital: 'Nairobi',
    currency: 'KES',
    currency_symbol: 'KSh',
    tld: '.ke',
    native: 'Kenya',
    region: 'Africa',
    subregion: 'Eastern Africa',
    timezones: [
      {
        zoneName: 'Africa/Nairobi',
        gmtOffset: 10800,
        gmtOffsetName: 'UTC+03:00',
        abbreviation: 'EAT',
        tzName: 'East Africa Time',
      },
    ],
    translations: {
      br: 'Quênia',
      pt: 'Quénia',
      nl: 'Kenia',
      hr: 'Kenija',
      fa: 'کنیا',
      de: 'Kenia',
      es: 'Kenia',
      fr: 'Kenya',
      ja: 'ケニア',
      it: 'Kenya',
    },
    latitude: '1.00000000',
    longitude: '38.00000000',
    emoji: '🇰🇪',
    emojiU: 'U+1F1F0 U+1F1EA',
  },
  {
    id: 114,
    name: 'Kiribati',
    iso3: 'KIR',
    iso2: 'KI',
    phone_code: '686',
    capital: 'Tarawa',
    currency: 'AUD',
    currency_symbol: '$',
    tld: '.ki',
    native: 'Kiribati',
    region: 'Oceania',
    subregion: 'Micronesia',
    timezones: [
      {
        zoneName: 'Pacific/Enderbury',
        gmtOffset: 46800,
        gmtOffsetName: 'UTC+13:00',
        abbreviation: 'PHOT',
        tzName: 'Phoenix Island Time',
      },
      {
        zoneName: 'Pacific/Kiritimati',
        gmtOffset: 50400,
        gmtOffsetName: 'UTC+14:00',
        abbreviation: 'LINT',
        tzName: 'Line Islands Time',
      },
      {
        zoneName: 'Pacific/Tarawa',
        gmtOffset: 43200,
        gmtOffsetName: 'UTC+12:00',
        abbreviation: 'GILT',
        tzName: 'Gilbert Island Time',
      },
    ],
    translations: {
      br: 'Kiribati',
      pt: 'Quiribáti',
      nl: 'Kiribati',
      hr: 'Kiribati',
      fa: 'کیریباتی',
      de: 'Kiribati',
      es: 'Kiribati',
      fr: 'Kiribati',
      ja: 'キリバス',
      it: 'Kiribati',
    },
    latitude: '1.41666666',
    longitude: '173.00000000',
    emoji: '🇰🇮',
    emojiU: 'U+1F1F0 U+1F1EE',
  },
  {
    id: 115,
    name: 'Korea North',
    iso3: 'PRK',
    iso2: 'KP',
    phone_code: '850',
    capital: 'Pyongyang',
    currency: 'KPW',
    currency_symbol: '₩',
    tld: '.kp',
    native: '북한',
    region: 'Asia',
    subregion: 'Eastern Asia',
    timezones: [
      {
        zoneName: 'Asia/Pyongyang',
        gmtOffset: 32400,
        gmtOffsetName: 'UTC+09:00',
        abbreviation: 'KST',
        tzName: 'Korea Standard Time',
      },
    ],
    translations: {
      br: 'Coreia do Norte',
      pt: 'Coreia do Norte',
      nl: 'Noord-Korea',
      hr: 'Sjeverna Koreja',
      fa: 'کره جنوبی',
      de: 'Nordkorea',
      es: 'Corea del Norte',
      fr: 'Corée du Nord',
      ja: '朝鮮民主主義人民共和国',
      it: 'Corea del Nord',
    },
    latitude: '40.00000000',
    longitude: '127.00000000',
    emoji: '🇰🇵',
    emojiU: 'U+1F1F0 U+1F1F5',
  },
  {
    id: 116,
    name: 'Korea South',
    iso3: 'KOR',
    iso2: 'KR',
    phone_code: '82',
    capital: 'Seoul',
    currency: 'KRW',
    currency_symbol: '₩',
    tld: '.kr',
    native: '대한민국',
    region: 'Asia',
    subregion: 'Eastern Asia',
    timezones: [
      {
        zoneName: 'Asia/Seoul',
        gmtOffset: 32400,
        gmtOffsetName: 'UTC+09:00',
        abbreviation: 'KST',
        tzName: 'Korea Standard Time',
      },
    ],
    translations: {
      br: 'Coreia do Sul',
      pt: 'Coreia do Sul',
      nl: 'Zuid-Korea',
      hr: 'Južna Koreja',
      fa: 'کره شمالی',
      de: 'Südkorea',
      es: 'Corea del Sur',
      fr: 'Corée du Sud',
      ja: '大韓民国',
      it: 'Corea del Sud',
    },
    latitude: '37.00000000',
    longitude: '127.50000000',
    emoji: '🇰🇷',
    emojiU: 'U+1F1F0 U+1F1F7',
  },
  {
    id: 248,
    name: 'Kosovo',
    iso3: 'XKX',
    iso2: 'XK',
    phone_code: '383',
    capital: 'Pristina',
    currency: 'EUR',
    currency_symbol: '€',
    tld: '.xk',
    native: 'Republika e Kosovës',
    region: 'Europe',
    subregion: 'Eastern Europe',
    timezones: [
      {
        zoneName: 'Europe/Belgrade',
        gmtOffset: 3600,
        gmtOffsetName: 'UTC+01:00',
        abbreviation: 'CET',
        tzName: 'Central European Time',
      },
    ],
    translations: null,
    latitude: '42.56129090',
    longitude: '20.34030350',
    emoji: '🇽🇰',
    emojiU: 'U+1F1FD U+1F1F0',
  },
  {
    id: 117,
    name: 'Kuwait',
    iso3: 'KWT',
    iso2: 'KW',
    phone_code: '965',
    capital: 'Kuwait City',
    currency: 'KWD',
    currency_symbol: 'ك.د',
    tld: '.kw',
    native: 'الكويت',
    region: 'Asia',
    subregion: 'Western Asia',
    timezones: [
      {
        zoneName: 'Asia/Kuwait',
        gmtOffset: 10800,
        gmtOffsetName: 'UTC+03:00',
        abbreviation: 'AST',
        tzName: 'Arabia Standard Time',
      },
    ],
    translations: {
      br: 'Kuwait',
      pt: 'Kuwait',
      nl: 'Koeweit',
      hr: 'Kuvajt',
      fa: 'کویت',
      de: 'Kuwait',
      es: 'Kuwait',
      fr: 'Koweït',
      ja: 'クウェート',
      it: 'Kuwait',
    },
    latitude: '29.50000000',
    longitude: '45.75000000',
    emoji: '🇰🇼',
    emojiU: 'U+1F1F0 U+1F1FC',
  },
  {
    id: 118,
    name: 'Kyrgyzstan',
    iso3: 'KGZ',
    iso2: 'KG',
    phone_code: '996',
    capital: 'Bishkek',
    currency: 'KGS',
    currency_symbol: 'лв',
    tld: '.kg',
    native: 'Кыргызстан',
    region: 'Asia',
    subregion: 'Central Asia',
    timezones: [
      {
        zoneName: 'Asia/Bishkek',
        gmtOffset: 21600,
        gmtOffsetName: 'UTC+06:00',
        abbreviation: 'KGT',
        tzName: 'Kyrgyzstan Time',
      },
    ],
    translations: {
      br: 'Quirguistão',
      pt: 'Quirguizistão',
      nl: 'Kirgizië',
      hr: 'Kirgistan',
      fa: 'قرقیزستان',
      de: 'Kirgisistan',
      es: 'Kirguizistán',
      fr: 'Kirghizistan',
      ja: 'キルギス',
      it: 'Kirghizistan',
    },
    latitude: '41.00000000',
    longitude: '75.00000000',
    emoji: '🇰🇬',
    emojiU: 'U+1F1F0 U+1F1EC',
  },
  {
    id: 119,
    name: 'Laos',
    iso3: 'LAO',
    iso2: 'LA',
    phone_code: '856',
    capital: 'Vientiane',
    currency: 'LAK',
    currency_symbol: '₭',
    tld: '.la',
    native: 'ສປປລາວ',
    region: 'Asia',
    subregion: 'South-Eastern Asia',
    timezones: [
      {
        zoneName: 'Asia/Vientiane',
        gmtOffset: 25200,
        gmtOffsetName: 'UTC+07:00',
        abbreviation: 'ICT',
        tzName: 'Indochina Time',
      },
    ],
    translations: {
      br: 'Laos',
      pt: 'Laos',
      nl: 'Laos',
      hr: 'Laos',
      fa: 'لائوس',
      de: 'Laos',
      es: 'Laos',
      fr: 'Laos',
      ja: 'ラオス人民民主共和国',
      it: 'Laos',
    },
    latitude: '18.00000000',
    longitude: '105.00000000',
    emoji: '🇱🇦',
    emojiU: 'U+1F1F1 U+1F1E6',
  },
  {
    id: 120,
    name: 'Latvia',
    iso3: 'LVA',
    iso2: 'LV',
    phone_code: '371',
    capital: 'Riga',
    currency: 'EUR',
    currency_symbol: '€',
    tld: '.lv',
    native: 'Latvija',
    region: 'Europe',
    subregion: 'Northern Europe',
    timezones: [
      {
        zoneName: 'Europe/Riga',
        gmtOffset: 7200,
        gmtOffsetName: 'UTC+02:00',
        abbreviation: 'EET',
        tzName: 'Eastern European Time',
      },
    ],
    translations: {
      br: 'Letônia',
      pt: 'Letónia',
      nl: 'Letland',
      hr: 'Latvija',
      fa: 'لتونی',
      de: 'Lettland',
      es: 'Letonia',
      fr: 'Lettonie',
      ja: 'ラトビア',
      it: 'Lettonia',
    },
    latitude: '57.00000000',
    longitude: '25.00000000',
    emoji: '🇱🇻',
    emojiU: 'U+1F1F1 U+1F1FB',
  },
  {
    id: 121,
    name: 'Lebanon',
    iso3: 'LBN',
    iso2: 'LB',
    phone_code: '961',
    capital: 'Beirut',
    currency: 'LBP',
    currency_symbol: '£',
    tld: '.lb',
    native: 'لبنان',
    region: 'Asia',
    subregion: 'Western Asia',
    timezones: [
      {
        zoneName: 'Asia/Beirut',
        gmtOffset: 7200,
        gmtOffsetName: 'UTC+02:00',
        abbreviation: 'EET',
        tzName: 'Eastern European Time',
      },
    ],
    translations: {
      br: 'Líbano',
      pt: 'Líbano',
      nl: 'Libanon',
      hr: 'Libanon',
      fa: 'لبنان',
      de: 'Libanon',
      es: 'Líbano',
      fr: 'Liban',
      ja: 'レバノン',
      it: 'Libano',
    },
    latitude: '33.83333333',
    longitude: '35.83333333',
    emoji: '🇱🇧',
    emojiU: 'U+1F1F1 U+1F1E7',
  },
  {
    id: 122,
    name: 'Lesotho',
    iso3: 'LSO',
    iso2: 'LS',
    phone_code: '266',
    capital: 'Maseru',
    currency: 'LSL',
    currency_symbol: 'L',
    tld: '.ls',
    native: 'Lesotho',
    region: 'Africa',
    subregion: 'Southern Africa',
    timezones: [
      {
        zoneName: 'Africa/Maseru',
        gmtOffset: 7200,
        gmtOffsetName: 'UTC+02:00',
        abbreviation: 'SAST',
        tzName: 'South African Standard Time',
      },
    ],
    translations: {
      br: 'Lesoto',
      pt: 'Lesoto',
      nl: 'Lesotho',
      hr: 'Lesoto',
      fa: 'لسوتو',
      de: 'Lesotho',
      es: 'Lesotho',
      fr: 'Lesotho',
      ja: 'レソト',
      it: 'Lesotho',
    },
    latitude: '-29.50000000',
    longitude: '28.50000000',
    emoji: '🇱🇸',
    emojiU: 'U+1F1F1 U+1F1F8',
  },
  {
    id: 123,
    name: 'Liberia',
    iso3: 'LBR',
    iso2: 'LR',
    phone_code: '231',
    capital: 'Monrovia',
    currency: 'LRD',
    currency_symbol: '$',
    tld: '.lr',
    native: 'Liberia',
    region: 'Africa',
    subregion: 'Western Africa',
    timezones: [
      {
        zoneName: 'Africa/Monrovia',
        gmtOffset: 0,
        gmtOffsetName: 'UTC±00',
        abbreviation: 'GMT',
        tzName: 'Greenwich Mean Time',
      },
    ],
    translations: {
      br: 'Libéria',
      pt: 'Libéria',
      nl: 'Liberia',
      hr: 'Liberija',
      fa: 'لیبریا',
      de: 'Liberia',
      es: 'Liberia',
      fr: 'Liberia',
      ja: 'リベリア',
      it: 'Liberia',
    },
    latitude: '6.50000000',
    longitude: '-9.50000000',
    emoji: '🇱🇷',
    emojiU: 'U+1F1F1 U+1F1F7',
  },
  {
    id: 124,
    name: 'Libya',
    iso3: 'LBY',
    iso2: 'LY',
    phone_code: '218',
    capital: 'Tripolis',
    currency: 'LYD',
    currency_symbol: 'د.ل',
    tld: '.ly',
    native: '‏ليبيا',
    region: 'Africa',
    subregion: 'Northern Africa',
    timezones: [
      {
        zoneName: 'Africa/Tripoli',
        gmtOffset: 7200,
        gmtOffsetName: 'UTC+02:00',
        abbreviation: 'EET',
        tzName: 'Eastern European Time',
      },
    ],
    translations: {
      br: 'Líbia',
      pt: 'Líbia',
      nl: 'Libië',
      hr: 'Libija',
      fa: 'لیبی',
      de: 'Libyen',
      es: 'Libia',
      fr: 'Libye',
      ja: 'リビア',
      it: 'Libia',
    },
    latitude: '25.00000000',
    longitude: '17.00000000',
    emoji: '🇱🇾',
    emojiU: 'U+1F1F1 U+1F1FE',
  },
  {
    id: 125,
    name: 'Liechtenstein',
    iso3: 'LIE',
    iso2: 'LI',
    phone_code: '423',
    capital: 'Vaduz',
    currency: 'CHF',
    currency_symbol: 'CHf',
    tld: '.li',
    native: 'Liechtenstein',
    region: 'Europe',
    subregion: 'Western Europe',
    timezones: [
      {
        zoneName: 'Europe/Vaduz',
        gmtOffset: 3600,
        gmtOffsetName: 'UTC+01:00',
        abbreviation: 'CET',
        tzName: 'Central European Time',
      },
    ],
    translations: {
      br: 'Liechtenstein',
      pt: 'Listenstaine',
      nl: 'Liechtenstein',
      hr: 'Lihtenštajn',
      fa: 'لیختن‌اشتاین',
      de: 'Liechtenstein',
      es: 'Liechtenstein',
      fr: 'Liechtenstein',
      ja: 'リヒテンシュタイン',
      it: 'Liechtenstein',
    },
    latitude: '47.26666666',
    longitude: '9.53333333',
    emoji: '🇱🇮',
    emojiU: 'U+1F1F1 U+1F1EE',
  },
  {
    id: 126,
    name: 'Lithuania',
    iso3: 'LTU',
    iso2: 'LT',
    phone_code: '370',
    capital: 'Vilnius',
    currency: 'EUR',
    currency_symbol: '€',
    tld: '.lt',
    native: 'Lietuva',
    region: 'Europe',
    subregion: 'Northern Europe',
    timezones: [
      {
        zoneName: 'Europe/Vilnius',
        gmtOffset: 7200,
        gmtOffsetName: 'UTC+02:00',
        abbreviation: 'EET',
        tzName: 'Eastern European Time',
      },
    ],
    translations: {
      br: 'Lituânia',
      pt: 'Lituânia',
      nl: 'Litouwen',
      hr: 'Litva',
      fa: 'لیتوانی',
      de: 'Litauen',
      es: 'Lituania',
      fr: 'Lituanie',
      ja: 'リトアニア',
      it: 'Lituania',
    },
    latitude: '56.00000000',
    longitude: '24.00000000',
    emoji: '🇱🇹',
    emojiU: 'U+1F1F1 U+1F1F9',
  },
  {
    id: 127,
    name: 'Luxembourg',
    iso3: 'LUX',
    iso2: 'LU',
    phone_code: '352',
    capital: 'Luxembourg',
    currency: 'EUR',
    currency_symbol: '€',
    tld: '.lu',
    native: 'Luxembourg',
    region: 'Europe',
    subregion: 'Western Europe',
    timezones: [
      {
        zoneName: 'Europe/Luxembourg',
        gmtOffset: 3600,
        gmtOffsetName: 'UTC+01:00',
        abbreviation: 'CET',
        tzName: 'Central European Time',
      },
    ],
    translations: {
      br: 'Luxemburgo',
      pt: 'Luxemburgo',
      nl: 'Luxemburg',
      hr: 'Luksemburg',
      fa: 'لوکزامبورگ',
      de: 'Luxemburg',
      es: 'Luxemburgo',
      fr: 'Luxembourg',
      ja: 'ルクセンブルク',
      it: 'Lussemburgo',
    },
    latitude: '49.75000000',
    longitude: '6.16666666',
    emoji: '🇱🇺',
    emojiU: 'U+1F1F1 U+1F1FA',
  },
  {
    id: 128,
    name: 'Macau S.A.R.',
    iso3: 'MAC',
    iso2: 'MO',
    phone_code: '853',
    capital: 'Macao',
    currency: 'MOP',
    currency_symbol: '$',
    tld: '.mo',
    native: '澳門',
    region: 'Asia',
    subregion: 'Eastern Asia',
    timezones: [
      {
        zoneName: 'Asia/Macau',
        gmtOffset: 28800,
        gmtOffsetName: 'UTC+08:00',
        abbreviation: 'CST',
        tzName: 'China Standard Time',
      },
    ],
    translations: {
      br: 'Macau',
      pt: 'Macau',
      nl: 'Macao',
      hr: 'Makao',
      fa: 'مکائو',
      de: 'Macao',
      es: 'Macao',
      fr: 'Macao',
      ja: 'マカオ',
      it: 'Macao',
    },
    latitude: '22.16666666',
    longitude: '113.55000000',
    emoji: '🇲🇴',
    emojiU: 'U+1F1F2 U+1F1F4',
  },
  {
    id: 129,
    name: 'Macedonia',
    iso3: 'MKD',
    iso2: 'MK',
    phone_code: '389',
    capital: 'Skopje',
    currency: 'MKD',
    currency_symbol: 'ден',
    tld: '.mk',
    native: 'Северна Македонија',
    region: 'Europe',
    subregion: 'Southern Europe',
    timezones: [
      {
        zoneName: 'Europe/Skopje',
        gmtOffset: 3600,
        gmtOffsetName: 'UTC+01:00',
        abbreviation: 'CET',
        tzName: 'Central European Time',
      },
    ],
    translations: {
      br: 'Macedônia',
      pt: 'Macedónia',
      nl: 'Macedonië',
      hr: 'Makedonija',
      fa: '',
      de: 'Mazedonien',
      es: 'Macedonia',
      fr: 'Macédoine',
      ja: 'マケドニア旧ユーゴスラビア共和国',
      it: 'Macedonia',
    },
    latitude: '41.83333333',
    longitude: '22.00000000',
    emoji: '🇲🇰',
    emojiU: 'U+1F1F2 U+1F1F0',
  },
  {
    id: 130,
    name: 'Madagascar',
    iso3: 'MDG',
    iso2: 'MG',
    phone_code: '261',
    capital: 'Antananarivo',
    currency: 'MGA',
    currency_symbol: 'Ar',
    tld: '.mg',
    native: 'Madagasikara',
    region: 'Africa',
    subregion: 'Eastern Africa',
    timezones: [
      {
        zoneName: 'Indian/Antananarivo',
        gmtOffset: 10800,
        gmtOffsetName: 'UTC+03:00',
        abbreviation: 'EAT',
        tzName: 'East Africa Time',
      },
    ],
    translations: {
      br: 'Madagascar',
      pt: 'Madagáscar',
      nl: 'Madagaskar',
      hr: 'Madagaskar',
      fa: 'ماداگاسکار',
      de: 'Madagaskar',
      es: 'Madagascar',
      fr: 'Madagascar',
      ja: 'マダガスカル',
      it: 'Madagascar',
    },
    latitude: '-20.00000000',
    longitude: '47.00000000',
    emoji: '🇲🇬',
    emojiU: 'U+1F1F2 U+1F1EC',
  },
  {
    id: 131,
    name: 'Malawi',
    iso3: 'MWI',
    iso2: 'MW',
    phone_code: '265',
    capital: 'Lilongwe',
    currency: 'MWK',
    currency_symbol: 'MK',
    tld: '.mw',
    native: 'Malawi',
    region: 'Africa',
    subregion: 'Eastern Africa',
    timezones: [
      {
        zoneName: 'Africa/Blantyre',
        gmtOffset: 7200,
        gmtOffsetName: 'UTC+02:00',
        abbreviation: 'CAT',
        tzName: 'Central Africa Time',
      },
    ],
    translations: {
      br: 'Malawi',
      pt: 'Malávi',
      nl: 'Malawi',
      hr: 'Malavi',
      fa: 'مالاوی',
      de: 'Malawi',
      es: 'Malawi',
      fr: 'Malawi',
      ja: 'マラウイ',
      it: 'Malawi',
    },
    latitude: '-13.50000000',
    longitude: '34.00000000',
    emoji: '🇲🇼',
    emojiU: 'U+1F1F2 U+1F1FC',
  },
  {
    id: 132,
    name: 'Malaysia',
    iso3: 'MYS',
    iso2: 'MY',
    phone_code: '60',
    capital: 'Kuala Lumpur',
    currency: 'MYR',
    currency_symbol: 'RM',
    tld: '.my',
    native: 'Malaysia',
    region: 'Asia',
    subregion: 'South-Eastern Asia',
    timezones: [
      {
        zoneName: 'Asia/Kuala_Lumpur',
        gmtOffset: 28800,
        gmtOffsetName: 'UTC+08:00',
        abbreviation: 'MYT',
        tzName: 'Malaysia Time',
      },
      {
        zoneName: 'Asia/Kuching',
        gmtOffset: 28800,
        gmtOffsetName: 'UTC+08:00',
        abbreviation: 'MYT',
        tzName: 'Malaysia Time',
      },
    ],
    translations: {
      br: 'Malásia',
      pt: 'Malásia',
      nl: 'Maleisië',
      hr: 'Malezija',
      fa: 'مالزی',
      de: 'Malaysia',
      es: 'Malasia',
      fr: 'Malaisie',
      ja: 'マレーシア',
      it: 'Malesia',
    },
    latitude: '2.50000000',
    longitude: '112.50000000',
    emoji: '🇲🇾',
    emojiU: 'U+1F1F2 U+1F1FE',
  },
  {
    id: 133,
    name: 'Maldives',
    iso3: 'MDV',
    iso2: 'MV',
    phone_code: '960',
    capital: 'Male',
    currency: 'MVR',
    currency_symbol: 'Rf',
    tld: '.mv',
    native: 'Maldives',
    region: 'Asia',
    subregion: 'Southern Asia',
    timezones: [
      {
        zoneName: 'Indian/Maldives',
        gmtOffset: 18000,
        gmtOffsetName: 'UTC+05:00',
        abbreviation: 'MVT',
        tzName: 'Maldives Time',
      },
    ],
    translations: {
      br: 'Maldivas',
      pt: 'Maldivas',
      nl: 'Maldiven',
      hr: 'Maldivi',
      fa: 'مالدیو',
      de: 'Malediven',
      es: 'Maldivas',
      fr: 'Maldives',
      ja: 'モルディブ',
      it: 'Maldive',
    },
    latitude: '3.25000000',
    longitude: '73.00000000',
    emoji: '🇲🇻',
    emojiU: 'U+1F1F2 U+1F1FB',
  },
  {
    id: 134,
    name: 'Mali',
    iso3: 'MLI',
    iso2: 'ML',
    phone_code: '223',
    capital: 'Bamako',
    currency: 'XOF',
    currency_symbol: 'CFA',
    tld: '.ml',
    native: 'Mali',
    region: 'Africa',
    subregion: 'Western Africa',
    timezones: [
      {
        zoneName: 'Africa/Bamako',
        gmtOffset: 0,
        gmtOffsetName: 'UTC±00',
        abbreviation: 'GMT',
        tzName: 'Greenwich Mean Time',
      },
    ],
    translations: {
      br: 'Mali',
      pt: 'Mali',
      nl: 'Mali',
      hr: 'Mali',
      fa: 'مالی',
      de: 'Mali',
      es: 'Mali',
      fr: 'Mali',
      ja: 'マリ',
      it: 'Mali',
    },
    latitude: '17.00000000',
    longitude: '-4.00000000',
    emoji: '🇲🇱',
    emojiU: 'U+1F1F2 U+1F1F1',
  },
  {
    id: 135,
    name: 'Malta',
    iso3: 'MLT',
    iso2: 'MT',
    phone_code: '356',
    capital: 'Valletta',
    currency: 'EUR',
    currency_symbol: '€',
    tld: '.mt',
    native: 'Malta',
    region: 'Europe',
    subregion: 'Southern Europe',
    timezones: [
      {
        zoneName: 'Europe/Malta',
        gmtOffset: 3600,
        gmtOffsetName: 'UTC+01:00',
        abbreviation: 'CET',
        tzName: 'Central European Time',
      },
    ],
    translations: {
      br: 'Malta',
      pt: 'Malta',
      nl: 'Malta',
      hr: 'Malta',
      fa: 'مالت',
      de: 'Malta',
      es: 'Malta',
      fr: 'Malte',
      ja: 'マルタ',
      it: 'Malta',
    },
    latitude: '35.83333333',
    longitude: '14.58333333',
    emoji: '🇲🇹',
    emojiU: 'U+1F1F2 U+1F1F9',
  },
  {
    id: 136,
    name: 'Man (Isle of)',
    iso3: 'IMN',
    iso2: 'IM',
    phone_code: '+44-1624',
    capital: 'Douglas, Isle of Man',
    currency: 'GBP',
    currency_symbol: '£',
    tld: '.im',
    native: 'Isle of Man',
    region: 'Europe',
    subregion: 'Northern Europe',
    timezones: [
      {
        zoneName: 'Europe/Isle_of_Man',
        gmtOffset: 0,
        gmtOffsetName: 'UTC±00',
        abbreviation: 'GMT',
        tzName: 'Greenwich Mean Time',
      },
    ],
    translations: {
      br: 'Ilha de Man',
      pt: 'Ilha de Man',
      nl: 'Isle of Man',
      hr: 'Otok Man',
      fa: 'جزیره من',
      de: 'Insel Man',
      es: 'Isla de Man',
      fr: 'Île de Man',
      ja: 'マン島',
      it: 'Isola di Man',
    },
    latitude: '54.25000000',
    longitude: '-4.50000000',
    emoji: '🇮🇲',
    emojiU: 'U+1F1EE U+1F1F2',
  },
  {
    id: 137,
    name: 'Marshall Islands',
    iso3: 'MHL',
    iso2: 'MH',
    phone_code: '692',
    capital: 'Majuro',
    currency: 'USD',
    currency_symbol: '$',
    tld: '.mh',
    native: 'M̧ajeļ',
    region: 'Oceania',
    subregion: 'Micronesia',
    timezones: [
      {
        zoneName: 'Pacific/Kwajalein',
        gmtOffset: 43200,
        gmtOffsetName: 'UTC+12:00',
        abbreviation: 'MHT',
        tzName: 'Marshall Islands Time',
      },
      {
        zoneName: 'Pacific/Majuro',
        gmtOffset: 43200,
        gmtOffsetName: 'UTC+12:00',
        abbreviation: 'MHT',
        tzName: 'Marshall Islands Time',
      },
    ],
    translations: {
      br: 'Ilhas Marshall',
      pt: 'Ilhas Marshall',
      nl: 'Marshalleilanden',
      hr: 'Maršalovi Otoci',
      fa: 'جزایر مارشال',
      de: 'Marshallinseln',
      es: 'Islas Marshall',
      fr: 'Îles Marshall',
      ja: 'マーシャル諸島',
      it: 'Isole Marshall',
    },
    latitude: '9.00000000',
    longitude: '168.00000000',
    emoji: '🇲🇭',
    emojiU: 'U+1F1F2 U+1F1ED',
  },
  {
    id: 138,
    name: 'Martinique',
    iso3: 'MTQ',
    iso2: 'MQ',
    phone_code: '596',
    capital: 'Fort-de-France',
    currency: 'EUR',
    currency_symbol: '€',
    tld: '.mq',
    native: 'Martinique',
    region: 'Americas',
    subregion: 'Caribbean',
    timezones: [
      {
        zoneName: 'America/Martinique',
        gmtOffset: -14400,
        gmtOffsetName: 'UTC-04:00',
        abbreviation: 'AST',
        tzName: 'Atlantic Standard Time',
      },
    ],
    translations: {
      br: 'Martinica',
      pt: 'Martinica',
      nl: 'Martinique',
      hr: 'Martinique',
      fa: 'مونتسرات',
      de: 'Martinique',
      es: 'Martinica',
      fr: 'Martinique',
      ja: 'マルティニーク',
      it: 'Martinica',
    },
    latitude: '14.66666700',
    longitude: '-61.00000000',
    emoji: '🇲🇶',
    emojiU: 'U+1F1F2 U+1F1F6',
  },
  {
    id: 139,
    name: 'Mauritania',
    iso3: 'MRT',
    iso2: 'MR',
    phone_code: '222',
    capital: 'Nouakchott',
    currency: 'MRO',
    currency_symbol: 'MRU',
    tld: '.mr',
    native: 'موريتانيا',
    region: 'Africa',
    subregion: 'Western Africa',
    timezones: [
      {
        zoneName: 'Africa/Nouakchott',
        gmtOffset: 0,
        gmtOffsetName: 'UTC±00',
        abbreviation: 'GMT',
        tzName: 'Greenwich Mean Time',
      },
    ],
    translations: {
      br: 'Mauritânia',
      pt: 'Mauritânia',
      nl: 'Mauritanië',
      hr: 'Mauritanija',
      fa: 'موریتانی',
      de: 'Mauretanien',
      es: 'Mauritania',
      fr: 'Mauritanie',
      ja: 'モーリタニア',
      it: 'Mauritania',
    },
    latitude: '20.00000000',
    longitude: '-12.00000000',
    emoji: '🇲🇷',
    emojiU: 'U+1F1F2 U+1F1F7',
  },
  {
    id: 140,
    name: 'Mauritius',
    iso3: 'MUS',
    iso2: 'MU',
    phone_code: '230',
    capital: 'Port Louis',
    currency: 'MUR',
    currency_symbol: '₨',
    tld: '.mu',
    native: 'Maurice',
    region: 'Africa',
    subregion: 'Eastern Africa',
    timezones: [
      {
        zoneName: 'Indian/Mauritius',
        gmtOffset: 14400,
        gmtOffsetName: 'UTC+04:00',
        abbreviation: 'MUT',
        tzName: 'Mauritius Time',
      },
    ],
    translations: {
      br: 'Maurício',
      pt: 'Maurícia',
      nl: 'Mauritius',
      hr: 'Mauricijus',
      fa: 'موریس',
      de: 'Mauritius',
      es: 'Mauricio',
      fr: 'Île Maurice',
      ja: 'モーリシャス',
      it: 'Mauritius',
    },
    latitude: '-20.28333333',
    longitude: '57.55000000',
    emoji: '🇲🇺',
    emojiU: 'U+1F1F2 U+1F1FA',
  },
  {
    id: 141,
    name: 'Mayotte',
    iso3: 'MYT',
    iso2: 'YT',
    phone_code: '262',
    capital: 'Mamoudzou',
    currency: 'EUR',
    currency_symbol: '€',
    tld: '.yt',
    native: 'Mayotte',
    region: 'Africa',
    subregion: 'Eastern Africa',
    timezones: [
      {
        zoneName: 'Indian/Mayotte',
        gmtOffset: 10800,
        gmtOffsetName: 'UTC+03:00',
        abbreviation: 'EAT',
        tzName: 'East Africa Time',
      },
    ],
    translations: {
      br: 'Mayotte',
      pt: 'Mayotte',
      nl: 'Mayotte',
      hr: 'Mayotte',
      fa: 'مایوت',
      de: 'Mayotte',
      es: 'Mayotte',
      fr: 'Mayotte',
      ja: 'マヨット',
      it: 'Mayotte',
    },
    latitude: '-12.83333333',
    longitude: '45.16666666',
    emoji: '🇾🇹',
    emojiU: 'U+1F1FE U+1F1F9',
  },
  {
    id: 142,
    name: 'Mexico',
    iso3: 'MEX',
    iso2: 'MX',
    phone_code: '52',
    capital: 'Mexico City',
    currency: 'MXN',
    currency_symbol: '$',
    tld: '.mx',
    native: 'México',
    region: 'Americas',
    subregion: 'Central America',
    timezones: [
      {
        zoneName: 'America/Bahia_Banderas',
        gmtOffset: -21600,
        gmtOffsetName: 'UTC-06:00',
        abbreviation: 'CST',
        tzName: 'Central Standard Time (North America',
      },
      {
        zoneName: 'America/Cancun',
        gmtOffset: -18000,
        gmtOffsetName: 'UTC-05:00',
        abbreviation: 'EST',
        tzName: 'Eastern Standard Time (North America',
      },
      {
        zoneName: 'America/Chihuahua',
        gmtOffset: -25200,
        gmtOffsetName: 'UTC-07:00',
        abbreviation: 'MST',
        tzName: 'Mountain Standard Time (North America',
      },
      {
        zoneName: 'America/Hermosillo',
        gmtOffset: -25200,
        gmtOffsetName: 'UTC-07:00',
        abbreviation: 'MST',
        tzName: 'Mountain Standard Time (North America',
      },
      {
        zoneName: 'America/Matamoros',
        gmtOffset: -21600,
        gmtOffsetName: 'UTC-06:00',
        abbreviation: 'CST',
        tzName: 'Central Standard Time (North America',
      },
      {
        zoneName: 'America/Mazatlan',
        gmtOffset: -25200,
        gmtOffsetName: 'UTC-07:00',
        abbreviation: 'MST',
        tzName: 'Mountain Standard Time (North America',
      },
      {
        zoneName: 'America/Merida',
        gmtOffset: -21600,
        gmtOffsetName: 'UTC-06:00',
        abbreviation: 'CST',
        tzName: 'Central Standard Time (North America',
      },
      {
        zoneName: 'America/Mexico_City',
        gmtOffset: -21600,
        gmtOffsetName: 'UTC-06:00',
        abbreviation: 'CST',
        tzName: 'Central Standard Time (North America',
      },
      {
        zoneName: 'America/Monterrey',
        gmtOffset: -21600,
        gmtOffsetName: 'UTC-06:00',
        abbreviation: 'CST',
        tzName: 'Central Standard Time (North America',
      },
      {
        zoneName: 'America/Ojinaga',
        gmtOffset: -25200,
        gmtOffsetName: 'UTC-07:00',
        abbreviation: 'MST',
        tzName: 'Mountain Standard Time (North America',
      },
      {
        zoneName: 'America/Tijuana',
        gmtOffset: -28800,
        gmtOffsetName: 'UTC-08:00',
        abbreviation: 'PST',
        tzName: 'Pacific Standard Time (North America',
      },
    ],
    translations: {
      br: 'México',
      pt: 'México',
      nl: 'Mexico',
      hr: 'Meksiko',
      fa: 'مکزیک',
      de: 'Mexiko',
      es: 'México',
      fr: 'Mexique',
      ja: 'メキシコ',
      it: 'Messico',
    },
    latitude: '23.00000000',
    longitude: '-102.00000000',
    emoji: '🇲🇽',
    emojiU: 'U+1F1F2 U+1F1FD',
  },
  {
    id: 143,
    name: 'Micronesia',
    iso3: 'FSM',
    iso2: 'FM',
    phone_code: '691',
    capital: 'Palikir',
    currency: 'USD',
    currency_symbol: '$',
    tld: '.fm',
    native: 'Micronesia',
    region: 'Oceania',
    subregion: 'Micronesia',
    timezones: [
      {
        zoneName: 'Pacific/Chuuk',
        gmtOffset: 36000,
        gmtOffsetName: 'UTC+10:00',
        abbreviation: 'CHUT',
        tzName: 'Chuuk Time',
      },
      {
        zoneName: 'Pacific/Kosrae',
        gmtOffset: 39600,
        gmtOffsetName: 'UTC+11:00',
        abbreviation: 'KOST',
        tzName: 'Kosrae Time',
      },
      {
        zoneName: 'Pacific/Pohnpei',
        gmtOffset: 39600,
        gmtOffsetName: 'UTC+11:00',
        abbreviation: 'PONT',
        tzName: 'Pohnpei Standard Time',
      },
    ],
    translations: {
      br: 'Micronésia',
      pt: 'Micronésia',
      nl: 'Micronesië',
      hr: 'Mikronezija',
      fa: 'ایالات فدرال میکرونزی',
      de: 'Mikronesien',
      es: 'Micronesia',
      fr: 'Micronésie',
      ja: 'ミクロネシア連邦',
      it: 'Micronesia',
    },
    latitude: '6.91666666',
    longitude: '158.25000000',
    emoji: '🇫🇲',
    emojiU: 'U+1F1EB U+1F1F2',
  },
  {
    id: 144,
    name: 'Moldova',
    iso3: 'MDA',
    iso2: 'MD',
    phone_code: '373',
    capital: 'Chisinau',
    currency: 'MDL',
    currency_symbol: 'L',
    tld: '.md',
    native: 'Moldova',
    region: 'Europe',
    subregion: 'Eastern Europe',
    timezones: [
      {
        zoneName: 'Europe/Chisinau',
        gmtOffset: 7200,
        gmtOffsetName: 'UTC+02:00',
        abbreviation: 'EET',
        tzName: 'Eastern European Time',
      },
    ],
    translations: {
      br: 'Moldávia',
      pt: 'Moldávia',
      nl: 'Moldavië',
      hr: 'Moldova',
      fa: 'مولداوی',
      de: 'Moldawie',
      es: 'Moldavia',
      fr: 'Moldavie',
      ja: 'モルドバ共和国',
      it: 'Moldavia',
    },
    latitude: '47.00000000',
    longitude: '29.00000000',
    emoji: '🇲🇩',
    emojiU: 'U+1F1F2 U+1F1E9',
  },
  {
    id: 145,
    name: 'Monaco',
    iso3: 'MCO',
    iso2: 'MC',
    phone_code: '377',
    capital: 'Monaco',
    currency: 'EUR',
    currency_symbol: '€',
    tld: '.mc',
    native: 'Monaco',
    region: 'Europe',
    subregion: 'Western Europe',
    timezones: [
      {
        zoneName: 'Europe/Monaco',
        gmtOffset: 3600,
        gmtOffsetName: 'UTC+01:00',
        abbreviation: 'CET',
        tzName: 'Central European Time',
      },
    ],
    translations: {
      br: 'Mônaco',
      pt: 'Mónaco',
      nl: 'Monaco',
      hr: 'Monako',
      fa: 'موناکو',
      de: 'Monaco',
      es: 'Mónaco',
      fr: 'Monaco',
      ja: 'モナコ',
      it: 'Principato di Monaco',
    },
    latitude: '43.73333333',
    longitude: '7.40000000',
    emoji: '🇲🇨',
    emojiU: 'U+1F1F2 U+1F1E8',
  },
  {
    id: 146,
    name: 'Mongolia',
    iso3: 'MNG',
    iso2: 'MN',
    phone_code: '976',
    capital: 'Ulan Bator',
    currency: 'MNT',
    currency_symbol: '₮',
    tld: '.mn',
    native: 'Монгол улс',
    region: 'Asia',
    subregion: 'Eastern Asia',
    timezones: [
      {
        zoneName: 'Asia/Choibalsan',
        gmtOffset: 28800,
        gmtOffsetName: 'UTC+08:00',
        abbreviation: 'CHOT',
        tzName: 'Choibalsan Standard Time',
      },
      {
        zoneName: 'Asia/Hovd',
        gmtOffset: 25200,
        gmtOffsetName: 'UTC+07:00',
        abbreviation: 'HOVT',
        tzName: 'Hovd Time',
      },
      {
        zoneName: 'Asia/Ulaanbaatar',
        gmtOffset: 28800,
        gmtOffsetName: 'UTC+08:00',
        abbreviation: 'ULAT',
        tzName: 'Ulaanbaatar Standard Time',
      },
    ],
    translations: {
      br: 'Mongólia',
      pt: 'Mongólia',
      nl: 'Mongolië',
      hr: 'Mongolija',
      fa: 'مغولستان',
      de: 'Mongolei',
      es: 'Mongolia',
      fr: 'Mongolie',
      ja: 'モンゴル',
      it: 'Mongolia',
    },
    latitude: '46.00000000',
    longitude: '105.00000000',
    emoji: '🇲🇳',
    emojiU: 'U+1F1F2 U+1F1F3',
  },
  {
    id: 147,
    name: 'Montenegro',
    iso3: 'MNE',
    iso2: 'ME',
    phone_code: '382',
    capital: 'Podgorica',
    currency: 'EUR',
    currency_symbol: '€',
    tld: '.me',
    native: 'Црна Гора',
    region: 'Europe',
    subregion: 'Southern Europe',
    timezones: [
      {
        zoneName: 'Europe/Podgorica',
        gmtOffset: 3600,
        gmtOffsetName: 'UTC+01:00',
        abbreviation: 'CET',
        tzName: 'Central European Time',
      },
    ],
    translations: {
      br: 'Montenegro',
      pt: 'Montenegro',
      nl: 'Montenegro',
      hr: 'Crna Gora',
      fa: 'مونته‌نگرو',
      de: 'Montenegro',
      es: 'Montenegro',
      fr: 'Monténégro',
      ja: 'モンテネグロ',
      it: 'Montenegro',
    },
    latitude: '42.50000000',
    longitude: '19.30000000',
    emoji: '🇲🇪',
    emojiU: 'U+1F1F2 U+1F1EA',
  },
  {
    id: 148,
    name: 'Montserrat',
    iso3: 'MSR',
    iso2: 'MS',
    phone_code: '+1-664',
    capital: 'Plymouth',
    currency: 'XCD',
    currency_symbol: '$',
    tld: '.ms',
    native: 'Montserrat',
    region: 'Americas',
    subregion: 'Caribbean',
    timezones: [
      {
        zoneName: 'America/Montserrat',
        gmtOffset: -14400,
        gmtOffsetName: 'UTC-04:00',
        abbreviation: 'AST',
        tzName: 'Atlantic Standard Time',
      },
    ],
    translations: {
      br: 'Montserrat',
      pt: 'Monserrate',
      nl: 'Montserrat',
      hr: 'Montserrat',
      fa: 'مایوت',
      de: 'Montserrat',
      es: 'Montserrat',
      fr: 'Montserrat',
      ja: 'モントセラト',
      it: 'Montserrat',
    },
    latitude: '16.75000000',
    longitude: '-62.20000000',
    emoji: '🇲🇸',
    emojiU: 'U+1F1F2 U+1F1F8',
  },
  {
    id: 149,
    name: 'Morocco',
    iso3: 'MAR',
    iso2: 'MA',
    phone_code: '212',
    capital: 'Rabat',
    currency: 'MAD',
    currency_symbol: 'DH',
    tld: '.ma',
    native: 'المغرب',
    region: 'Africa',
    subregion: 'Northern Africa',
    timezones: [
      {
        zoneName: 'Africa/Casablanca',
        gmtOffset: 3600,
        gmtOffsetName: 'UTC+01:00',
        abbreviation: 'WEST',
        tzName: 'Western European Summer Time',
      },
    ],
    translations: {
      br: 'Marrocos',
      pt: 'Marrocos',
      nl: 'Marokko',
      hr: 'Maroko',
      fa: 'مراکش',
      de: 'Marokko',
      es: 'Marruecos',
      fr: 'Maroc',
      ja: 'モロッコ',
      it: 'Marocco',
    },
    latitude: '32.00000000',
    longitude: '-5.00000000',
    emoji: '🇲🇦',
    emojiU: 'U+1F1F2 U+1F1E6',
  },
  {
    id: 150,
    name: 'Mozambique',
    iso3: 'MOZ',
    iso2: 'MZ',
    phone_code: '258',
    capital: 'Maputo',
    currency: 'MZN',
    currency_symbol: 'MT',
    tld: '.mz',
    native: 'Moçambique',
    region: 'Africa',
    subregion: 'Eastern Africa',
    timezones: [
      {
        zoneName: 'Africa/Maputo',
        gmtOffset: 7200,
        gmtOffsetName: 'UTC+02:00',
        abbreviation: 'CAT',
        tzName: 'Central Africa Time',
      },
    ],
    translations: {
      br: 'Moçambique',
      pt: 'Moçambique',
      nl: 'Mozambique',
      hr: 'Mozambik',
      fa: 'موزامبیک',
      de: 'Mosambik',
      es: 'Mozambique',
      fr: 'Mozambique',
      ja: 'モザンビーク',
      it: 'Mozambico',
    },
    latitude: '-18.25000000',
    longitude: '35.00000000',
    emoji: '🇲🇿',
    emojiU: 'U+1F1F2 U+1F1FF',
  },
  {
    id: 151,
    name: 'Myanmar',
    iso3: 'MMR',
    iso2: 'MM',
    phone_code: '95',
    capital: 'Nay Pyi Taw',
    currency: 'MMK',
    currency_symbol: 'K',
    tld: '.mm',
    native: 'မြန်မာ',
    region: 'Asia',
    subregion: 'South-Eastern Asia',
    timezones: [
      {
        zoneName: 'Asia/Yangon',
        gmtOffset: 23400,
        gmtOffsetName: 'UTC+06:30',
        abbreviation: 'MMT',
        tzName: 'Myanmar Standard Time',
      },
    ],
    translations: {
      br: 'Myanmar',
      pt: 'Myanmar',
      nl: 'Myanmar',
      hr: 'Mijanmar',
      fa: 'میانمار',
      de: 'Myanmar',
      es: 'Myanmar',
      fr: 'Myanmar',
      ja: 'ミャンマー',
      it: 'Birmania',
    },
    latitude: '22.00000000',
    longitude: '98.00000000',
    emoji: '🇲🇲',
    emojiU: 'U+1F1F2 U+1F1F2',
  },
  {
    id: 152,
    name: 'Namibia',
    iso3: 'NAM',
    iso2: 'NA',
    phone_code: '264',
    capital: 'Windhoek',
    currency: 'NAD',
    currency_symbol: '$',
    tld: '.na',
    native: 'Namibia',
    region: 'Africa',
    subregion: 'Southern Africa',
    timezones: [
      {
        zoneName: 'Africa/Windhoek',
        gmtOffset: 7200,
        gmtOffsetName: 'UTC+02:00',
        abbreviation: 'WAST',
        tzName: 'West Africa Summer Time',
      },
    ],
    translations: {
      br: 'Namíbia',
      pt: 'Namíbia',
      nl: 'Namibië',
      hr: 'Namibija',
      fa: 'نامیبیا',
      de: 'Namibia',
      es: 'Namibia',
      fr: 'Namibie',
      ja: 'ナミビア',
      it: 'Namibia',
    },
    latitude: '-22.00000000',
    longitude: '17.00000000',
    emoji: '🇳🇦',
    emojiU: 'U+1F1F3 U+1F1E6',
  },
  {
    id: 153,
    name: 'Nauru',
    iso3: 'NRU',
    iso2: 'NR',
    phone_code: '674',
    capital: 'Yaren',
    currency: 'AUD',
    currency_symbol: '$',
    tld: '.nr',
    native: 'Nauru',
    region: 'Oceania',
    subregion: 'Micronesia',
    timezones: [
      {
        zoneName: 'Pacific/Nauru',
        gmtOffset: 43200,
        gmtOffsetName: 'UTC+12:00',
        abbreviation: 'NRT',
        tzName: 'Nauru Time',
      },
    ],
    translations: {
      br: 'Nauru',
      pt: 'Nauru',
      nl: 'Nauru',
      hr: 'Nauru',
      fa: 'نائورو',
      de: 'Nauru',
      es: 'Nauru',
      fr: 'Nauru',
      ja: 'ナウル',
      it: 'Nauru',
    },
    latitude: '-0.53333333',
    longitude: '166.91666666',
    emoji: '🇳🇷',
    emojiU: 'U+1F1F3 U+1F1F7',
  },
  {
    id: 154,
    name: 'Nepal',
    iso3: 'NPL',
    iso2: 'NP',
    phone_code: '977',
    capital: 'Kathmandu',
    currency: 'NPR',
    currency_symbol: '₨',
    tld: '.np',
    native: 'नपल',
    region: 'Asia',
    subregion: 'Southern Asia',
    timezones: [
      {
        zoneName: 'Asia/Kathmandu',
        gmtOffset: 20700,
        gmtOffsetName: 'UTC+05:45',
        abbreviation: 'NPT',
        tzName: 'Nepal Time',
      },
    ],
    translations: {
      br: 'Nepal',
      pt: 'Nepal',
      nl: 'Nepal',
      hr: 'Nepal',
      fa: 'نپال',
      de: 'Népal',
      es: 'Nepal',
      fr: 'Népal',
      ja: 'ネパール',
      it: 'Nepal',
    },
    latitude: '28.00000000',
    longitude: '84.00000000',
    emoji: '🇳🇵',
    emojiU: 'U+1F1F3 U+1F1F5',
  },
  {
    id: 156,
    name: 'Netherlands The',
    iso3: 'NLD',
    iso2: 'NL',
    phone_code: '31',
    capital: 'Amsterdam',
    currency: 'EUR',
    currency_symbol: '€',
    tld: '.nl',
    native: 'Nederland',
    region: 'Europe',
    subregion: 'Western Europe',
    timezones: [
      {
        zoneName: 'Europe/Amsterdam',
        gmtOffset: 3600,
        gmtOffsetName: 'UTC+01:00',
        abbreviation: 'CET',
        tzName: 'Central European Time',
      },
    ],
    translations: {
      br: 'Holanda',
      pt: 'Países Baixos',
      nl: 'Nederland',
      hr: 'Nizozemska',
      fa: 'پادشاهی هلند',
      de: 'Niederlande',
      es: 'Países Bajos',
      fr: 'Pays-Bas',
      ja: 'オランダ',
      it: 'Paesi Bassi',
    },
    latitude: '52.50000000',
    longitude: '5.75000000',
    emoji: '🇳🇱',
    emojiU: 'U+1F1F3 U+1F1F1',
  },
  {
    id: 157,
    name: 'New Caledonia',
    iso3: 'NCL',
    iso2: 'NC',
    phone_code: '687',
    capital: 'Noumea',
    currency: 'XPF',
    currency_symbol: '₣',
    tld: '.nc',
    native: 'Nouvelle-Calédonie',
    region: 'Oceania',
    subregion: 'Melanesia',
    timezones: [
      {
        zoneName: 'Pacific/Noumea',
        gmtOffset: 39600,
        gmtOffsetName: 'UTC+11:00',
        abbreviation: 'NCT',
        tzName: 'New Caledonia Time',
      },
    ],
    translations: {
      br: 'Nova Caledônia',
      pt: 'Nova Caledónia',
      nl: 'Nieuw-Caledonië',
      hr: 'Nova Kaledonija',
      fa: 'کالدونیای جدید',
      de: 'Neukaledonien',
      es: 'Nueva Caledonia',
      fr: 'Nouvelle-Calédonie',
      ja: 'ニューカレドニア',
      it: 'Nuova Caledonia',
    },
    latitude: '-21.50000000',
    longitude: '165.50000000',
    emoji: '🇳🇨',
    emojiU: 'U+1F1F3 U+1F1E8',
  },
  {
    id: 158,
    name: 'New Zealand',
    iso3: 'NZL',
    iso2: 'NZ',
    phone_code: '64',
    capital: 'Wellington',
    currency: 'NZD',
    currency_symbol: '$',
    tld: '.nz',
    native: 'New Zealand',
    region: 'Oceania',
    subregion: 'Australia and New Zealand',
    timezones: [
      {
        zoneName: 'Pacific/Auckland',
        gmtOffset: 46800,
        gmtOffsetName: 'UTC+13:00',
        abbreviation: 'NZDT',
        tzName: 'New Zealand Daylight Time',
      },
      {
        zoneName: 'Pacific/Chatham',
        gmtOffset: 49500,
        gmtOffsetName: 'UTC+13:45',
        abbreviation: 'CHAST',
        tzName: 'Chatham Standard Time',
      },
    ],
    translations: {
      br: 'Nova Zelândia',
      pt: 'Nova Zelândia',
      nl: 'Nieuw-Zeeland',
      hr: 'Novi Zeland',
      fa: 'نیوزیلند',
      de: 'Neuseeland',
      es: 'Nueva Zelanda',
      fr: 'Nouvelle-Zélande',
      ja: 'ニュージーランド',
      it: 'Nuova Zelanda',
    },
    latitude: '-41.00000000',
    longitude: '174.00000000',
    emoji: '🇳🇿',
    emojiU: 'U+1F1F3 U+1F1FF',
  },
  {
    id: 159,
    name: 'Nicaragua',
    iso3: 'NIC',
    iso2: 'NI',
    phone_code: '505',
    capital: 'Managua',
    currency: 'NIO',
    currency_symbol: 'C$',
    tld: '.ni',
    native: 'Nicaragua',
    region: 'Americas',
    subregion: 'Central America',
    timezones: [
      {
        zoneName: 'America/Managua',
        gmtOffset: -21600,
        gmtOffsetName: 'UTC-06:00',
        abbreviation: 'CST',
        tzName: 'Central Standard Time (North America',
      },
    ],
    translations: {
      br: 'Nicarágua',
      pt: 'Nicarágua',
      nl: 'Nicaragua',
      hr: 'Nikaragva',
      fa: 'نیکاراگوئه',
      de: 'Nicaragua',
      es: 'Nicaragua',
      fr: 'Nicaragua',
      ja: 'ニカラグア',
      it: 'Nicaragua',
    },
    latitude: '13.00000000',
    longitude: '-85.00000000',
    emoji: '🇳🇮',
    emojiU: 'U+1F1F3 U+1F1EE',
  },
  {
    id: 160,
    name: 'Niger',
    iso3: 'NER',
    iso2: 'NE',
    phone_code: '227',
    capital: 'Niamey',
    currency: 'XOF',
    currency_symbol: 'CFA',
    tld: '.ne',
    native: 'Niger',
    region: 'Africa',
    subregion: 'Western Africa',
    timezones: [
      {
        zoneName: 'Africa/Niamey',
        gmtOffset: 3600,
        gmtOffsetName: 'UTC+01:00',
        abbreviation: 'WAT',
        tzName: 'West Africa Time',
      },
    ],
    translations: {
      br: 'Níger',
      pt: 'Níger',
      nl: 'Niger',
      hr: 'Niger',
      fa: 'نیجر',
      de: 'Niger',
      es: 'Níger',
      fr: 'Niger',
      ja: 'ニジェール',
      it: 'Niger',
    },
    latitude: '16.00000000',
    longitude: '8.00000000',
    emoji: '🇳🇪',
    emojiU: 'U+1F1F3 U+1F1EA',
  },
  {
    id: 161,
    name: 'Nigeria',
    iso3: 'NGA',
    iso2: 'NG',
    phone_code: '234',
    capital: 'Abuja',
    currency: 'NGN',
    currency_symbol: '₦',
    tld: '.ng',
    native: 'Nigeria',
    region: 'Africa',
    subregion: 'Western Africa',
    timezones: [
      {
        zoneName: 'Africa/Lagos',
        gmtOffset: 3600,
        gmtOffsetName: 'UTC+01:00',
        abbreviation: 'WAT',
        tzName: 'West Africa Time',
      },
    ],
    translations: {
      br: 'Nigéria',
      pt: 'Nigéria',
      nl: 'Nigeria',
      hr: 'Nigerija',
      fa: 'نیجریه',
      de: 'Nigeria',
      es: 'Nigeria',
      fr: 'Nigéria',
      ja: 'ナイジェリア',
      it: 'Nigeria',
    },
    latitude: '10.00000000',
    longitude: '8.00000000',
    emoji: '🇳🇬',
    emojiU: 'U+1F1F3 U+1F1EC',
  },
  {
    id: 162,
    name: 'Niue',
    iso3: 'NIU',
    iso2: 'NU',
    phone_code: '683',
    capital: 'Alofi',
    currency: 'NZD',
    currency_symbol: '$',
    tld: '.nu',
    native: 'Niuē',
    region: 'Oceania',
    subregion: 'Polynesia',
    timezones: [
      {
        zoneName: 'Pacific/Niue',
        gmtOffset: -39600,
        gmtOffsetName: 'UTC-11:00',
        abbreviation: 'NUT',
        tzName: 'Niue Time',
      },
    ],
    translations: {
      br: 'Niue',
      pt: 'Niue',
      nl: 'Niue',
      hr: 'Niue',
      fa: 'نیووی',
      de: 'Niue',
      es: 'Niue',
      fr: 'Niue',
      ja: 'ニウエ',
      it: 'Niue',
    },
    latitude: '-19.03333333',
    longitude: '-169.86666666',
    emoji: '🇳🇺',
    emojiU: 'U+1F1F3 U+1F1FA',
  },
  {
    id: 163,
    name: 'Norfolk Island',
    iso3: 'NFK',
    iso2: 'NF',
    phone_code: '672',
    capital: 'Kingston',
    currency: 'AUD',
    currency_symbol: '$',
    tld: '.nf',
    native: 'Norfolk Island',
    region: 'Oceania',
    subregion: 'Australia and New Zealand',
    timezones: [
      {
        zoneName: 'Pacific/Norfolk',
        gmtOffset: 43200,
        gmtOffsetName: 'UTC+12:00',
        abbreviation: 'NFT',
        tzName: 'Norfolk Time',
      },
    ],
    translations: {
      br: 'Ilha Norfolk',
      pt: 'Ilha Norfolk',
      nl: 'Norfolkeiland',
      hr: 'Otok Norfolk',
      fa: 'جزیره نورفک',
      de: 'Norfolkinsel',
      es: 'Isla de Norfolk',
      fr: 'Île de Norfolk',
      ja: 'ノーフォーク島',
      it: 'Isola Norfolk',
    },
    latitude: '-29.03333333',
    longitude: '167.95000000',
    emoji: '🇳🇫',
    emojiU: 'U+1F1F3 U+1F1EB',
  },
  {
    id: 164,
    name: 'Northern Mariana Islands',
    iso3: 'MNP',
    iso2: 'MP',
    phone_code: '+1-670',
    capital: 'Saipan',
    currency: 'USD',
    currency_symbol: '$',
    tld: '.mp',
    native: 'Northern Mariana Islands',
    region: 'Oceania',
    subregion: 'Micronesia',
    timezones: [
      {
        zoneName: 'Pacific/Saipan',
        gmtOffset: 36000,
        gmtOffsetName: 'UTC+10:00',
        abbreviation: 'ChST',
        tzName: 'Chamorro Standard Time',
      },
    ],
    translations: {
      br: 'Ilhas Marianas',
      pt: 'Ilhas Marianas',
      nl: 'Noordelijke Marianeneilanden',
      hr: 'Sjevernomarijanski otoci',
      fa: 'جزایر ماریانای شمالی',
      de: 'Nördliche Marianen',
      es: 'Islas Marianas del Norte',
      fr: 'Îles Mariannes du Nord',
      ja: '北マリアナ諸島',
      it: 'Isole Marianne Settentrionali',
    },
    latitude: '15.20000000',
    longitude: '145.75000000',
    emoji: '🇲🇵',
    emojiU: 'U+1F1F2 U+1F1F5',
  },
  {
    id: 165,
    name: 'Norway',
    iso3: 'NOR',
    iso2: 'NO',
    phone_code: '47',
    capital: 'Oslo',
    currency: 'NOK',
    currency_symbol: 'kr',
    tld: '.no',
    native: 'Norge',
    region: 'Europe',
    subregion: 'Northern Europe',
    timezones: [
      {
        zoneName: 'Europe/Oslo',
        gmtOffset: 3600,
        gmtOffsetName: 'UTC+01:00',
        abbreviation: 'CET',
        tzName: 'Central European Time',
      },
    ],
    translations: {
      br: 'Noruega',
      pt: 'Noruega',
      nl: 'Noorwegen',
      hr: 'Norveška',
      fa: 'نروژ',
      de: 'Norwegen',
      es: 'Noruega',
      fr: 'Norvège',
      ja: 'ノルウェー',
      it: 'Norvegia',
    },
    latitude: '62.00000000',
    longitude: '10.00000000',
    emoji: '🇳🇴',
    emojiU: 'U+1F1F3 U+1F1F4',
  },
  {
    id: 166,
    name: 'Oman',
    iso3: 'OMN',
    iso2: 'OM',
    phone_code: '968',
    capital: 'Muscat',
    currency: 'OMR',
    currency_symbol: '.ع.ر',
    tld: '.om',
    native: 'عمان',
    region: 'Asia',
    subregion: 'Western Asia',
    timezones: [
      {
        zoneName: 'Asia/Muscat',
        gmtOffset: 14400,
        gmtOffsetName: 'UTC+04:00',
        abbreviation: 'GST',
        tzName: 'Gulf Standard Time',
      },
    ],
    translations: {
      br: 'Omã',
      pt: 'Omã',
      nl: 'Oman',
      hr: 'Oman',
      fa: 'عمان',
      de: 'Oman',
      es: 'Omán',
      fr: 'Oman',
      ja: 'オマーン',
      it: 'oman',
    },
    latitude: '21.00000000',
    longitude: '57.00000000',
    emoji: '🇴🇲',
    emojiU: 'U+1F1F4 U+1F1F2',
  },
  {
    id: 167,
    name: 'Pakistan',
    iso3: 'PAK',
    iso2: 'PK',
    phone_code: '92',
    capital: 'Islamabad',
    currency: 'PKR',
    currency_symbol: '₨',
    tld: '.pk',
    native: 'Pakistan',
    region: 'Asia',
    subregion: 'Southern Asia',
    timezones: [
      {
        zoneName: 'Asia/Karachi',
        gmtOffset: 18000,
        gmtOffsetName: 'UTC+05:00',
        abbreviation: 'PKT',
        tzName: 'Pakistan Standard Time',
      },
    ],
    translations: {
      br: 'Paquistão',
      pt: 'Paquistão',
      nl: 'Pakistan',
      hr: 'Pakistan',
      fa: 'پاکستان',
      de: 'Pakistan',
      es: 'Pakistán',
      fr: 'Pakistan',
      ja: 'パキスタン',
      it: 'Pakistan',
    },
    latitude: '30.00000000',
    longitude: '70.00000000',
    emoji: '🇵🇰',
    emojiU: 'U+1F1F5 U+1F1F0',
  },
  {
    id: 168,
    name: 'Palau',
    iso3: 'PLW',
    iso2: 'PW',
    phone_code: '680',
    capital: 'Melekeok',
    currency: 'USD',
    currency_symbol: '$',
    tld: '.pw',
    native: 'Palau',
    region: 'Oceania',
    subregion: 'Micronesia',
    timezones: [
      {
        zoneName: 'Pacific/Palau',
        gmtOffset: 32400,
        gmtOffsetName: 'UTC+09:00',
        abbreviation: 'PWT',
        tzName: 'Palau Time',
      },
    ],
    translations: {
      br: 'Palau',
      pt: 'Palau',
      nl: 'Palau',
      hr: 'Palau',
      fa: 'پالائو',
      de: 'Palau',
      es: 'Palau',
      fr: 'Palaos',
      ja: 'パラオ',
      it: 'Palau',
    },
    latitude: '7.50000000',
    longitude: '134.50000000',
    emoji: '🇵🇼',
    emojiU: 'U+1F1F5 U+1F1FC',
  },
  {
    id: 169,
    name: 'Palestinian Territory Occupied',
    iso3: 'PSE',
    iso2: 'PS',
    phone_code: '970',
    capital: 'East Jerusalem',
    currency: 'ILS',
    currency_symbol: '₪',
    tld: '.ps',
    native: 'فلسطين',
    region: 'Asia',
    subregion: 'Western Asia',
    timezones: [
      {
        zoneName: 'Asia/Gaza',
        gmtOffset: 7200,
        gmtOffsetName: 'UTC+02:00',
        abbreviation: 'EET',
        tzName: 'Eastern European Time',
      },
      {
        zoneName: 'Asia/Hebron',
        gmtOffset: 7200,
        gmtOffsetName: 'UTC+02:00',
        abbreviation: 'EET',
        tzName: 'Eastern European Time',
      },
    ],
    translations: {
      br: 'Palestina',
      pt: 'Palestina',
      nl: 'Palestijnse gebieden',
      hr: 'Palestina',
      fa: 'فلسطین',
      de: 'Palästina',
      es: 'Palestina',
      fr: 'Palestine',
      ja: 'パレスチナ',
      it: 'Palestina',
    },
    latitude: '31.90000000',
    longitude: '35.20000000',
    emoji: '🇵🇸',
    emojiU: 'U+1F1F5 U+1F1F8',
  },
  {
    id: 170,
    name: 'Panama',
    iso3: 'PAN',
    iso2: 'PA',
    phone_code: '507',
    capital: 'Panama City',
    currency: 'PAB',
    currency_symbol: 'B/.',
    tld: '.pa',
    native: 'Panamá',
    region: 'Americas',
    subregion: 'Central America',
    timezones: [
      {
        zoneName: 'America/Panama',
        gmtOffset: -18000,
        gmtOffsetName: 'UTC-05:00',
        abbreviation: 'EST',
        tzName: 'Eastern Standard Time (North America',
      },
    ],
    translations: {
      br: 'Panamá',
      pt: 'Panamá',
      nl: 'Panama',
      hr: 'Panama',
      fa: 'پاناما',
      de: 'Panama',
      es: 'Panamá',
      fr: 'Panama',
      ja: 'パナマ',
      it: 'Panama',
    },
    latitude: '9.00000000',
    longitude: '-80.00000000',
    emoji: '🇵🇦',
    emojiU: 'U+1F1F5 U+1F1E6',
  },
  {
    id: 171,
    name: 'Papua new Guinea',
    iso3: 'PNG',
    iso2: 'PG',
    phone_code: '675',
    capital: 'Port Moresby',
    currency: 'PGK',
    currency_symbol: 'K',
    tld: '.pg',
    native: 'Papua Niugini',
    region: 'Oceania',
    subregion: 'Melanesia',
    timezones: [
      {
        zoneName: 'Pacific/Bougainville',
        gmtOffset: 39600,
        gmtOffsetName: 'UTC+11:00',
        abbreviation: 'BST',
        tzName: 'Bougainville Standard Time[6',
      },
      {
        zoneName: 'Pacific/Port_Moresby',
        gmtOffset: 36000,
        gmtOffsetName: 'UTC+10:00',
        abbreviation: 'PGT',
        tzName: 'Papua New Guinea Time',
      },
    ],
    translations: {
      br: 'Papua Nova Guiné',
      pt: 'Papua Nova Guiné',
      nl: 'Papoea-Nieuw-Guinea',
      hr: 'Papua Nova Gvineja',
      fa: 'پاپوآ گینه نو',
      de: 'Papua-Neuguinea',
      es: 'Papúa Nueva Guinea',
      fr: 'Papouasie-Nouvelle-Guinée',
      ja: 'パプアニューギニア',
      it: 'Papua Nuova Guinea',
    },
    latitude: '-6.00000000',
    longitude: '147.00000000',
    emoji: '🇵🇬',
    emojiU: 'U+1F1F5 U+1F1EC',
  },
  {
    id: 172,
    name: 'Paraguay',
    iso3: 'PRY',
    iso2: 'PY',
    phone_code: '595',
    capital: 'Asuncion',
    currency: 'PYG',
    currency_symbol: '₲',
    tld: '.py',
    native: 'Paraguay',
    region: 'Americas',
    subregion: 'South America',
    timezones: [
      {
        zoneName: 'America/Asuncion',
        gmtOffset: -10800,
        gmtOffsetName: 'UTC-03:00',
        abbreviation: 'PYST',
        tzName: 'Paraguay Summer Time',
      },
    ],
    translations: {
      br: 'Paraguai',
      pt: 'Paraguai',
      nl: 'Paraguay',
      hr: 'Paragvaj',
      fa: 'پاراگوئه',
      de: 'Paraguay',
      es: 'Paraguay',
      fr: 'Paraguay',
      ja: 'パラグアイ',
      it: 'Paraguay',
    },
    latitude: '-23.00000000',
    longitude: '-58.00000000',
    emoji: '🇵🇾',
    emojiU: 'U+1F1F5 U+1F1FE',
  },
  {
    id: 173,
    name: 'Peru',
    iso3: 'PER',
    iso2: 'PE',
    phone_code: '51',
    capital: 'Lima',
    currency: 'PEN',
    currency_symbol: 'S/.',
    tld: '.pe',
    native: 'Perú',
    region: 'Americas',
    subregion: 'South America',
    timezones: [
      {
        zoneName: 'America/Lima',
        gmtOffset: -18000,
        gmtOffsetName: 'UTC-05:00',
        abbreviation: 'PET',
        tzName: 'Peru Time',
      },
    ],
    translations: {
      br: 'Peru',
      pt: 'Peru',
      nl: 'Peru',
      hr: 'Peru',
      fa: 'پرو',
      de: 'Peru',
      es: 'Perú',
      fr: 'Pérou',
      ja: 'ペルー',
      it: 'Perù',
    },
    latitude: '-10.00000000',
    longitude: '-76.00000000',
    emoji: '🇵🇪',
    emojiU: 'U+1F1F5 U+1F1EA',
  },
  {
    id: 174,
    name: 'Philippines',
    iso3: 'PHL',
    iso2: 'PH',
    phone_code: '63',
    capital: 'Manila',
    currency: 'PHP',
    currency_symbol: '₱',
    tld: '.ph',
    native: 'Pilipinas',
    region: 'Asia',
    subregion: 'South-Eastern Asia',
    timezones: [
      {
        zoneName: 'Asia/Manila',
        gmtOffset: 28800,
        gmtOffsetName: 'UTC+08:00',
        abbreviation: 'PHT',
        tzName: 'Philippine Time',
      },
    ],
    translations: {
      br: 'Filipinas',
      pt: 'Filipinas',
      nl: 'Filipijnen',
      hr: 'Filipini',
      fa: 'جزایر الندفیلیپین',
      de: 'Philippinen',
      es: 'Filipinas',
      fr: 'Philippines',
      ja: 'フィリピン',
      it: 'Filippine',
    },
    latitude: '13.00000000',
    longitude: '122.00000000',
    emoji: '🇵🇭',
    emojiU: 'U+1F1F5 U+1F1ED',
  },
  {
    id: 175,
    name: 'Pitcairn Island',
    iso3: 'PCN',
    iso2: 'PN',
    phone_code: '870',
    capital: 'Adamstown',
    currency: 'NZD',
    currency_symbol: '$',
    tld: '.pn',
    native: 'Pitcairn Islands',
    region: 'Oceania',
    subregion: 'Polynesia',
    timezones: [
      {
        zoneName: 'Pacific/Pitcairn',
        gmtOffset: -28800,
        gmtOffsetName: 'UTC-08:00',
        abbreviation: 'PST',
        tzName: 'Pacific Standard Time (North America',
      },
    ],
    translations: {
      br: 'Ilhas Pitcairn',
      pt: 'Ilhas Picárnia',
      nl: 'Pitcairneilanden',
      hr: 'Pitcairnovo otočje',
      fa: 'پیتکرن',
      de: 'Pitcairn',
      es: 'Islas Pitcairn',
      fr: 'Îles Pitcairn',
      ja: 'ピトケアン',
      it: 'Isole Pitcairn',
    },
    latitude: '-25.06666666',
    longitude: '-130.10000000',
    emoji: '🇵🇳',
    emojiU: 'U+1F1F5 U+1F1F3',
  },
  {
    id: 176,
    name: 'Poland',
    iso3: 'POL',
    iso2: 'PL',
    phone_code: '48',
    capital: 'Warsaw',
    currency: 'PLN',
    currency_symbol: 'zł',
    tld: '.pl',
    native: 'Polska',
    region: 'Europe',
    subregion: 'Eastern Europe',
    timezones: [
      {
        zoneName: 'Europe/Warsaw',
        gmtOffset: 3600,
        gmtOffsetName: 'UTC+01:00',
        abbreviation: 'CET',
        tzName: 'Central European Time',
      },
    ],
    translations: {
      br: 'Polônia',
      pt: 'Polónia',
      nl: 'Polen',
      hr: 'Poljska',
      fa: 'لهستان',
      de: 'Polen',
      es: 'Polonia',
      fr: 'Pologne',
      ja: 'ポーランド',
      it: 'Polonia',
    },
    latitude: '52.00000000',
    longitude: '20.00000000',
    emoji: '🇵🇱',
    emojiU: 'U+1F1F5 U+1F1F1',
  },
  {
    id: 177,
    name: 'Portugal',
    iso3: 'PRT',
    iso2: 'PT',
    phone_code: '351',
    capital: 'Lisbon',
    currency: 'EUR',
    currency_symbol: '€',
    tld: '.pt',
    native: 'Portugal',
    region: 'Europe',
    subregion: 'Southern Europe',
    timezones: [
      {
        zoneName: 'Atlantic/Azores',
        gmtOffset: -3600,
        gmtOffsetName: 'UTC-01:00',
        abbreviation: 'AZOT',
        tzName: 'Azores Standard Time',
      },
      {
        zoneName: 'Atlantic/Madeira',
        gmtOffset: 0,
        gmtOffsetName: 'UTC±00',
        abbreviation: 'WET',
        tzName: 'Western European Time',
      },
      {
        zoneName: 'Europe/Lisbon',
        gmtOffset: 0,
        gmtOffsetName: 'UTC±00',
        abbreviation: 'WET',
        tzName: 'Western European Time',
      },
    ],
    translations: {
      br: 'Portugal',
      pt: 'Portugal',
      nl: 'Portugal',
      hr: 'Portugal',
      fa: 'پرتغال',
      de: 'Portugal',
      es: 'Portugal',
      fr: 'Portugal',
      ja: 'ポルトガル',
      it: 'Portogallo',
    },
    latitude: '39.50000000',
    longitude: '-8.00000000',
    emoji: '🇵🇹',
    emojiU: 'U+1F1F5 U+1F1F9',
  },
  {
    id: 178,
    name: 'Puerto Rico',
    iso3: 'PRI',
    iso2: 'PR',
    phone_code: '+1-787 and 1-939',
    capital: 'San Juan',
    currency: 'USD',
    currency_symbol: '$',
    tld: '.pr',
    native: 'Puerto Rico',
    region: 'Americas',
    subregion: 'Caribbean',
    timezones: [
      {
        zoneName: 'America/Puerto_Rico',
        gmtOffset: -14400,
        gmtOffsetName: 'UTC-04:00',
        abbreviation: 'AST',
        tzName: 'Atlantic Standard Time',
      },
    ],
    translations: {
      br: 'Porto Rico',
      pt: 'Porto Rico',
      nl: 'Puerto Rico',
      hr: 'Portoriko',
      fa: 'پورتو ریکو',
      de: 'Puerto Rico',
      es: 'Puerto Rico',
      fr: 'Porto Rico',
      ja: 'プエルトリコ',
      it: 'Porto Rico',
    },
    latitude: '18.25000000',
    longitude: '-66.50000000',
    emoji: '🇵🇷',
    emojiU: 'U+1F1F5 U+1F1F7',
  },
  {
    id: 179,
    name: 'Qatar',
    iso3: 'QAT',
    iso2: 'QA',
    phone_code: '974',
    capital: 'Doha',
    currency: 'QAR',
    currency_symbol: 'ق.ر',
    tld: '.qa',
    native: 'قطر',
    region: 'Asia',
    subregion: 'Western Asia',
    timezones: [
      {
        zoneName: 'Asia/Qatar',
        gmtOffset: 10800,
        gmtOffsetName: 'UTC+03:00',
        abbreviation: 'AST',
        tzName: 'Arabia Standard Time',
      },
    ],
    translations: {
      br: 'Catar',
      pt: 'Catar',
      nl: 'Qatar',
      hr: 'Katar',
      fa: 'قطر',
      de: 'Katar',
      es: 'Catar',
      fr: 'Qatar',
      ja: 'カタール',
      it: 'Qatar',
    },
    latitude: '25.50000000',
    longitude: '51.25000000',
    emoji: '🇶🇦',
    emojiU: 'U+1F1F6 U+1F1E6',
  },
  {
    id: 180,
    name: 'Reunion',
    iso3: 'REU',
    iso2: 'RE',
    phone_code: '262',
    capital: 'Saint-Denis',
    currency: 'EUR',
    currency_symbol: '€',
    tld: '.re',
    native: 'La Réunion',
    region: 'Africa',
    subregion: 'Eastern Africa',
    timezones: [
      {
        zoneName: 'Indian/Reunion',
        gmtOffset: 14400,
        gmtOffsetName: 'UTC+04:00',
        abbreviation: 'RET',
        tzName: 'Réunion Time',
      },
    ],
    translations: {
      br: 'Reunião',
      pt: 'Reunião',
      nl: 'Réunion',
      hr: 'Réunion',
      fa: 'رئونیون',
      de: 'Réunion',
      es: 'Reunión',
      fr: 'Réunion',
      ja: 'レユニオン',
      it: 'Riunione',
    },
    latitude: '-21.15000000',
    longitude: '55.50000000',
    emoji: '🇷🇪',
    emojiU: 'U+1F1F7 U+1F1EA',
  },
  {
    id: 181,
    name: 'Romania',
    iso3: 'ROU',
    iso2: 'RO',
    phone_code: '40',
    capital: 'Bucharest',
    currency: 'RON',
    currency_symbol: 'lei',
    tld: '.ro',
    native: 'România',
    region: 'Europe',
    subregion: 'Eastern Europe',
    timezones: [
      {
        zoneName: 'Europe/Bucharest',
        gmtOffset: 7200,
        gmtOffsetName: 'UTC+02:00',
        abbreviation: 'EET',
        tzName: 'Eastern European Time',
      },
    ],
    translations: {
      br: 'Romênia',
      pt: 'Roménia',
      nl: 'Roemenië',
      hr: 'Rumunjska',
      fa: 'رومانی',
      de: 'Rumänien',
      es: 'Rumania',
      fr: 'Roumanie',
      ja: 'ルーマニア',
      it: 'Romania',
    },
    latitude: '46.00000000',
    longitude: '25.00000000',
    emoji: '🇷🇴',
    emojiU: 'U+1F1F7 U+1F1F4',
  },
  {
    id: 182,
    name: 'Russia',
    iso3: 'RUS',
    iso2: 'RU',
    phone_code: '7',
    capital: 'Moscow',
    currency: 'RUB',
    currency_symbol: '₽',
    tld: '.ru',
    native: 'Россия',
    region: 'Europe',
    subregion: 'Eastern Europe',
    timezones: [
      {
        zoneName: 'Asia/Anadyr',
        gmtOffset: 43200,
        gmtOffsetName: 'UTC+12:00',
        abbreviation: 'ANAT',
        tzName: 'Anadyr Time[4',
      },
      {
        zoneName: 'Asia/Barnaul',
        gmtOffset: 25200,
        gmtOffsetName: 'UTC+07:00',
        abbreviation: 'KRAT',
        tzName: 'Krasnoyarsk Time',
      },
      {
        zoneName: 'Asia/Chita',
        gmtOffset: 32400,
        gmtOffsetName: 'UTC+09:00',
        abbreviation: 'YAKT',
        tzName: 'Yakutsk Time',
      },
      {
        zoneName: 'Asia/Irkutsk',
        gmtOffset: 28800,
        gmtOffsetName: 'UTC+08:00',
        abbreviation: 'IRKT',
        tzName: 'Irkutsk Time',
      },
      {
        zoneName: 'Asia/Kamchatka',
        gmtOffset: 43200,
        gmtOffsetName: 'UTC+12:00',
        abbreviation: 'PETT',
        tzName: 'Kamchatka Time',
      },
      {
        zoneName: 'Asia/Khandyga',
        gmtOffset: 32400,
        gmtOffsetName: 'UTC+09:00',
        abbreviation: 'YAKT',
        tzName: 'Yakutsk Time',
      },
      {
        zoneName: 'Asia/Krasnoyarsk',
        gmtOffset: 25200,
        gmtOffsetName: 'UTC+07:00',
        abbreviation: 'KRAT',
        tzName: 'Krasnoyarsk Time',
      },
      {
        zoneName: 'Asia/Magadan',
        gmtOffset: 39600,
        gmtOffsetName: 'UTC+11:00',
        abbreviation: 'MAGT',
        tzName: 'Magadan Time',
      },
      {
        zoneName: 'Asia/Novokuznetsk',
        gmtOffset: 25200,
        gmtOffsetName: 'UTC+07:00',
        abbreviation: 'KRAT',
        tzName: 'Krasnoyarsk Time',
      },
      {
        zoneName: 'Asia/Novosibirsk',
        gmtOffset: 25200,
        gmtOffsetName: 'UTC+07:00',
        abbreviation: 'NOVT',
        tzName: 'Novosibirsk Time',
      },
      {
        zoneName: 'Asia/Omsk',
        gmtOffset: 21600,
        gmtOffsetName: 'UTC+06:00',
        abbreviation: 'OMST',
        tzName: 'Omsk Time',
      },
      {
        zoneName: 'Asia/Sakhalin',
        gmtOffset: 39600,
        gmtOffsetName: 'UTC+11:00',
        abbreviation: 'SAKT',
        tzName: 'Sakhalin Island Time',
      },
      {
        zoneName: 'Asia/Srednekolymsk',
        gmtOffset: 39600,
        gmtOffsetName: 'UTC+11:00',
        abbreviation: 'SRET',
        tzName: 'Srednekolymsk Time',
      },
      {
        zoneName: 'Asia/Tomsk',
        gmtOffset: 25200,
        gmtOffsetName: 'UTC+07:00',
        abbreviation: 'MSD+3',
        tzName: 'Moscow Daylight Time+3',
      },
      {
        zoneName: 'Asia/Ust-Nera',
        gmtOffset: 36000,
        gmtOffsetName: 'UTC+10:00',
        abbreviation: 'VLAT',
        tzName: 'Vladivostok Time',
      },
      {
        zoneName: 'Asia/Vladivostok',
        gmtOffset: 36000,
        gmtOffsetName: 'UTC+10:00',
        abbreviation: 'VLAT',
        tzName: 'Vladivostok Time',
      },
      {
        zoneName: 'Asia/Yakutsk',
        gmtOffset: 32400,
        gmtOffsetName: 'UTC+09:00',
        abbreviation: 'YAKT',
        tzName: 'Yakutsk Time',
      },
      {
        zoneName: 'Asia/Yekaterinburg',
        gmtOffset: 18000,
        gmtOffsetName: 'UTC+05:00',
        abbreviation: 'YEKT',
        tzName: 'Yekaterinburg Time',
      },
      {
        zoneName: 'Europe/Astrakhan',
        gmtOffset: 14400,
        gmtOffsetName: 'UTC+04:00',
        abbreviation: 'SAMT',
        tzName: 'Samara Time',
      },
      {
        zoneName: 'Europe/Kaliningrad',
        gmtOffset: 7200,
        gmtOffsetName: 'UTC+02:00',
        abbreviation: 'EET',
        tzName: 'Eastern European Time',
      },
      {
        zoneName: 'Europe/Kirov',
        gmtOffset: 10800,
        gmtOffsetName: 'UTC+03:00',
        abbreviation: 'MSK',
        tzName: 'Moscow Time',
      },
      {
        zoneName: 'Europe/Moscow',
        gmtOffset: 10800,
        gmtOffsetName: 'UTC+03:00',
        abbreviation: 'MSK',
        tzName: 'Moscow Time',
      },
      {
        zoneName: 'Europe/Samara',
        gmtOffset: 14400,
        gmtOffsetName: 'UTC+04:00',
        abbreviation: 'SAMT',
        tzName: 'Samara Time',
      },
      {
        zoneName: 'Europe/Saratov',
        gmtOffset: 14400,
        gmtOffsetName: 'UTC+04:00',
        abbreviation: 'MSD',
        tzName: 'Moscow Daylight Time+4',
      },
      {
        zoneName: 'Europe/Ulyanovsk',
        gmtOffset: 14400,
        gmtOffsetName: 'UTC+04:00',
        abbreviation: 'SAMT',
        tzName: 'Samara Time',
      },
      {
        zoneName: 'Europe/Volgograd',
        gmtOffset: 14400,
        gmtOffsetName: 'UTC+04:00',
        abbreviation: 'MSK',
        tzName: 'Moscow Standard Time',
      },
    ],
    translations: {
      br: 'Rússia',
      pt: 'Rússia',
      nl: 'Rusland',
      hr: 'Rusija',
      fa: 'روسیه',
      de: 'Russland',
      es: 'Rusia',
      fr: 'Russie',
      ja: 'ロシア連邦',
      it: 'Russia',
    },
    latitude: '60.00000000',
    longitude: '100.00000000',
    emoji: '🇷🇺',
    emojiU: 'U+1F1F7 U+1F1FA',
  },
  {
    id: 183,
    name: 'Rwanda',
    iso3: 'RWA',
    iso2: 'RW',
    phone_code: '250',
    capital: 'Kigali',
    currency: 'RWF',
    currency_symbol: 'FRw',
    tld: '.rw',
    native: 'Rwanda',
    region: 'Africa',
    subregion: 'Eastern Africa',
    timezones: [
      {
        zoneName: 'Africa/Kigali',
        gmtOffset: 7200,
        gmtOffsetName: 'UTC+02:00',
        abbreviation: 'CAT',
        tzName: 'Central Africa Time',
      },
    ],
    translations: {
      br: 'Ruanda',
      pt: 'Ruanda',
      nl: 'Rwanda',
      hr: 'Ruanda',
      fa: 'رواندا',
      de: 'Ruanda',
      es: 'Ruanda',
      fr: 'Rwanda',
      ja: 'ルワンダ',
      it: 'Ruanda',
    },
    latitude: '-2.00000000',
    longitude: '30.00000000',
    emoji: '🇷🇼',
    emojiU: 'U+1F1F7 U+1F1FC',
  },
  {
    id: 184,
    name: 'Saint Helena',
    iso3: 'SHN',
    iso2: 'SH',
    phone_code: '290',
    capital: 'Jamestown',
    currency: 'SHP',
    currency_symbol: '£',
    tld: '.sh',
    native: 'Saint Helena',
    region: 'Africa',
    subregion: 'Western Africa',
    timezones: [
      {
        zoneName: 'Atlantic/St_Helena',
        gmtOffset: 0,
        gmtOffsetName: 'UTC±00',
        abbreviation: 'GMT',
        tzName: 'Greenwich Mean Time',
      },
    ],
    translations: {
      br: 'Santa Helena',
      pt: 'Santa Helena',
      nl: 'Sint-Helena',
      hr: 'Sveta Helena',
      fa: 'سنت هلنا، اسنشن و تریستان دا کونا',
      de: 'Sankt Helena',
      es: 'Santa Helena',
      fr: 'Sainte-Hélène',
      ja: 'セントヘレナ・アセンションおよびトリスタンダクーニャ',
      it: "Sant'Elena",
    },
    latitude: '-15.95000000',
    longitude: '-5.70000000',
    emoji: '🇸🇭',
    emojiU: 'U+1F1F8 U+1F1ED',
  },
  {
    id: 185,
    name: 'Saint Kitts And Nevis',
    iso3: 'KNA',
    iso2: 'KN',
    phone_code: '+1-869',
    capital: 'Basseterre',
    currency: 'XCD',
    currency_symbol: '$',
    tld: '.kn',
    native: 'Saint Kitts and Nevis',
    region: 'Americas',
    subregion: 'Caribbean',
    timezones: [
      {
        zoneName: 'America/St_Kitts',
        gmtOffset: -14400,
        gmtOffsetName: 'UTC-04:00',
        abbreviation: 'AST',
        tzName: 'Atlantic Standard Time',
      },
    ],
    translations: {
      br: 'São Cristóvão e Neves',
      pt: 'São Cristóvão e Neves',
      nl: 'Saint Kitts en Nevis',
      hr: 'Sveti Kristof i Nevis',
      fa: 'سنت کیتس و نویس',
      de: 'St. Kitts und Nevis',
      es: 'San Cristóbal y Nieves',
      fr: 'Saint-Christophe-et-Niévès',
      ja: 'セントクリストファー・ネイビス',
      it: 'Saint Kitts e Nevis',
    },
    latitude: '17.33333333',
    longitude: '-62.75000000',
    emoji: '🇰🇳',
    emojiU: 'U+1F1F0 U+1F1F3',
  },
  {
    id: 186,
    name: 'Saint Lucia',
    iso3: 'LCA',
    iso2: 'LC',
    phone_code: '+1-758',
    capital: 'Castries',
    currency: 'XCD',
    currency_symbol: '$',
    tld: '.lc',
    native: 'Saint Lucia',
    region: 'Americas',
    subregion: 'Caribbean',
    timezones: [
      {
        zoneName: 'America/St_Lucia',
        gmtOffset: -14400,
        gmtOffsetName: 'UTC-04:00',
        abbreviation: 'AST',
        tzName: 'Atlantic Standard Time',
      },
    ],
    translations: {
      br: 'Santa Lúcia',
      pt: 'Santa Lúcia',
      nl: 'Saint Lucia',
      hr: 'Sveta Lucija',
      fa: 'سنت لوسیا',
      de: 'Saint Lucia',
      es: 'Santa Lucía',
      fr: 'Saint-Lucie',
      ja: 'セントルシア',
      it: 'Santa Lucia',
    },
    latitude: '13.88333333',
    longitude: '-60.96666666',
    emoji: '🇱🇨',
    emojiU: 'U+1F1F1 U+1F1E8',
  },
  {
    id: 187,
    name: 'Saint Pierre and Miquelon',
    iso3: 'SPM',
    iso2: 'PM',
    phone_code: '508',
    capital: 'Saint-Pierre',
    currency: 'EUR',
    currency_symbol: '€',
    tld: '.pm',
    native: 'Saint-Pierre-et-Miquelon',
    region: 'Americas',
    subregion: 'Northern America',
    timezones: [
      {
        zoneName: 'America/Miquelon',
        gmtOffset: -10800,
        gmtOffsetName: 'UTC-03:00',
        abbreviation: 'PMDT',
        tzName: 'Pierre & Miquelon Daylight Time',
      },
    ],
    translations: {
      br: 'Saint-Pierre e Miquelon',
      pt: 'São Pedro e Miquelon',
      nl: 'Saint Pierre en Miquelon',
      hr: 'Sveti Petar i Mikelon',
      fa: 'سن پیر و میکلن',
      de: 'Saint-Pierre und Miquelon',
      es: 'San Pedro y Miquelón',
      fr: 'Saint-Pierre-et-Miquelon',
      ja: 'サンピエール島・ミクロン島',
      it: 'Saint-Pierre e Miquelon',
    },
    latitude: '46.83333333',
    longitude: '-56.33333333',
    emoji: '🇵🇲',
    emojiU: 'U+1F1F5 U+1F1F2',
  },
  {
    id: 188,
    name: 'Saint Vincent And The Grenadines',
    iso3: 'VCT',
    iso2: 'VC',
    phone_code: '+1-784',
    capital: 'Kingstown',
    currency: 'XCD',
    currency_symbol: '$',
    tld: '.vc',
    native: 'Saint Vincent and the Grenadines',
    region: 'Americas',
    subregion: 'Caribbean',
    timezones: [
      {
        zoneName: 'America/St_Vincent',
        gmtOffset: -14400,
        gmtOffsetName: 'UTC-04:00',
        abbreviation: 'AST',
        tzName: 'Atlantic Standard Time',
      },
    ],
    translations: {
      br: 'São Vicente e Granadinas',
      pt: 'São Vicente e Granadinas',
      nl: 'Saint Vincent en de Grenadines',
      hr: 'Sveti Vincent i Grenadini',
      fa: 'سنت وینسنت و گرنادین‌ها',
      de: 'Saint Vincent und die Grenadinen',
      es: 'San Vicente y Granadinas',
      fr: 'Saint-Vincent-et-les-Grenadines',
      ja: 'セントビンセントおよびグレナディーン諸島',
      it: 'Saint Vincent e Grenadine',
    },
    latitude: '13.25000000',
    longitude: '-61.20000000',
    emoji: '🇻🇨',
    emojiU: 'U+1F1FB U+1F1E8',
  },
  {
    id: 189,
    name: 'Saint-Barthelemy',
    iso3: 'BLM',
    iso2: 'BL',
    phone_code: '590',
    capital: 'Gustavia',
    currency: 'EUR',
    currency_symbol: '€',
    tld: '.bl',
    native: 'Saint-Barthélemy',
    region: 'Americas',
    subregion: 'Caribbean',
    timezones: [
      {
        zoneName: 'America/St_Barthelemy',
        gmtOffset: -14400,
        gmtOffsetName: 'UTC-04:00',
        abbreviation: 'AST',
        tzName: 'Atlantic Standard Time',
      },
    ],
    translations: {
      br: 'São Bartolomeu',
      pt: 'São Bartolomeu',
      nl: 'Saint Barthélemy',
      hr: 'Saint Barthélemy',
      fa: 'سن-بارتلمی',
      de: 'Saint-Barthélemy',
      es: 'San Bartolomé',
      fr: 'Saint-Barthélemy',
      ja: 'サン・バルテルミー',
      it: 'Antille Francesi',
    },
    latitude: '18.50000000',
    longitude: '-63.41666666',
    emoji: '🇧🇱',
    emojiU: 'U+1F1E7 U+1F1F1',
  },
  {
    id: 190,
    name: 'Saint-Martin (French part)',
    iso3: 'MAF',
    iso2: 'MF',
    phone_code: '590',
    capital: 'Marigot',
    currency: 'EUR',
    currency_symbol: '€',
    tld: '.mf',
    native: 'Saint-Martin',
    region: 'Americas',
    subregion: 'Caribbean',
    timezones: [
      {
        zoneName: 'America/Marigot',
        gmtOffset: -14400,
        gmtOffsetName: 'UTC-04:00',
        abbreviation: 'AST',
        tzName: 'Atlantic Standard Time',
      },
    ],
    translations: {
      br: 'Saint Martin',
      pt: 'Ilha São Martinho',
      nl: 'Saint-Martin',
      hr: 'Sveti Martin',
      fa: 'سینت مارتن',
      de: 'Saint Martin',
      es: 'Saint Martin',
      fr: 'Saint-Martin',
      ja: 'サン・マルタン（フランス領）',
      it: 'Saint Martin',
    },
    latitude: '18.08333333',
    longitude: '-63.95000000',
    emoji: '🇲🇫',
    emojiU: 'U+1F1F2 U+1F1EB',
  },
  {
    id: 191,
    name: 'Samoa',
    iso3: 'WSM',
    iso2: 'WS',
    phone_code: '685',
    capital: 'Apia',
    currency: 'WST',
    currency_symbol: 'SAT',
    tld: '.ws',
    native: 'Samoa',
    region: 'Oceania',
    subregion: 'Polynesia',
    timezones: [
      {
        zoneName: 'Pacific/Apia',
        gmtOffset: 50400,
        gmtOffsetName: 'UTC+14:00',
        abbreviation: 'WST',
        tzName: 'West Samoa Time',
      },
    ],
    translations: {
      br: 'Samoa',
      pt: 'Samoa',
      nl: 'Samoa',
      hr: 'Samoa',
      fa: 'ساموآ',
      de: 'Samoa',
      es: 'Samoa',
      fr: 'Samoa',
      ja: 'サモア',
      it: 'Samoa',
    },
    latitude: '-13.58333333',
    longitude: '-172.33333333',
    emoji: '🇼🇸',
    emojiU: 'U+1F1FC U+1F1F8',
  },
  {
    id: 192,
    name: 'San Marino',
    iso3: 'SMR',
    iso2: 'SM',
    phone_code: '378',
    capital: 'San Marino',
    currency: 'EUR',
    currency_symbol: '€',
    tld: '.sm',
    native: 'San Marino',
    region: 'Europe',
    subregion: 'Southern Europe',
    timezones: [
      {
        zoneName: 'Europe/San_Marino',
        gmtOffset: 3600,
        gmtOffsetName: 'UTC+01:00',
        abbreviation: 'CET',
        tzName: 'Central European Time',
      },
    ],
    translations: {
      br: 'San Marino',
      pt: 'São Marinho',
      nl: 'San Marino',
      hr: 'San Marino',
      fa: 'سان مارینو',
      de: 'San Marino',
      es: 'San Marino',
      fr: 'Saint-Marin',
      ja: 'サンマリノ',
      it: 'San Marino',
    },
    latitude: '43.76666666',
    longitude: '12.41666666',
    emoji: '🇸🇲',
    emojiU: 'U+1F1F8 U+1F1F2',
  },
  {
    id: 193,
    name: 'Sao Tome and Principe',
    iso3: 'STP',
    iso2: 'ST',
    phone_code: '239',
    capital: 'Sao Tome',
    currency: 'STD',
    currency_symbol: 'Db',
    tld: '.st',
    native: 'São Tomé e Príncipe',
    region: 'Africa',
    subregion: 'Middle Africa',
    timezones: [
      {
        zoneName: 'Africa/Sao_Tome',
        gmtOffset: 0,
        gmtOffsetName: 'UTC±00',
        abbreviation: 'GMT',
        tzName: 'Greenwich Mean Time',
      },
    ],
    translations: {
      br: 'São Tomé e Príncipe',
      pt: 'São Tomé e Príncipe',
      nl: 'Sao Tomé en Principe',
      hr: 'Sveti Toma i Princip',
      fa: 'کواترو دو فرویرو',
      de: 'São Tomé und Príncipe',
      es: 'Santo Tomé y Príncipe',
      fr: 'Sao Tomé-et-Principe',
      ja: 'サントメ・プリンシペ',
      it: 'São Tomé e Príncipe',
    },
    latitude: '1.00000000',
    longitude: '7.00000000',
    emoji: '🇸🇹',
    emojiU: 'U+1F1F8 U+1F1F9',
  },
  {
    id: 194,
    name: 'Saudi Arabia',
    iso3: 'SAU',
    iso2: 'SA',
    phone_code: '966',
    capital: 'Riyadh',
    currency: 'SAR',
    currency_symbol: '﷼',
    tld: '.sa',
    native: 'العربية السعودية',
    region: 'Asia',
    subregion: 'Western Asia',
    timezones: [
      {
        zoneName: 'Asia/Riyadh',
        gmtOffset: 10800,
        gmtOffsetName: 'UTC+03:00',
        abbreviation: 'AST',
        tzName: 'Arabia Standard Time',
      },
    ],
    translations: {
      br: 'Arábia Saudita',
      pt: 'Arábia Saudita',
      nl: 'Saoedi-Arabië',
      hr: 'Saudijska Arabija',
      fa: 'عربستان سعودی',
      de: 'Saudi-Arabien',
      es: 'Arabia Saudí',
      fr: 'Arabie Saoudite',
      ja: 'サウジアラビア',
      it: 'Arabia Saudita',
    },
    latitude: '25.00000000',
    longitude: '45.00000000',
    emoji: '🇸🇦',
    emojiU: 'U+1F1F8 U+1F1E6',
  },
  {
    id: 195,
    name: 'Senegal',
    iso3: 'SEN',
    iso2: 'SN',
    phone_code: '221',
    capital: 'Dakar',
    currency: 'XOF',
    currency_symbol: 'CFA',
    tld: '.sn',
    native: 'Sénégal',
    region: 'Africa',
    subregion: 'Western Africa',
    timezones: [
      {
        zoneName: 'Africa/Dakar',
        gmtOffset: 0,
        gmtOffsetName: 'UTC±00',
        abbreviation: 'GMT',
        tzName: 'Greenwich Mean Time',
      },
    ],
    translations: {
      br: 'Senegal',
      pt: 'Senegal',
      nl: 'Senegal',
      hr: 'Senegal',
      fa: 'سنگال',
      de: 'Senegal',
      es: 'Senegal',
      fr: 'Sénégal',
      ja: 'セネガル',
      it: 'Senegal',
    },
    latitude: '14.00000000',
    longitude: '-14.00000000',
    emoji: '🇸🇳',
    emojiU: 'U+1F1F8 U+1F1F3',
  },
  {
    id: 196,
    name: 'Serbia',
    iso3: 'SRB',
    iso2: 'RS',
    phone_code: '381',
    capital: 'Belgrade',
    currency: 'RSD',
    currency_symbol: 'din',
    tld: '.rs',
    native: 'Србија',
    region: 'Europe',
    subregion: 'Southern Europe',
    timezones: [
      {
        zoneName: 'Europe/Belgrade',
        gmtOffset: 3600,
        gmtOffsetName: 'UTC+01:00',
        abbreviation: 'CET',
        tzName: 'Central European Time',
      },
    ],
    translations: {
      br: 'Sérvia',
      pt: 'Sérvia',
      nl: 'Servië',
      hr: 'Srbija',
      fa: 'صربستان',
      de: 'Serbien',
      es: 'Serbia',
      fr: 'Serbie',
      ja: 'セルビア',
      it: 'Serbia',
    },
    latitude: '44.00000000',
    longitude: '21.00000000',
    emoji: '🇷🇸',
    emojiU: 'U+1F1F7 U+1F1F8',
  },
  {
    id: 197,
    name: 'Seychelles',
    iso3: 'SYC',
    iso2: 'SC',
    phone_code: '248',
    capital: 'Victoria',
    currency: 'SCR',
    currency_symbol: 'SRe',
    tld: '.sc',
    native: 'Seychelles',
    region: 'Africa',
    subregion: 'Eastern Africa',
    timezones: [
      {
        zoneName: 'Indian/Mahe',
        gmtOffset: 14400,
        gmtOffsetName: 'UTC+04:00',
        abbreviation: 'SCT',
        tzName: 'Seychelles Time',
      },
    ],
    translations: {
      br: 'Seicheles',
      pt: 'Seicheles',
      nl: 'Seychellen',
      hr: 'Sejšeli',
      fa: 'سیشل',
      de: 'Seychellen',
      es: 'Seychelles',
      fr: 'Seychelles',
      ja: 'セーシェル',
      it: 'Seychelles',
    },
    latitude: '-4.58333333',
    longitude: '55.66666666',
    emoji: '🇸🇨',
    emojiU: 'U+1F1F8 U+1F1E8',
  },
  {
    id: 198,
    name: 'Sierra Leone',
    iso3: 'SLE',
    iso2: 'SL',
    phone_code: '232',
    capital: 'Freetown',
    currency: 'SLL',
    currency_symbol: 'Le',
    tld: '.sl',
    native: 'Sierra Leone',
    region: 'Africa',
    subregion: 'Western Africa',
    timezones: [
      {
        zoneName: 'Africa/Freetown',
        gmtOffset: 0,
        gmtOffsetName: 'UTC±00',
        abbreviation: 'GMT',
        tzName: 'Greenwich Mean Time',
      },
    ],
    translations: {
      br: 'Serra Leoa',
      pt: 'Serra Leoa',
      nl: 'Sierra Leone',
      hr: 'Sijera Leone',
      fa: 'سیرالئون',
      de: 'Sierra Leone',
      es: 'Sierra Leone',
      fr: 'Sierra Leone',
      ja: 'シエラレオネ',
      it: 'Sierra Leone',
    },
    latitude: '8.50000000',
    longitude: '-11.50000000',
    emoji: '🇸🇱',
    emojiU: 'U+1F1F8 U+1F1F1',
  },
  {
    id: 199,
    name: 'Singapore',
    iso3: 'SGP',
    iso2: 'SG',
    phone_code: '65',
    capital: 'Singapur',
    currency: 'SGD',
    currency_symbol: '$',
    tld: '.sg',
    native: 'Singapore',
    region: 'Asia',
    subregion: 'South-Eastern Asia',
    timezones: [
      {
        zoneName: 'Asia/Singapore',
        gmtOffset: 28800,
        gmtOffsetName: 'UTC+08:00',
        abbreviation: 'SGT',
        tzName: 'Singapore Time',
      },
    ],
    translations: {
      br: 'Singapura',
      pt: 'Singapura',
      nl: 'Singapore',
      hr: 'Singapur',
      fa: 'سنگاپور',
      de: 'Singapur',
      es: 'Singapur',
      fr: 'Singapour',
      ja: 'シンガポール',
      it: 'Singapore',
    },
    latitude: '1.36666666',
    longitude: '103.80000000',
    emoji: '🇸🇬',
    emojiU: 'U+1F1F8 U+1F1EC',
  },
  {
    id: 250,
    name: 'Sint Maarten (Dutch part)',
    iso3: 'SXM',
    iso2: 'SX',
    phone_code: '1721',
    capital: 'Philipsburg',
    currency: 'ANG',
    currency_symbol: 'ƒ',
    tld: '.sx',
    native: 'Sint Maarten',
    region: 'Americas',
    subregion: 'Caribbean',
    timezones: [
      {
        zoneName: 'America/Anguilla',
        gmtOffset: -14400,
        gmtOffsetName: 'UTC-04:00',
        abbreviation: 'AST',
        tzName: 'Atlantic Standard Time',
      },
    ],
    translations: {
      br: 'Sint Maarten',
      pt: 'São Martinho',
      nl: 'Sint Maarten',
      fa: 'سینت مارتن',
      de: 'Sint Maarten (niederl. Teil)',
      fr: 'Saint Martin (partie néerlandaise)',
      it: 'Saint Martin (parte olandese)',
    },
    latitude: '18.03333300',
    longitude: '-63.05000000',
    emoji: '🇸🇽',
    emojiU: 'U+1F1F8 U+1F1FD',
  },
  {
    id: 200,
    name: 'Slovakia',
    iso3: 'SVK',
    iso2: 'SK',
    phone_code: '421',
    capital: 'Bratislava',
    currency: 'EUR',
    currency_symbol: '€',
    tld: '.sk',
    native: 'Slovensko',
    region: 'Europe',
    subregion: 'Eastern Europe',
    timezones: [
      {
        zoneName: 'Europe/Bratislava',
        gmtOffset: 3600,
        gmtOffsetName: 'UTC+01:00',
        abbreviation: 'CET',
        tzName: 'Central European Time',
      },
    ],
    translations: {
      br: 'Eslováquia',
      pt: 'Eslováquia',
      nl: 'Slowakije',
      hr: 'Slovačka',
      fa: 'اسلواکی',
      de: 'Slowakei',
      es: 'República Eslovaca',
      fr: 'Slovaquie',
      ja: 'スロバキア',
      it: 'Slovacchia',
    },
    latitude: '48.66666666',
    longitude: '19.50000000',
    emoji: '🇸🇰',
    emojiU: 'U+1F1F8 U+1F1F0',
  },
  {
    id: 201,
    name: 'Slovenia',
    iso3: 'SVN',
    iso2: 'SI',
    phone_code: '386',
    capital: 'Ljubljana',
    currency: 'EUR',
    currency_symbol: '€',
    tld: '.si',
    native: 'Slovenija',
    region: 'Europe',
    subregion: 'Southern Europe',
    timezones: [
      {
        zoneName: 'Europe/Ljubljana',
        gmtOffset: 3600,
        gmtOffsetName: 'UTC+01:00',
        abbreviation: 'CET',
        tzName: 'Central European Time',
      },
    ],
    translations: {
      br: 'Eslovênia',
      pt: 'Eslovénia',
      nl: 'Slovenië',
      hr: 'Slovenija',
      fa: 'اسلوونی',
      de: 'Slowenien',
      es: 'Eslovenia',
      fr: 'Slovénie',
      ja: 'スロベニア',
      it: 'Slovenia',
    },
    latitude: '46.11666666',
    longitude: '14.81666666',
    emoji: '🇸🇮',
    emojiU: 'U+1F1F8 U+1F1EE',
  },
  {
    id: 202,
    name: 'Solomon Islands',
    iso3: 'SLB',
    iso2: 'SB',
    phone_code: '677',
    capital: 'Honiara',
    currency: 'SBD',
    currency_symbol: 'Si$',
    tld: '.sb',
    native: 'Solomon Islands',
    region: 'Oceania',
    subregion: 'Melanesia',
    timezones: [
      {
        zoneName: 'Pacific/Guadalcanal',
        gmtOffset: 39600,
        gmtOffsetName: 'UTC+11:00',
        abbreviation: 'SBT',
        tzName: 'Solomon Islands Time',
      },
    ],
    translations: {
      br: 'Ilhas Salomão',
      pt: 'Ilhas Salomão',
      nl: 'Salomonseilanden',
      hr: 'Solomonski Otoci',
      fa: 'جزایر سلیمان',
      de: 'Salomonen',
      es: 'Islas Salomón',
      fr: 'Îles Salomon',
      ja: 'ソロモン諸島',
      it: 'Isole Salomone',
    },
    latitude: '-8.00000000',
    longitude: '159.00000000',
    emoji: '🇸🇧',
    emojiU: 'U+1F1F8 U+1F1E7',
  },
  {
    id: 203,
    name: 'Somalia',
    iso3: 'SOM',
    iso2: 'SO',
    phone_code: '252',
    capital: 'Mogadishu',
    currency: 'SOS',
    currency_symbol: 'Sh.so.',
    tld: '.so',
    native: 'Soomaaliya',
    region: 'Africa',
    subregion: 'Eastern Africa',
    timezones: [
      {
        zoneName: 'Africa/Mogadishu',
        gmtOffset: 10800,
        gmtOffsetName: 'UTC+03:00',
        abbreviation: 'EAT',
        tzName: 'East Africa Time',
      },
    ],
    translations: {
      br: 'Somália',
      pt: 'Somália',
      nl: 'Somalië',
      hr: 'Somalija',
      fa: 'سومالی',
      de: 'Somalia',
      es: 'Somalia',
      fr: 'Somalie',
      ja: 'ソマリア',
      it: 'Somalia',
    },
    latitude: '10.00000000',
    longitude: '49.00000000',
    emoji: '🇸🇴',
    emojiU: 'U+1F1F8 U+1F1F4',
  },
  {
    id: 204,
    name: 'South Africa',
    iso3: 'ZAF',
    iso2: 'ZA',
    phone_code: '27',
    capital: 'Pretoria',
    currency: 'ZAR',
    currency_symbol: 'R',
    tld: '.za',
    native: 'South Africa',
    region: 'Africa',
    subregion: 'Southern Africa',
    timezones: [
      {
        zoneName: 'Africa/Johannesburg',
        gmtOffset: 7200,
        gmtOffsetName: 'UTC+02:00',
        abbreviation: 'SAST',
        tzName: 'South African Standard Time',
      },
    ],
    translations: {
      br: 'República Sul-Africana',
      pt: 'República Sul-Africana',
      nl: 'Zuid-Afrika',
      hr: 'Južnoafrička Republika',
      fa: 'آفریقای جنوبی',
      de: 'Republik Südafrika',
      es: 'República de Sudáfrica',
      fr: 'Afrique du Sud',
      ja: '南アフリカ',
      it: 'Sud Africa',
    },
    latitude: '-29.00000000',
    longitude: '24.00000000',
    emoji: '🇿🇦',
    emojiU: 'U+1F1FF U+1F1E6',
  },
  {
    id: 205,
    name: 'South Georgia',
    iso3: 'SGS',
    iso2: 'GS',
    phone_code: '',
    capital: 'Grytviken',
    currency: 'GBP',
    currency_symbol: '£',
    tld: '.gs',
    native: 'South Georgia',
    region: 'Americas',
    subregion: 'South America',
    timezones: [
      {
        zoneName: 'Atlantic/South_Georgia',
        gmtOffset: -7200,
        gmtOffsetName: 'UTC-02:00',
        abbreviation: 'GST',
        tzName: 'South Georgia and the South Sandwich Islands Time',
      },
    ],
    translations: {
      br: 'Ilhas Geórgias do Sul e Sandwich do Sul',
      pt: 'Ilhas Geórgia do Sul e Sanduíche do Sul',
      nl: 'Zuid-Georgia en Zuidelijke Sandwicheilanden',
      hr: 'Južna Georgija i otočje Južni Sandwich',
      fa: 'جزایر جورجیای جنوبی و ساندویچ جنوبی',
      de: 'Südgeorgien und die Südlichen Sandwichinseln',
      es: 'Islas Georgias del Sur y Sandwich del Sur',
      fr: 'Géorgie du Sud-et-les Îles Sandwich du Sud',
      ja: 'サウスジョージア・サウスサンドウィッチ諸島',
      it: 'Georgia del Sud e Isole Sandwich Meridionali',
    },
    latitude: '-54.50000000',
    longitude: '-37.00000000',
    emoji: '🇬🇸',
    emojiU: 'U+1F1EC U+1F1F8',
  },
  {
    id: 206,
    name: 'South Sudan',
    iso3: 'SSD',
    iso2: 'SS',
    phone_code: '211',
    capital: 'Juba',
    currency: 'SSP',
    currency_symbol: '£',
    tld: '.ss',
    native: 'South Sudan',
    region: 'Africa',
    subregion: 'Middle Africa',
    timezones: [
      {
        zoneName: 'Africa/Juba',
        gmtOffset: 10800,
        gmtOffsetName: 'UTC+03:00',
        abbreviation: 'EAT',
        tzName: 'East Africa Time',
      },
    ],
    translations: {
      br: 'Sudão do Sul',
      pt: 'Sudão do Sul',
      nl: 'Zuid-Soedan',
      hr: 'Južni Sudan',
      fa: 'سودان جنوبی',
      de: 'Südsudan',
      es: 'Sudán del Sur',
      fr: 'Soudan du Sud',
      ja: '南スーダン',
      it: 'Sudan del sud',
    },
    latitude: '7.00000000',
    longitude: '30.00000000',
    emoji: '🇸🇸',
    emojiU: 'U+1F1F8 U+1F1F8',
  },
  {
    id: 207,
    name: 'Spain',
    iso3: 'ESP',
    iso2: 'ES',
    phone_code: '34',
    capital: 'Madrid',
    currency: 'EUR',
    currency_symbol: '€',
    tld: '.es',
    native: 'España',
    region: 'Europe',
    subregion: 'Southern Europe',
    timezones: [
      {
        zoneName: 'Africa/Ceuta',
        gmtOffset: 3600,
        gmtOffsetName: 'UTC+01:00',
        abbreviation: 'CET',
        tzName: 'Central European Time',
      },
      {
        zoneName: 'Atlantic/Canary',
        gmtOffset: 0,
        gmtOffsetName: 'UTC±00',
        abbreviation: 'WET',
        tzName: 'Western European Time',
      },
      {
        zoneName: 'Europe/Madrid',
        gmtOffset: 3600,
        gmtOffsetName: 'UTC+01:00',
        abbreviation: 'CET',
        tzName: 'Central European Time',
      },
    ],
    translations: {
      br: 'Espanha',
      pt: 'Espanha',
      nl: 'Spanje',
      hr: 'Španjolska',
      fa: 'اسپانیا',
      de: 'Spanien',
      es: 'España',
      fr: 'Espagne',
      ja: 'スペイン',
      it: 'Spagna',
    },
    latitude: '40.00000000',
    longitude: '-4.00000000',
    emoji: '🇪🇸',
    emojiU: 'U+1F1EA U+1F1F8',
  },
  {
    id: 208,
    name: 'Sri Lanka',
    iso3: 'LKA',
    iso2: 'LK',
    phone_code: '94',
    capital: 'Colombo',
    currency: 'LKR',
    currency_symbol: 'Rs',
    tld: '.lk',
    native: 'śrī laṃkāva',
    region: 'Asia',
    subregion: 'Southern Asia',
    timezones: [
      {
        zoneName: 'Asia/Colombo',
        gmtOffset: 19800,
        gmtOffsetName: 'UTC+05:30',
        abbreviation: 'IST',
        tzName: 'Indian Standard Time',
      },
    ],
    translations: {
      br: 'Sri Lanka',
      pt: 'Sri Lanka',
      nl: 'Sri Lanka',
      hr: 'Šri Lanka',
      fa: 'سری‌لانکا',
      de: 'Sri Lanka',
      es: 'Sri Lanka',
      fr: 'Sri Lanka',
      ja: 'スリランカ',
      it: 'Sri Lanka',
    },
    latitude: '7.00000000',
    longitude: '81.00000000',
    emoji: '🇱🇰',
    emojiU: 'U+1F1F1 U+1F1F0',
  },
  {
    id: 209,
    name: 'Sudan',
    iso3: 'SDN',
    iso2: 'SD',
    phone_code: '249',
    capital: 'Khartoum',
    currency: 'SDG',
    currency_symbol: '.س.ج',
    tld: '.sd',
    native: 'السودان',
    region: 'Africa',
    subregion: 'Northern Africa',
    timezones: [
      {
        zoneName: 'Africa/Khartoum',
        gmtOffset: 7200,
        gmtOffsetName: 'UTC+02:00',
        abbreviation: 'EAT',
        tzName: 'Eastern African Time',
      },
    ],
    translations: {
      br: 'Sudão',
      pt: 'Sudão',
      nl: 'Soedan',
      hr: 'Sudan',
      fa: 'سودان',
      de: 'Sudan',
      es: 'Sudán',
      fr: 'Soudan',
      ja: 'スーダン',
      it: 'Sudan',
    },
    latitude: '15.00000000',
    longitude: '30.00000000',
    emoji: '🇸🇩',
    emojiU: 'U+1F1F8 U+1F1E9',
  },
  {
    id: 210,
    name: 'Suriname',
    iso3: 'SUR',
    iso2: 'SR',
    phone_code: '597',
    capital: 'Paramaribo',
    currency: 'SRD',
    currency_symbol: '$',
    tld: '.sr',
    native: 'Suriname',
    region: 'Americas',
    subregion: 'South America',
    timezones: [
      {
        zoneName: 'America/Paramaribo',
        gmtOffset: -10800,
        gmtOffsetName: 'UTC-03:00',
        abbreviation: 'SRT',
        tzName: 'Suriname Time',
      },
    ],
    translations: {
      br: 'Suriname',
      pt: 'Suriname',
      nl: 'Suriname',
      hr: 'Surinam',
      fa: 'سورینام',
      de: 'Suriname',
      es: 'Surinam',
      fr: 'Surinam',
      ja: 'スリナム',
      it: 'Suriname',
    },
    latitude: '4.00000000',
    longitude: '-56.00000000',
    emoji: '🇸🇷',
    emojiU: 'U+1F1F8 U+1F1F7',
  },
  {
    id: 211,
    name: 'Svalbard And Jan Mayen Islands',
    iso3: 'SJM',
    iso2: 'SJ',
    phone_code: '47',
    capital: 'Longyearbyen',
    currency: 'NOK',
    currency_symbol: 'kr',
    tld: '.sj',
    native: 'Svalbard og Jan Mayen',
    region: 'Europe',
    subregion: 'Northern Europe',
    timezones: [
      {
        zoneName: 'Arctic/Longyearbyen',
        gmtOffset: 3600,
        gmtOffsetName: 'UTC+01:00',
        abbreviation: 'CET',
        tzName: 'Central European Time',
      },
    ],
    translations: {
      br: 'Svalbard',
      pt: 'Svalbard',
      nl: 'Svalbard en Jan Mayen',
      hr: 'Svalbard i Jan Mayen',
      fa: 'سوالبارد و یان ماین',
      de: 'Svalbard und Jan Mayen',
      es: 'Islas Svalbard y Jan Mayen',
      fr: 'Svalbard et Jan Mayen',
      ja: 'スヴァールバル諸島およびヤンマイエン島',
      it: 'Svalbard e Jan Mayen',
    },
    latitude: '78.00000000',
    longitude: '20.00000000',
    emoji: '🇸🇯',
    emojiU: 'U+1F1F8 U+1F1EF',
  },
  {
    id: 212,
    name: 'Swaziland',
    iso3: 'SWZ',
    iso2: 'SZ',
    phone_code: '268',
    capital: 'Mbabane',
    currency: 'SZL',
    currency_symbol: 'E',
    tld: '.sz',
    native: 'Swaziland',
    region: 'Africa',
    subregion: 'Southern Africa',
    timezones: [
      {
        zoneName: 'Africa/Mbabane',
        gmtOffset: 7200,
        gmtOffsetName: 'UTC+02:00',
        abbreviation: 'SAST',
        tzName: 'South African Standard Time',
      },
    ],
    translations: {
      br: 'Suazilândia',
      pt: 'Suazilândia',
      nl: 'Swaziland',
      hr: 'Svazi',
      fa: 'سوازیلند',
      de: 'Swasiland',
      es: 'Suazilandia',
      fr: 'Swaziland',
      ja: 'スワジランド',
      it: 'Swaziland',
    },
    latitude: '-26.50000000',
    longitude: '31.50000000',
    emoji: '🇸🇿',
    emojiU: 'U+1F1F8 U+1F1FF',
  },
  {
    id: 213,
    name: 'Sweden',
    iso3: 'SWE',
    iso2: 'SE',
    phone_code: '46',
    capital: 'Stockholm',
    currency: 'SEK',
    currency_symbol: 'kr',
    tld: '.se',
    native: 'Sverige',
    region: 'Europe',
    subregion: 'Northern Europe',
    timezones: [
      {
        zoneName: 'Europe/Stockholm',
        gmtOffset: 3600,
        gmtOffsetName: 'UTC+01:00',
        abbreviation: 'CET',
        tzName: 'Central European Time',
      },
    ],
    translations: {
      br: 'Suécia',
      pt: 'Suécia',
      nl: 'Zweden',
      hr: 'Švedska',
      fa: 'سوئد',
      de: 'Schweden',
      es: 'Suecia',
      fr: 'Suède',
      ja: 'スウェーデン',
      it: 'Svezia',
    },
    latitude: '62.00000000',
    longitude: '15.00000000',
    emoji: '🇸🇪',
    emojiU: 'U+1F1F8 U+1F1EA',
  },
  {
    id: 214,
    name: 'Switzerland',
    iso3: 'CHE',
    iso2: 'CH',
    phone_code: '41',
    capital: 'Bern',
    currency: 'CHF',
    currency_symbol: 'CHf',
    tld: '.ch',
    native: 'Schweiz',
    region: 'Europe',
    subregion: 'Western Europe',
    timezones: [
      {
        zoneName: 'Europe/Zurich',
        gmtOffset: 3600,
        gmtOffsetName: 'UTC+01:00',
        abbreviation: 'CET',
        tzName: 'Central European Time',
      },
    ],
    translations: {
      br: 'Suíça',
      pt: 'Suíça',
      nl: 'Zwitserland',
      hr: 'Švicarska',
      fa: 'سوئیس',
      de: 'Schweiz',
      es: 'Suiza',
      fr: 'Suisse',
      ja: 'スイス',
      it: 'Svizzera',
    },
    latitude: '47.00000000',
    longitude: '8.00000000',
    emoji: '🇨🇭',
    emojiU: 'U+1F1E8 U+1F1ED',
  },
  {
    id: 215,
    name: 'Syria',
    iso3: 'SYR',
    iso2: 'SY',
    phone_code: '963',
    capital: 'Damascus',
    currency: 'SYP',
    currency_symbol: 'LS',
    tld: '.sy',
    native: 'سوريا',
    region: 'Asia',
    subregion: 'Western Asia',
    timezones: [
      {
        zoneName: 'Asia/Damascus',
        gmtOffset: 7200,
        gmtOffsetName: 'UTC+02:00',
        abbreviation: 'EET',
        tzName: 'Eastern European Time',
      },
    ],
    translations: {
      br: 'Síria',
      pt: 'Síria',
      nl: 'Syrië',
      hr: 'Sirija',
      fa: 'سوریه',
      de: 'Syrien',
      es: 'Siria',
      fr: 'Syrie',
      ja: 'シリア・アラブ共和国',
      it: 'Siria',
    },
    latitude: '35.00000000',
    longitude: '38.00000000',
    emoji: '🇸🇾',
    emojiU: 'U+1F1F8 U+1F1FE',
  },
  {
    id: 216,
    name: 'Taiwan',
    iso3: 'TWN',
    iso2: 'TW',
    phone_code: '886',
    capital: 'Taipei',
    currency: 'TWD',
    currency_symbol: '$',
    tld: '.tw',
    native: '臺灣',
    region: 'Asia',
    subregion: 'Eastern Asia',
    timezones: [
      {
        zoneName: 'Asia/Taipei',
        gmtOffset: 28800,
        gmtOffsetName: 'UTC+08:00',
        abbreviation: 'CST',
        tzName: 'China Standard Time',
      },
    ],
    translations: {
      br: 'Taiwan',
      pt: 'Taiwan',
      nl: 'Taiwan',
      hr: 'Tajvan',
      fa: 'تایوان',
      de: 'Taiwan',
      es: 'Taiwán',
      fr: 'Taïwan',
      ja: '台湾（中華民国）',
      it: 'Taiwan',
    },
    latitude: '23.50000000',
    longitude: '121.00000000',
    emoji: '🇹🇼',
    emojiU: 'U+1F1F9 U+1F1FC',
  },
  {
    id: 217,
    name: 'Tajikistan',
    iso3: 'TJK',
    iso2: 'TJ',
    phone_code: '992',
    capital: 'Dushanbe',
    currency: 'TJS',
    currency_symbol: 'SM',
    tld: '.tj',
    native: 'Тоҷикистон',
    region: 'Asia',
    subregion: 'Central Asia',
    timezones: [
      {
        zoneName: 'Asia/Dushanbe',
        gmtOffset: 18000,
        gmtOffsetName: 'UTC+05:00',
        abbreviation: 'TJT',
        tzName: 'Tajikistan Time',
      },
    ],
    translations: {
      br: 'Tajiquistão',
      pt: 'Tajiquistão',
      nl: 'Tadzjikistan',
      hr: 'Tađikistan',
      fa: 'تاجیکستان',
      de: 'Tadschikistan',
      es: 'Tayikistán',
      fr: 'Tadjikistan',
      ja: 'タジキスタン',
      it: 'Tagikistan',
    },
    latitude: '39.00000000',
    longitude: '71.00000000',
    emoji: '🇹🇯',
    emojiU: 'U+1F1F9 U+1F1EF',
  },
  {
    id: 218,
    name: 'Tanzania',
    iso3: 'TZA',
    iso2: 'TZ',
    phone_code: '255',
    capital: 'Dodoma',
    currency: 'TZS',
    currency_symbol: 'TSh',
    tld: '.tz',
    native: 'Tanzania',
    region: 'Africa',
    subregion: 'Eastern Africa',
    timezones: [
      {
        zoneName: 'Africa/Dar_es_Salaam',
        gmtOffset: 10800,
        gmtOffsetName: 'UTC+03:00',
        abbreviation: 'EAT',
        tzName: 'East Africa Time',
      },
    ],
    translations: {
      br: 'Tanzânia',
      pt: 'Tanzânia',
      nl: 'Tanzania',
      hr: 'Tanzanija',
      fa: 'تانزانیا',
      de: 'Tansania',
      es: 'Tanzania',
      fr: 'Tanzanie',
      ja: 'タンザニア',
      it: 'Tanzania',
    },
    latitude: '-6.00000000',
    longitude: '35.00000000',
    emoji: '🇹🇿',
    emojiU: 'U+1F1F9 U+1F1FF',
  },
  {
    id: 219,
    name: 'Thailand',
    iso3: 'THA',
    iso2: 'TH',
    phone_code: '66',
    capital: 'Bangkok',
    currency: 'THB',
    currency_symbol: '฿',
    tld: '.th',
    native: 'ประเทศไทย',
    region: 'Asia',
    subregion: 'South-Eastern Asia',
    timezones: [
      {
        zoneName: 'Asia/Bangkok',
        gmtOffset: 25200,
        gmtOffsetName: 'UTC+07:00',
        abbreviation: 'ICT',
        tzName: 'Indochina Time',
      },
    ],
    translations: {
      br: 'Tailândia',
      pt: 'Tailândia',
      nl: 'Thailand',
      hr: 'Tajland',
      fa: 'تایلند',
      de: 'Thailand',
      es: 'Tailandia',
      fr: 'Thaïlande',
      ja: 'タイ',
      it: 'Tailandia',
    },
    latitude: '15.00000000',
    longitude: '100.00000000',
    emoji: '🇹🇭',
    emojiU: 'U+1F1F9 U+1F1ED',
  },
  {
    id: 220,
    name: 'Togo',
    iso3: 'TGO',
    iso2: 'TG',
    phone_code: '228',
    capital: 'Lome',
    currency: 'XOF',
    currency_symbol: 'CFA',
    tld: '.tg',
    native: 'Togo',
    region: 'Africa',
    subregion: 'Western Africa',
    timezones: [
      {
        zoneName: 'Africa/Lome',
        gmtOffset: 0,
        gmtOffsetName: 'UTC±00',
        abbreviation: 'GMT',
        tzName: 'Greenwich Mean Time',
      },
    ],
    translations: {
      br: 'Togo',
      pt: 'Togo',
      nl: 'Togo',
      hr: 'Togo',
      fa: 'توگو',
      de: 'Togo',
      es: 'Togo',
      fr: 'Togo',
      ja: 'トーゴ',
      it: 'Togo',
    },
    latitude: '8.00000000',
    longitude: '1.16666666',
    emoji: '🇹🇬',
    emojiU: 'U+1F1F9 U+1F1EC',
  },
  {
    id: 221,
    name: 'Tokelau',
    iso3: 'TKL',
    iso2: 'TK',
    phone_code: '690',
    capital: '',
    currency: 'NZD',
    currency_symbol: '$',
    tld: '.tk',
    native: 'Tokelau',
    region: 'Oceania',
    subregion: 'Polynesia',
    timezones: [
      {
        zoneName: 'Pacific/Fakaofo',
        gmtOffset: 46800,
        gmtOffsetName: 'UTC+13:00',
        abbreviation: 'TKT',
        tzName: 'Tokelau Time',
      },
    ],
    translations: {
      br: 'Tokelau',
      pt: 'Toquelau',
      nl: 'Tokelau',
      hr: 'Tokelau',
      fa: 'توکلائو',
      de: 'Tokelau',
      es: 'Islas Tokelau',
      fr: 'Tokelau',
      ja: 'トケラウ',
      it: 'Isole Tokelau',
    },
    latitude: '-9.00000000',
    longitude: '-172.00000000',
    emoji: '🇹🇰',
    emojiU: 'U+1F1F9 U+1F1F0',
  },
  {
    id: 222,
    name: 'Tonga',
    iso3: 'TON',
    iso2: 'TO',
    phone_code: '676',
    capital: "Nuku'alofa",
    currency: 'TOP',
    currency_symbol: '$',
    tld: '.to',
    native: 'Tonga',
    region: 'Oceania',
    subregion: 'Polynesia',
    timezones: [
      {
        zoneName: 'Pacific/Tongatapu',
        gmtOffset: 46800,
        gmtOffsetName: 'UTC+13:00',
        abbreviation: 'TOT',
        tzName: 'Tonga Time',
      },
    ],
    translations: {
      br: 'Tonga',
      pt: 'Tonga',
      nl: 'Tonga',
      hr: 'Tonga',
      fa: 'تونگا',
      de: 'Tonga',
      es: 'Tonga',
      fr: 'Tonga',
      ja: 'トンガ',
      it: 'Tonga',
    },
    latitude: '-20.00000000',
    longitude: '-175.00000000',
    emoji: '🇹🇴',
    emojiU: 'U+1F1F9 U+1F1F4',
  },
  {
    id: 223,
    name: 'Trinidad And Tobago',
    iso3: 'TTO',
    iso2: 'TT',
    phone_code: '+1-868',
    capital: 'Port of Spain',
    currency: 'TTD',
    currency_symbol: '$',
    tld: '.tt',
    native: 'Trinidad and Tobago',
    region: 'Americas',
    subregion: 'Caribbean',
    timezones: [
      {
        zoneName: 'America/Port_of_Spain',
        gmtOffset: -14400,
        gmtOffsetName: 'UTC-04:00',
        abbreviation: 'AST',
        tzName: 'Atlantic Standard Time',
      },
    ],
    translations: {
      br: 'Trinidad e Tobago',
      pt: 'Trindade e Tobago',
      nl: 'Trinidad en Tobago',
      hr: 'Trinidad i Tobago',
      fa: 'ترینیداد و توباگو',
      de: 'Trinidad und Tobago',
      es: 'Trinidad y Tobago',
      fr: 'Trinité et Tobago',
      ja: 'トリニダード・トバゴ',
      it: 'Trinidad e Tobago',
    },
    latitude: '11.00000000',
    longitude: '-61.00000000',
    emoji: '🇹🇹',
    emojiU: 'U+1F1F9 U+1F1F9',
  },
  {
    id: 224,
    name: 'Tunisia',
    iso3: 'TUN',
    iso2: 'TN',
    phone_code: '216',
    capital: 'Tunis',
    currency: 'TND',
    currency_symbol: 'ت.د',
    tld: '.tn',
    native: 'تونس',
    region: 'Africa',
    subregion: 'Northern Africa',
    timezones: [
      {
        zoneName: 'Africa/Tunis',
        gmtOffset: 3600,
        gmtOffsetName: 'UTC+01:00',
        abbreviation: 'CET',
        tzName: 'Central European Time',
      },
    ],
    translations: {
      br: 'Tunísia',
      pt: 'Tunísia',
      nl: 'Tunesië',
      hr: 'Tunis',
      fa: 'تونس',
      de: 'Tunesien',
      es: 'Túnez',
      fr: 'Tunisie',
      ja: 'チュニジア',
      it: 'Tunisia',
    },
    latitude: '34.00000000',
    longitude: '9.00000000',
    emoji: '🇹🇳',
    emojiU: 'U+1F1F9 U+1F1F3',
  },
  {
    id: 225,
    name: 'Turkey',
    iso3: 'TUR',
    iso2: 'TR',
    phone_code: '90',
    capital: 'Ankara',
    currency: 'TRY',
    currency_symbol: '₺',
    tld: '.tr',
    native: 'Türkiye',
    region: 'Asia',
    subregion: 'Western Asia',
    timezones: [
      {
        zoneName: 'Europe/Istanbul',
        gmtOffset: 10800,
        gmtOffsetName: 'UTC+03:00',
        abbreviation: 'EET',
        tzName: 'Eastern European Time',
      },
    ],
    translations: {
      br: 'Turquia',
      pt: 'Turquia',
      nl: 'Turkije',
      hr: 'Turska',
      fa: 'ترکیه',
      de: 'Türkei',
      es: 'Turquía',
      fr: 'Turquie',
      ja: 'トルコ',
      it: 'Turchia',
    },
    latitude: '39.00000000',
    longitude: '35.00000000',
    emoji: '🇹🇷',
    emojiU: 'U+1F1F9 U+1F1F7',
  },
  {
    id: 226,
    name: 'Turkmenistan',
    iso3: 'TKM',
    iso2: 'TM',
    phone_code: '993',
    capital: 'Ashgabat',
    currency: 'TMT',
    currency_symbol: 'T',
    tld: '.tm',
    native: 'Türkmenistan',
    region: 'Asia',
    subregion: 'Central Asia',
    timezones: [
      {
        zoneName: 'Asia/Ashgabat',
        gmtOffset: 18000,
        gmtOffsetName: 'UTC+05:00',
        abbreviation: 'TMT',
        tzName: 'Turkmenistan Time',
      },
    ],
    translations: {
      br: 'Turcomenistão',
      pt: 'Turquemenistão',
      nl: 'Turkmenistan',
      hr: 'Turkmenistan',
      fa: 'ترکمنستان',
      de: 'Turkmenistan',
      es: 'Turkmenistán',
      fr: 'Turkménistan',
      ja: 'トルクメニスタン',
      it: 'Turkmenistan',
    },
    latitude: '40.00000000',
    longitude: '60.00000000',
    emoji: '🇹🇲',
    emojiU: 'U+1F1F9 U+1F1F2',
  },
  {
    id: 227,
    name: 'Turks And Caicos Islands',
    iso3: 'TCA',
    iso2: 'TC',
    phone_code: '+1-649',
    capital: 'Cockburn Town',
    currency: 'USD',
    currency_symbol: '$',
    tld: '.tc',
    native: 'Turks and Caicos Islands',
    region: 'Americas',
    subregion: 'Caribbean',
    timezones: [
      {
        zoneName: 'America/Grand_Turk',
        gmtOffset: -18000,
        gmtOffsetName: 'UTC-05:00',
        abbreviation: 'EST',
        tzName: 'Eastern Standard Time (North America',
      },
    ],
    translations: {
      br: 'Ilhas Turcas e Caicos',
      pt: 'Ilhas Turcas e Caicos',
      nl: 'Turks- en Caicoseilanden',
      hr: 'Otoci Turks i Caicos',
      fa: 'جزایر تورکس و کایکوس',
      de: 'Turks- und Caicosinseln',
      es: 'Islas Turks y Caicos',
      fr: 'Îles Turques-et-Caïques',
      ja: 'タークス・カイコス諸島',
      it: 'Isole Turks e Caicos',
    },
    latitude: '21.75000000',
    longitude: '-71.58333333',
    emoji: '🇹🇨',
    emojiU: 'U+1F1F9 U+1F1E8',
  },
  {
    id: 228,
    name: 'Tuvalu',
    iso3: 'TUV',
    iso2: 'TV',
    phone_code: '688',
    capital: 'Funafuti',
    currency: 'AUD',
    currency_symbol: '$',
    tld: '.tv',
    native: 'Tuvalu',
    region: 'Oceania',
    subregion: 'Polynesia',
    timezones: [
      {
        zoneName: 'Pacific/Funafuti',
        gmtOffset: 43200,
        gmtOffsetName: 'UTC+12:00',
        abbreviation: 'TVT',
        tzName: 'Tuvalu Time',
      },
    ],
    translations: {
      br: 'Tuvalu',
      pt: 'Tuvalu',
      nl: 'Tuvalu',
      hr: 'Tuvalu',
      fa: 'تووالو',
      de: 'Tuvalu',
      es: 'Tuvalu',
      fr: 'Tuvalu',
      ja: 'ツバル',
      it: 'Tuvalu',
    },
    latitude: '-8.00000000',
    longitude: '178.00000000',
    emoji: '🇹🇻',
    emojiU: 'U+1F1F9 U+1F1FB',
  },
  {
    id: 229,
    name: 'Uganda',
    iso3: 'UGA',
    iso2: 'UG',
    phone_code: '256',
    capital: 'Kampala',
    currency: 'UGX',
    currency_symbol: 'USh',
    tld: '.ug',
    native: 'Uganda',
    region: 'Africa',
    subregion: 'Eastern Africa',
    timezones: [
      {
        zoneName: 'Africa/Kampala',
        gmtOffset: 10800,
        gmtOffsetName: 'UTC+03:00',
        abbreviation: 'EAT',
        tzName: 'East Africa Time',
      },
    ],
    translations: {
      br: 'Uganda',
      pt: 'Uganda',
      nl: 'Oeganda',
      hr: 'Uganda',
      fa: 'اوگاندا',
      de: 'Uganda',
      es: 'Uganda',
      fr: 'Uganda',
      ja: 'ウガンダ',
      it: 'Uganda',
    },
    latitude: '1.00000000',
    longitude: '32.00000000',
    emoji: '🇺🇬',
    emojiU: 'U+1F1FA U+1F1EC',
  },
  {
    id: 230,
    name: 'Ukraine',
    iso3: 'UKR',
    iso2: 'UA',
    phone_code: '380',
    capital: 'Kiev',
    currency: 'UAH',
    currency_symbol: '₴',
    tld: '.ua',
    native: 'Україна',
    region: 'Europe',
    subregion: 'Eastern Europe',
    timezones: [
      {
        zoneName: 'Europe/Kiev',
        gmtOffset: 7200,
        gmtOffsetName: 'UTC+02:00',
        abbreviation: 'EET',
        tzName: 'Eastern European Time',
      },
      {
        zoneName: 'Europe/Simferopol',
        gmtOffset: 10800,
        gmtOffsetName: 'UTC+03:00',
        abbreviation: 'MSK',
        tzName: 'Moscow Time',
      },
      {
        zoneName: 'Europe/Uzhgorod',
        gmtOffset: 7200,
        gmtOffsetName: 'UTC+02:00',
        abbreviation: 'EET',
        tzName: 'Eastern European Time',
      },
      {
        zoneName: 'Europe/Zaporozhye',
        gmtOffset: 7200,
        gmtOffsetName: 'UTC+02:00',
        abbreviation: 'EET',
        tzName: 'Eastern European Time',
      },
    ],
    translations: {
      br: 'Ucrânia',
      pt: 'Ucrânia',
      nl: 'Oekraïne',
      hr: 'Ukrajina',
      fa: 'وکراین',
      de: 'Ukraine',
      es: 'Ucrania',
      fr: 'Ukraine',
      ja: 'ウクライナ',
      it: 'Ucraina',
    },
    latitude: '49.00000000',
    longitude: '32.00000000',
    emoji: '🇺🇦',
    emojiU: 'U+1F1FA U+1F1E6',
  },
  {
    id: 231,
    name: 'United Arab Emirates',
    iso3: 'ARE',
    iso2: 'AE',
    phone_code: '971',
    capital: 'Abu Dhabi',
    currency: 'AED',
    currency_symbol: 'إ.د',
    tld: '.ae',
    native: 'دولة الإمارات العربية المتحدة',
    region: 'Asia',
    subregion: 'Western Asia',
    timezones: [
      {
        zoneName: 'Asia/Dubai',
        gmtOffset: 14400,
        gmtOffsetName: 'UTC+04:00',
        abbreviation: 'GST',
        tzName: 'Gulf Standard Time',
      },
    ],
    translations: {
      br: 'Emirados árabes Unidos',
      pt: 'Emirados árabes Unidos',
      nl: 'Verenigde Arabische Emiraten',
      hr: 'Ujedinjeni Arapski Emirati',
      fa: 'امارات متحده عربی',
      de: 'Vereinigte Arabische Emirate',
      es: 'Emiratos Árabes Unidos',
      fr: 'Émirats arabes unis',
      ja: 'アラブ首長国連邦',
      it: 'Emirati Arabi Uniti',
    },
    latitude: '24.00000000',
    longitude: '54.00000000',
    emoji: '🇦🇪',
    emojiU: 'U+1F1E6 U+1F1EA',
  },
  {
    id: 232,
    name: 'United Kingdom',
    iso3: 'GBR',
    iso2: 'GB',
    phone_code: '44',
    capital: 'London',
    currency: 'GBP',
    currency_symbol: '£',
    tld: '.uk',
    native: 'United Kingdom',
    region: 'Europe',
    subregion: 'Northern Europe',
    timezones: [
      {
        zoneName: 'Europe/London',
        gmtOffset: 0,
        gmtOffsetName: 'UTC±00',
        abbreviation: 'GMT',
        tzName: 'Greenwich Mean Time',
      },
    ],
    translations: {
      br: 'Reino Unido',
      pt: 'Reino Unido',
      nl: 'Verenigd Koninkrijk',
      hr: 'Ujedinjeno Kraljevstvo',
      fa: 'بریتانیای کبیر و ایرلند شمالی',
      de: 'Vereinigtes Königreich',
      es: 'Reino Unido',
      fr: 'Royaume-Uni',
      ja: 'イギリス',
      it: 'Regno Unito',
    },
    latitude: '54.00000000',
    longitude: '-2.00000000',
    emoji: '🇬🇧',
    emojiU: 'U+1F1EC U+1F1E7',
  },
  {
    id: 233,
    name: 'United States',
    iso3: 'USA',
    iso2: 'US',
    phone_code: '1',
    capital: 'Washington',
    currency: 'USD',
    currency_symbol: '$',
    tld: '.us',
    native: 'United States',
    region: 'Americas',
    subregion: 'Northern America',
    timezones: [
      {
        zoneName: 'America/Adak',
        gmtOffset: -36000,
        gmtOffsetName: 'UTC-10:00',
        abbreviation: 'HST',
        tzName: 'Hawaii–Aleutian Standard Time',
      },
      {
        zoneName: 'America/Anchorage',
        gmtOffset: -32400,
        gmtOffsetName: 'UTC-09:00',
        abbreviation: 'AKST',
        tzName: 'Alaska Standard Time',
      },
      {
        zoneName: 'America/Boise',
        gmtOffset: -25200,
        gmtOffsetName: 'UTC-07:00',
        abbreviation: 'MST',
        tzName: 'Mountain Standard Time (North America',
      },
      {
        zoneName: 'America/Chicago',
        gmtOffset: -21600,
        gmtOffsetName: 'UTC-06:00',
        abbreviation: 'CST',
        tzName: 'Central Standard Time (North America',
      },
      {
        zoneName: 'America/Denver',
        gmtOffset: -25200,
        gmtOffsetName: 'UTC-07:00',
        abbreviation: 'MST',
        tzName: 'Mountain Standard Time (North America',
      },
      {
        zoneName: 'America/Detroit',
        gmtOffset: -18000,
        gmtOffsetName: 'UTC-05:00',
        abbreviation: 'EST',
        tzName: 'Eastern Standard Time (North America',
      },
      {
        zoneName: 'America/Indiana/Indianapolis',
        gmtOffset: -18000,
        gmtOffsetName: 'UTC-05:00',
        abbreviation: 'EST',
        tzName: 'Eastern Standard Time (North America',
      },
      {
        zoneName: 'America/Indiana/Knox',
        gmtOffset: -21600,
        gmtOffsetName: 'UTC-06:00',
        abbreviation: 'CST',
        tzName: 'Central Standard Time (North America',
      },
      {
        zoneName: 'America/Indiana/Marengo',
        gmtOffset: -18000,
        gmtOffsetName: 'UTC-05:00',
        abbreviation: 'EST',
        tzName: 'Eastern Standard Time (North America',
      },
      {
        zoneName: 'America/Indiana/Petersburg',
        gmtOffset: -18000,
        gmtOffsetName: 'UTC-05:00',
        abbreviation: 'EST',
        tzName: 'Eastern Standard Time (North America',
      },
      {
        zoneName: 'America/Indiana/Tell_City',
        gmtOffset: -21600,
        gmtOffsetName: 'UTC-06:00',
        abbreviation: 'CST',
        tzName: 'Central Standard Time (North America',
      },
      {
        zoneName: 'America/Indiana/Vevay',
        gmtOffset: -18000,
        gmtOffsetName: 'UTC-05:00',
        abbreviation: 'EST',
        tzName: 'Eastern Standard Time (North America',
      },
      {
        zoneName: 'America/Indiana/Vincennes',
        gmtOffset: -18000,
        gmtOffsetName: 'UTC-05:00',
        abbreviation: 'EST',
        tzName: 'Eastern Standard Time (North America',
      },
      {
        zoneName: 'America/Indiana/Winamac',
        gmtOffset: -18000,
        gmtOffsetName: 'UTC-05:00',
        abbreviation: 'EST',
        tzName: 'Eastern Standard Time (North America',
      },
      {
        zoneName: 'America/Juneau',
        gmtOffset: -32400,
        gmtOffsetName: 'UTC-09:00',
        abbreviation: 'AKST',
        tzName: 'Alaska Standard Time',
      },
      {
        zoneName: 'America/Kentucky/Louisville',
        gmtOffset: -18000,
        gmtOffsetName: 'UTC-05:00',
        abbreviation: 'EST',
        tzName: 'Eastern Standard Time (North America',
      },
      {
        zoneName: 'America/Kentucky/Monticello',
        gmtOffset: -18000,
        gmtOffsetName: 'UTC-05:00',
        abbreviation: 'EST',
        tzName: 'Eastern Standard Time (North America',
      },
      {
        zoneName: 'America/Los_Angeles',
        gmtOffset: -28800,
        gmtOffsetName: 'UTC-08:00',
        abbreviation: 'PST',
        tzName: 'Pacific Standard Time (North America',
      },
      {
        zoneName: 'America/Menominee',
        gmtOffset: -21600,
        gmtOffsetName: 'UTC-06:00',
        abbreviation: 'CST',
        tzName: 'Central Standard Time (North America',
      },
      {
        zoneName: 'America/Metlakatla',
        gmtOffset: -32400,
        gmtOffsetName: 'UTC-09:00',
        abbreviation: 'AKST',
        tzName: 'Alaska Standard Time',
      },
      {
        zoneName: 'America/New_York',
        gmtOffset: -18000,
        gmtOffsetName: 'UTC-05:00',
        abbreviation: 'EST',
        tzName: 'Eastern Standard Time (North America',
      },
      {
        zoneName: 'America/Nome',
        gmtOffset: -32400,
        gmtOffsetName: 'UTC-09:00',
        abbreviation: 'AKST',
        tzName: 'Alaska Standard Time',
      },
      {
        zoneName: 'America/North_Dakota/Beulah',
        gmtOffset: -21600,
        gmtOffsetName: 'UTC-06:00',
        abbreviation: 'CST',
        tzName: 'Central Standard Time (North America',
      },
      {
        zoneName: 'America/North_Dakota/Center',
        gmtOffset: -21600,
        gmtOffsetName: 'UTC-06:00',
        abbreviation: 'CST',
        tzName: 'Central Standard Time (North America',
      },
      {
        zoneName: 'America/North_Dakota/New_Salem',
        gmtOffset: -21600,
        gmtOffsetName: 'UTC-06:00',
        abbreviation: 'CST',
        tzName: 'Central Standard Time (North America',
      },
      {
        zoneName: 'America/Phoenix',
        gmtOffset: -25200,
        gmtOffsetName: 'UTC-07:00',
        abbreviation: 'MST',
        tzName: 'Mountain Standard Time (North America',
      },
      {
        zoneName: 'America/Sitka',
        gmtOffset: -32400,
        gmtOffsetName: 'UTC-09:00',
        abbreviation: 'AKST',
        tzName: 'Alaska Standard Time',
      },
      {
        zoneName: 'America/Yakutat',
        gmtOffset: -32400,
        gmtOffsetName: 'UTC-09:00',
        abbreviation: 'AKST',
        tzName: 'Alaska Standard Time',
      },
      {
        zoneName: 'Pacific/Honolulu',
        gmtOffset: -36000,
        gmtOffsetName: 'UTC-10:00',
        abbreviation: 'HST',
        tzName: 'Hawaii–Aleutian Standard Time',
      },
    ],
    translations: {
      br: 'Estados Unidos',
      pt: 'Estados Unidos',
      nl: 'Verenigde Staten',
      hr: 'Sjedinjene Američke Države',
      fa: 'ایالات متحده آمریکا',
      de: 'Vereinigte Staaten von Amerika',
      es: 'Estados Unidos',
      fr: 'États-Unis',
      ja: 'アメリカ合衆国',
      it: "Stati Uniti D'America",
    },
    latitude: '38.00000000',
    longitude: '-97.00000000',
    emoji: '🇺🇸',
    emojiU: 'U+1F1FA U+1F1F8',
  },
  {
    id: 234,
    name: 'United States Minor Outlying Islands',
    iso3: 'UMI',
    iso2: 'UM',
    phone_code: '1',
    capital: '',
    currency: 'USD',
    currency_symbol: '$',
    tld: '.us',
    native: 'United States Minor Outlying Islands',
    region: 'Americas',
    subregion: 'Northern America',
    timezones: [
      {
        zoneName: 'Pacific/Midway',
        gmtOffset: -39600,
        gmtOffsetName: 'UTC-11:00',
        abbreviation: 'SST',
        tzName: 'Samoa Standard Time',
      },
      {
        zoneName: 'Pacific/Wake',
        gmtOffset: 43200,
        gmtOffsetName: 'UTC+12:00',
        abbreviation: 'WAKT',
        tzName: 'Wake Island Time',
      },
    ],
    translations: {
      br: 'Ilhas Menores Distantes dos Estados Unidos',
      pt: 'Ilhas Menores Distantes dos Estados Unidos',
      nl: 'Kleine afgelegen eilanden van de Verenigde Staten',
      hr: 'Mali udaljeni otoci SAD-a',
      fa: 'جزایر کوچک حاشیه‌ای ایالات متحده آمریکا',
      de: 'Kleinere Inselbesitzungen der Vereinigten Staaten',
      es: 'Islas Ultramarinas Menores de Estados Unidos',
      fr: 'Îles mineures éloignées des États-Unis',
      ja: '合衆国領有小離島',
      it: "Isole minori esterne degli Stati Uniti d'America",
    },
    latitude: '0.00000000',
    longitude: '0.00000000',
    emoji: '🇺🇲',
    emojiU: 'U+1F1FA U+1F1F2',
  },
  {
    id: 235,
    name: 'Uruguay',
    iso3: 'URY',
    iso2: 'UY',
    phone_code: '598',
    capital: 'Montevideo',
    currency: 'UYU',
    currency_symbol: '$',
    tld: '.uy',
    native: 'Uruguay',
    region: 'Americas',
    subregion: 'South America',
    timezones: [
      {
        zoneName: 'America/Montevideo',
        gmtOffset: -10800,
        gmtOffsetName: 'UTC-03:00',
        abbreviation: 'UYT',
        tzName: 'Uruguay Standard Time',
      },
    ],
    translations: {
      br: 'Uruguai',
      pt: 'Uruguai',
      nl: 'Uruguay',
      hr: 'Urugvaj',
      fa: 'اروگوئه',
      de: 'Uruguay',
      es: 'Uruguay',
      fr: 'Uruguay',
      ja: 'ウルグアイ',
      it: 'Uruguay',
    },
    latitude: '-33.00000000',
    longitude: '-56.00000000',
    emoji: '🇺🇾',
    emojiU: 'U+1F1FA U+1F1FE',
  },
  {
    id: 236,
    name: 'Uzbekistan',
    iso3: 'UZB',
    iso2: 'UZ',
    phone_code: '998',
    capital: 'Tashkent',
    currency: 'UZS',
    currency_symbol: 'лв',
    tld: '.uz',
    native: 'O‘zbekiston',
    region: 'Asia',
    subregion: 'Central Asia',
    timezones: [
      {
        zoneName: 'Asia/Samarkand',
        gmtOffset: 18000,
        gmtOffsetName: 'UTC+05:00',
        abbreviation: 'UZT',
        tzName: 'Uzbekistan Time',
      },
      {
        zoneName: 'Asia/Tashkent',
        gmtOffset: 18000,
        gmtOffsetName: 'UTC+05:00',
        abbreviation: 'UZT',
        tzName: 'Uzbekistan Time',
      },
    ],
    translations: {
      br: 'Uzbequistão',
      pt: 'Usbequistão',
      nl: 'Oezbekistan',
      hr: 'Uzbekistan',
      fa: 'ازبکستان',
      de: 'Usbekistan',
      es: 'Uzbekistán',
      fr: 'Ouzbékistan',
      ja: 'ウズベキスタン',
      it: 'Uzbekistan',
    },
    latitude: '41.00000000',
    longitude: '64.00000000',
    emoji: '🇺🇿',
    emojiU: 'U+1F1FA U+1F1FF',
  },
  {
    id: 237,
    name: 'Vanuatu',
    iso3: 'VUT',
    iso2: 'VU',
    phone_code: '678',
    capital: 'Port Vila',
    currency: 'VUV',
    currency_symbol: 'VT',
    tld: '.vu',
    native: 'Vanuatu',
    region: 'Oceania',
    subregion: 'Melanesia',
    timezones: [
      {
        zoneName: 'Pacific/Efate',
        gmtOffset: 39600,
        gmtOffsetName: 'UTC+11:00',
        abbreviation: 'VUT',
        tzName: 'Vanuatu Time',
      },
    ],
    translations: {
      br: 'Vanuatu',
      pt: 'Vanuatu',
      nl: 'Vanuatu',
      hr: 'Vanuatu',
      fa: 'وانواتو',
      de: 'Vanuatu',
      es: 'Vanuatu',
      fr: 'Vanuatu',
      ja: 'バヌアツ',
      it: 'Vanuatu',
    },
    latitude: '-16.00000000',
    longitude: '167.00000000',
    emoji: '🇻🇺',
    emojiU: 'U+1F1FB U+1F1FA',
  },
  {
    id: 238,
    name: 'Vatican City State (Holy See)',
    iso3: 'VAT',
    iso2: 'VA',
    phone_code: '379',
    capital: 'Vatican City',
    currency: 'EUR',
    currency_symbol: '€',
    tld: '.va',
    native: 'Vaticano',
    region: 'Europe',
    subregion: 'Southern Europe',
    timezones: [
      {
        zoneName: 'Europe/Vatican',
        gmtOffset: 3600,
        gmtOffsetName: 'UTC+01:00',
        abbreviation: 'CET',
        tzName: 'Central European Time',
      },
    ],
    translations: {
      br: 'Vaticano',
      pt: 'Vaticano',
      nl: 'Heilige Stoel',
      hr: 'Sveta Stolica',
      fa: 'سریر مقدس',
      de: 'Heiliger Stuhl',
      es: 'Santa Sede',
      fr: 'voir Saint',
      ja: '聖座',
      it: 'Santa Sede',
    },
    latitude: '41.90000000',
    longitude: '12.45000000',
    emoji: '🇻🇦',
    emojiU: 'U+1F1FB U+1F1E6',
  },
  {
    id: 239,
    name: 'Venezuela',
    iso3: 'VEN',
    iso2: 'VE',
    phone_code: '58',
    capital: 'Caracas',
    currency: 'VEF',
    currency_symbol: 'Bs',
    tld: '.ve',
    native: 'Venezuela',
    region: 'Americas',
    subregion: 'South America',
    timezones: [
      {
        zoneName: 'America/Caracas',
        gmtOffset: -14400,
        gmtOffsetName: 'UTC-04:00',
        abbreviation: 'VET',
        tzName: 'Venezuelan Standard Time',
      },
    ],
    translations: {
      br: 'Venezuela',
      pt: 'Venezuela',
      nl: 'Venezuela',
      hr: 'Venezuela',
      fa: 'ونزوئلا',
      de: 'Venezuela',
      es: 'Venezuela',
      fr: 'Venezuela',
      ja: 'ベネズエラ・ボリバル共和国',
      it: 'Venezuela',
    },
    latitude: '8.00000000',
    longitude: '-66.00000000',
    emoji: '🇻🇪',
    emojiU: 'U+1F1FB U+1F1EA',
  },
  {
    id: 240,
    name: 'Vietnam',
    iso3: 'VNM',
    iso2: 'VN',
    phone_code: '84',
    capital: 'Hanoi',
    currency: 'VND',
    currency_symbol: '₫',
    tld: '.vn',
    native: 'Việt Nam',
    region: 'Asia',
    subregion: 'South-Eastern Asia',
    timezones: [
      {
        zoneName: 'Asia/Ho_Chi_Minh',
        gmtOffset: 25200,
        gmtOffsetName: 'UTC+07:00',
        abbreviation: 'ICT',
        tzName: 'Indochina Time',
      },
    ],
    translations: {
      br: 'Vietnã',
      pt: 'Vietname',
      nl: 'Vietnam',
      hr: 'Vijetnam',
      fa: 'ویتنام',
      de: 'Vietnam',
      es: 'Vietnam',
      fr: 'Viêt Nam',
      ja: 'ベトナム',
      it: 'Vietnam',
    },
    latitude: '16.16666666',
    longitude: '107.83333333',
    emoji: '🇻🇳',
    emojiU: 'U+1F1FB U+1F1F3',
  },
  {
    id: 241,
    name: 'Virgin Islands (British)',
    iso3: 'VGB',
    iso2: 'VG',
    phone_code: '+1-284',
    capital: 'Road Town',
    currency: 'USD',
    currency_symbol: '$',
    tld: '.vg',
    native: 'British Virgin Islands',
    region: 'Americas',
    subregion: 'Caribbean',
    timezones: [
      {
        zoneName: 'America/Tortola',
        gmtOffset: -14400,
        gmtOffsetName: 'UTC-04:00',
        abbreviation: 'AST',
        tzName: 'Atlantic Standard Time',
      },
    ],
    translations: {
      br: 'Ilhas Virgens Britânicas',
      pt: 'Ilhas Virgens Britânicas',
      nl: 'Britse Maagdeneilanden',
      hr: 'Britanski Djevičanski Otoci',
      fa: 'جزایر ویرجین بریتانیا',
      de: 'Britische Jungferninseln',
      es: 'Islas Vírgenes del Reino Unido',
      fr: 'Îles Vierges britanniques',
      ja: 'イギリス領ヴァージン諸島',
      it: 'Isole Vergini Britanniche',
    },
    latitude: '18.43138300',
    longitude: '-64.62305000',
    emoji: '🇻🇬',
    emojiU: 'U+1F1FB U+1F1EC',
  },
  {
    id: 242,
    name: 'Virgin Islands (US)',
    iso3: 'VIR',
    iso2: 'VI',
    phone_code: '+1-340',
    capital: 'Charlotte Amalie',
    currency: 'USD',
    currency_symbol: '$',
    tld: '.vi',
    native: 'United States Virgin Islands',
    region: 'Americas',
    subregion: 'Caribbean',
    timezones: [
      {
        zoneName: 'America/St_Thomas',
        gmtOffset: -14400,
        gmtOffsetName: 'UTC-04:00',
        abbreviation: 'AST',
        tzName: 'Atlantic Standard Time',
      },
    ],
    translations: {
      br: 'Ilhas Virgens Americanas',
      pt: 'Ilhas Virgens Americanas',
      nl: 'Verenigde Staten Maagdeneilanden',
      fa: 'جزایر ویرجین آمریکا',
      de: 'Amerikanische Jungferninseln',
      es: 'Islas Vírgenes de los Estados Unidos',
      fr: 'Îles Vierges des États-Unis',
      ja: 'アメリカ領ヴァージン諸島',
      it: 'Isole Vergini americane',
    },
    latitude: '18.34000000',
    longitude: '-64.93000000',
    emoji: '🇻🇮',
    emojiU: 'U+1F1FB U+1F1EE',
  },
  {
    id: 243,
    name: 'Wallis And Futuna Islands',
    iso3: 'WLF',
    iso2: 'WF',
    phone_code: '681',
    capital: 'Mata Utu',
    currency: 'XPF',
    currency_symbol: '₣',
    tld: '.wf',
    native: 'Wallis et Futuna',
    region: 'Oceania',
    subregion: 'Polynesia',
    timezones: [
      {
        zoneName: 'Pacific/Wallis',
        gmtOffset: 43200,
        gmtOffsetName: 'UTC+12:00',
        abbreviation: 'WFT',
        tzName: 'Wallis & Futuna Time',
      },
    ],
    translations: {
      br: 'Wallis e Futuna',
      pt: 'Wallis e Futuna',
      nl: 'Wallis en Futuna',
      hr: 'Wallis i Fortuna',
      fa: 'والیس و فوتونا',
      de: 'Wallis und Futuna',
      es: 'Wallis y Futuna',
      fr: 'Wallis-et-Futuna',
      ja: 'ウォリス・フツナ',
      it: 'Wallis e Futuna',
    },
    latitude: '-13.30000000',
    longitude: '-176.20000000',
    emoji: '🇼🇫',
    emojiU: 'U+1F1FC U+1F1EB',
  },
  {
    id: 244,
    name: 'Western Sahara',
    iso3: 'ESH',
    iso2: 'EH',
    phone_code: '212',
    capital: 'El-Aaiun',
    currency: 'MAD',
    currency_symbol: 'MAD',
    tld: '.eh',
    native: 'الصحراء الغربية',
    region: 'Africa',
    subregion: 'Northern Africa',
    timezones: [
      {
        zoneName: 'Africa/El_Aaiun',
        gmtOffset: 3600,
        gmtOffsetName: 'UTC+01:00',
        abbreviation: 'WEST',
        tzName: 'Western European Summer Time',
      },
    ],
    translations: {
      br: 'Saara Ocidental',
      pt: 'Saara Ocidental',
      nl: 'Westelijke Sahara',
      hr: 'Zapadna Sahara',
      fa: 'جمهوری دموکراتیک عربی صحرا',
      de: 'Westsahara',
      es: 'Sahara Occidental',
      fr: 'Sahara Occidental',
      ja: '西サハラ',
      it: 'Sahara Occidentale',
    },
    latitude: '24.50000000',
    longitude: '-13.00000000',
    emoji: '🇪🇭',
    emojiU: 'U+1F1EA U+1F1ED',
  },
  {
    id: 245,
    name: 'Yemen',
    iso3: 'YEM',
    iso2: 'YE',
    phone_code: '967',
    capital: 'Sanaa',
    currency: 'YER',
    currency_symbol: '﷼',
    tld: '.ye',
    native: 'اليَمَن',
    region: 'Asia',
    subregion: 'Western Asia',
    timezones: [
      {
        zoneName: 'Asia/Aden',
        gmtOffset: 10800,
        gmtOffsetName: 'UTC+03:00',
        abbreviation: 'AST',
        tzName: 'Arabia Standard Time',
      },
    ],
    translations: {
      br: 'Iêmen',
      pt: 'Iémen',
      nl: 'Jemen',
      hr: 'Jemen',
      fa: 'یمن',
      de: 'Jemen',
      es: 'Yemen',
      fr: 'Yémen',
      ja: 'イエメン',
      it: 'Yemen',
    },
    latitude: '15.00000000',
    longitude: '48.00000000',
    emoji: '🇾🇪',
    emojiU: 'U+1F1FE U+1F1EA',
  },
  {
    id: 246,
    name: 'Zambia',
    iso3: 'ZMB',
    iso2: 'ZM',
    phone_code: '260',
    capital: 'Lusaka',
    currency: 'ZMW',
    currency_symbol: 'ZK',
    tld: '.zm',
    native: 'Zambia',
    region: 'Africa',
    subregion: 'Eastern Africa',
    timezones: [
      {
        zoneName: 'Africa/Lusaka',
        gmtOffset: 7200,
        gmtOffsetName: 'UTC+02:00',
        abbreviation: 'CAT',
        tzName: 'Central Africa Time',
      },
    ],
    translations: {
      br: 'Zâmbia',
      pt: 'Zâmbia',
      nl: 'Zambia',
      hr: 'Zambija',
      fa: 'زامبیا',
      de: 'Sambia',
      es: 'Zambia',
      fr: 'Zambie',
      ja: 'ザンビア',
      it: 'Zambia',
    },
    latitude: '-15.00000000',
    longitude: '30.00000000',
    emoji: '🇿🇲',
    emojiU: 'U+1F1FF U+1F1F2',
  },
  {
    id: 247,
    name: 'Zimbabwe',
    iso3: 'ZWE',
    iso2: 'ZW',
    phone_code: '263',
    capital: 'Harare',
    currency: 'ZWL',
    currency_symbol: '$',
    tld: '.zw',
    native: 'Zimbabwe',
    region: 'Africa',
    subregion: 'Eastern Africa',
    timezones: [
      {
        zoneName: 'Africa/Harare',
        gmtOffset: 7200,
        gmtOffsetName: 'UTC+02:00',
        abbreviation: 'CAT',
        tzName: 'Central Africa Time',
      },
    ],
    translations: {
      br: 'Zimbabwe',
      pt: 'Zimbabué',
      nl: 'Zimbabwe',
      hr: 'Zimbabve',
      fa: 'زیمباوه',
      de: 'Simbabwe',
      es: 'Zimbabue',
      fr: 'Zimbabwe',
      ja: 'ジンバブエ',
      it: 'Zimbabwe',
    },
    latitude: '-20.00000000',
    longitude: '30.00000000',
    emoji: '🇿🇼',
    emojiU: 'U+1F1FF U+1F1FC',
  },
];

export default countiresList;
