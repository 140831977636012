import { Skeleton } from '@material-ui/lab';
import { string } from 'prop-types';
import React from 'react';
import { getImageUrl, getProtectedImage } from 'src/utils/helper';

const AsyncImage = (props) => {
  const { url, ...rest } = props;
  const [loadedSrc, setLoadedSrc] = React.useState(null);
  React.useEffect(() => {
    setLoadedSrc(null);
    const getImage = async () => {
      try {
        const imageUrl = await getProtectedImage(getImageUrl(url));
        setLoadedSrc(imageUrl);
      } catch (error) {
        console.error(error);
        setLoadedSrc(null);
      }
    };
    if (url) {
      getImage();
    }
  }, [url]);
  if (loadedSrc) {
    return <img src={loadedSrc} {...rest} alt="story" />;
  }
  return <Skeleton variant="rect" width={200} height={200} />;
};

AsyncImage.propTypes = {
  url: string,
};
export default AsyncImage;
