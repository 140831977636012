import {
  Avatar,
  Box,
  Button,
  Container,
  FormControl,
  TextField,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  LinearProgress,
  makeStyles,
  MenuItem,
} from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import { Field, Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Page from 'src/components/Page';
import adminService from 'src/services/adminService';
import { consoleError, getImageUrl } from 'src/utils/helper';
import * as yup from 'yup';
// import { TextField, Switch } from 'formik-material-ui';
import FormPreviewDev from 'src/components/common/FormPreviewDev';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import CustomFileUpload from 'src/components/CustomFileUpload';
import Select from '@material-ui/core/Select';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    // height: 'auto',
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(1),
  },
  switchField: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 300,
  },
}));

function AddData({
  data = null,
  field = null,
  functionName = null,
  userId = null,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [update, setUpdate] = useState(false);

  useEffect(() => {
    if (data) {
      setUpdate(true);
    }
  }, [data]);

  const [updatedData, setUpdatedData] = useState(data ? data.data : '');
  const [NewData, setNewData] = useState(field ? field : '');

  const handleNewData = (event) => {
    const { name, value } = event.target;
    setNewData((prevValue) => {
      return {
        ...prevValue,
        [name]: value,
      };
    });
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setUpdatedData((prevValue) => {
      return {
        ...prevValue,
        [name]: value,
      };
    });
  };

  const updateHandler = (e) => {
    e.preventDefault();
    console.log(updatedData);
    console.log(userId);
  };
  const addNewHandler = (e) => {
    e.preventDefault();
    console.log(NewData);
    console.log(userId);
  };

  return (
    <Page
      className={classes.root}
      title={update ? 'Update Data' : 'Add New Data'}
    >
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="sm">
          {/* {data ? console.log(data) : 'Add new Data'} */}
          {data ? (
            update && data.functionName === 'adminUpdateStory' ? (
              <>
                <TextField
                  fullWidth
                  id="outlined-select-currency-native"
                  select
                  label="Privacy"
                  name="privacy"
                  value={updatedData.privacy}
                  onChange={handleChange}
                  SelectProps={{
                    native: true,
                  }}
                  variant="outlined"
                >
                  <option aria-label="None" value="" />
                  <option value="private">Private</option>
                  <option value="public">Public</option>
                </TextField>
                <InputLabel>Image</InputLabel>
                <FormControl className={classes.formControl}>
                  <TextField
                    name="avatar"
                    value=""
                    id="outlined-basic"
                    type="file"
                  />
                </FormControl>
              </>
            ) : update && data.functionName === 'adminUpdateFriend' ? (
              <FormControl className={classes.formControl}>
                <TextField
                  name="name"
                  value={updatedData.name}
                  onChange={handleChange}
                  id="outlined-basic"
                  label="Name"
                  variant="outlined"
                  style={{ marginTop: '10px' }}
                />
                <TextField
                  name="phone"
                  value={updatedData.phone}
                  onChange={handleChange}
                  id="outlined-basic"
                  label="Phone"
                  variant="outlined"
                  style={{ marginTop: '10px' }}
                />
                <TextField
                  name="address"
                  value={updatedData.address}
                  onChange={handleChange}
                  id="outlined-basic"
                  label="Address"
                  variant="outlined"
                  style={{ marginTop: '10px' }}
                />
                <TextField
                  name="email"
                  value={updatedData.email}
                  onChange={handleChange}
                  id="outlined-basic"
                  label="Email"
                  variant="outlined"
                  style={{ marginTop: '10px' }}
                />
              </FormControl>
            ) : update && data.functionName === 'adminUpdateFriendRequest' ? (
              <FormControl className={classes.formControl}>
                <TextField
                  name="name"
                  value={updatedData.name}
                  onChange={handleChange}
                  id="outlined-basic"
                  label="Name"
                  variant="outlined"
                  style={{ marginTop: '10px' }}
                />
                <TextField
                  name="phone"
                  value={updatedData.phone}
                  onChange={handleChange}
                  id="outlined-basic"
                  label="Phone"
                  variant="outlined"
                  style={{ marginTop: '10px' }}
                />
                <TextField
                  name="address"
                  value={updatedData.address}
                  onChange={handleChange}
                  id="outlined-basic"
                  label="Address"
                  variant="outlined"
                  style={{ marginTop: '10px' }}
                />
                <TextField
                  name="email"
                  value={updatedData.email}
                  onChange={handleChange}
                  id="outlined-basic"
                  label="Email"
                  variant="outlined"
                  style={{ marginTop: '10px' }}
                />
              </FormControl>
            ) : update && data.functionName === 'adminUpdateSendRequest' ? (
              <FormControl className={classes.formControl}>
                <TextField
                  name="name"
                  value={updatedData.name}
                  onChange={handleChange}
                  id="outlined-basic"
                  label="Name"
                  variant="outlined"
                  style={{ marginTop: '10px' }}
                />
                <TextField
                  name="phone"
                  value={updatedData.phone}
                  onChange={handleChange}
                  id="outlined-basic"
                  label="Phone"
                  variant="outlined"
                  style={{ marginTop: '10px' }}
                />
                <TextField
                  name="address"
                  value={updatedData.address}
                  onChange={handleChange}
                  id="outlined-basic"
                  label="Address"
                  variant="outlined"
                  style={{ marginTop: '10px' }}
                />
                <TextField
                  name="email"
                  value={updatedData.email}
                  onChange={handleChange}
                  id="outlined-basic"
                  label="Email"
                  variant="outlined"
                  style={{ marginTop: '10px' }}
                />
              </FormControl>
            ) : update && data.functionName === 'adminUpdateSubscription' ? (
              <>
                <FormControl className={classes.formControl}>
                  <TextField
                    name="user"
                    value={updatedData.user}
                    onChange={handleChange}
                    id="outlined-basic"
                    label="User"
                    variant="outlined"
                    style={{ marginTop: '10px' }}
                  />
                  {/* <TextField
                    name="package"
                    value={updatedData.package}
                    onChange={handleChange}
                    id="outlined-basic"
                    label="Package"
                    variant="outlined"
                    style={{ marginTop: '10px' }}
                  /> */}
                  <TextField
                    fullWidth
                    id="outlined-select-currency-native"
                    select
                    label="Package"
                    name="package"
                    value={updatedData.package}
                    onChange={handleChange}
                    SelectProps={{
                      native: true,
                    }}
                    variant="outlined"
                    style={{ marginTop: '10px' }}
                  >
                    <option aria-label="None" value="" />
                    <option value="uk">UK</option>
                    <option value="nepal">Nepal</option>
                  </TextField>
                  <TextField
                    name="subscribedOn"
                    value={updatedData.subscribedOn}
                    onChange={handleChange}
                    id="outlined-basic"
                    label="Subscribed On"
                    variant="outlined"
                    style={{ marginTop: '10px' }}
                  />
                </FormControl>
              </>
            ) : update && data.functionName === 'adminUpdateInvitation' ? (
              <FormControl className={classes.formControl}>
                <TextField
                  name="user"
                  value={updatedData.user}
                  onChange={handleChange}
                  id="outlined-basic"
                  label="User"
                  variant="outlined"
                  style={{ marginTop: '10px' }}
                />
                <TextField
                  name="code"
                  value={updatedData.code}
                  onChange={handleChange}
                  id="outlined-basic"
                  label="Code"
                  variant="outlined"
                  style={{ marginTop: '10px' }}
                />
                <TextField
                  name="invitedUser"
                  value={updatedData.invitedUser}
                  onChange={handleChange}
                  id="outlined-basic"
                  label="Invited User"
                  variant="outlined"
                  style={{ marginTop: '10px' }}
                />
                <TextField
                  name="registeredUser"
                  value={updatedData.registeredUser}
                  onChange={handleChange}
                  id="outlined-basic"
                  label="Registered User"
                  variant="outlined"
                  style={{ marginTop: '10px' }}
                />
              </FormControl>
            ) : (
              ''
            )
          ) : (
            ''
          )}
          {!update && functionName === 'adminAddStory' ? (
            <>
              <TextField
                fullWidth
                id="outlined-select-currency-native"
                select
                label="Privacy"
                name="privacy"
                value={NewData.privacy}
                onChange={handleNewData}
                SelectProps={{
                  native: true,
                }}
                variant="outlined"
              >
                <option aria-label="None" value="" />
                <option value="private">Private</option>
                <option value="public">Public</option>
              </TextField>
              <InputLabel>Image</InputLabel>
              <FormControl className={classes.formControl}>
                <TextField
                  name="avatar"
                  value=""
                  id="outlined-basic"
                  type="file"
                />
              </FormControl>
            </>
          ) : !update && functionName === 'adminAddFriend' ? (
            <FormControl className={classes.formControl}>
              <TextField
                name="name"
                value={NewData.name}
                onChange={handleNewData}
                id="outlined-basic"
                label="Name"
                variant="outlined"
                style={{ marginTop: '10px' }}
              />
              <TextField
                name="phone"
                value={NewData.phone}
                onChange={handleNewData}
                id="outlined-basic"
                label="Phone"
                variant="outlined"
                style={{ marginTop: '10px' }}
              />
              <TextField
                name="address"
                value={NewData.address}
                onChange={handleNewData}
                id="outlined-basic"
                label="Address"
                variant="outlined"
                style={{ marginTop: '10px' }}
              />
              <TextField
                name="email"
                value={NewData.email}
                onChange={handleNewData}
                id="outlined-basic"
                label="Email"
                variant="outlined"
                style={{ marginTop: '10px' }}
              />
            </FormControl>
          ) : !update && functionName === 'adminAddFriendRequest' ? (
            <FormControl className={classes.formControl}>
              <TextField
                name="name"
                value={NewData.name}
                onChange={handleNewData}
                id="outlined-basic"
                label="Name"
                variant="outlined"
                style={{ marginTop: '10px' }}
              />
              <TextField
                name="phone"
                value={NewData.phone}
                onChange={handleNewData}
                id="outlined-basic"
                label="Phone"
                variant="outlined"
                style={{ marginTop: '10px' }}
              />
              <TextField
                name="address"
                value={NewData.address}
                onChange={handleNewData}
                id="outlined-basic"
                label="Address"
                variant="outlined"
                style={{ marginTop: '10px' }}
              />
              <TextField
                name="email"
                value={NewData.email}
                onChange={handleNewData}
                id="outlined-basic"
                label="Email"
                variant="outlined"
                style={{ marginTop: '10px' }}
              />
            </FormControl>
          ) : !update && functionName === 'adminAddSendRequest' ? (
            <FormControl className={classes.formControl}>
              <TextField
                name="name"
                value={NewData.name}
                onChange={handleNewData}
                id="outlined-basic"
                label="Name"
                variant="outlined"
                style={{ marginTop: '10px' }}
              />
              <TextField
                name="phone"
                value={NewData.phone}
                onChange={handleNewData}
                id="outlined-basic"
                label="Phone"
                variant="outlined"
                style={{ marginTop: '10px' }}
              />
              <TextField
                name="address"
                value={NewData.address}
                onChange={handleNewData}
                id="outlined-basic"
                label="Address"
                variant="outlined"
                style={{ marginTop: '10px' }}
              />
              <TextField
                name="email"
                value={NewData.email}
                onChange={handleNewData}
                id="outlined-basic"
                label="Email"
                variant="outlined"
                style={{ marginTop: '10px' }}
              />
            </FormControl>
          ) : !update && functionName === 'adminAddSubscription' ? (
            <>
              <FormControl className={classes.formControl}>
                <TextField
                  name="user"
                  value={NewData.user}
                  onChange={handleNewData}
                  id="outlined-basic"
                  label="User"
                  variant="outlined"
                  style={{ marginTop: '10px' }}
                />
                {/* <TextField
                  name="package"
                  value={NewData.package}
                  onChange={handleNewData}
                  id="outlined-basic"
                  label="Package"
                  variant="outlined"
                  style={{ marginTop: '10px' }}
                /> */}
                <TextField
                  fullWidth
                  id="outlined-select-currency-native"
                  select
                  label="Package"
                  name="package"
                  value={NewData.package}
                  onChange={handleNewData}
                  SelectProps={{
                    native: true,
                  }}
                  variant="outlined"
                  style={{ marginTop: '10px' }}
                >
                  <option aria-label="None" value="" />
                  <option value="uk">UK</option>
                  <option value="nepal">Nepal</option>
                </TextField>
                <TextField
                  name="subscribedOn"
                  value={NewData.subscribedOn}
                  onChange={handleNewData}
                  id="outlined-basic"
                  label="Subscribed On"
                  variant="outlined"
                  style={{ marginTop: '10px' }}
                />
              </FormControl>
            </>
          ) : !update && functionName === 'adminAddInvitation' ? (
            <FormControl className={classes.formControl}>
              <TextField
                name="user"
                value={NewData.user}
                onChange={handleNewData}
                id="outlined-basic"
                label="User"
                variant="outlined"
                style={{ marginTop: '10px' }}
              />
              <TextField
                name="code"
                value={NewData.code}
                onChange={handleNewData}
                id="outlined-basic"
                label="Code"
                variant="outlined"
                style={{ marginTop: '10px' }}
              />
              <TextField
                name="invitedUser"
                value={NewData.invitedUser}
                onChange={handleNewData}
                id="outlined-basic"
                label="Invited User"
                variant="outlined"
                style={{ marginTop: '10px' }}
              />
              <TextField
                name="registeredUser"
                value={NewData.registeredUser}
                onChange={handleNewData}
                id="outlined-basic"
                label="Registered User"
                variant="outlined"
                style={{ marginTop: '10px' }}
              />
            </FormControl>
          ) : (
            ''
          )}
        </Container>
      </Box>
      <Box my={2}>
        <Button
          color="primary"
          fullWidth
          size="medium"
          type="submit"
          variant="contained"
          onClick={update ? updateHandler : addNewHandler}
        >
          {update ? 'Update Data' : 'Add Data'}
        </Button>
      </Box>
    </Page>
  );
}

export default AddData;
