const continentList = [
  {
    id: 1,
    name: 'Asia',
    iso3: 'AS',
    iso2: '',
  },
  {
    id: 2,
    name: 'Europe',
    iso3: 'EU',
    iso2: '',
  },
  {
    id: 3,
    name: 'Antarctica',
    iso3: 'AN',
    iso2: '',
  },
  {
    id: 4,
    name: 'South America',
    iso3: 'SA',
    iso2: '',
  },
  {
    id: 5,
    name: 'North America',
    iso3: 'NA',
    iso2: '',
  },
  {
    id: 6,
    name: 'Africa',
    iso3: 'AF',
    iso2: '',
  },
  {
    id: 7,
    name: 'Oceania',
    iso3: 'OC',
    iso2: '',
  },
];

export default continentList;
