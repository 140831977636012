import { isString } from 'lodash';

export default (name = '') => {
  if (isString(name)) {
    return name
      .replace(/\s+/, ' ')
      .split(' ')
      .slice(0, 2)
      .map((v) => v && v[0].toUpperCase())
      .join('');
  }
  return '';
};
