import React, { useEffect } from 'react';
import Page from 'src/components/Page';
import { Link } from 'react-router-dom';
import {
  Box,
  Card,
  CardContent,
  //   CardHeader,
  Container,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  Breadcrumbs,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import adminService from 'src/services/adminService';
import { useParams } from 'react-router';

import AsyncImage from 'src/components/common/AsyncImage';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  mb3: {
    marginBottom: '15px',
  },
  storyImage: {
    maxHeight: '200px',
  },
}));

function PointSystem() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const points = useSelector((state) => state.admin.points);
  const { pointId } = useParams();

  const point = points.find((Item) => Item._id.toString() === pointId);
  useEffect(() => {
    async function getPoints() {
      try {
        adminService.getPoints(dispatch);
      } catch (error) {
        console.error(error);
      }
    }
    getPoints();
  }, []);
  return (
    <Page className={classes.root} title="Ledger">
      <Container maxWidth={false}>
        <Box mt={3}>
          <Typography color="textPrimary" gutterBottom variant="h2">
            Point System
          </Typography>
          <Box mt={2}>
            <Breadcrumbs
              separator={<NavigateNextIcon fontSize="small" />}
              aria-label="breadcrumb"
            >
              <Link color="gray" to="/">
                Dashboard
              </Link>
              <Link color="grey" to="/point-system">
                Point System
              </Link>
              <Typography color="textPrimary">
                {point ? point._id : ''}
              </Typography>
            </Breadcrumbs>
          </Box>
          {point ? (
            <Box>
              <Card>
                <Box display="flex" ml={2} mt={2} alignItems="center">
                  <Typography color="textPrimary" variant="h4">
                    Details{' '}
                  </Typography>
                </Box>
                <CardContent>
                  <Table>
                    <TableBody>
                      <TableRow>
                        <TableCell>
                          <Typography
                            color="textSecondary"
                            gutterBottom
                            variant="h6"
                          >
                            Id
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography
                            color="textPrimary"
                            gutterBottom
                            variant="h6"
                          >
                            {point._id}
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <Typography
                            color="textSecondary"
                            gutterBottom
                            variant="h6"
                          >
                            Name
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography
                            color="textPrimary"
                            gutterBottom
                            variant="h6"
                          >
                            {point.name}
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <Typography
                            color="textSecondary"
                            gutterBottom
                            variant="h6"
                          >
                            Type
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography
                            color="textPrimary"
                            gutterBottom
                            variant="h6"
                          >
                            {point.type}
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <Typography
                            color="textSecondary"
                            gutterBottom
                            variant="h6"
                          >
                            Point
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography
                            color="textPrimary"
                            gutterBottom
                            variant="h6"
                          >
                            {point.point}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </CardContent>
              </Card>
            </Box>
          ) : (
            <Box mt={5} display="flex" justifyContent="center">
              <Typography
                align="center"
                color="textSecondary"
                gutterBottom
                variant="h3"
              >
                Point System Not Available
              </Typography>
            </Box>
          )}
        </Box>
      </Container>
    </Page>
  );
}
export default PointSystem;
