import React, { useState } from 'react';

import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { Formik, Form, Field } from 'formik';
import { TextField } from 'formik-material-ui';
import {
  Box,
  Button,
  Container,
  Typography,
  makeStyles,
  LinearProgress,
} from '@material-ui/core';

import Page from 'src/components/Page';
import userService from 'src/services/userService';
import { Alert, AlertTitle } from '@material-ui/lab';
import { useLocation } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

const LoginView = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const location = useLocation();

  const pathname = location.state?.pathname;

  const [errorMessage, setErrorMessage] = useState('');

  return (
    <Page className={classes.root} title="Login">
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="sm">
          <Formik
            initialValues={{
              email: '',
              password: '',
            }}
            validationSchema={Yup.object().shape({
              email: Yup.string()
                .email('Must be a valid email')
                .max(255)
                .required('Email is required'),
              password: Yup.string()
                .max(255)
                .required('Password is required')
                .min(8, 'Password is minimum 8 characters.'),
            })}
            onSubmit={async (values) => {
              try {
                setErrorMessage('');
                const { email, password } = values;
                let res = await userService.login(
                  { email, password, panel: 'admin' },
                  dispatch,
                );
                // console.log(res);
                if (res && res.ok) {
                  // if (pathname) {
                  //   window.location.replace(pathname);
                  //   return;
                  // }
                  // window.location.replace('/');
                }
              } catch (error) {
                // console.error(error);
                setErrorMessage(
                  error.message || 'Login Error. Please try again.',
                );
              }
            }}
          >
            {({
              isSubmitting,
              // values,
            }) => (
              <Form>
                <Box mb={3}>
                  <Typography align="center" color="textPrimary" variant="h2">
                    Login
                  </Typography>
                </Box>
                <Box mt={3} mb={1}>
                  <Typography
                    align="center"
                    color="textSecondary"
                    variant="body1"
                  >
                    Login with admin credentials only
                  </Typography>
                  {errorMessage && (
                    <Alert severity="error" onClose={() => setErrorMessage('')}>
                      <AlertTitle>Error</AlertTitle>
                      {errorMessage}
                    </Alert>
                  )}
                </Box>
                <Field
                  component={TextField}
                  label="Email Address"
                  margin="normal"
                  name="email"
                  type="email"
                  fullWidth
                  variant="outlined"
                  autoComplete="username"
                />
                <Field
                  component={TextField}
                  fullWidth
                  label="Password"
                  margin="normal"
                  name="password"
                  type="password"
                  variant="outlined"
                  autoComplete="current-password"
                />
                {isSubmitting && <LinearProgress />}
                <Box my={2}>
                  <Button
                    color="primary"
                    disabled={isSubmitting}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    Login
                  </Button>
                </Box>
              </Form>
            )}
          </Formik>
        </Container>
      </Box>
    </Page>
  );
};

export default LoginView;
