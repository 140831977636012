import api from './api';

import {
  getProfile,
  loginUser,
  logoutUser,
  updateProfile,
  changeAvatar,
} from '../store/actions/userActions';

const userService = {
  login: async (data, dispatch) => {
    try {
      const res = await api.post('/api/v1/auth/login', data);
      if (res?.accessToken) {
        localStorage.setItem('accessToken', res.accessToken);
      }
      dispatch(loginUser(res.user));
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  logout: async (dispatch) => {
    try {
      localStorage.removeItem('accessToken');
      dispatch(logoutUser());
      window.location.href = '/login';
      return Promise.resolve();
    } catch (error) {
      localStorage.removeItem('accessToken');
      dispatch(logoutUser());
      console.error(error);
    }
  },
  profile: async (dispatch) => {
    try {
      const res = await api.get('/api/v1/user/profile');
      if (res && res.ok) {
        dispatch(getProfile(res.data));
        return Promise.resolve(res);
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
  updateProfile: async (data, dispatch) => {
    try {
      const res = await api.patch('/api/v1/user/profile', data);
      if (res && res.ok) {
        dispatch(updateProfile(res.data));
        return Promise.resolve(res);
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
  changeAvatar: async (formData, dispatch) => {
    try {
      const res = await api.post('/api/v1/user/profile/avatar', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      if (res && res.ok) {
        dispatch(changeAvatar(res.data));
        return Promise.resolve(res);
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
};

export default userService;
